export class GenerateColors {
  scope = [];

  make() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    if (this.scope.indexOf(color) !== -1) {
      this.make();
    } else {
      this.scope.push(color);

      return color;
    }
  }
}
