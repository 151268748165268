import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItemText, MenuItem, OutlinedInput, Select, Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled, useTheme } from "@mui/material/styles";
import { MenuProps } from "shared/components/Select/CustomizedSelectDeprecated";
import { selectDepartmentsFilter, selectMappedDepartments } from "core/selectors";
import { setDepartmentsFilter } from "features/filters/filtersSlice";
import PropTypes from "prop-types";

const getStyles = (option, optionsList, theme) => {
  const isSelected = optionsList?.length && optionsList?.indexOf(option) !== -1;

  return {
    fontWeight: isSelected ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
    backgroundColor: isSelected ? "#00203E" : "#ffffff",
    color: isSelected ? "#ffffff" : "#000000"
  };
};

// TODO: multiple
const DepartmentSelector = ({ isMultiple = true }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [departments, setDepartments] = useState([]);
  const [open, setOpen] = useState(false);

  const selectedDepartments = useSelector(selectDepartmentsFilter);
  const departmentsList = useSelector(selectMappedDepartments);

  const getSelectedValue = () => {
    if (departments && departments.length) {
      return departments;
    } else {
      if (!isMultiple) {
        return selectedDepartments.length && [selectedDepartments[0]];
      }

      return !selectedDepartments?.length ? [] : selectedDepartments;
    }
  };

  const data = useMemo(() => getSelectedValue(), [selectedDepartments, departments]);

  useEffect(() => {
    setDepartments(getSelectedValue());
  }, [selectedDepartments]);

  const onChange = (event) => {
    const {
      target: { value }
    } = event;

    setDepartments(typeof value === "string" ? value.split(",") : value);
    if (!isMultiple) {
      dispatch(setDepartmentsFilter([value]));
    }
  };

  const onClear = () => {
    setDepartments([]);

    dispatch(setDepartmentsFilter([]));
  };

  const onClose = () => {
    setOpen(false);
    if (departments?.length && isMultiple) {
      setTimeout(() => {
        const copy = [...departments];
        const payload = copy.sort((a, b) => a?.localeCompare(b));

        dispatch(setDepartmentsFilter(payload));
      }, 0);
    }
  };

  const onOpen = () => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-evenly" minWidth="200px">
      <Typography mr={1}>departments</Typography>
      <Select
        multiple={isMultiple}
        displayEmpty
        required
        value={data || []}
        onChange={onChange}
        defaultValue={departmentsList || []}
        fullWidth
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        sx={{ minHeight: "20px" }}
        renderValue={(selected) => {
          if (!departments?.length) return <em>Select</em>;

          return selected?.join(", ");
        }}
        input={<CustomizedOutlineInput label="" size="small" isTableHeaderSelect />}
        MenuProps={MenuProps}
      >
        <MenuItem onMouseDown={onClear}>
          <em>Select</em>
        </MenuItem>

        {departmentsList?.map(({ option, value }) => (
          <MenuItem key={option} value={option} style={getStyles(option, data, theme)}>
            {data?.indexOf(option) > -1 && <CheckIcon sx={{ color: "#fff", paddingRight: "8px" }} fontSize="medium" />}
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const CustomizedOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  background: "#FFF",
  color: "#00203E",
  border: "1px solid #000",
  width: "120px",
  borderRadius: theme.spacing(2.3),
  "& .MuiOutlinedInput-root": {
    "& > fieldset": {
      borderColor: "#6B6C72",
      borderWidth: "1px"
    }
  }
}));

DepartmentSelector.propTypes = {
  isMultiple: PropTypes.bool
};

export default DepartmentSelector;
