import styled from "@mui/material/styles/styled";
import { Badge, Box, Button, Collapse, TextField, Typography } from "@mui/material";

export const GREEN_COLOR = "#108000";

export const WeekPlanContainer = styled(Box)(({ theme, expanded }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(0, 0.5),
  background: expanded ? "#EDF0F4" : "inherit"
}));

export const Day = styled(Box)(({ theme, selected }) => ({
  display: "flex",
  justifyContent: "center",
  background: selected ? "#00203E" : "inherit",
  borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  minWidth: theme.spacing(14),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    minWidth: theme.spacing(10)
  }
}));

export const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));

export const Room = styled(Box)(({ theme, color }) => ({
  color: color,
  marginTop: theme.spacing(1)
}));

export const CustomizedCollapse = styled(Collapse)(({ theme, isUserWeekPlan, containerHeight }) => ({
  width: "99% !important",
  background: "#EDF0F4",
  borderRadius: theme.spacing(0.5),
  position: "absolute",
  top: `${containerHeight}px`,
  left: theme.spacing(0.5),
  zIndex: 10,

  "&.MuiCollapse-root": {
    transition: "none !important"
  },

  "& .MuiCollapse-wrapperInner": {
    width: "100%"
  }
}));

export const CustomizedBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    right: "-8px"
  }
});

export const RemoveButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 3),
  margin: theme.spacing(0.5, 0),
  width: "100%",

  "&:hover": {
    background: "rgba(255,3,3,0.04)"
  }
}));

export const CustomizedTextField = styled(TextField)({
  color: "#00203E",
  background: "#FFFFFF",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#8D9096"
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#f8fafe"
  }
});

export const CustomIcon = styled("img")(({ disabled }) => ({
  opacity: disabled && "0.5"
}));

export const AutocompleteItem = styled("li")(({ theme, selected }) => ({
  "&.MuiAutocomplete-option": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: selected && "#00203E !important",
    padding: theme.spacing(1, 2, 1, selected ? 2 : 4.25)
  }
}));

export const AutocompleteItemIcons = styled("img")(({ theme, selected }) => ({
  display: "block",
  width: theme.spacing(1.5),
  height: theme.spacing(1.25),
  marginRight: theme.spacing(0.75)
}));

export const AutocompleteItemText = styled(Typography)(({ selected }) => ({
  color: selected ? "#FFFFFF" : "#00203E"
}));
