import React, { useEffect, useState } from "react";
import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Search from "shared/components/Search/Search";
import Filter from "shared/components/Filters/Filter/Filter";
import CardContainer from "shared/components/CardContainer/CardContainer";
import AvatarInitials from "shared/components/AvatarInitials/AvatarInitials";
import CustomizedDivider from "shared/components/CustomizedDivider/CustomizedDivider";
import styled from "@mui/material/styles/styled";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Overlap from "shared/components/individualInsightsTabs/Overlap/Overlap";
import Flexibility from "shared/components/individualInsightsTabs/Flexibility/Flexibility";
import LifeInteractions from "shared/components/individualInsightsTabs/LifeInteractions/LifeInteractions";
import Score from "shared/components/Score/Score";
import OnboardingChart from "shared/components/Charts/OnboardingChart/OnboardingChart";
import CoworkersOverlapChart from "shared/components/Charts/CoworkersOverlapChart/CoworkersOverlapChart";
import ManagerOverlapChart from "shared/components/Charts/ManagerOverlapChart/ManagerOverlapChart";
import OrganizationConnectionsChart from "shared/components/Charts/OrganizationConnectionsChart/OrganizationConnectionsChart";
import SiteAndBuilding from "shared/components/Filters/SiteAndBulding/SiteAndBulding";
import SiteAndBuildingPage from "shared/components/DataTable/components/SiteAndBuildingSidebar/SiteAndBuildingSidebar";
import { AUTO_PLAY_INTERVAL, ROUTE_INDIVIDUAL, STORAGE_SELECTED_TEAM_MEMBER } from "shared/constants";
import TeamMemberDialog from "shared/components/Dialogs/TeamMemberDialog/TeamMemberDialog";
import { selectMappedTeamMembersList, selectSelectedTeamMember, selectTeamUsersList } from "core/selectors";
import SiteIcon from "shared/assets/icons/web.svg";
import BuildingIcon from "shared/assets/icons/building.svg";
import DepartmentIcon from "shared/assets/icons/department.svg";
import UserIcon from "shared/assets/icons/user.svg";
import CalendarIcon from "shared/assets/icons/calendar.svg";
import ProfessionIcon from "shared/assets/icons/proffesion.svg";
import { getDataEntityItemById, getFullName } from "shared/lib/getters";
import TeamMemberSelector from "shared/components/Filters/TeamMember/TeamMember";
import { setSelectedUser } from "features/teamUsers/teamUsersSlice";

const steps = [Overlap, Flexibility, LifeInteractions];

const IndividualPage = () => {
  const [showSiteSidebar, setShowSiteSidebar] = useState(false);
  const [showTeamMemberDialog, setShowTeamMemberDialog] = useState(false);
  const [user, setUser] = useState([]);
  const teamMembersList = useSelector(selectTeamUsersList);
  const selectedTeamMember = useSelector(selectSelectedTeamMember);
  const mappedTeamMembersList = useSelector(selectMappedTeamMembersList);

  const { userId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const currentUser = getDataEntityItemById(teamMembersList, userId);

  const { firstName, lastName, image, phone, email, site, building, department, manager, profession, officeDays } =
    currentUser;

  useEffect(() => {
    if (!selectedTeamMember) {
      setShowTeamMemberDialog(true);
    } else {
      setShowTeamMemberDialog(false);
    }
  }, [selectedTeamMember]);

  useEffect(() => {
    if (user.length) {
      localStorage.setItem(STORAGE_SELECTED_TEAM_MEMBER, user[0]);
      dispatch(setSelectedUser(user[0]));
      history.replace(`/${ROUTE_INDIVIDUAL}/${user[0]}`);
    }
  }, [user]);

  const handleChangeUser = (event) => {
    const {
      target: { value }
    } = event;
    setUser(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box>
      <Typography variant="h4" fontWeight="700">
        Individual insights - {getFullName(firstName, lastName)}
      </Typography>
      <Box mb={2}>
        <Grid container display="flex" alignItems="flex-start" spacing={2} my={1}>
          <Grid item>
            <SiteAndBuilding onClick={() => setShowSiteSidebar(true)} title="Site and Team" />
          </Grid>
          <Grid item>
            <TeamMemberSelector
              onChange={handleChangeUser}
              values={mappedTeamMembersList}
              selectedValue={[selectedTeamMember] || user}
            />
          </Grid>
          <Grid item>
            <Filter onClick={() => {}} />
          </Grid>
          <Grid item>
            <Search placeholder="Enter name to search" />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Container>
        <Box style={{ gridColumn: "1 / 3", gridRow: 1 }}>
          <InsightCardContainer p={2}>
            <Grid container spacing={2} mb={2}>
              <GridItem item xs={3}>
                {image ? (
                  <Avatar src={image} alt="User Image" sx={{ width: 80, height: 80 }} />
                ) : (
                  <AvatarInitials firstName={firstName} lastName={lastName} />
                )}
              </GridItem>
              <GridItem item xs={9} display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="h5" component="p" fontWeight="700">
                  {getFullName(firstName, lastName)}
                </Typography>
                <Box display="flex">
                  <Typography pr={3} color="#6B6C72">
                    {phone}
                  </Typography>
                  <Typography color="#6B6C72">{email}</Typography>
                </Box>
              </GridItem>
            </Grid>
            <Divider />
            <Box mt={2} mb={4}>
              <Grid container display="flex" justifyContent="flex-start" mt={1}>
                <GridItem item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Icon src={SiteIcon} />
                    <Typography>{site}</Typography>
                  </Box>
                  <CustomizedDivider />
                </GridItem>
                <GridItem item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Icon src={BuildingIcon} />
                    <Typography>{building}</Typography>
                  </Box>
                  <CustomizedDivider />
                </GridItem>
              </Grid>
              <Grid container display="flex" justifyContent="flex-start" mt={1}>
                <GridItem item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Icon src={DepartmentIcon} />
                    <Typography>{department}</Typography>
                  </Box>
                  <CustomizedDivider />
                </GridItem>
                <GridItem item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Icon src={UserIcon} />
                    <Typography>{manager}</Typography>
                  </Box>
                  <CustomizedDivider />
                </GridItem>
              </Grid>
              <Grid container display="flex" justifyContent="flex-start" mt={1}>
                <GridItem item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Icon src={ProfessionIcon} />
                    <Typography>{profession}</Typography>
                  </Box>
                </GridItem>
                <GridItem item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Icon src={CalendarIcon} />
                    <Typography>{officeDays} office days per week</Typography>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </InsightCardContainer>
        </Box>
        <Box style={{ gridColumn: "3 / 5", gridRow: 1 }}>
          <CardContainer styles={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Typography variant="h5" fontWeight="700">
              Individual insights
            </Typography>
            <Carousel interval={AUTO_PLAY_INTERVAL} animation="slide" height="345px">
              {steps.map((item, index) => {
                const Tab = item;
                return (
                  <React.Fragment key={index}>
                    <Tab />
                  </React.Fragment>
                );
              })}
            </Carousel>
          </CardContainer>
        </Box>
        <Box style={{ gridColumn: "5 / 7", gridRow: 1 }}>
          <CardContainer styles={{ height: "100%" }}>
            <Box display="flex" alignItems="center">
              <Score score={9.1} variant="h4" />
              <Typography variant="h5" fontWeight="700" pl={2}>
                Onboarding
              </Typography>
            </Box>
            <OnboardingChart />
          </CardContainer>
        </Box>
        <Box style={{ gridColumn: "1 / 3", gridRow: 2 }}>
          <CardContainer styles={{ height: "100%" }}>
            <Box display="flex" alignItems="center">
              <Score score={6.1} variant="h4" />
              <Typography variant="h5" fontWeight="700" pl={2}>
                Overlap with coworkers
              </Typography>
            </Box>
            <Box>
              <CoworkersOverlapChart />
            </Box>
          </CardContainer>
        </Box>
        <Box style={{ gridColumn: "3 / 5", gridRow: 2 }}>
          <CardContainer styles={{ height: "100%" }}>
            <Box display="flex" alignItems="center">
              <Score score={8.1} variant="h4" />
              <Typography variant="h5" fontWeight="700" pl={2}>
                Overlap with manager
              </Typography>
            </Box>
            <Box>
              <ManagerOverlapChart />
            </Box>
          </CardContainer>
        </Box>
        <Box style={{ gridColumn: "5 / 7", gridRow: 2 }}>
          <CardContainer styles={{ height: "100%" }}>
            <Box display="flex" alignItems="center">
              <Score score="8.0" variant="h4" />
              <Typography variant="h5" fontWeight="700" pl={2}>
                Organization connections
              </Typography>
            </Box>
            <Box>
              <OrganizationConnectionsChart />
            </Box>
          </CardContainer>
        </Box>
      </Container>
      <SiteAndBuildingPage
        isDrawer
        isOpen={showSiteSidebar}
        setIsOpen={setShowSiteSidebar}
        title="Site and Team"
        showTeam
      />
      <TeamMemberDialog
        setIsOpen={setShowTeamMemberDialog}
        isOpen={showTeamMemberDialog}
        teamMembersList={teamMembersList}
      />
    </Box>
  );
};

const Container = styled("div")`
  display: grid;
  row-gap: 24px;
  column-gap: 24px;
  grid-template-columns: repeat(6, 1fr);
`;

const InsightCardContainer = styled(Box)(({ theme }) => ({
  background: "#fff",
  borderRadius: theme.spacing(1),
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
  overflow: "hidden",
  height: "100%",
  minHeight: "700px",

  [theme.breakpoints.up("lg")]: {
    minHeight: "340px"
  },

  [theme.breakpoints.up("xl")]: {
    minHeight: "340px"
  },

  [theme.breakpoints.up("xxl")]: {
    minHeight: "340px"
  },

  [theme.breakpoints.up("xxxl")]: {
    minHeight: "340px"
  }
}));

const GridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xxxl")]: {
    padding: theme.spacing(1.5, 1)
  }
}));

const Icon = styled("img")(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  marginRight: theme.spacing(1)
}));

export default IndividualPage;
