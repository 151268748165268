import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Grid, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import Sidebar from "../Sidebar/Sidebar";
import Score from "../Score/Score";
import {
  AVERAGE_DAYS_PER_WEEK,
  GENDER_INCLUSION,
  ORGANIZATION_CONNECTIONS,
  OVERLAP_WITH_MANAGER,
  ROUTE_INDIVIDUAL,
  ROUTE_TEAM,
  SUSTAINABILITY,
  TEAM_MEMBER_OVERLAP
} from "shared/constants";
import GenderInclusionTable from "./GenderInclusionTable/GenderInclusionTable";
import TeamMemberOverlap from "./TeamMemberOverlap/TeamMemberOverlap";
import AverageDaysCharts from "./AverageDaysCharts/AverageDaysCharts";
import Connections from "./Connections/Connections";
import SustainabilityTable from "./SustainabilityTable/SustainabilityTable";
import CustomizedTooltip from "../CustomizedTooltip/CustomizedTooltip";
import { selectTeamUsersList } from "core/selectors";
import { setSelectedUser } from "features/teamUsers/teamUsersSlice";
import { convertStringToKebabCase } from "shared/lib";
import GroupIcon from "shared/assets/icons/group.png";
import GenderIcon from "shared/assets/icons/gender.png";
import OverlapIcon from "shared/assets/icons/overlap.png";
import ConnectionsIcon from "shared/assets/icons/connections.png";
import CalendarIcon from "shared/assets/icons/hr-calendar.svg";
import TreeIcon from "shared/assets/icons/tree.svg";

const teamIndicatorsData = [
  { score: "9.1", icon: GroupIcon, label: OVERLAP_WITH_MANAGER },
  { score: "8.1", icon: GenderIcon, label: GENDER_INCLUSION },
  { score: "7.4", icon: OverlapIcon, label: TEAM_MEMBER_OVERLAP },
  { score: "8.4", icon: ConnectionsIcon, label: ORGANIZATION_CONNECTIONS },
  { score: "2.3", icon: CalendarIcon, label: AVERAGE_DAYS_PER_WEEK },
  { score: "8.6", icon: TreeIcon, label: SUSTAINABILITY }
];

const lowestTeamMemberOverlap = [
  { firstName: "Ella", lastName: "Fitzgerald", teamMemberOverlap: 7.8 },
  { firstName: "Chet", lastName: "Baker", teamMemberOverlap: 7.1 },
  { firstName: "John", lastName: "Coletrane", teamMemberOverlap: 5.1 }
];

const getTooltipText = (sidebarName) => {
  let tooltipTitle = "";
  let tooltipTextItems = [];

  if (sidebarName === OVERLAP_WITH_MANAGER) {
    tooltipTitle = "Weekly team overlay";
    tooltipTextItems = [
      "This score shows how many days each team member was able to spend in the office with his or her manager.",
      "A high score means the team members had many overlapping office days with their manager, improving engagement and retention."
    ];
  }

  if (sidebarName === GENDER_INCLUSION) {
    tooltipTitle = "Gender inclusion";
    tooltipTextItems = [
      "This score shows variations in team indicators between men and women team members.",
      "A high score means that both men and women receive equal opportunities in terms of office days and overlaps, indicating good workplace inclusion."
    ];
  }

  if (sidebarName === TEAM_MEMBER_OVERLAP) {
    tooltipTitle = "Team member overlap";
    tooltipTextItems = [
      "This score shows how many days each team member was able to spend in the office with the people important to them, both in the team and outside it in the organization.",
      "A high score means the team members had many overlapping office days with the people important to them, improving engagement, effectiveness, and retention."
    ];
  }

  if (sidebarName === ORGANIZATION_CONNECTIONS) {
    tooltipTitle = "Organization connections";
    tooltipTextItems = [
      "This score shows how well this team is connected to other teams in the organization.",
      "It takes into account both connections formed from other teams to this one (incoming) and vice versa (outgoing)",
      "The score takes into account both the amount of connections between the teams, and their diversity - how many teams the team interacts with."
    ];
  }

  return (
    <Box p={1}>
      <Typography variant="h6">{tooltipTitle}</Typography>
      <ul>
        {tooltipTextItems.map((text, index) => (
          <li key={index}>
            <Typography variant="h6">{text}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

const TeamInsightsSidebars = ({ history }) => {
  const [tooltipText, setTooltipText] = useState("");

  const teamMembersList = useSelector(selectTeamUsersList);

  const sidebarName = history?.location?.state?.label;

  const currentIndicator = teamIndicatorsData.find((indicator) => indicator.label === sidebarName);

  const dispatch = useDispatch();

  useEffect(() => {
    setTooltipText(getTooltipText(sidebarName));
  }, [sidebarName]);

  const onTeamMemberClick = (id) => {
    history.replace(`/${ROUTE_INDIVIDUAL}/${id}`);
    dispatch(setSelectedUser(id));
  };

  const onTeamIndicatorClick = (event, label) => {
    history.replace({ pathname: `/${ROUTE_TEAM}/indicators/${convertStringToKebabCase(label)}`, state: { label } });
  };

  return (
    <Sidebar history={history} showCancelButton={false} resolveLabel="OK">
      <Box>
        <IndicatorsContainer container spacing={2}>
          {teamIndicatorsData.map(({ score, icon, label }, index) => (
            <IndicatorsBox item xs={2} key={`item-${index}`} onClick={(e) => onTeamIndicatorClick(e, label)}>
              <Score variant="h6" score={score} isSelected={sidebarName === label} />
              <Typography textAlign="center" mt={0.5}>
                {label}
              </Typography>
            </IndicatorsBox>
          ))}
        </IndicatorsContainer>
        <Divider color="#8D9096" />
        <Box mt={1}>
          <Typography variant="h5" fontWeight="700">
            {sidebarName}
          </Typography>
          <Box display="flex" my={2}>
            <Score variant="h4" score={currentIndicator?.score} />
            <Box ml={2}>
              <Box display="flex">
                <Typography variant="h6" color="#6B6C72">
                  Team:
                </Typography>
                <Typography variant="h6" ml={4.5}>
                  Finance
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="h6" color="#6B6C72">
                  Manager:
                </Typography>
                <Typography variant="h6" ml={1.5}>
                  Benny Golson
                </Typography>
              </Box>
            </Box>
            <Box ml="auto" sx={{ cursor: "pointer" }}>
              <CustomizedTooltip
                tooltipText={tooltipText}
                placement="left-end"
                TransitionProps={{ timeout: 400 }}
                arrow
              />
            </Box>
          </Box>
          <Box pb={3}>
            <Typography variant="h6" color="#00203E">
              This team’s score is significantly higher than the company avg. of <strong>8.2</strong>.
            </Typography>
          </Box>
          <Divider color="#8D9096" />
          {sidebarName === AVERAGE_DAYS_PER_WEEK && (
            <Box my={2}>
              <AverageDaysCharts />
            </Box>
          )}
          {sidebarName === ORGANIZATION_CONNECTIONS && <Connections />}
          {sidebarName === GENDER_INCLUSION && (
            <Box py={2}>
              <GenderInclusionTable />
            </Box>
          )}
          {sidebarName === SUSTAINABILITY && (
            <Box py={2}>
              <SustainabilityTable />
            </Box>
          )}
          {sidebarName === AVERAGE_DAYS_PER_WEEK && (
            <>
              <Divider color="#8D9096" />
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                  <Typography variant="h6">Highest team member-manager overlap:</Typography>
                  <TeamMemberOverlap items={teamMembersList} onClick={onTeamMemberClick} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Lowest team member-manager overlap:</Typography>
                  <TeamMemberOverlap items={lowestTeamMemberOverlap} />
                </Grid>
              </Grid>
            </>
          )}
          {(sidebarName === OVERLAP_WITH_MANAGER || sidebarName === TEAM_MEMBER_OVERLAP) && (
            <>
              <Box my={2}>
                <Typography variant="h6">Highest team member-manager overlap:</Typography>
                <TeamMemberOverlap items={teamMembersList} onClick={onTeamMemberClick} />
              </Box>
              <Box my={2}>
                <Typography variant="h6">Lowest team member-manager overlap:</Typography>
                <TeamMemberOverlap items={lowestTeamMemberOverlap} />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Sidebar>
  );
};

const IndicatorsContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    justifyContent: "space-around"
  }
}));

const IndicatorsBox = styled(Grid)(({ theme }) => ({
  minWidth: "90px",
  maxWidth: "120px",
  marginBottom: "8px",
  paddingLeft: "0 !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  },

  [theme.breakpoints.up("sm")]: {
    minWidth: 0
  },
  [theme.breakpoints.up("xl")]: {
    justifyContent: "space-around"
  }
}));

TeamInsightsSidebars.propTypes = {
  history: PropTypes.object
};

export default withRouter(TeamInsightsSidebars);
