import React from "react";
import { getFullName } from "shared/lib/getters";
import NotInBWith from "../../NotInBWith";
import { Row, InfoContainer } from "../style/insightsTabStyle";
import { Tooltip, Box, Typography } from "@mui/material";
import InfoSvg from "shared/assets/icons/info.svg";
import TooltipText from "shared/components/CustomizedTooltip/TooltipText";
import { CHART_DAY_LABELS } from "shared/constants";
import Overlaps from "shared/components/Overlaps";
import { GenerateColors } from "shared/lib/generators";

export const insightsTableColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 140,
    valueGetter: (params) => getFullName(params.row.firstName, params.row.lastName),
    renderCell: (params) => (
      <Row>
        <Typography>{getFullName(params.row.firstName, params.row.lastName)}</Typography>

        {params.row.status !== "ACTIVE" ? <NotInBWith /> : null}
      </Row>
    )
  },
  {
    field: "department",
    headerName: "Department",
    width: 140
  },
  {
    field: "avgDaysInOffice",
    headerName: "Avg.days",
    width: 80,
    renderHeader: () => {
      return (
        <Box>
          <Typography>Avg.</Typography>
          <Typography>days</Typography>
        </Box>
      );
    },
    valueGetter: (params) => {
      if (!params?.value) return null;

      return parseFloat(params.value).toFixed(1);
    }
  },
  {
    field: "manager",
    headerName: "Manager",
    width: 140,
    valueGetter: (params) => getFullName(params.row.manager?.firstName, params.row.manager?.lastName),
    renderCell: (params) => (
      <Typography>{getFullName(params.row.manager?.firstName, params.row.manager?.lastName)}</Typography>
    )
  },
  {
    field: "managerOverlapPct",
    sortable: false,
    width: 120,
    renderHeader: () => {
      return (
        <Row>
          <Box>
            <Typography>Manager</Typography>
            <Typography>Overlap</Typography>
          </Box>

          <Tooltip
            arrow
            title={
              <TooltipText
                title={"Overlap with manager"}
                message={[
                  "This score shows how many days team member were able to spend in the office with their manager. ",
                  "A high score means the team members had many overlapping office days with their manager, improving engagement and retention."
                ]}
              />
            }
            placement="top"
          >
            <InfoContainer>
              <img src={InfoSvg} />
            </InfoContainer>
          </Tooltip>
        </Row>
      );
    },
    renderCell: (params) => {
      if (!params?.row?.manager?.id) return null;

      return <Overlaps value={params.value} />;
    }
  },
  {
    field: "relationsOverlapPct",
    sortable: false,
    width: 140,
    renderHeader: () => {
      return (
        <Row>
          <Box>
            <Typography>Connections</Typography>
            <Typography>Overlap</Typography>
          </Box>

          <Tooltip
            arrow
            title={
              <TooltipText
                title={"Cross-organization overlap"}
                message={[
                  "This score shows how many days each team member was able to spend in the office with the people important to them, both in the team and outside it in the organization.",
                  "A high score means the team members had many overlapping office days with the people important to them, improving engagement, effectiveness, and retention."
                ]}
              />
            }
            placement="top"
          >
            <InfoContainer>
              <img src={InfoSvg} />
            </InfoContainer>
          </Tooltip>
        </Row>
      );
    },
    renderCell: (params) => {
      return <Overlaps value={params.value} />;
    }
  }
];

export const arrivalsChartData = ({ checkInData, officeData, labels }) => ({
  labels: labels || CHART_DAY_LABELS,
  datasets: [
    {
      label: "Forecast",
      data: officeData,
      backgroundColor: "#CCF2FF",
      borderRadius: 32,
      stack: "Dataset 1",
      barPercentage: 0.4,
      categoryPercentage: 0.6
    },
    {
      label: "Actual arrivals",
      data: checkInData,
      backgroundColor: "#00B4F0",
      borderRadius: 32,
      stack: "Dataset 2",
      barPercentage: 0.4,
      categoryPercentage: 0.6
    }
  ]
});

export const arrivalsChartOptions = {
  layout: {
    autoPadding: true,
    padding: {
      top: 25
    }
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true
      }
    },
    datalabels: {
      align: "end",
      anchor: "end",
      padding: 6
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      padding: {
        top: 50
      },
      display: false,
      grid: {
        display: false
      }
    }
  }
};

export const avgEmployeeDayChartData = (data) => {
  const colors = new GenerateColors();
  let labels = [];
  let datasets = [];

  if (data?.length > 0) {
    labels = data.map(({ date }) => date);

    datasets = data.reduce((acc, cur) => {
      acc = cur.departments?.map(({ name, avgEmployeesPerDay }) => {
        const index = acc?.findIndex((i) => i.label === name);
        const color = colors.make();

        if (index === -1) {
          return {
            label: name,
            data: [avgEmployeesPerDay],
            stack: name,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            radius: 0.5
          };
        } else {
          const prev = acc[index];

          return {
            ...prev,
            data: [...prev.data, avgEmployeesPerDay]
          };
        }
      });

      return acc;
    }, []);
  }

  return {
    labels,
    datasets
  };
};

export const avgDaysPerWeekChartData = (data) => {
  const colors = new GenerateColors();
  let labels = [];
  let datasets = [];

  if (data?.length > 0) {
    labels = data.map(({ date }) => date);

    datasets = data.reduce((acc, cur) => {
      acc = cur.departments?.map(({ name, avgDaysPerWeek }) => {
        const index = acc?.findIndex((i) => i.label === name);
        const color = colors.make();

        if (index === -1) {
          return {
            label: name,
            data: [avgDaysPerWeek],
            stack: name,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            radius: 0.5
          };
        } else {
          const prev = acc[index];

          return {
            ...prev,
            data: [...prev.data, avgDaysPerWeek]
          };
        }
      });

      return acc;
    }, []);
  }

  return {
    labels,
    datasets
  };
};

export const lineChartOptions = {
  responsive: true,
  layout: {
    autoPadding: true,
    padding: {
      top: 25
    }
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        font: {
          size: 10
        }
      }
    },
    datalabels: {
      display: false
    },
    title: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        maxTicksLimit: 12,
        autoSkipPadding: 5,
        font: {
          size: 10
        }
      }
    },
    y: {
      padding: {
        top: 50
      }
    }
  }
};
