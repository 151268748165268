import styled from "@mui/material/styles/styled";
import { Box, Button, Grid } from "@mui/material";

export const Icon = styled("img")(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  marginRight: theme.spacing(1)
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center"
}));

export const CustomizedGridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between"
}));

export const EditButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  minWidth: "191px",
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  position: "relative"
}));

export const ActionContainer = styled(Box)(({ selectedIndex }) => ({
  marginTop: selectedIndex ? "170px" : "16px",
  transition: "margin-top 700ms ease",
  zIndex: 5
}));

export const CustomizedIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(1.3)
}));

export const styles = {
  tabsContainerStyle: {
    width: "100%",
    paddingBottom: "24px"
  },

  tabsStyle: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "4px solid #ccc"
    },

    "& .MuiTabs-indicator": {
      height: "4px"
    }
  },

  tabItemStyle: {
    fontSize: "1.1rem",

    "&&.MuiTab-root": {
      padding: "10px 15px",
      minWidth: "120px"
    }
  }
};
