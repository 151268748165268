import { Box, OutlinedInput as MuiOutlinedInput } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiCheck from "@mui/icons-material/Check";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const Container = styled(Box)(() => ({
  width: "100%"
}));

export const OutlineInput = styled(MuiOutlinedInput)(() => ({
  background: "#FFF",
  color: "#00203E",
  "& .MuiOutlinedInput-root": {
    "& > fieldset": {
      borderColor: "#6B6C72",
      borderWidth: "1px"
    }
  }
}));

export const HelperText = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(5),
  color: "#d32f2f"
}));

export const CheckIcon = styled(MuiCheck)(() => ({
  color: "#fff",
  paddingRight: "8px"
}));

export const themedStyles = (theme) => ({
  containerWithHelperStyles: {
    paddingBottom: theme.spacing(2),
    position: "relative"
  },
  selectStyles: {
    minHeight: "20px"
  },
  menuItemCheckedStyle: {
    "&, &:hover": {
      fontWeight: theme.typography.fontWeightRegular,
      backgroundColor: "#00203E !important",
      color: "#ffffff !important"
    }
  },
  menuItemDefaultStyle: {
    "&, &:hover": {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#ffffff !important",
      color: "#ffffff !000000"
    }
  }
});

export const menuStyleProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
