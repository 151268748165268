import React, { useEffect, useState } from "react";
import { gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import { Container } from "./style/dateTableFooterStyle";
import PropTypes from "prop-types";

function Pagination({ page, onPageChange, className, rowsPerPage, setPage, setRowsPerPage }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  useEffect(() => {
    setPage(page);
    setRowsPerPage(rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

const DateTableFooter = ({ hideFooterPagination, renderFooterComponent, ...props }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  const renderPagination = (props) => <Pagination {...props} setPage={setPage} setRowsPerPage={setRowsPerPage} />;

  return (
    <Container>
      {renderFooterComponent ? renderFooterComponent(page, rowsPerPage) : null}

      {!hideFooterPagination ? (
        <GridPagination ActionsComponent={(props) => renderPagination(props)} {...props} />
      ) : null}
    </Container>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func,
  setRowsPerPage: PropTypes.func
};

DateTableFooter.propTypes = {
  hideFooterPagination: PropTypes.bool,
  renderFooterComponent: PropTypes.func
};

export default React.memo(DateTableFooter);
