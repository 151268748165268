import React from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CarIcon from "shared/assets/icons/car.svg";
import Co2Icon from "shared/assets/icons/co2.svg";
import TimerIcon from "shared/assets/icons/timer.svg";
import TreeIcon from "shared/assets/icons/tree.svg";

function createData(name, avgWeek, year, icon) {
  return { name, avgWeek, year, icon };
}

const rows = [
  createData("Trips saved", 25, 247, CarIcon),
  createData("Greenhouse gases prevented (lbs)", 163, 4.401, Co2Icon),
  createData("Commute time saved", 32, 869, TimerIcon),
  createData("Trees planted", 3, 83, TreeIcon)
];

const GenderInclusionTable = () => {
  return (
    <Table sx={{ width: "100%" }}>
      <TableHead>
        <TableRow>
          <TableCell align="center" />
          <TableCell align="center">
            <Box>
              <Typography>Average week</Typography>
            </Box>
          </TableCell>
          <TableCell align="center">
            <Box>
              <Typography>This year</Typography>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.name} hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Box display="flex" alignItems="center">
                <img src={row.icon} style={{ minWidth: "25px", maxHeight: "25px" }} />
                <Typography pl={1}>{row.name}</Typography>
              </Box>
            </TableCell>
            <TableCell align="center">{row.avgWeek}</TableCell>
            <TableCell align="center">{row.year}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GenderInclusionTable;
