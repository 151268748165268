import React from "react";
import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const Search = ({ placeholder }) => {
  return <SearchInput variant="outlined" placeholder={placeholder} />;
};

const SearchInput = styled(TextField)(({ theme }) => ({
  background: "#FFF",
  minWidth: "250px",
  "& .MuiOutlinedInput-input": {
    padding: theme.spacing(1.4)
  }
}));

Search.propTypes = {
  placeholder: PropTypes.string
};

export default Search;
