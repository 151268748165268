import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Box, Grid, Divider } from "@mui/material";
import { Doughnut, Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import styled from "@mui/material/styles/styled";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import ChartDataLabels from "chartjs-plugin-datalabels";
import SiteAndBuilding from "shared/components/Filters/SiteAndBulding/SiteAndBulding";
import SiteAndBuildingPage from "shared/components/DataTable/components/SiteAndBuildingSidebar/SiteAndBuildingSidebar";
import FloorSelector from "shared/components/Filters/Floor/Floor";
import Filter from "shared/components/Filters/Filter/Filter";
import WorkstationInfo from "shared/components/WorkstationInfo/WorkstationInfo";
import FilterWorkstationSidebar from "shared/components/DataTable/components/FilterWorkstationSidebar/FilterWorkstationSidebar";
import { BUILDING_PLANS, CHART_DAY_LABELS, WORKSTATION_TABLE_NAME } from "shared/constants";
import OfficeIcon from "shared/assets/icons/office.svg";
import HomeIcon from "shared/assets/icons/home.svg";
import PlaneIcon from "shared/assets/icons/plane.svg";
import VacationIcon from "shared/assets/icons/vacation.svg";
import SickIcon from "shared/assets/icons/sick.svg";
import CardContainer from "shared/components/CardContainer/CardContainer";
import LiveViewWorkstationMap from "shared/components/LiveViewWorkstationMap/LiveViewWorkstationMap";
import { selectFloorsFilter } from "core/selectors";
import NoAssignment from "shared/components/NoAssignment/NoAssignment";
import { selectIsUsersWithoutWorkstation } from "core/selectors/userManagementSelectors";
import { selectCompanyAndSiteSettings } from "../../core/selectors/profileSelectors";

const TABS_LIST = ["List View", "Look Back", "Look Ahead"];

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);

const firstDoughnutData = {
  labels: ["Regular", "Vegetarian", "Vegan"],
  datasets: [
    {
      data: [30, 15, 8],
      backgroundColor: ["#66D9FF", "#33CCFF", "#0099CC", "#007399"],
      borderColor: ["#66D9FF", "#33CCFF", "#0099CC", "#007399"],
      borderWidth: 1
    }
  ]
};

const secondDoughnutData = {
  labels: ["Kosher", "Gluten-free", "Lactose-free"],
  datasets: [
    {
      data: [30, 20, 15],
      backgroundColor: ["#016563", "#029794", "#02CAC7"],
      borderColor: ["#016563", "#029794", "#02CAC7"],
      borderWidth: 1
    }
  ]
};

export const barData = {
  labels: CHART_DAY_LABELS,
  datasets: [
    {
      label: "High tech",
      data: [103, 101, 90, 85, 100],
      backgroundColor: "#66D9FF",
      stack: "Stack 0",
      barThickness: 10
    },
    {
      label: "Tax",
      data: [70, 55, 102, 85, 88],
      backgroundColor: "#33CCFF",
      stack: "Stack 0",
      barThickness: 10
    },
    {
      label: "Corporate",
      data: [103, 101, 90, 85, 100],
      backgroundColor: "#0099CC",
      borderRadius: 32,
      stack: "Stack 0",
      barThickness: 10
    }
  ]
};

export const requestsBarData = {
  labels: ["Office", "Home", "Travel", "Vacation", "Sick"],
  datasets: [
    {
      data: [13, 10, 3, 5, 1],
      backgroundColor: "#66D9FF",
      stack: "Stack 0",
      barThickness: 10,
      borderRadius: 32
    }
  ]
};

const images = [OfficeIcon, HomeIcon, PlaneIcon, VacationIcon, SickIcon];

const barOptions = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 16
      }
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  }
};

const requestsBarOptions = {
  layout: {
    padding: {
      bottom: 22
    }
  },
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 16
      }
    },
    datalabels: {
      anchor: "end",
      formatter: (value) => `${value} /\n`
    }
  },
  scales: {
    x: {
      ticks: {
        display: false
      },
      grid: {
        display: false
      }
    },
    y: {
      display: false,
      grid: {
        display: false
      }
    }
  }
};

const IconPlugin = {
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;
    xAxis.ticks.forEach((value, index) => {
      const x = xAxis.getPixelForTick(index);
      const image = new Image();
      image.src = images[index];
      ctx.drawImage(image, x - 12, yAxis.bottom + 10);
    });
  }
};

const doughnutOptions = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 16
      }
    },
    datalabels: {
      display: false
    }
  },
  cutout: "85%"
};

const mapStateToProps = (state) => ({
  selectedFloors: selectFloorsFilter(state),
  companyAndSiteSettings: selectCompanyAndSiteSettings(state),
  isUsersWithoutWorkstation: selectIsUsersWithoutWorkstation(state)
});

const LiveViewPage = () => {
  const [tab, setTab] = useState(0);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [selectedBuildingFloor, setSelectedBuildingFloor] = useState(null);
  const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
  const [buildingPlan, setBuildingPlan] = useState(null);

  const { selectedFloors, companyAndSiteSettings, isUsersWithoutWorkstation } = useSelector(mapStateToProps);

  const mapRef = useRef();

  useEffect(() => {
    if (selectedFloors?.length) {
      setSelectedBuildingFloor(selectedFloors[selectedFloorIndex]);
    }
  }, [selectedFloors, selectedFloorIndex]);

  useEffect(() => {
    if (selectedBuildingFloor) {
      const building = BUILDING_PLANS?.find((plan) => plan?.floor === selectedBuildingFloor);
      setBuildingPlan(building);
    }
  }, [selectedBuildingFloor]);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const onSiteAndBuildingFilterClick = () => {
    setShowSidebar(true);
  };

  const onFilterClick = () => {
    setShowFilterSidebar(true);
  };

  const onPrevFloorClick = () => {
    setSelectedFloorIndex((prevIndex) => prevIndex - 1);
  };

  const onNextFloorClick = () => {
    setSelectedFloorIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <Box width="100%">
      <Box sx={{ width: "100%", background: "#fff", padding: "0 24px" }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            "& > div > div": {
              borderBottom: "2px solid #ccc"
            }
          }}
        >
          {TABS_LIST.map((tab, index) => (
            <Tab
              key={index}
              value={index}
              label={tab}
              disabled={tab !== "List View"}
              sx={{ fontSize: "1.1rem", "&&.MuiTab-root": { padding: "20px 16px" } }}
            />
          ))}
        </Tabs>
      </Box>
      <Box my={2}>
        <Grid container display="flex" alignItems="flex-start" spacing={2}>
          <Grid item>
            <SiteAndBuilding onClick={onSiteAndBuildingFilterClick} />
          </Grid>
          <Grid item>
            <FloorSelector />
          </Grid>
          <Grid item>
            <Filter onClick={onFilterClick} pageName={WORKSTATION_TABLE_NAME} />
          </Grid>
          {isUsersWithoutWorkstation && (
            <Grid item ml={1.7}>
              <NoAssignment />
            </Grid>
          )}
        </Grid>
      </Box>
      <Container>
        <Box style={{ gridColumn: "1 / 3", gridRow: 1 }}>
          <CardContainer p={3}>
            <WorkstationInfo />
          </CardContainer>
        </Box>
        <Box overflow="hidden" position="relative" style={{ gridColumn: "3 / 7", gridRow: "1 / 3" }} ref={mapRef}>
          <CardContainer styles={{ height: "100%" }}>
            <Box display="flex" justifyContent="center" position="relative">
              <Box position="absolute" left="0">
                <Typography variant="h5" fontWeight="700">
                  Workstations
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <NavigationButton
                  onClick={onPrevFloorClick}
                  disabled={selectedFloors.length <= 1 || selectedFloorIndex === 0}
                >
                  <ChevronLeftIcon color="secondary" />
                </NavigationButton>
                <Typography variant="h5" fontWeight="700">
                  Floor {selectedBuildingFloor}
                </Typography>
                <NavigationButton
                  onClick={onNextFloorClick}
                  disabled={selectedFloors.length <= 1 || selectedFloorIndex === selectedFloors.length - 1}
                >
                  <ChevronRightIcon color="secondary" />
                </NavigationButton>
              </Box>
            </Box>
            <Box>
              {companyAndSiteSettings.is_room_map_enabled && (
                <LiveViewWorkstationMap
                  selectedBuildingFloor={selectedBuildingFloor}
                  buildingPlan={buildingPlan}
                  containerWidth={mapRef.current?.offsetWidth}
                />
              )}
            </Box>
          </CardContainer>
        </Box>
        <Box style={{ gridColumn: "1 / 3", gridRow: 2 }}>
          <CardContainer>
            <Typography variant="h5" fontWeight="700">
              Today&apos;s food preferences
            </Typography>
            <Grid container my={2}>
              <Grid item xs={6}>
                <Box style={{ margin: "0 auto" }}>
                  <Doughnut data={firstDoughnutData} options={doughnutOptions} />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box style={{ margin: "0 auto" }}>
                  <Doughnut data={secondDoughnutData} options={doughnutOptions} />
                </Box>
              </Grid>
            </Grid>
          </CardContainer>
        </Box>
        <Box style={{ gridColumn: "1 / 3", gridRow: 3 }}>
          <CardContainer styles={{ height: "100%" }}>
            <Typography variant="h5" fontWeight="700">
              Today&apos;s requests
            </Typography>
            <Box py={1} px={3}>
              <Bar data={requestsBarData} plugins={[ChartDataLabels, IconPlugin]} options={requestsBarOptions} />
            </Box>
          </CardContainer>
        </Box>
        <Box style={{ gridColumn: "3 / 7", gridRow: 3 }}>
          <CardContainer styles={{ height: "100%" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" fontWeight="700">
                Capacity plan
              </Typography>
              <Typography variant="caption">June 13-17</Typography>
            </Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={5}>
                <Box ml={2}>
                  <Typography>By department</Typography>
                </Box>
                <Box p={2} style={{ margin: "0 auto" }}>
                  <Bar data={barData} options={barOptions} />
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={5}>
                <Box ml={2}>
                  <Typography>By floor</Typography>
                </Box>
                <Box p={2} style={{ margin: "0 auto" }}>
                  <Bar data={barData} options={barOptions} />
                </Box>
              </Grid>
            </Grid>
          </CardContainer>
        </Box>
      </Container>
      <SiteAndBuildingPage isDrawer isOpen={showSidebar} setIsOpen={setShowSidebar} />
      <FilterWorkstationSidebar isDrawer isOpen={showFilterSidebar} setIsOpen={setShowFilterSidebar} />
    </Box>
  );
};

const Container = styled("div")`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);
`;

const NavigationButton = styled(Button)(() => ({
  "&:disabled": {
    "& > svg": {
      opacity: 0.5
    }
  }
}));

export default LiveViewPage;
