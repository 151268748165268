import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CustomizedSelect from "shared/components/Select/CustomizedSelectDeprecated";

const TeamMemberSelector = ({ onChange, selectedValue, values }) => {
  return (
    <Box>
      <CustomizedSelect
        handleChange={onChange}
        values={values}
        selectedValue={selectedValue}
        name="userId"
        isMultiple={false}
        styles={{ minWidth: "150px" }}
        isTableHeaderSelect
      />
    </Box>
  );
};

TeamMemberSelector.propTypes = {
  onChange: PropTypes.func,
  selectedValue: PropTypes.array,
  values: PropTypes.array
};

export default TeamMemberSelector;
