import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ActivitiesGroupItem from "../ActivitiesGroupItem/ActivitiesGroupItem";

const ActivitiesGroups = ({ groups = [], onGroupClick, onAddSessionClick }) => {
  return (
    <Grid container spacing={1} my={2} overflow="auto" height="90%" pb={2}>
      {groups.map((group) => (
        <Grid item xs={4} key={group.id}>
          <ActivitiesGroupItem group={group} onGroupClick={onGroupClick} onAddSessionClick={onAddSessionClick} />
        </Grid>
      ))}
    </Grid>
  );
};

ActivitiesGroups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  onGroupClick: PropTypes.func,
  onAddSessionClick: PropTypes.func
};

export default ActivitiesGroups;
