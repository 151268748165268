import { apiSlice } from "core/api/apiSlice";
import {
  SIGN_OUT_ACTION_TYPE,
  STORAGE_FLOORS_FILTERS,
  STORAGE_NAVIGATION_RESOURCES,
  STORAGE_ROLE,
  STORAGE_SELECTED_TEAM_MEMBER,
  STORAGE_SITE_AND_BUILDING,
  STORAGE_USER_INFO
} from "shared/constants";

export function signOut(dispatch) {
  dispatch(apiSlice.util.resetApiState());
  dispatch({ type: SIGN_OUT_ACTION_TYPE });

  localStorage.removeItem(STORAGE_USER_INFO);
  localStorage.removeItem(STORAGE_SITE_AND_BUILDING);
  localStorage.removeItem(STORAGE_FLOORS_FILTERS);
  localStorage.removeItem(STORAGE_ROLE);
  localStorage.removeItem(STORAGE_NAVIGATION_RESOURCES);
  localStorage.removeItem(STORAGE_SELECTED_TEAM_MEMBER);
}
