import { apiSlice } from "core/api/apiSlice";

export const neighborhoodApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    neighborhoods: build.query({
      query: ({ site }) => ({
        url: "/seats/neighborhoods",
        method: "GET",
        params: { site }
      }),
      providesTags: ["Neighborhood"]
    }),
    addNeighborhood: build.mutation({
      query: ({ name, site, building }) => ({
        url: "/seats/neighborhoods",
        method: "POST",
        body: { name, site, building }
      }),
      invalidatesTags: ["Neighborhood"]
    }),
    updateNeighborhood: build.mutation({
      query: (args) => ({
        url: "/seats/neighborhoods",
        method: "PUT",
        body: args
      }),
      invalidatesTags: ["Neighborhood"]
    }),
    deleteNeighborhood: build.mutation({
      query: ({ name, site, building }) => ({
        url: "/seats/neighborhoods",
        method: "DELETE",
        body: { name, site }
      }),
      invalidatesTags: ["Neighborhood"]
    })
  })
});

export const {
  useNeighborhoodsQuery,
  useAddNeighborhoodMutation,
  useUpdateNeighborhoodMutation,
  useDeleteNeighborhoodMutation
} = neighborhoodApiSlice;
