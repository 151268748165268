import React from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import MaleIcon from "shared/assets/icons/male.svg";
import FemaleIcon from "shared/assets/icons/female.svg";
import GroupIcon from "shared/assets/icons/group.png";
import OverlapIcon from "shared/assets/icons/overlap.png";
import CalendarIcon from "shared/assets/icons/hr-calendar.svg";
import ConnectionsIcon from "shared/assets/icons/connections.png";

function createData(name, men, women, icon) {
  return { name, men, women, icon };
}

const rows = [
  createData("Manager overlap", 9.1, 9.2, GroupIcon),
  createData("Team member overlap", 7.4, 7.2, OverlapIcon),
  createData("Office days per week", 2.4, 1.8, CalendarIcon),
  createData("Organization connections", 8.2, 8.3, ConnectionsIcon)
];

const GenderInclusionTable = () => {
  return (
    <Table sx={{ width: "100%" }}>
      <TableHead>
        <TableRow>
          <TableCell align="center" />
          <TableCell align="center">
            <Box>
              <img src={MaleIcon} />
              <Typography>
                Team score: <br /> men
              </Typography>
            </Box>
          </TableCell>
          <TableCell align="center">
            <Box>
              <img src={FemaleIcon} />
              <Typography>
                Team score: <br /> women
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.name} hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Box display="flex" alignItems="center">
                <img src={row.icon} style={{ minWidth: "25px", maxHeight: "25px" }} />
                <Typography pl={1}>{row.name}</Typography>
              </Box>
            </TableCell>
            <TableCell align="center">{row.men}</TableCell>
            <TableCell align="center">{row.women}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GenderInclusionTable;
