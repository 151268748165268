import { apiSlice } from "core/api/apiSlice";

export const ACTIVITY_TAG = {
  group: "ActivityGroups",
  event: "ActivityEvents"
};

export const activitiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // GROUPS
    getGroups: build.query({
      query: (params) => ({
        url: "/preferences/interestGroups/sessions",
        method: "GET",
        params
      }),
      providesTags: [ACTIVITY_TAG.group]
    }),
    getGroupDetails: build.query({
      query: ({ id }) => ({
        url: `/preferences/interestGroups/${id}`,
        method: "GET"
      })
    }),
    addGroup: build.mutation({
      query: (params) => ({
        url: `/preferences/interestGroups`,
        method: "POST",
        body: {
          ...params
        }
      }),
      invalidatesTags: [ACTIVITY_TAG.group]
    }),
    updateGroup: build.mutation({
      query: ({ id, params }) => ({
        url: `/preferences/interestGroups/${id}`,
        method: "PUT",
        body: {
          ...params
        }
      }),
      invalidatesTags: [ACTIVITY_TAG.group]
    }),

    // EVENTS
    getSiteEvents: build.query({
      query: ({ site, from, to }) => ({
        url: "/preferences/interactions/siteEvents",
        method: "GET",
        params: {
          siteName: site,
          fromStartTime: from,
          toStartTime: to
        }
      }),
      providesTags: [ACTIVITY_TAG.event]
    }),

    // INTERACTIONS (sessions events)
    createInteraction: build.mutation({
      query: ({ params, type }) => ({
        url: `/preferences/interactions`,
        method: "POST",
        body: {
          type,
          ...params
        }
      }),
      invalidatesTags: (_res, _err, arg) => [arg.tagType]
    }),
    updateInteraction: build.mutation({
      query: ({ id, params, type }) => ({
        url: `/preferences/interactions/${id}`,
        method: "PUT",
        body: {
          type,
          ...params
        }
      }),
      invalidatesTags: (_res, _err, arg) => [arg.tagType]
    }),
    deleteInteraction: build.mutation({
      query: ({ id }) => ({
        url: `/preferences/interactions/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: (_res, _err, arg) => [arg.tagType]
    }),
    groupsSessionsByIds: build.mutation({
      query: ({ interestGroupIds, fromStartTime, toStartTime }) => ({
        url: `preferences/interactions`,
        method: "GET",
        params: { interestGroupIds, fromStartTime, toStartTime }
      })
    }),
    sendReminder: build.mutation({
      query: ({ id }) => ({
        url: `/preferences/interactions/${id}/sendReminder`,
        method: "POST"
      })
    })
  })
});

export const {
  useGetGroupsQuery,
  useGetGroupDetailsQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useGetSiteEventsQuery,
  useCreateInteractionMutation,
  useUpdateInteractionMutation,
  useDeleteInteractionMutation,
  useGroupsSessionsByIdsMutation,
  useSendReminderMutation
} = activitiesApiSlice;
