import { apiSlice } from "core/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    users: build.query({
      query: () => ({
        url: "/employees/users",
        method: "GET"
      }),
      providesTags: ["User"]
    }),
    usersByIds: build.mutation({
      query: ({ ids, cToken }) => ({
        url: "/employees/allByIds",
        method: "GET",
        params: {
          ids,
          cToken
        }
      })
    }),
    addUser: build.mutation({
      query: (args) => ({
        url: "/employees/users/v1",
        method: "POST",
        body: args
      }),
      invalidatesTags: ["User"]
    }),
    updateUser: build.mutation({
      query: ({ id, params }) => ({
        url: `/employees/users/${id}`,
        method: "PUT",
        body: params
      }),
      invalidatesTags: ["User"]
    }),
    uploadUserAvatar: build.mutation({
      query: ({ id, params }) => ({
        url: `/employees/users/${id}/image`,
        method: "PATCH",
        body: params
      }),
      invalidatesTags: ["User"]
    }),
    deleteUser: build.mutation({
      query: ({ id }) => ({
        url: `/employees/users/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["User"]
    }),
    getDepartmentsList: build.query({
      // TODO: deprecated // use departments from AdminSlice
      query: () => ({
        url: "/employees/departments",
        method: "GET"
      })
    })
  })
});

export const {
  useUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useGetDepartmentsListQuery,
  useDeleteUserMutation,
  useUsersByIdsMutation,
  useUploadUserAvatarMutation
} = userApiSlice;
