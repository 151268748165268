import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import styled from "@mui/material/styles/styled";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";

const FreeSoloAutocomplete = ({ name, control, label, rules, placeholder, data, styles, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {label && <CustomizedLabel text={label} error={!!error} />}
          <Autocomplete
            options={data}
            disabled={disabled}
            freeSolo
            onChange={(event, item, reason) => {
              if (reason === "clear") {
                onChange("");
              } else {
                onChange(item);
              }
            }}
            onInputChange={(_, data) => {
              if (data) onChange(data);
            }}
            size="small"
            value={value}
            fullWidth
            renderInput={(params) => (
              <CustomizedTextField
                {...params}
                label=""
                placeholder={placeholder}
                helperText={error ? error.message : null}
                error={!!error}
                sx={styles}
              />
            )}
          />
        </>
      )}
    />
  );
};

const CustomizedTextField = styled(TextField)({
  color: "#00203E",
  background: "#FFFFFF",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#8D9096"
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#f8fafe"
  }
});

FreeSoloAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  styles: PropTypes.object,
  disabled: PropTypes.bool
};

export default FreeSoloAutocomplete;
