import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import OutlinedPlusIcon from "shared/assets/icons/outlined-plus.svg";
import DoneIcon from "shared/assets/icons/done.svg";
import PropTypes from "prop-types";

const UsersInterests = ({ data, onPress, personalInterestsNames = [] }) => {
  const addIcon = <img src={OutlinedPlusIcon} alt="add" />;
  const doneIcon = <img src={DoneIcon} alt="selected" />;

  return (
    <Box>
      {data?.length
        ? data.map((item, index) => (
            <Chip
              key={index}
              icon={personalInterestsNames.includes(item) ? doneIcon : addIcon}
              label={item}
              variant="outlined"
              sx={{ margin: "4px" }}
              onClick={() => onPress(item)}
            />
          ))
        : null}
    </Box>
  );
};

UsersInterests.propTypes = {
  data: PropTypes.array.isRequired,
  personalInterestsNames: PropTypes.array,
  onPress: PropTypes.func
};

export default React.memo(UsersInterests);
