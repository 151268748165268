import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "shared/components/Sidebar/Sidebar";
import FormInputAutocomplete from "shared/components/FormInputs/Autocomplete/FormInputAutocomplete";
import { useForm } from "react-hook-form";
import FormInputDropdown from "shared/components/FormInputs/Dropdown";
import FormInputCheckbox from "shared/components/FormInputs/Checkbox/FormInputCheckbox";
import {
  selectAccessToken,
  selectBuildingFilter,
  selectFilteredWorkstationsList,
  selectFloorsFilter,
  selectFloorsList,
  selectMappedNeighborhoodsList,
  selectRoomsByBuilding,
  selectSiteFilter,
  selectUsersByBuildingOptions,
  selectWorkstationsFilter
} from "core/selectors";
import { resetWorkstationFilter, setWorkstationFilter } from "features/filters/filtersSlice";
import { setFormValues } from "shared/lib/setFormValues";
import {
  getDataEntityItemById,
  getFieldName,
  getFormattedDate,
  getMappedWorkstationFilters,
  getMappedWorkstationList,
  getRoomTypeTitle
} from "shared/lib/getters";
import {
  useAddWorkstationMutation,
  useFilterWorkstationsMutation,
  useUpdateWorkstationMutation,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";
import { useRoomsMapQuery } from "features/rooms/roomsApiSlice";
import { setRoomsByBuilding } from "features/rooms/roomsSlice";
import { selectCompanyId } from "core/selectors/siteSelectors";
import UsbIcon from "shared/assets/icons/usb.svg";
import DualMonitorIcon from "shared/assets/icons/dual-monitor.svg";
import LargeMonitorIcon from "shared/assets/icons/large-monitor.svg";
import WheelchairIcon from "shared/assets/icons/wheelchair.svg";
import MacIcon from "shared/assets/icons/mac.svg";
import LandLineIcon from "shared/assets/icons/land-line.svg";
import PresentationScreenIcon from "shared/assets/icons/presentation.svg";
import PrinterIcon from "shared/assets/icons/printer.svg";
import SofaIcon from "shared/assets/icons/sofa.svg";
import ErgonomicSeatIcon from "shared/assets/icons/ergonomic-seat.svg";
import PcIcon from "shared/assets/icons/pc.svg";
import { setWorkstationsByBuildings } from "features/workstations/workstationsSlice";
import TrashIcon from "shared/assets/icons/trash.svg";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { isEmptyObject, isValidObject } from "../../lib/validation";
import HotDeskIcon from "shared/assets/icons/hotDesk.svg";
import AutomationIcon from "shared/assets/icons/automation.svg";

const windowsList = [
  { option: 0, value: 0 },
  { option: 1, value: 1 },
  { option: 2, value: 2 },
  { option: 3, value: 3 }
];

const roomTypeList = [
  { option: "Private, associate", value: "Private, associate" },
  { option: "Private, partner", value: "Private, partner" },
  { option: "Shared, associate", value: "Shared, associate" },
  { option: "Shared, intern", value: "Shared, intern" },
  { option: "Shared, pre-Intern", value: "Shared, pre-Intern" }
];

const additionalFeatureList = [
  { checkboxLabel: "Docking station", checkboxValue: "dockingStation", icon: UsbIcon },
  { checkboxLabel: "Large monitor", checkboxValue: "largeMonitor", icon: LargeMonitorIcon },
  { checkboxLabel: "Wheelchair accessible", checkboxValue: "wheelchair", icon: WheelchairIcon },
  { checkboxLabel: "Dual monitor", checkboxValue: "dualMonitor", icon: DualMonitorIcon },
  { checkboxLabel: "Mac", checkboxValue: "mac", icon: MacIcon },
  { checkboxLabel: "Land line", checkboxValue: "landLine", icon: LandLineIcon },
  { checkboxLabel: "Presentation screen", checkboxValue: "presentationScreen", icon: PresentationScreenIcon },
  { checkboxLabel: "Printer", checkboxValue: "printer", icon: PrinterIcon },
  { checkboxLabel: "Sitting area", checkboxValue: "sittingArea", icon: SofaIcon },
  { checkboxLabel: "Ergonomic chair", checkboxValue: "ergonomicChair", icon: ErgonomicSeatIcon },
  { checkboxLabel: "PC", checkboxValue: "pc", icon: PcIcon },
  { checkboxLabel: "Can be booked from mobile", checkboxValue: "hotDesk", icon: HotDeskIcon },
  { checkboxLabel: "Use for automatic seating", checkboxValue: "availableForAutoSeating", icon: AutomationIcon }
];

const defaultValues = {
  roomName: "",
  neighborhoodName: "",
  ownerId: "",
  roomType: "",
  windows: "",
  seatFeatures: []
};

const mapStateToProps = (state) => ({
  site: selectSiteFilter(state),
  building: selectBuildingFilter(state),
  selectedFilters: selectWorkstationsFilter(state),
  workstationList: selectFilteredWorkstationsList(state),
  selectedFloors: selectFloorsFilter(state),
  floorList: selectFloorsList(state),
  usersByBuildingOptions: selectUsersByBuildingOptions(state),
  neighborhoodList: selectMappedNeighborhoodsList(state),
  roomsByBuilding: selectRoomsByBuilding(state),
  companyId: selectCompanyId(state),
  accessToken: selectAccessToken(state)
});

const getDefaultValues = ({ isFilter, isEdit }) => {
  if (isFilter) {
    return { ...defaultValues, availability: false };
  }

  if (!isEdit && !isFilter) {
    delete defaultValues.roomType;
    return defaultValues;
  }
};

const WorkstationSidebar = ({
  history,
  isOpen,
  setIsOpen,
  headerText,
  contentText,
  isFilter = false,
  isEdit,
  workstationId,
  currentRoomName
}) => {
  /* ------------------ HOOKs ------------------ */

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { isValid }
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues({ isFilter, isEdit })
  });
  const dispatch = useDispatch();

  /* ------------------ STATEs ------------------ */

  const [filteredRoomList, setFilteredRoomList] = useState([]);

  const {
    site,
    building,
    selectedFilters,
    workstationList,
    selectedFloors,
    floorList,
    usersByBuildingOptions,
    neighborhoodList,
    roomsByBuilding,
    companyId,
    accessToken
  } = useSelector(mapStateToProps);
  const [floor, ownerId] = watch(["floor", "ownerId"]);

  /* ------------------ EFFECTs ------------------ */

  const [addWorkstation, { isLoading: isAddLoading, error: addError, isSuccess: isSuccessAdded }] =
    useAddWorkstationMutation();

  const [updateWorkstation, { isLoading: isUpdateLoading, error: updateError, isSuccess: isSuccessUpdated }] =
    useUpdateWorkstationMutation();

  const { data: roomsData, isLoading: isRoomsLoading } = useRoomsMapQuery(
    { site, building },
    { skip: !site || !building }
  );

  const [
    filterWorkstations,
    {
      data: filteredWorkstationsData,
      isLoading: isFilteredDataLoading,
      error: filterError,
      isSuccess: isSuccessFiltered
    }
  ] = useFilterWorkstationsMutation();

  const { refetch: refetchWorkstationsByBuildings } = useWorkstationsByBuildingsQuery(
    { site, building, date: getFormattedDate(new Date(), "yyyy-MM-dd") },
    { skip: !site || !building || !accessToken }
  );

  /* ------------------ MEMOs ------------------ */

  const workstation = useMemo(
    () => isEdit && getDataEntityItemById(workstationList, workstationId),
    [isEdit, workstationList, workstationId]
  );

  /* ------------------ EFFECTs ------------------ */

  const getResolveLabel = () => {
    if (isEdit) {
      return "Update";
    }

    if (isFilter) {
      return "Apply Filter";
    }

    return "Add";
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (floor && isValidObject(roomsByBuilding)) {
      if (roomsByBuilding[floor]) {
        const list = roomsByBuilding[floor]
          ?.map((item) => ({ option: item.name, value: item.name, atCapacity: item.atCapacity }))
          .sort((a, b) => a.value?.localeCompare(b.value));

        if (!isFilter) {
          setFilteredRoomList(list.filter(({ atCapacity }) => !atCapacity));
          return;
        }

        setFilteredRoomList(list);
      }
    }
  }, [floor, roomsByBuilding]);

  useEffect(() => {
    if (!isEdit) {
      setValue("floor", selectedFloors[0]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentRoomName && isValidObject(roomsByBuilding) && floor) {
      const rooms = roomsByBuilding[floor]?.map((i) => ({ option: i.name, value: i.name }));
      setValue(
        "roomName",
        rooms?.find((room) => room.option === currentRoomName)
      );
    }
  }, [currentRoomName, roomsByBuilding, floor]);

  useEffect(() => {
    if (isFilter && selectedFilters && isValidObject(roomsByBuilding)) {
      setFormValues({
        data: selectedFilters,
        setValue,
        neighborhoodList,
        usersByBuildingOptions,
        roomsList: roomsByBuilding[floor]?.map((i) => ({ option: i.name, value: i.name }))
      });
    }
    if (isEdit && workstation && isValidObject(roomsByBuilding)) {
      setFormValues({
        data: workstation,
        setValue,
        neighborhoodList,
        usersByBuildingOptions,
        workstationList,
        roomsList: filteredRoomList
      });
    }
  }, [selectedFilters, workstation, roomsByBuilding, isOpen, filteredRoomList, usersByBuildingOptions]);

  useEffect(() => {
    if (roomsData && !isRoomsLoading && building) {
      dispatch(setRoomsByBuilding(roomsData[building]));
    }
  }, [roomsData, isRoomsLoading, building]);

  useEffect(() => {
    if (filteredWorkstationsData && !isFilteredDataLoading) {
      const mappedList = getMappedWorkstationList(filteredWorkstationsData, usersByBuildingOptions);

      dispatch(setWorkstationsByBuildings(mappedList));
    }
  }, [filteredWorkstationsData, isFilteredDataLoading, usersByBuildingOptions]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onRemoveAssignmentClick = () => {
    setValue("ownerId", "");
  };

  const onClear = async () => {
    const workstationsByBuildings = await refetchWorkstationsByBuildings().unwrap();

    const mappedList = getMappedWorkstationList(workstationsByBuildings[building], usersByBuildingOptions);

    reset();
    dispatch(setWorkstationsByBuildings(mappedList));
    dispatch(resetWorkstationFilter());
  };

  const onSubmit = async (values) => {
    const transformedValues = {
      ...values,
      workstationId: values.id,
      ownerId: values.ownerId?.id || null,
      neighborhoodName: values.neighborhoodName?.value || null,
      roomName: values.roomName?.option,
      floor: parseInt(floor),
      building,
      site,
      roomType: getRoomTypeTitle(values.roomType),
      features: values.seatFeatures
    };

    delete transformedValues.id;
    delete transformedValues.permanentOwner;
    delete transformedValues.seatFeature;
    delete transformedValues.assignedEmployeeId;
    delete transformedValues.seatFeatures;

    try {
      if (isEdit) {
        return await updateWorkstation({ ...transformedValues, previousRoomName: workstation.roomName });
      }
      if (isFilter) {
        const payload = {
          ...transformedValues,
          companyId,
          employeeId: transformedValues.ownerId,
          floors: [transformedValues.floor],
          neighborhoods: transformedValues.neighborhoodName ? [transformedValues.neighborhoodName] : [],
          date: getFormattedDate(new Date(), "yyyy-MM-dd"),
          availability: values.availability,
          roomTypes: transformedValues.roomType ? [transformedValues.roomType] : []
        };
        delete payload.workstationId;
        delete payload.roomType;
        delete payload.ownerId;
        delete payload.floor;
        delete payload.neighborhoodName;

        const storePayload = { ...payload };

        delete storePayload.site;
        delete storePayload.building;
        delete storePayload.date;

        await filterWorkstations(payload);
        return dispatch(setWorkstationFilter(getMappedWorkstationFilters(storePayload)));
      }
      delete transformedValues.roomType;
      return await addWorkstation(transformedValues);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Sidebar
        history={history}
        isDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shouldValidate
        resolveLabel={getResolveLabel()}
        showClearButton={isFilter}
        onClearClick={onClear}
        isValid={isValid}
        resetForm={reset}
        onResolve={handleSubmit(onSubmit)}
        isLoading={isAddLoading || isUpdateLoading || isFilteredDataLoading}
        error={addError || updateError || filterError}
        isSuccess={isSuccessAdded || isSuccessUpdated || isSuccessFiltered}
      >
        <Box>
          <Typography variant="h5" fontWeight="700">
            {headerText}
          </Typography>
        </Box>
        <Box my={2}>
          <Typography>{`${contentText} ${!isFilter ? workstation?.workstationName : ""} at ${site}, ${building}, ${
            !isFilter ? workstation?.floor : floor
          } floor`}</Typography>
        </Box>
        {!isFilter && (
          <Box mt={2}>
            <FormInputDropdown
              label="Floor*"
              control={control}
              name="floor"
              rules={{ required: "Floor is required" }}
              data={floorList}
              isMultiple={false}
              disabled={isEdit}
              placeholder="Select floor"
            />
          </Box>
        )}
        <Box mt={2}>
          <FormInputAutocomplete
            label={getFieldName("Room name", !isFilter)}
            control={control}
            name="roomName"
            disabled={isEdit}
            data={filteredRoomList}
            placeholder="Enter name"
          />
        </Box>
        {isFilter && (
          <Box mt={2}>
            <FormInputCheckbox
              control={control}
              name="availability"
              isBoolean
              tempLabel="Show only available and assignable"
            />
          </Box>
        )}
        <Box mt={2}>
          <FormInputAutocomplete
            label="Neighborhood (optional)"
            control={control}
            name="neighborhoodName"
            data={neighborhoodList}
            placeholder="Select neighborhood"
          />
        </Box>
        <Box mt={2}>
          <Grid container spacing={2} alignItems="end">
            <Grid item xs={isEdit ? 9 : 12}>
              <FormInputAutocomplete
                label="Permanent owner (optional)"
                control={control}
                name="ownerId"
                data={usersByBuildingOptions}
                placeholder="Enter name to search"
              />
            </Grid>
            {isEdit && (
              <Grid item xs={3} display="flex" justifyContent="end">
                <RemoveButton onClick={onRemoveAssignmentClick} disabled={isEmptyObject(ownerId) || !ownerId}>
                  <img src={TrashIcon} />
                  <Typography ml={1}>Unassign</Typography>
                </RemoveButton>
              </Grid>
            )}
          </Grid>
        </Box>
        {isEdit || isFilter ? (
          <Box mt={2}>
            <FormInputDropdown
              label="Room type"
              control={control}
              name="roomType"
              data={roomTypeList}
              disabled={isEdit}
              placeholder="Select room type"
              isMultiple={false}
            />
          </Box>
        ) : null}
        <Box mt={2}>
          <FormInputDropdown
            label={getFieldName("Number of windows", !isFilter)}
            control={control}
            name="windows"
            data={windowsList}
            rules={isFilter ? {} : { required: "Number of windows is required" }}
            placeholder="Select windows"
            isMultiple={false}
          />
        </Box>
        <Box mt={2}>
          <FormInputCheckbox
            control={control}
            name="seatFeatures"
            data={additionalFeatureList}
            styles={{ display: "grid", gridTemplateColumns: "repeat(2, 50%)" }}
          />
        </Box>
      </Sidebar>
    </div>
  );
};

const RemoveButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(1, 2),
  display: "inline-flex",

  "&:disabled": {
    "& > img": {
      opacity: 0.5
    }
  }
}));

WorkstationSidebar.propTypes = {
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  headerText: PropTypes.string,
  contentText: PropTypes.string,
  isFilter: PropTypes.bool,
  isEdit: PropTypes.bool,
  workstationId: PropTypes.number,
  currentRoomName: PropTypes.string
};

export default withRouter(WorkstationSidebar);
