import React from "react";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getScoreColor } from "shared/lib/getters";

const Score = ({ variant, score, isSelected }) => {
  return (
    <CustomizedScore score={score} isSelected={isSelected}>
      <Typography variant={variant} fontWeight="700">
        {score}
      </Typography>
    </CustomizedScore>
  );
};

const CustomizedScore = styled(Box)(({ theme, score, isSelected }) => ({
  border: "1px solid #00203E",
  borderRadius: theme.spacing(0.5),
  width: "fit-content",
  height: "fit-content",
  padding: theme.spacing(1),
  color: getScoreColor(score, isSelected),
  backgroundColor: isSelected && "#00203E"
}));

Score.propTypes = {
  variant: PropTypes.string,
  score: PropTypes.number,
  isSelected: PropTypes.bool
};

export default Score;
