export const interestsChartOptions = {
  indexAxis: "y",
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  }
};

export const interestsChartData = ({ data = [], labels = [] }) => ({
  labels,
  datasets: [
    {
      label: "Subscribers",
      data: data,
      backgroundColor: "#0099CC",
      barThickness: 10,
      borderRadius: 32
    }
  ]
});
