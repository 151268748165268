import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CustomizedLabel from "../../CustomizedLabel/CustomizedLabel";
import { TextField } from "@mui/material";
import styled from "@mui/material/styles/styled";

const FormInputTime = ({ name, control, label, rules, disabled = false }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {label && <CustomizedLabel text={label} error={!!error} />}
            <TimePicker
              onChange={onChange}
              value={value}
              ampm={false}
              disabled={disabled}
              renderInput={(params) => (
                <Input
                  {...params}
                  fullWidth
                  helperText={error ? error.message : null}
                  error={!!error}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "Select time"
                  }}
                />
              )}
            />
          </>
        )}
      />
    </LocalizationProvider>
  );
};

const Input = styled(TextField)({
  color: "#00203E",
  background: "#FFFFFF",
  marginTop: 0,
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6B6C72"
    }
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 14px"
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#f8fafe"
  }
});

FormInputTime.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

export default FormInputTime;
