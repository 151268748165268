import { apiSlice } from "core/api/apiSlice";

export const INTERESTS_TAG = {
  personal: "interestsPersonal",
  professional: "interestsProfessional"
};

export const interestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    personalInterestsBySiteId: build.query({
      query: ({ id }) => ({
        url: `/preferences/personalInterests/sites/${id}`,
        method: "GET"
      }),
      providesTags: [INTERESTS_TAG.personal]
    }),
    professionalInterestsBySiteId: build.query({
      query: ({ id }) => ({
        url: `/preferences/professionalInterests/sites/${id}`,
        method: "GET"
      }),
      providesTags: [INTERESTS_TAG.professional]
    }),
    usersPersonalInterestsBySiteId: build.query({
      query: ({ id }) => ({
        url: `/preferences/personalInterests/sites/${id}/addedByUsers`,
        method: "GET"
      })
    }),
    personalInterestAdd: build.mutation({
      query: ({ id, name }) => ({
        url: `/preferences/personalInterests/sites/${id}`,
        method: "POST",
        body: {
          name
        }
      }),
      invalidatesTags: [INTERESTS_TAG.personal]
    }),
    personalInterestDelete: build.mutation({
      query: ({ id, name }) => ({
        url: `/preferences/personalInterests/sites/${id}/names/${name}`,
        method: "DELETE"
      }),
      invalidatesTags: [INTERESTS_TAG.personal]
    }),
    professionalInterestAdd: build.mutation({
      query: ({ id, name }) => ({
        url: `/preferences/professionalInterests/sites/${id}`,
        method: "POST",
        body: {
          name
        }
      }),
      invalidatesTags: [INTERESTS_TAG.professional]
    }),
    professionalInterestDelete: build.mutation({
      query: ({ id, name }) => ({
        url: `/preferences/professionalInterests/sites/${id}/names/${name}`,
        method: "DELETE"
      }),
      invalidatesTags: [INTERESTS_TAG.professional]
    })
  })
});

export const {
  usePersonalInterestsBySiteIdQuery,
  useProfessionalInterestsBySiteIdQuery,
  useUsersPersonalInterestsBySiteIdQuery,
  usePersonalInterestAddMutation,
  usePersonalInterestDeleteMutation,
  useProfessionalInterestAddMutation,
  useProfessionalInterestDeleteMutation
} = interestsApiSlice;
