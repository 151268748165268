import { Box, styled } from "@mui/material";

export const ContainerBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%"
}));

export const BodyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "space-between",
  marginTop: theme.spacing(2)
}));

// export const
