import React from "react";
import CytoscapeComponent from "react-cytoscapejs";
import { Container, Scene, styles, cyStylesheet } from "./style/relationChartStyle";
import { useRelationChartLogic } from "./hooks/useRelationChartLogic";
import PropTypes from "prop-types";

// const params = {
//   name: "cola",
//   nodeSpacing: 5,
//   edgeLengthVal: 45,
//   animate: true,
//   randomize: false,
//   maxSimulationTime: 1500
// };

// function makeLayout(opts) {
//   params.randomize = true;
//   params.edgeLength = function (e) {
//     return params.edgeLengthVal / e.data("weight");
//   };

//   return cy.layout(params);
// }

const layout = {
  name: "cola",
  nodeSpacing: 10,
  edgeLengthVal: 50,
  animate: true,
  randomize: true,
  maxSimulationTime: 2500,
  edgeLength: function (e) {
    return this.edgeLengthVal / e.data("weight");
  }
};

// const layout = { name: "random" };

export default function RelationChart({ isLoading, ...rest }) {
  const { onRef, nodes, edges } = useRelationChartLogic(rest);

  return (
    <Scene ref={onRef}>
      {!isLoading && (
        <CytoscapeComponent
          elements={CytoscapeComponent.normalizeElements({ nodes, edges })}
          style={styles.chart}
          layout={layout}
          stylesheet={cyStylesheet}
        />
      )}
    </Scene>
  );
}

RelationChart.propTypes = {
  relations: PropTypes.array,
  users: PropTypes.object,
  isLoading: PropTypes.bool
};
