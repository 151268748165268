import React from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Box } from "@mui/material";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "@mui/material/styles/styled";
import PropTypes from "prop-types";
import CustomizedBadge from "shared/components/CustomizedBadge/CustomizedBadge";
import { format } from "date-fns";
import SendIcon from "shared/assets/icons/send.svg";

const EventsTable = ({ events, onEventClick, onSendReminderClick }) => {
  // const onDuplicate = () => {};

  const renderTableItem = (item) => {
    if (!item) return null;

    const active = new Date(item.startTime) > new Date();

    return (
      <TableRow key={item.name} hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <CustomizedCell active={active} padding="small">
          {item.name}
        </CustomizedCell>
        <CustomizedCell active={active} padding="small">
          {format(new Date(item.startTime), "yyyy-MM-dd")}
        </CustomizedCell>
        <CustomizedCell active={active} padding="small">
          {format(new Date(item.startTime), "EEEE")}
        </CustomizedCell>

        <CustomizedCell active={active} padding="small">
          <CustomizedBadge color="warning" invisible={item.guests?.length > 4} positionRight="-5px">
            {item.guests?.length ? item.guests.length : 0}
          </CustomizedBadge>
        </CustomizedCell>

        <TableCell align="center" padding="none">
          <ActionButton onClick={() => onSendReminderClick(item.id)} disabled={!active}>
            <img src={SendIcon} style={{ width: "18px", height: "18px" }} />
          </ActionButton>
        </TableCell>

        <TableCell align="center" padding="none">
          <ActionButton onClick={() => onEventClick(item)}>
            <InfoOutlinedIcon fontSize="small" />
          </ActionButton>
        </TableCell>

        {/* TODO: */}
        {/* <TableCell align="center" padding="none">
          <ActionButton onClick={onDuplicate}>
            <ContentCopyIcon fontSize="small" />
          </ActionButton>
        </TableCell> */}
      </TableRow>
    );
  };

  return (
    <Box height="90%" overflow="auto">
      <Table sx={{ width: "100%" }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Previous events</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Day of the week</TableCell>
            <TableCell>Signed up</TableCell>
            <TableCell colSpan={2} />
          </TableRow>
        </TableHead>
        <TableBody>{events.map((row) => renderTableItem(row))}</TableBody>
      </Table>
    </Box>
  );
};

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: "25px",
  color: "#000",
  marginRight: "12px",
  "&:focus": {
    outline: "none"
  },
  "&[disabled]": {
    opacity: 0.3
  }
}));

const CustomizedCell = styled(TableCell)(({ theme, active }) => ({
  color: active ? "#00203E" : "#BABEC5"
}));

EventsTable.propTypes = {
  events: PropTypes.array.isRequired,
  onEventClick: PropTypes.func,
  onSendReminderClick: PropTypes.func
};

export default EventsTable;
