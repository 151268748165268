/**
 * @type {String}
 */
export const ROUTE_LOGIN = "login";

/**
 * @type {String}
 */
export const ROUTE_CONFIRMATION_CODE = "confirmation";

/**
 * @type {String}
 */
export const ROUTE_LIVE_VIEW = "live-view";

/**
 * @type {String}
 */
export const ROUTE_USER_MANAGEMENT = "user-management";

/**
 * @type {String}
 */
export const ROUTE_WORKSTATIONS = "workstation";

/**
 * @type {String}
 */
export const ROUTE_SETTINGS = "settings";

/**
 * @type {String}
 */
export const ROUTE_TEAM = "team";

/**
 * @type {String}
 */
export const ROUTE_INDIVIDUAL = "individual";

/**
 * @type {String}
 */
export const ROUTE_ACTIVITIES = "activities";

/**
 * @type {String}
 */
export const ROUTE_NEIGHBORHOODS = "neighborhoods";

/**
 * @type {String}
 */
export const ROUTE_DIGITAL_SIGN = "digital-sign";

/**
 * @type {String}
 */
export const ROUTE_ATTENDANCE = "attendance";

/**
 * @type {String}
 */
export const ROUTE_CONFIRM_PLAN_REDIRECT = "confirm-plan";

/**
 * @type {Object}
 */
export const DEFAULT_RESOURCES_NAMES = {
  liveView: "live view",
  workstations: "workstations",
  users: "users",
  neighborhoods: "neighborhoods"
};

/**
 * @type {Object}
 */
export const RESOURCES_NAMES = {
  ...DEFAULT_RESOURCES_NAMES,
  attendance: "attendance",
  activities: "activities",
  individual: "individual",
  team: "team",
  adminSettings: "adminSettings"
};
