import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { useDispatch, useSelector } from "react-redux";
import SiteAndBuilding from "shared/components/Filters/SiteAndBulding/SiteAndBulding";
import SiteAndBuildingPage from "shared/components/DataTable/components/SiteAndBuildingSidebar/SiteAndBuildingSidebar";
import FilterWorkstationSidebar from "shared/components/DataTable/components/FilterWorkstationSidebar/FilterWorkstationSidebar";
import WorkstationInfo from "shared/components/WorkstationInfo/WorkstationInfo";
import { BUILDING_PLANS, ROUTE_WORKSTATIONS } from "shared/constants";
import MainStage from "shared/components/LiveMap/MainStage";
import { isValidArray } from "shared/lib/validation";
import {
  getAxisPositions,
  getEmptyWorkstations,
  getFormattedDate,
  getMappedWorkstationList,
  getPathAxisPositions,
  getWorkstationMapItemSvgProps
} from "shared/lib/getters";
import {
  useGetMapViewWorkstationsQuery,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";
import {
  selectBuildingFilter,
  selectAccessToken,
  selectFloorsFilter,
  selectSiteFilter,
  selectUserManagementList
} from "core/selectors";
import { setWorkstationsByBuildings } from "features/workstations/workstationsSlice";
import FloorSelector from "shared/components/Filters/Floor/Floor";
import { isEqualData } from "shared/lib";
import WorkstationPage from "../WorkstationPage/WorkstationPage";
import WorkstationInfoPage from "../WorkstationInfoPage/WorkstationInfoPage";

const WorkstationMapView = ({ showHeader = true }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [svgRectElements, setSvgRectElements] = useState([]);
  const [svgPathElements, setSvgPathElement] = useState([]);
  const [workstationsMapList, setWorkstationMapList] = useState([]);
  const [rectMapList, setRectMapList] = useState([]);
  const [pathMapList, setPathMapList] = useState([]);
  const [notAssignedRectRooms, setNotAssignedRectRooms] = useState([]);
  const [notAssignedPathRooms, setNotAssignedPathRooms] = useState([]);
  const [notAssignedRoomsList, setNotAssignedRoomsList] = useState([]);
  const [buildingPlan, setBuildingPlan] = useState(null);
  const [showAddSidebar, setShowAddSidebar] = useState(false);
  const [roomName, setRoomName] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [showInfoSidebar, setShowInfoSidebar] = useState(false);
  const [workstationId, setWorkstationId] = useState(null);
  const [workstationsList, setWorkstationsList] = useState([]);
  const [mapWorkstationList, setMapWorkstationList] = useState(null);

  const history = useHistory();
  const token = useSelector(selectAccessToken);
  const site = useSelector(selectSiteFilter);
  const building = useSelector(selectBuildingFilter);
  const selectedFloors = useSelector(selectFloorsFilter);
  const userList = useSelector(selectUserManagementList);

  const dispatch = useDispatch();
  const mapRef = useRef();

  const { data: mapViewData, isLoading } = useGetMapViewWorkstationsQuery(
    { site, building, floor: selectedFloors[0] },
    { skip: !site || !selectedFloors?.length }
  );
  const { data: workstationData, isLoading: isWorkstationLoading } = useWorkstationsByBuildingsQuery(
    { site, building, date: getFormattedDate(new Date(), "yyyy-MM-dd") },
    { skip: !token || !site || !building }
  );

  const handleChangeView = (event) => {
    history.replace(`/${ROUTE_WORKSTATIONS}`);
  };

  const onSiteAndBuildingFilterClick = () => {
    setShowSidebar(true);
  };

  const onRectElementSelected = (data) => {
    setSvgRectElements((prevData) => {
      if (isEqualData(prevData, data)) {
        return data;
      } else {
        return prevData;
      }
    });
  };

  const onPathElementSelected = (data) => {
    setSvgPathElement((prevData) => {
      if (isEqualData(prevData, data)) {
        return data;
      } else {
        return prevData;
      }
    });
  };

  useEffect(() => {
    if (selectedFloors.length) {
      setBuildingPlan(BUILDING_PLANS?.find((plan) => plan?.floor === selectedFloors[0]));
    }
  }, [selectedFloors]);

  useEffect(() => {
    if (workstationData && !isWorkstationLoading) {
      const mappedList = getMappedWorkstationList(workstationData[building], userList);
      setWorkstationsList(mappedList);
      dispatch(setWorkstationsByBuildings(mappedList));
    }
  }, [workstationData, isWorkstationLoading]);

  useEffect(() => {
    if (isLoading || !mapViewData?.rooms) return;

    if (!workstationsList?.length) {
      setMapWorkstationList(mapViewData);
    } else {
      const rooms = { ...mapViewData.rooms };

      for (const key in mapViewData.rooms) {
        const room = mapViewData.rooms[key];

        const updatedRoom = room?.map((seat) => {
          const { permanentOwner, assignedEmployee } = workstationsList.find(
            (i) => seat.workstationId === i.workstationId
          );

          return {
            ...seat,
            permanentOwner,
            assignedEmployee
          };
        });

        rooms[key] = updatedRoom;
      }

      setMapWorkstationList({ rooms });
    }
  }, [mapViewData, isLoading, workstationsList]);

  useEffect(() => {
    if (isValidArray(svgRectElements) && svgRectElements.length && mapWorkstationList) {
      const empty = getEmptyWorkstations(svgRectElements, mapWorkstationList);

      const workstationsList = svgRectElements?.flatMap((element) => {
        const x = element?.x?.baseVal?.valueAsString;
        const y = element?.y?.baseVal?.valueAsString;
        const transform = element.attributes?.transform?.value;
        const height = element?.height?.baseVal?.valueAsString;
        const width = element?.width?.baseVal?.valueAsString;
        const roomName = element.attributes?.room_name?.value;

        const workstations =
          Object.hasOwn(mapWorkstationList?.rooms, parseInt(roomName)) && mapWorkstationList?.rooms[parseInt(roomName)];

        if (workstations?.length) {
          const res = workstations.map((workstation, index) => {
            const { xCoord, yCoord } = getAxisPositions({
              x: parseInt(x),
              y: parseInt(y),
              transform,
              height: parseInt(height),
              width: parseInt(width),
              index: index + 1
            });

            return {
              x: xCoord,
              y: yCoord,
              id: workstation?.workstationId,
              name: workstation?.workstationName,
              status: workstation?.status,
              owner: workstation?.permanentOwner,
              assigned: workstation?.assignedEmployee,
              ...getWorkstationMapItemSvgProps(workstation.status)
            };
          });
          return res;
        } else {
          return [];
        }
      });
      setRectMapList(workstationsList);
      setNotAssignedRectRooms(empty);
    }
  }, [svgRectElements, mapWorkstationList]);

  useEffect(() => {
    const empty = getEmptyWorkstations(svgPathElements, mapWorkstationList);

    if (isValidArray(svgPathElements) && svgPathElements.length && mapWorkstationList) {
      const workstationsList = svgPathElements?.flatMap((element) => {
        const roomName = element.attributes?.room_name?.value;
        const d = element.attributes?.d.nodeValue;

        const workstations =
          Object.hasOwn(mapWorkstationList?.rooms, parseInt(roomName)) && mapWorkstationList?.rooms[parseInt(roomName)];

        if (workstations?.length) {
          const res = workstations.map((workstation, index) => {
            const { xCoord, yCoord } = getPathAxisPositions({ d, index: index + 1 });
            const svgProps = getWorkstationMapItemSvgProps(workstation.status);

            return {
              x: xCoord,
              y: yCoord,
              id: workstation?.workstationId,
              name: workstation?.workstationName,
              status: workstation?.status,
              owner: workstation?.permanentOwner,
              assigned: workstation?.assignedEmployee,
              ...svgProps
            };
          });
          return res;
        } else {
          return [];
        }
      });
      setPathMapList(workstationsList);
      setNotAssignedPathRooms(empty);
    }
  }, [svgPathElements, mapWorkstationList]);

  useEffect(() => {
    if (pathMapList?.length || rectMapList?.length) {
      const payload = [...pathMapList, ...rectMapList];
      setWorkstationMapList(payload);
    }
  }, [pathMapList, rectMapList]);

  useEffect(() => {
    if (notAssignedRectRooms?.length || notAssignedPathRooms?.length) {
      const payload = [...notAssignedRectRooms, ...notAssignedPathRooms];
      setNotAssignedRoomsList(payload);
    }
  }, [notAssignedPathRooms, notAssignedRectRooms]);

  const onEmptyRoomClick = (room) => {
    setRoomName(room);
    setIsEdit(false);
    setShowAddSidebar(true);
  };

  const onWorkstationClick = (id) => {
    setWorkstationId(id);
    setShowInfoSidebar(true);
  };

  const getMapWidth = (width) => {
    if (width > 1850) {
      return {
        xCoord: (mapRef?.current?.offsetLeft + mapRef?.current?.offsetWidth) / 16,
        stageScale: 0.7
      };
    }

    if (width > 1536) {
      return {
        xCoord: (mapRef?.current?.offsetLeft + mapRef?.current?.offsetWidth) / 20,
        stageScale: 0.5
      };
    }

    if (width > 1150) {
      return {
        xCoord: (mapRef?.current?.offsetLeft + mapRef?.current?.offsetWidth) / 20
      };
    }
  };

  const stageAttributes = getMapWidth(mapRef?.current?.offsetWidth);

  return (
    <Box width="100%" ref={mapRef}>
      {showHeader ? (
        <Typography variant="h3" fontWeight="700">
          Workstations
        </Typography>
      ) : null}
      <Paper sx={{ width: "100%", mb: 2, mt: 2 }}>
        <Box pt={2}>
          <Grid container display="flex" alignItems="flex-start" spacing={2} px={4} pb={2}>
            <Grid item>
              <SiteAndBuilding onClick={onSiteAndBuildingFilterClick} />
            </Grid>
            <Grid item>
              <FloorSelector isMultiple={false} />
            </Grid>
            <Grid item sx={{ marginLeft: "auto" }}>
              <FormControl>
                <RadioGroup row value="map" onChange={handleChangeView}>
                  <FormControlLabel control={<Radio color="success" />} label="List" value="list" />
                  <FormControlLabel control={<Radio color="success" />} label="Map" value="map" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          {!isLoading && buildingPlan ? (
            <Box
              px={4}
              position="relative"
              overflow="hidden"
              sx={{ height: showHeader ? "calc(100vh - 270px)" : "440px" }}
            >
              <BoxContainer>
                <WorkstationInfo />
              </BoxContainer>
              <Box>
                <MainStage
                  workstationsMapList={workstationsMapList}
                  notAssignedRoomsList={notAssignedRoomsList}
                  onEmptyRoomClick={onEmptyRoomClick}
                  onWorkstationClick={onWorkstationClick}
                  buildingPlan={buildingPlan?.plan}
                  xCoord={stageAttributes?.xCoord}
                  stageScale={stageAttributes?.stageScale}
                />

                <Box display="none">
                  <SvgLoader id="svgPlan" path={buildingPlan?.plan} style={{ width: "100%", height: "auto" }}>
                    <SvgProxy selector="rect" onElementSelected={onRectElementSelected} />
                    <SvgProxy selector="path" onElementSelected={onPathElementSelected} />
                  </SvgLoader>
                  <Box
                    position={"absolute"}
                    sx={{ width: "15px", height: "15px", borderRadius: "50%", background: "blue" }}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box height="calc(100vh - 270px)" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Paper>
      <SiteAndBuildingPage isDrawer isOpen={showSidebar} setIsOpen={setShowSidebar} />
      <FilterWorkstationSidebar isDrawer isOpen={showFilterSidebar} setIsOpen={setShowFilterSidebar} />
      {showAddSidebar && (
        <WorkstationPage
          history={history}
          isOpen={showAddSidebar}
          setIsOpen={setShowAddSidebar}
          isEdit={isEdit}
          roomName={roomName}
        />
      )}
      {showInfoSidebar && (
        <WorkstationInfoPage
          history={history}
          isOpen={showInfoSidebar}
          setIsOpen={setShowInfoSidebar}
          workstationId={workstationId}
          isMapView={true}
        />
      )}
    </Box>
  );
};

const BoxContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "32px",
  top: theme.spacing(2),
  background: "#fff",
  borderRadius: theme.spacing(1),
  width: "296px",
  zIndex: 20,
  boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.35)"
}));

WorkstationMapView.propTypes = {
  path: PropTypes.string,
  showHeader: PropTypes.bool
};

export default React.memo(WorkstationMapView);
