import React from "react";
import { Divider, Grid, Paper, Stack, Typography, Box } from "@mui/material";
import styled from "@mui/material/styles/styled";
import IncomingArrowsImage from "shared/assets/images/incoming-arrows.svg";
import OutgoingArrowsImage from "shared/assets/images/outgoing-arrows.svg";
import GroupIcon from "shared/assets/icons/group.svg";

const incomingConnectionsData = [
  { label: "Legal", value: "47%" },
  { label: "Marketing", value: "21%" },
  { label: "R&D", value: "9%" },
  { label: "Algo", value: "9%" },
  { label: "All others...", value: "14%" }
];

const outgoingConnectionsData = [
  { label: "Planning", value: "44%" },
  { label: "Marketing", value: "25%" },
  { label: "R&D", value: "12%" },
  { label: "Algo", value: "8%" },
  { label: "All others...", value: "13%" }
];

const Connections = () => {
  return (
    <Box>
      <Box py={2}>
        <Typography variant="h6">Incoming connections</Typography>
        <Grid container spacing={2} my={2}>
          <Grid item xs={3}>
            <Typography variant="h6">
              Amount:{" "}
              <ConnectionScore fontWeight="600" component="span" value={8.1}>
                8.1
              </ConnectionScore>
            </Typography>
            <Typography variant="h6">
              Diversity:{" "}
              <ConnectionScore fontWeight="600" component="span" value={8.5}>
                8.5
              </ConnectionScore>
            </Typography>
          </Grid>
          <Box pt={2} ml={1} position="relative">
            <Stack spacing={2.4} position="relative" zIndex="15">
              {incomingConnectionsData.map(({ label, value }, index) => (
                <Item key={index}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography mr={2}>{label}</Typography>
                    <Typography>{value}</Typography>
                  </Box>
                </Item>
              ))}
            </Stack>
            <Box zIndex="1">
              <IncomingArrows src={IncomingArrowsImage} />
            </Box>
            <IncomingConnectionsCircle top="40%" left="365px">
              <Box textAlign="center">
                <img src={GroupIcon} />
                <Typography>FINANCE</Typography>
              </Box>
            </IncomingConnectionsCircle>
          </Box>
        </Grid>
      </Box>
      <Divider color="#8D9096" />
      <Box py={2}>
        <Typography variant="h6">Outgoing connections</Typography>
        <Grid container spacing={2} my={2}>
          <Grid item xs={8}>
            <Typography variant="h6">
              Amount:{" "}
              <ConnectionScore fontWeight="600" component="span" value={8.4}>
                8.4
              </ConnectionScore>
            </Typography>
            <Typography variant="h6">
              Diversity:{" "}
              <ConnectionScore fontWeight="600" component="span" value={8.6}>
                8.6
              </ConnectionScore>
            </Typography>
          </Grid>
          <Box pt={2} position="relative" marginLeft="auto">
            <OutgoingConnectionsCircle top="40%" right="360px">
              <Box textAlign="center">
                <img src={GroupIcon} />
                <Typography>FINANCE</Typography>
              </Box>
            </OutgoingConnectionsCircle>
            <OutgoingArrows src={OutgoingArrowsImage} />
            <Stack spacing={2.4} zIndex="15" position="relative">
              {outgoingConnectionsData.map(({ label, value }, index) => (
                <Item key={index}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography mr={2}>{label}</Typography>
                    <Typography>{value}</Typography>
                  </Box>
                </Item>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

const ConnectionScore = styled(Typography)(({ value }) => ({
  color: value >= 8.2 ? "#108000" : "#000"
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1.2, 2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  [theme.breakpoints.down("xl")]: {
    padding: theme.spacing(0.8)
  }
}));

const IncomingArrows = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "auto",
  height: "99%",
  top: "8px",
  left: "16px",

  [theme.breakpoints.up("md")]: {
    height: "99%"
  },

  [theme.breakpoints.down("xl")]: {
    height: "97%",
    left: "0"
  }
}));

const OutgoingArrows = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "auto",
  height: "97%",
  top: "14px",
  right: "70px",

  [theme.breakpoints.up("md")]: {
    height: "97%"
  },

  [theme.breakpoints.down("xl")]: {
    height: "95%"
  }
}));

const IncomingConnectionsCircle = styled(Box)(({ theme, top, left, right }) => ({
  border: "1px solid #0099CC",
  borderRadius: "50%",
  background: "#D4DCE8",
  width: "80px",
  height: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  zIndex: 20,
  top,
  left,

  [theme.breakpoints.up("md")]: {
    width: "80px",
    height: "80px",
    top,
    left
  },

  [theme.breakpoints.down("xl")]: {
    left: "275px",
    width: "70px",
    height: "70px"
  },

  [theme.breakpoints.down("md")]: {
    left: "250px",
    width: "70px",
    height: "70px"
  }
}));

const OutgoingConnectionsCircle = styled(Box)(({ theme, top, right }) => ({
  border: "1px solid #0099CC",
  borderRadius: "50%",
  background: "#D4DCE8",
  width: "80px",
  height: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  zIndex: 20,
  top,
  right,

  [theme.breakpoints.up("md")]: {
    width: "80px",
    height: "80px",
    top,
    right
  },

  [theme.breakpoints.down("xl")]: {
    right: "300px",
    width: "70px",
    height: "70px"
  },

  [theme.breakpoints.down("md")]: {
    right: "250px",
    width: "70px",
    height: "70px"
  }
}));

export default Connections;
