import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const PageTitle = ({ title }) => (
  <Typography variant="h4" fontWeight="700" mb={2}>
    {title}
  </Typography>
);

PageTitle.propTypes = {
  title: PropTypes.string
};

export default React.memo(PageTitle);
