import { selectBuildingFilter, selectDepartmentsFilter, selectFloorsFilter } from "core/selectors";
import {
  useExportAttendanceMutation,
  useExportAvgDaysPerWeekMutation,
  useExportAvgEmployeesPerDayMutation,
  useExportDayTypeChangesMutation
} from "features/attendance/attendanceApiSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import { EXPORT_ATTENDANCE_TYPE, MOMENT_DATE_FORMAT } from "shared/constants";
import { useToggle } from "shared/hooks";

const mapStateToProps = (state) => [
  selectBuildingFilter(state),
  selectFloorsFilter(state),
  selectDepartmentsFilter(state)
];

export default function useExportAttendance() {
  /* ------------------ STATE ------------------ */

  const [building, floors, departments] = useSelector(mapStateToProps);

  /* ------------------ HOOKs ------------------ */

  const exportFileSidebar = useToggle();

  /* ------------------ APIs ------------------ */

  const [exportAttendance, { isLoading: isLoadingAttendance, isSuccess: isSuccessAttendance, error: errorAttendance }] =
    useExportAttendanceMutation();

  const [
    exportAvgDaysPerWeek,
    { isLoading: isLoadingAvgDaysPerWeek, isSuccess: isSuccessAvgDaysPerWeek, error: errorAvgDaysPerWeek }
  ] = useExportAvgDaysPerWeekMutation();

  const [
    exportAvgEmployeesPerDay,
    { isLoading: isLoadingAvgEmployeesPerDay, isSuccess: isSuccessAvgEmployeesPerDay, error: errorAvgEmployeesPerDay }
  ] = useExportAvgEmployeesPerDayMutation();

  const [
    exportDayTypeChanges,
    {
      isLoading: isLoadingExportDayTypeChanges,
      isSuccess: isSuccessExportDayTypeChanges,
      error: errorExportDayTypeChanges
    }
  ] = useExportDayTypeChangesMutation();

  /* ------------------ HANDLERs ------------------ */

  /**
   *
   * @param {EXPORT_ATTENDANCE_TYPE?} type - one of EXPORT_ATTENDANCE_TYPE
   * @param {*} param1
   */
  const handleExportFile = async (type, { fileType, fromDate, toDate }) => {
    const params = {
      fromDate: moment(fromDate).format(MOMENT_DATE_FORMAT),
      toDate: moment(toDate).format(MOMENT_DATE_FORMAT),
      fileType,
      building,
      floors,
      departments
    };

    if (!type) {
      exportAttendance(params);
    }

    if (type === EXPORT_ATTENDANCE_TYPE.daysPerWeek) {
      exportAvgDaysPerWeek(params);
    }

    if (type === EXPORT_ATTENDANCE_TYPE.employeesPerDay) {
      exportAvgEmployeesPerDay(params);
    }
  };

  const handleSingleDayExport = ({ fileType, date }) => {
    exportDayTypeChanges({ date: moment(date).format(MOMENT_DATE_FORMAT), fileType, building, floors, departments });
  };

  return {
    exportFileSidebar,
    handleExportFile,
    handleSingleDayExport,
    isLoadingExportFile:
      isLoadingAttendance || isLoadingAvgDaysPerWeek || isLoadingAvgEmployeesPerDay || isLoadingExportDayTypeChanges,
    isSuccessExportFile:
      isSuccessAttendance || isSuccessAvgDaysPerWeek || isSuccessAvgEmployeesPerDay || isSuccessExportDayTypeChanges,
    errorExportFile: errorAttendance || errorAvgDaysPerWeek || errorAvgEmployeesPerDay || errorExportDayTypeChanges
  };
}
