import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@mui/material/styles/styled";
import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import CalendarArrowIcon from "shared/assets/icons/calendarArrow.svg";
import FilterButton from "shared/components/Filters/FilterButton/FilterButton";

const pickerLang = {
  months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};

const styles = {
  arrowStyle: {
    transform: "rotate(180deg)"
  }
};

export const PICKET_DEFAULT = { year: 0, month: 0 };
const CHECK = 1;
const UNCHECK = 0;

const MonthPicker = ({ onSelectMonth, labelText, defaultValue, value }) => {
  const pickerRef = React.createRef();

  const [pickerValue, setPickerValue] = useState(PICKET_DEFAULT);
  const [isActive, setIsActive] = useState(UNCHECK);
  const [displayValue, setDisplayValue] = useState("");
  const [isOpened, setIsOpened] = useState(false);

  const checkDiff = (curr, prev) => {
    return curr.year === prev.year && curr.month === prev.month;
  };

  const displayValueHandler = (value) => {
    if (checkDiff(value, PICKET_DEFAULT)) {
      setDisplayValue(labelText);
    } else {
      const monthIndex = value.month - 1;
      const month = pickerLang.months[monthIndex];

      setPickerValue(value);
      setDisplayValue(`${month} ${value.year}`);
      setIsActive(true);
    }
  };

  useEffect(() => {
    displayValueHandler(value);
  }, [value, labelText]);

  const onOpenPicker = () => {
    pickerRef.current.show();

    setIsOpened(true);
  };

  const onChange = (year, month) => {
    if (checkDiff({ year, month }, pickerValue)) {
      setPickerValue(PICKET_DEFAULT);

      setIsActive(false);
    } else {
      setPickerValue({ year, month });

      setIsActive(true);
    }
  };

  const onDismiss = () => {
    setIsOpened(false);

    if (checkDiff(pickerValue, PICKET_DEFAULT)) {
      onSelectMonth && onSelectMonth(defaultValue);
    } else {
      onSelectMonth && onSelectMonth(pickerValue);
    }
  };

  const renderLabel = () => (
    <FilterButton onClick={onOpenPicker}>
      <Typography>{displayValue}</Typography>
      <ArrowContainer>
        <ArrowDropDownIcon color="#000" style={isOpened ? styles.arrowStyle : {}} />
      </ArrowContainer>
    </FilterButton>
  );

  return (
    <Container>
      {renderLabel()}

      <PickerContainer active={isActive ? CHECK : UNCHECK}>
        <Picker
          ref={pickerRef}
          value={value}
          onChange={onChange}
          onDismiss={onDismiss}
          years={{ min: 2013 }}
          lang={pickerLang}
          age={0}
        />
      </PickerContainer>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  position: "relative"
}));

const ArrowContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const PickerContainer = styled(Box)(({ theme, active }) => ({
  "& .month-picker": {
    ".rmp-container": {
      width: "100%"
    },

    ".rmp-cell": {
      position: "relative",

      ".rmp-popup": {
        backgroundColor: "#FBFBFB",
        position: "absolute",
        right: 0,
        padding: theme.spacing(2),
        borderRadius: "5px",
        borderColor: "#ccc",

        ".rmp-pad": {
          // pickerHeader
          "& > div": {
            marginBottom: theme.spacing(2),
            position: "relative",

            label: {
              textAlign: "left",
              fontWeight: "bold",
              fontSize: theme.spacing(2),
              lineHeight: theme.spacing(3),
              color: "#737373"
            },

            i: {
              width: theme.spacing(3),
              height: theme.spacing(3),
              margin: 0,
              padding: 0,
              fontSize: 0,
              textIndent: "-9999px",
              background: `url(${CalendarArrowIcon}) center center`,

              "&:hover": {
                backgroundColor: "transparent !important",
                opacity: 0.5
              }
            },
            ".rmp-btn.prev": {
              right: theme.spacing(3),
              left: "auto"
            },
            ".rmp-btn.next": {
              transform: "rotate(180deg)",
              right: 0
            }
          },

          // pickerBody
          "& > ul": {
            "li.rmp-btn": {
              fontSize: theme.spacing(2),
              lineHeight: theme.spacing(4),
              marginBottom: theme.spacing(1),
              borderRadius: theme.spacing(2),
              color: "#444",

              "&:hover": {
                backgroundColor: "transparent",
                color: "#00B4F0"
              },

              "&.active": {
                backgroundColor: active === CHECK ? "#00B4F0" : "transparent",
                color: active === CHECK ? "#fff" : "#444",
                borderRadius: theme.spacing(2),

                "&:hover": {
                  color: active === CHECK ? "#fff" : "#00B4F0"
                }
              }
            }
          }
        }
      }
    }
  }
}));

MonthPicker.propTypes = {
  onSelectMonth: PropTypes.func.isRequired,
  value: PropTypes.object,
  labelText: PropTypes.string,
  defaultValue: PropTypes.number
};

export default MonthPicker;
