import React, { useCallback } from "react";
import PropTypes from "prop-types";
import DataTable from "shared/components/DataTable/DataTable";
import PreviousWeeksAgenda from "./components/PreviousWeeksAgenda";
import { Container, styles } from "./style/previousWeeksTabStyle";
import TotalArrivalsFooter from "../TotalArrivalsFooter";
import usePreviousWeeksTabLogic, { NUMBER_OF_WEEKS } from "./hooks/usePreviousWeeksTabLogic";
import AttendanceSummary from "../AttendanceSummary/AttendanceSummary";
import ExportFileSidebar from "shared/components/ExportFileSidebar";
import useExportAttendance from "./hooks/useExportAttendance";

const PreviousWeeksTab = (props) => {
  const {
    rows,
    columns,
    monthPickerValue,
    isLoading,
    onSelectMonth,
    onRenderFooterComponent,
    avgEmployeeDays,
    avgEmployeeArrivals,
    avgEmployees,
    startDate,
    endDate
  } = usePreviousWeeksTabLogic(props);

  const {
    exportFileSidebar,
    handleExportFile,
    isLoadingExportFile,
    isSuccessExportFile,
    errorExportFile,
    handleSingleDayExport
  } = useExportAttendance();

  const renderFooterComponent = useCallback(() => {
    const data = onRenderFooterComponent();

    return (
      <TotalArrivalsFooter title="Total actual arrivals" titleWidth={"660px"} arrivalsData={data} showBorder={true} />
    );
  }, [onRenderFooterComponent]);

  const renderSummary = useCallback(
    () => (
      <AttendanceSummary
        avgEmployeeDays={avgEmployeeDays}
        avgEmployeeArrivals={avgEmployeeArrivals}
        avgEmployees={avgEmployees}
      />
    ),
    [avgEmployeeDays, avgEmployeeArrivals, avgEmployees]
  );

  return (
    <>
      <Container>
        <DataTable
          rows={rows}
          rowIdFieldName={"employeeId"}
          columns={columns}
          showDeleteColumn={false}
          showEditColumn={false}
          showInfoColumn={false}
          showMonthPicker={true}
          showSiteAndBuilding
          showFloorSelector
          showDepartmentSelector
          showSearch
          autoHeight={false}
          isLoading={isLoading}
          monthPickerProps={{
            onSelectMonth,
            value: monthPickerValue,
            defaultValue: NUMBER_OF_WEEKS,
            labelText: `Past ${NUMBER_OF_WEEKS} weeks`
          }}
          sx={styles.tableStyle}
          renderFooterComponent={renderFooterComponent}
          renderToolbarDetailsComponent={renderSummary}
          onExportFile={exportFileSidebar.on}
        />

        <PreviousWeeksAgenda />
      </Container>

      <ExportFileSidebar
        startDate={startDate}
        endDate={endDate}
        isOpen={exportFileSidebar.enable}
        setIsOpen={exportFileSidebar.set}
        onClose={exportFileSidebar.off}
        handleExportHistory={handleExportFile}
        handleSingleDayExport={handleSingleDayExport}
        loading={isLoadingExportFile}
        success={isSuccessExportFile}
        error={errorExportFile}
      />
    </>
  );
};

PreviousWeeksTab.propTypes = {
  setSummary: PropTypes.func
};

export default PreviousWeeksTab;
