import React from "react";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InfoRedIcon from "shared/assets/icons/info-red.svg";
import { setShowNoAssignmentSidebar } from "features/workstations/workstationsSlice";
import styled from "@mui/material/styles/styled";

const NoAssignment = () => {
  const dispatch = useDispatch();

  const onClick = () => dispatch(setShowNoAssignmentSidebar(true));

  return (
    <Box>
      <NoAssignmentButton onClick={onClick}>
        <IconImage src={InfoRedIcon} />
        <Typography ml={0.5} color="#00203E">
          No Assignment
        </Typography>
      </NoAssignmentButton>
    </Box>
  );
};

const NoAssignmentButton = styled(Button)(({ theme }) => ({
  border: "1px solid #D52B1E",
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 2, 1, 5),
  position: "relative"
}));

const IconImage = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  marginTop: "-12px",
  left: theme.spacing(1.5)
}));

export default NoAssignment;
