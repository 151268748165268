import { Box, Button, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { ReactComponent as DeleteSvg } from "shared/assets/icons/delete.svg";

export const HelperText = styled(Box)(({ theme }) => ({
  top: theme.spacing(5),
  color: "#d32f2f"
}));

export const DeleteIcon = styled(DeleteSvg)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  marginRight: theme.spacing(1),
  color: "#D52B1E"
}));

export const DeleteText = styled(Typography)(() => ({
  color: "#D52B1E"
}));

export const RemoveButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 3),
  margin: theme.spacing(2, 0),
  width: "100%",

  "&:disabled": {
    "& > img": {
      opacity: 0.5
    }
  },

  "&:hover": {
    background: "rgba(255,3,3,0.04)"
  }
}));

export const DeleteButton = styled(RemoveButton)(() => ({
  borderColor: "#D52B1E"
}));

export const CheckboxWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  alignItems: "flex-end"
}));
