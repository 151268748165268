import React from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const UserStatus = ({ children, isUserInfo = false }) => {
  return <Status isUserInfo={isUserInfo}>{children}</Status>;
};

const Status = styled(Box)(({ theme, isUserInfo }) => ({
  position: isUserInfo && "absolute",
  color: "#2CA01C",
  background: "white",
  textAlign: "center",
  border: "2px solid #2CA01C",
  padding: theme.spacing(0, 1),
  borderRadius: theme.spacing(0.5),
  width: "fit-content",
  bottom: isUserInfo && "14px",
  left: isUserInfo && "8px"
}));

UserStatus.propTypes = {
  children: PropTypes.node.isRequired,
  isUserInfo: PropTypes.bool
};

export default UserStatus;
