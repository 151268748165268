import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement
} from "chart.js";
import Sidebar from "shared/components/Sidebar/Sidebar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DataTableLite from "shared/components/DataTable/DataTableLite";
import { format } from "date-fns";
import { activitiesIconsMap } from "pages/ActivitiesPage/utils/activitiesUtils";
import useViewGroupSidebarLogic from "./hooks/useViewGroupSidebarLogic";
import EditIcon from "shared/assets/icons/edit.svg";
import {
  ActionButton,
  GroupIcon,
  MembersList,
  ActionContainer,
  EditButton,
  CustomizedIcon,
  SessionsContainer,
  SessionItem,
  SessionTypography
} from "./style/viewGroupSidebarStyle";
import CustomizedDivider from "shared/components/CustomizedDivider/CustomizedDivider";
import SendIcon from "shared/assets/icons/send.svg";
import { columns, barChartOptions } from "./utils/viewGroupSidebarUtils";
import { Bar } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement);

const ViewGroupSidebar = ({ history, isOpen, setIsOpen, isDrawer, group, onSendReminderClick, ...props }) => {
  const { rows, activeTeams, isLoading, onEditSessionClick, onViewSessionClick, onEdit, onAddSessionClick, sessions } =
    useViewGroupSidebarLogic({
      ...props,
      setIsOpen,
      group
    });

  const renderInfoItem = (label, value) => (
    <>
      <Grid item xs={4}>
        <Typography variant="h6" color="#6B6C72">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} display="flex" alignItems="center">
        <Typography variant="h6">{value}</Typography>
      </Grid>
    </>
  );

  const renderSession = (session, index) => {
    if (!session?.id) return null;

    return (
      <SessionItem key={index}>
        <Typography variant="h6">{format(new Date(session?.startTime), "MMMM dd, k:mm")}</Typography>

        <Box sx={{ marginLeft: "auto" }}>
          <Button
            onClick={() => onSendReminderClick(session.id)}
            color="secondary"
            sx={{ minWidth: "40px", minHeight: "40px" }}
          >
            <img src={SendIcon} style={{ width: "18px", height: "18px" }} />
          </Button>
          <Button
            onClick={() => onEditSessionClick(session)}
            color="secondary"
            sx={{ minWidth: "40px", minHeight: "40px" }}
          >
            <CreateOutlinedIcon fontSize="small" />
          </Button>
          <Button
            onClick={() => onViewSessionClick(session)}
            color="secondary"
            sx={{ minWidth: "40px", minHeight: "40px" }}
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </Button>
        </Box>
      </SessionItem>
    );
  };

  return (
    <Sidebar
      history={history}
      isOpen={isOpen}
      isDrawer={isDrawer}
      setIsOpen={setIsOpen}
      resolveLabel="OK"
      showCancelButton={false}
      onResolve={() => setIsOpen(false)}
    >
      {/* Head */}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            {group.icon ? <GroupIcon src={activitiesIconsMap[group.icon]} /> : null}

            <Typography variant="h5" fontWeight="700">
              {group.name} Group
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} justifyContent={"flex-end"}>
          <ActionButton onClick={onAddSessionClick}>{"New session"}</ActionButton>
        </Grid>
      </Grid>

      {/* Info */}
      <Grid container spacing={1} mt={2} mb={3} alignItems="center">
        {group?.employeeIds?.length ? renderInfoItem("Members:", group.employeeIds.length) : null}

        {group?.previous?.startTime
          ? renderInfoItem("Prev. session:", format(new Date(group.previous.startTime), "MMMM dd"))
          : null}

        <Grid container item>
          <Grid item xs={4}>
            <SessionTypography variant="h6" color="#6B6C72">
              {"Next session:"}
            </SessionTypography>
          </Grid>

          <Grid item xs={8} display="flex">
            <SessionsContainer>
              {sessions?.length ? sessions.map((session, index) => renderSession(session, index)) : null}
            </SessionsContainer>
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      <Box>
        {/* Statistics */}
        <Box mt={2}>
          <Typography variant="h6">Active teams in the group</Typography>
          <Box mt={2}>
            <Bar options={barChartOptions} data={activeTeams} />
          </Box>
        </Box>

        <Divider />

        {/* Members */}
        <Box mt={2}>
          <Typography variant="h6">Group members</Typography>
          <MembersList>
            <DataTableLite columns={columns} rows={rows} hideFooter={true} isLoading={isLoading} />
          </MembersList>
        </Box>

        <ActionContainer mt={4}>
          <CustomizedDivider bottomWidth="1px" hrColor="#8D9096" />
          <Box display="flex" justifyContent="center">
            <EditButton onClick={onEdit}>
              <CustomizedIcon src={EditIcon} />
              <Typography>Edit info</Typography>
            </EditButton>
          </Box>
        </ActionContainer>
      </Box>
    </Sidebar>
  );
};

ViewGroupSidebar.propTypes = {
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onEditGroup: PropTypes.func,
  onAddSession: PropTypes.func,
  onEditSession: PropTypes.func,
  isDrawer: PropTypes.bool,
  group: PropTypes.object,
  onSendReminderClick: PropTypes.func
};

export default withRouter(ViewGroupSidebar);
