import { apiSlice } from "core/api/apiSlice";

export const roomsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    roomsMap: build.query({
      query: (args) => ({
        url: "/seats/rooms/map",
        method: "GET",
        params: args
      })
    })
  })
});

export const { useRoomsMapQuery } = roomsApiSlice;
