import { isValidArray } from "shared/lib/validation";

const BWITH_KEY = "bwith";

const getNonBWithUsers = (list = [], field = "department") => {
  if (!list?.length) return [];

  return list.filter((employee) => {
    if (isValidArray(employee[field])) {
      return !employee[field].some((item) => item.toLowerCase().includes(BWITH_KEY));
    } else {
      return !employee[field].toLowerCase().includes(BWITH_KEY);
    }
  });
};

export default getNonBWithUsers;
