import { createSlice } from "@reduxjs/toolkit";
import getNonBWithUsers from "shared/lib/getNonBWithUsers";
import { userApiSlice } from "./userManagementApiSlice";

const initialState = {
  list: [],
  unassignedUsersList: [],
  departments: []
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setUserManagementList: (state, action) => {
      const list = getNonBWithUsers(action.payload);

      state.list = list;
    },
    setUnassignedUsers: (state, action) => {
      const list = getNonBWithUsers(action.payload);

      state.unassignedUsersList = list;
    },
    setDepartmentsList: (state, action) => {
      state.departments = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApiSlice.endpoints.users.matchFulfilled, (state, action) => {
      const list = getNonBWithUsers(action.payload?.employees).sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`;
        const nameB = `${b.firstName} ${b.lastName}`;

        return nameA.localeCompare(nameB);
      });

      state.list = list;
    });
  }
});

export const { setUserManagementList, setUnassignedUsers, setDepartmentsList } = userManagementSlice.actions;

export default userManagementSlice.reducer;
