import { useCallback, useState } from "react";
import cytoscape from "cytoscape";
import cola from "cytoscape-cola";
import { classNames } from "../style/relationChartStyle";
import { GenerateColors } from "shared/lib/generators";

cytoscape.use(cola);

const genColor = new GenerateColors();

export function useRelationChartLogic({ relations, users }) {
  const [el, setEl] = useState(null);

  const nodes = relations2Nodes(relations, users) || [];

  const edges = relation2Edges(relations, nodes) || [];

  const onRef = useCallback((node) => setEl(node), []);

  return { onRef, nodes, edges };
}

/**
 * @param {{
 *	companyId: number;
 *	firstEmployee: number;
 *	relationType: string | "DECLARED_PREFERRED_COLLEAGUE";
 *	secondEmployee: number;
 *	site: string;
 *}[]} relations
 */
function relations2Nodes(relations, users) {
  if (!relations?.length) return [];

  const usersA = relations.map(({ firstEmployee }) => firstEmployee).filter(Boolean);
  const usersB = relations.map(({ secondEmployee }) => secondEmployee).filter(Boolean);
  const uniqueIds = [...new Set([...usersA, ...usersB])];

  const { incomes, outcomes } = relation2Map(relations);

  // console.log({ incomesLen: incomes.length, outcomesLen: outcomes.length, incomes, outcomes });

  return !uniqueIds?.length
    ? []
    : uniqueIds.map((id) => {
        const income = incomes.find(([inId]) => id === inId);

        return {
          data: {
            id,
            score: income ? income[1]?.length : 0,
            ...userFill(users, id)
          },
          classes: [classNames.nodes.employee]
        };
      });
}

function userFill(users, userId) {
  const user = users?.employees?.find(({ id }) => id === userId);

  return user
    ? {
        id: userId,
        department: user.department,
        label: `${user.firstName} ${user.lastName}`
      }
    : {};
}

function relation2Map(relations) {
  const incomes = [];
  const outcomes = [];

  relations.forEach(({ firstEmployee, secondEmployee }) => {
    subMap(incomes, firstEmployee, secondEmployee);
    subMap(outcomes, secondEmployee, firstEmployee);
  });

  return {
    incomes,
    outcomes
  };
}

function subMap(map, idA, idB) {
  const index = map.findIndex(([id]) => id === idA);

  if (index !== -1) {
    map[index][1].push(idB);
  } else {
    map.push([idA, [idB]]);
  }
}

/**
 * @param {{
 *	companyId: number;
 *	firstEmployee: number;
 *	relationType: string | "DECLARED_PREFERRED_COLLEAGUE";
 *	secondEmployee: number;
 *	site: string;
 *}[]} relations
 */
function relation2Edges(relations) {
  if (!relations?.length) return [];

  return relations
    .map(({ firstEmployee, secondEmployee }) => {
      if (!firstEmployee || !secondEmployee) return null;

      return {
        data: { id: `${firstEmployee}-${secondEmployee}`, source: firstEmployee, target: secondEmployee }
      };
    })
    .filter(Boolean);
}
