import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";
import neighborhoodsReducer from "../features/neighborhoods/neighborhoodsSlice";
import userManagementReducer from "../features/userManagement/userManagementSlice";
import workstationsReducer from "../features/workstations/workstationsSlice";
import adminSettingsReducer from "../features/adminSettings/adminSettingsSlice";
import filtersReducer from "../features/filters/filtersSlice";
import siteReducer from "../features/site/siteSlice";
import teamUsersReducer from "../features/teamUsers/teamUsersSlice";
import roomReducer from "../features/rooms/roomsSlice";
import tableSortReducer from "../features/tableSort/tableSortSlice";
import profileReducer from "../features/profile/profileSlice";
import { SIGN_OUT_ACTION_TYPE } from "shared/constants";

const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  neighborhoods: neighborhoodsReducer,
  userManagement: userManagementReducer,
  workstations: workstationsReducer,
  adminSettings: adminSettingsReducer,
  filters: filtersReducer,
  site: siteReducer,
  teamUsers: teamUsersReducer,
  room: roomReducer,
  tableSort: tableSortReducer,
  profile: profileReducer
});

const store = configureStore({
  reducer: (store, action) => {
    if (action.type === SIGN_OUT_ACTION_TYPE) {
      return appReducer(undefined, action);
    }

    return appReducer(store, action);
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== "production"
});

export default store;
