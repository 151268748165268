import { useSelector } from "react-redux";
import {
  selectBuildingFilter,
  selectAccessToken,
  selectDepartmentsFilter,
  selectFloorsFilter,
  selectSiteFilter,
  selectCompanyAndSiteSettings,
  selectCToken
} from "core/selectors";
import moment from "moment";
import {
  useAttendanceQuery,
  useAvgDaysPerWeekQuery,
  useAvgEmployeesPerDayQuery,
  useOverlapsQuery
} from "features/attendance/attendanceApiSlice";
import { useEffect, useMemo, useState } from "react";
import { calculateTotalArrivals } from "pages/Attendance/helpers";
import getNonBWithUsers from "shared/lib/getNonBWithUsers";
import { useToggle } from "shared/hooks";
import { useRelationsBySiteQuery } from "features/relations/relationsApiSlice";
import { useUsersQuery } from "features/userManagement/userManagementApiSlice";
import { MOMENT_DATE_FORMAT } from "shared/constants";

export const START_DATE = moment().startOf("week").format(MOMENT_DATE_FORMAT);
export const END_DATE = moment().endOf("week").format(MOMENT_DATE_FORMAT);

export const AVG_START_DATE = moment().subtract(1, "months").format(MOMENT_DATE_FORMAT);
export const AVG_END_DATE = moment().format(MOMENT_DATE_FORMAT);

const mapStateToProps = (state) => ({
  site: selectSiteFilter(state),
  token: selectAccessToken(state),
  building: selectBuildingFilter(state),
  floors: selectFloorsFilter(state),
  departments: selectDepartmentsFilter(state),
  companyAndSiteSettings: selectCompanyAndSiteSettings(state),
  cToken: selectCToken(state)
});

export default function useInsightsTabLogic() {
  /* ------------------ HOOKs ------------------ */

  const relationChartModal = useToggle();

  /* ------------------ STATEs ------------------ */
  const [chartOptions, setChartOptions] = useState({ checkInData: [], officeData: [], labels: [] });

  const { token, building, departments, floors, site, companyAndSiteSettings, cToken } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: overlapsData, isLoading } = useOverlapsQuery({ site, building, departments, floors }, { skip: !token });
  const { data: relationData, isLoading: isLoadingRelations } = useRelationsBySiteQuery({ cToken, site });
  const { data: users, isLoading: isLoadingUsers } = useUsersQuery();

  const { data: attendanceData, isLoading: isLoadingAttendance } = useAttendanceQuery(
    {
      fromDate: START_DATE,
      toDate: END_DATE,
      building,
      departments,
      floors
    },
    { skip: !token }
  );

  const { data: avgEmployeesPerDayData, isLoading: isLoadingAvgEmployee } = useAvgEmployeesPerDayQuery({
    fromDate: AVG_START_DATE,
    toDate: AVG_END_DATE,
    building,
    departments,
    floors
  });

  const { data: avgDaysPerWeekData, isLoading: isLoadingAvgDays } = useAvgDaysPerWeekQuery({
    fromDate: AVG_START_DATE,
    toDate: AVG_END_DATE,
    building,
    departments,
    floors
  });

  /* ------------------ MEMOs ------------------ */

  const rows = useMemo(() => (overlapsData?.length ? getNonBWithUsers(overlapsData) : []), [overlapsData]);

  /* ------------------ HANDLERs ------------------ */

  const collectChartDataHandler = () => {
    const checkInData = [];
    const officeData = [];
    const labels = [];

    const arrivalsData = calculateTotalArrivals(attendanceData.data, {
      startDate: START_DATE,
      endDate: END_DATE,
      sundayFirst: companyAndSiteSettings.first_day_of_week === "Sunday",
      addWeekends: false
    });

    arrivalsData.forEach((item) => {
      checkInData.push(item?.checkInCount);
      officeData.push(item?.officeDaysCount);
      labels.push(moment(item?.date).format("ddd").toUpperCase());
    });

    setChartOptions({ checkInData, officeData, labels });
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!attendanceData || isLoadingAttendance) return;

    collectChartDataHandler();
  }, [attendanceData, companyAndSiteSettings, isLoadingAttendance]);

  return {
    rows,
    isLoading,
    chartOptions,
    relationChartModal,
    relationData,
    users,
    isLoadingRelations: isLoadingRelations || isLoadingUsers,
    avgEmployeesPerDayData,
    avgDaysPerWeekData,
    isLoadingAvgEmployee,
    isLoadingAvgDays,
    isLoadingAttendance
  };
}
