import React, { useState, useEffect, memo } from "react";
import { PropTypes } from "prop-types";
import { Box, Typography } from "@mui/material";
import { parse, format, isToday } from "date-fns";
import styled from "@mui/material/styles/styled";
import { WORK_DAYS_COUNT } from "shared/constants";

const DateOfWeekColumnTitle = ({ schedule }) => {
  const [weeks, setWeeks] = useState([]);

  const checkCurrentWeekHandler = (week) => {
    return week.some((day) => isToday(parse(day.date, "yyyy-MM-dd", new Date())));
  };

  const parseScheduleHandler = () => {
    if (!schedule?.length) return [];

    const sliced = [];
    const copy = [...schedule];

    while (copy.length) {
      const week = copy.splice(0, WORK_DAYS_COUNT);

      sliced.push(week);
    }

    setWeeks(sliced);
  };

  useEffect(() => {
    if (!schedule) return null;

    parseScheduleHandler();
  }, [schedule]);

  const renderWeekItem = (week, orderNumber) => {
    if (!week?.length) return null;
    const isCurrentWeek = checkCurrentWeekHandler(week);

    const startDate = format(parse(week[0].date, "yyyy-MM-dd", new Date()), "dd");
    const endDate = format(parse(week[week.length - 1].date, "yyyy-MM-dd", new Date()), "dd.MM");

    const weekTitle = isCurrentWeek ? "This week" : `Week - ${orderNumber}`;
    const dateText = `${startDate} - ${endDate}`;

    return (
      <Week key={orderNumber}>
        <Typography fontWeight={isCurrentWeek ? "bold" : "normal"} align={"center"}>
          {weekTitle}
        </Typography>
        <Typography fontWeight={isCurrentWeek ? "bold" : "normal"} align={"center"}>
          {dateText}
        </Typography>
      </Week>
    );
  };

  return <Container>{weeks.map((week, index) => renderWeekItem(week, weeks.length - (index + 1)))}</Container>;
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%"
}));

const Week = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  width: "20%",
  alignItems: "center",
  padding: theme.spacing(0, 0.5),
  borderLeft: "1px solid #6B6C72"
}));

DateOfWeekColumnTitle.propTypes = {
  schedule: PropTypes.array
};

export default memo(DateOfWeekColumnTitle);
