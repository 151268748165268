import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Axios from "axios";
import { setUserInfo } from "features/auth/authSlice";
import jwtDecode from "jwt-decode";
import { STORAGE_USER_INFO } from "shared/constants";
import { signOut, timeoutPromise } from "shared/lib";

class DelayInterceptor {
  enabled = false;

  getEnabled() {
    return this.enabled;
  }

  setEnabled(value) {
    this.enabled = value;
  }
}

const delayClient = new DelayInterceptor();

export const axios = Axios.create({
  headers: {
    accept: "application/json"
  }
});

const BASE_URL = process.env.NODE_ENV === "development" ? "https://dev.bwithapp.com" : "https://bwithapp.com";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    const cToken = getState().auth.cToken;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("cToken", cToken);
    }

    return headers;
  }
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403 && api.getState().auth.token) {
    if (!delayClient.getEnabled()) {
      delayClient.setEnabled(true);

      result = await fetchRefreshToken({ args, api, extraOptions });

      delayClient.setEnabled(false);
    } else {
      const resolve = async () => {
        delayClient.setEnabled(false);

        return await baseQueryWithReAuth(args, api, extraOptions);
      };

      result = await timeoutPromise(resolve, 1000);
    }
  }

  return result;
};

async function fetchRefreshToken({ args, api, extraOptions }) {
  try {
    const refreshToken = api.getState().auth.refreshToken;
    const refreshResult = await axios.post(
      `${BASE_URL}/employees/api/v1/auth/refreshToken`,
      { refreshToken },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    if (refreshResult?.data) {
      const { accessToken, refreshToken } = refreshResult.data;
      const email = api.getState().auth.email;
      const parsed = await jwtDecode(accessToken);

      const userInfo = {
        email,
        accessToken,
        refreshToken,
        cToken: parsed.cToken,
        site: parsed.site
      };

      localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userInfo));
      api.dispatch(setUserInfo(userInfo));

      return await baseQuery(args, api, extraOptions);
    } else {
      signOut(api.dispatch);
    }
  } catch (error) {
    signOut(api.dispatch);
    console.error("refresh error", error);

    return error;
  }
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({})
});
