import React from "react";

export const columnsHolidays = [
  {
    field: "name",
    headerName: "Name",
    editable: false,
    flex: 1
  },
  {
    field: "date",
    headerName: "Date",
    editable: false,
    flex: 1
  },
  {
    field: "sites",
    headerName: "Sites",
    editable: false,
    flex: 1,
    valueGetter: (params) => params.row?.sites?.join(", ")
  }
];

export const deleteHolidayModalContent = {
  title: "Delete Holiday",
  body: (
    <div>
      <span>If you choose to delete this holiday:</span>
      <ul>
        <li>All user calendars will no longer show a holiday on this date</li>
        <li>User calendars will continue behaving according to the previous forecast and user requests</li>
      </ul>
    </div>
  )
};
