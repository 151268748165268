import { Box, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import RejectButton from "shared/components/Buttons/RejectButton/RejectButton";
import Button from "@mui/material/Button";

export const MembersList = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  paddingTop: theme.spacing(0.5),
  marginTop: theme.spacing(2),
  height: theme.spacing(41),
  borderRadius: "3px",
  border: "1px solid #E3E5E8"
}));

export const ConfirmationText = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  textAlign: "center",
  lineHeight: theme.spacing(4),
  fontSize: theme.spacing(3),
  color: "#6B6C72",
  margin: 0,
  padding: 0
}));

export const TitleRow = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
}));

export const CopyPreviousButton = styled(RejectButton)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  padding: theme.spacing(0.25, 3)
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#00203E",
  padding: theme.spacing(1, 3),
  borderRadius: theme.spacing(2),
  fontWeight: "700",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#003f77",
    color: "#fff"
  }
}));
