import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement
} from "chart.js";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CHART_DAY_LABELS } from "shared/constants";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement);

export const barData = {
  labels: CHART_DAY_LABELS,
  datasets: [
    {
      label: "Office",
      data: [16, 16, 18, 14, 12],
      backgroundColor: "#0099CC",
      stack: "Stack 0",
      barThickness: 10,
      borderRadius: 32
    }
  ]
};

const barOptions = {
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  },
  maintainAspectRatio: false
};

const lineData = {
  labels: ["APR", "MAY", "JUN", "JUL", "AUG"],
  datasets: [
    {
      label: "First dataset",
      data: [2, 3, 2, 4, 2],
      borderColor: "#0099CC",
      borderWidth: 1,
      tension: 0,
      cubicInterpolationMode: "monotone",
      drawActiveElementsOnTop: false,
      pointRadius: 3,
      pointBackgroundColor: "#0099CC"
    }
  ]
};

const lineOptions = {
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      },
      ticks: {
        reverse: false,
        stepSize: 1
      }
    }
  },
  maintainAspectRatio: false
};

const AverageDaysCharts = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6">When is the team usually in the office?</Typography>
        <Box p={2} sx={{ height: "200px", width: "auto", margin: "0 auto" }}>
          <Bar data={barData} options={barOptions} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" pb={3}>
          Average days per week
        </Typography>
        <Box p={2} sx={{ height: "200px", width: "auto", margin: "0 auto" }}>
          <Line type="line" data={lineData} options={lineOptions} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AverageDaysCharts;
