import { DAY_TYPES, MOMENT_DATE_FORMAT } from "shared/constants";
import moment from "moment";

export const getDayColor = (dayType, hasCheckedIn) => {
  const value = dayType?.toLowerCase();

  switch (value) {
    case DAY_TYPES.office:
      return hasCheckedIn ? "#00B4F0" : "#CCF2FF";
    case DAY_TYPES.home:
      return "#ECEEF1";
    default:
      return "#BABEC5";
  }
};

export const getDayOffSign = (dayType) => {
  const value = dayType?.toLowerCase();

  switch (value) {
    case DAY_TYPES.travel:
      return "t";
    case DAY_TYPES.off:
      return "o";
    case DAY_TYPES.sick:
      return "s";
    default:
      return "";
  }
};

export const getCountWeeksInMonth = ({ year, month }, sundayFirst = true) => {
  const firstOfMonth = new Date(year, month - 1, 1);
  const lastOfMonth = new Date(year, month, 0);

  const options = sundayFirst ? 0 : 6;
  const used = firstOfMonth.getDay() + options + lastOfMonth.getDate();

  return Math.ceil(used / 7);
};

export const getDateOfWeekDay = (date, sundayFirst, isLast = false) => {
  const d = new Date(date);
  const day = d.getDay();
  const targetDay = isLast ? (sundayFirst ? 4 : 5) : sundayFirst ? 0 : 1;
  const diff = d.getDate() - day + targetDay;

  return new Date(d.setDate(diff));
};

export const getFromToDates = ({ year, month }) => {
  const monthFirstDay = new Date(year, month - 1, 1);
  const monthLastDay = new Date(year, month, 0);

  const weekFirstBeginDate = getDateOfWeekDay(monthFirstDay, true);
  const weekLastEndDate = getDateOfWeekDay(monthLastDay, true, true);

  return {
    fromDate: moment(weekFirstBeginDate).format(MOMENT_DATE_FORMAT),
    toDate: moment(weekLastEndDate).format(MOMENT_DATE_FORMAT)
  };
};

export const getFromToDatesByWeeks = (weeksCount) => {
  const fromDate = moment().subtract(weeksCount, "w").startOf("w").format(MOMENT_DATE_FORMAT);
  const toDate = moment().endOf("w").format(MOMENT_DATE_FORMAT);

  return {
    fromDate,
    toDate
  };
};
