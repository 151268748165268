import { Box, Button, Container, Icon, TextField, styled } from "@mui/material";
import BackgroundImage from "shared/assets/images/login-background.png";

export const CustomizedDiv = styled("div")`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

export const CustomizedContainer = styled(Container)`
  min-width: 750px;
  min-height: 600px;
  border-radius: 13px;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
`;

export const CustomizedIcon = styled(Icon)`
  height: auto;
  width: auto;
`;

export const CustomizedTextField = styled(TextField)({
  color: "rgba(21, 20, 57, 0.4)",
  background: "#FFFFFF",
  borderRadius: "100px",
  minWidth: "400px",
  border: "1px solid transparent",

  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "none"
    },
    "& fieldset": {
      border: "none"
    }
  }
});

export const CustomizedButton = styled(Button)({
  background: "#00b4f0",
  color: "#00203e",
  borderRadius: "100px",
  fontSize: "1rem",
  "&.Mui-disabled": {
    opacity: 0.7,
    background: "#00b4f0"
  }
});

export const WrapCaptchaBox = styled(Box)`
  margin: 10px 0 0 5px;
`;

export const CustomizedErrorText = styled(Box)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -10px;
  text-align: center;
  z-index: 10;
`;

export const styles = {
  customizedTextFieldInvalidStyle: {
    borderColor: "#FF2525"
  }
};
