import React from "react";
import PropTypes from "prop-types";
import { TextField, styled } from "@mui/material";

const CustomizedTextField = ({
  onChange,
  required,
  fullWidth,
  label = "",
  placeholder,
  autoFocus,
  name,
  size = "small",
  value,
  error,
  helperText,
  styles,
  disabled,
  multiline,
  labelStyles
}) => {
  return (
    <Input
      margin="normal"
      required={required}
      fullWidth={fullWidth}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      name={name}
      autoFocus={autoFocus}
      size={size}
      value={value}
      error={error}
      multiline={multiline}
      rows={multiline ? 2 : 1}
      helperText={helperText}
      sx={[setLabelStyles(labelStyles), styles]}
      disabled={disabled}
    />
  );
};

const Input = styled(TextField)({
  color: "#00203E",
  background: "#FFFFFF",
  marginTop: 0,
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6B6C72"
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#f8fafe"
  }
});

const setLabelStyles = (styles = {}) => ({
  "& .MuiInputLabel-root": {
    ...styles
  }
});

CustomizedTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  styles: PropTypes.object,
  labelStyles: PropTypes.object,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool
};

export default CustomizedTextField;
