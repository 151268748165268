import React, { useState } from "react";
import { Box, Divider, Grid, Slider, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { Bar } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import GroupIcon from "shared/assets/icons/group.png";
import GenderIcon from "shared/assets/icons/gender.png";
import OverlapIcon from "shared/assets/icons/overlap.png";
import ConnectionsIcon from "shared/assets/icons/connections.png";
import CalendarIcon from "shared/assets/icons/hr-calendar.svg";
import TreeIcon from "shared/assets/icons/tree.svg";
import LunchIcon from "shared/assets/icons/lunch.svg";
import PeopleIcon from "shared/assets/icons/people.svg";
import CoffeeIcon from "shared/assets/icons/coffee.svg";
import GuitarIcon from "shared/assets/icons/guitar.svg";
import SeatIcon from "shared/assets/icons/seat.svg";
import CoworkersImage from "shared/assets/images/coworkers.svg";
import Score from "shared/components/Score/Score";
import Search from "shared/components/Search/Search";
import CardContainer from "shared/components/CardContainer/CardContainer";
import {
  AVERAGE_DAYS_PER_WEEK,
  CHART_DAY_LABELS,
  GENDER_INCLUSION,
  ORGANIZATION_CONNECTIONS,
  OVERLAP_WITH_MANAGER,
  ROUTE_TEAM,
  SUSTAINABILITY,
  TEAM_MEMBER_OVERLAP
} from "shared/constants";
import SiteAndBuilding from "shared/components/Filters/SiteAndBulding/SiteAndBulding";
import SiteAndBuildingPage from "shared/components/DataTable/components/SiteAndBuildingSidebar/SiteAndBuildingSidebar";
import AlertsAndInsightsCarousel from "shared/components/AlertsAndInsightsCarousel/AlertsAndInsightsCarousel";
import { convertStringToKebabCase } from "shared/lib";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

export const barData = {
  labels: CHART_DAY_LABELS,
  datasets: [
    {
      label: "Office",
      data: [5, 3, 5, 4, 3],
      backgroundColor: "#004D66",
      borderRadius: 32,
      stack: "Stack 0",
      barThickness: 10
    },
    {
      label: "Home",
      data: [0, 2, 0, 1, 2],
      backgroundColor: "#33CCFF",
      borderRadius: 32,
      stack: "Stack 0",
      barThickness: 10
    },
    {
      label: "Travel",
      data: [4, 2, 0, 0, 1],
      backgroundColor: "#66D9FF",
      borderRadius: 32,
      stack: "Stack 0",
      barThickness: 10
    },
    {
      label: "Day off",
      data: [3, 1, 0, 0, 4],
      backgroundColor: "#99E6FF",
      borderRadius: 32,
      stack: "Stack 0",
      barThickness: 10
    }
  ]
};

const barOptions = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 16
      }
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      min: 0,
      grid: {
        display: false
      },
      ticks: {
        stepSize: 5
      }
    }
  },
  maintainAspectRatio: false
};

const teamIndicatorsData = [
  { score: "9.1", icon: GroupIcon, label: OVERLAP_WITH_MANAGER },
  { score: "8.1", icon: GenderIcon, label: GENDER_INCLUSION },
  { score: "7.4", icon: OverlapIcon, label: TEAM_MEMBER_OVERLAP },
  { score: "8.4", icon: ConnectionsIcon, label: ORGANIZATION_CONNECTIONS },
  { score: "2.3", icon: CalendarIcon, label: AVERAGE_DAYS_PER_WEEK },
  { score: "8.6", icon: TreeIcon, label: SUSTAINABILITY }
];

const serendipityData = [
  { score: 5, label: "Lunch invites", icon: LunchIcon },
  { score: 5, label: "Public lunches", icon: PeopleIcon },
  { score: 3, label: "Coffee suggestion", icon: CoffeeIcon },
  { score: 4, label: "Activities", icon: GuitarIcon },
  { score: 3, label: "Seat neat a friend", icon: SeatIcon }
];

const TeamPage = ({ history }) => {
  const [showSiteSidebar, setShowSiteSidebar] = useState(false);

  const onTeamIndicatorClick = (event, label) => {
    history.push({ pathname: `/${ROUTE_TEAM}/indicators/${convertStringToKebabCase(label)}`, state: { label } });
  };

  return (
    <Box>
      <Typography variant="h4" fontWeight="700">
        Team insights - Finance
      </Typography>
      <Box>
        <Grid container display="flex" alignItems="flex-start" spacing={2} my={1}>
          <Grid item>
            <SiteAndBuilding onClick={() => setShowSiteSidebar(true)} title="Site and Team" />
          </Grid>
          <Grid item>
            <Search placeholder="Enter name to search" />
          </Grid>
        </Grid>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardContainer minHeight="332px">
            <Typography variant="h5" fontWeight="700">
              Team indicators
            </Typography>
            <Grid container spacing={1} my={1}>
              {teamIndicatorsData.map(({ score, icon, label }, index) => (
                <Grid key={`item-${index}`} xs={6} px={1}>
                  <IndicatorBox onClick={(e) => onTeamIndicatorClick(e, label)}>
                    <Score variant="h4" score={score} />
                    <Box ml={2}>
                      <img src={icon} alt="team indicator" />
                      <Typography>{label}</Typography>
                    </Box>
                  </IndicatorBox>
                </Grid>
              ))}
            </Grid>
          </CardContainer>
        </Grid>
        <Grid item xs={6}>
          <CardContainer minHeight="300px">
            <Typography variant="h5" fontWeight="700">
              Alerts and insights
            </Typography>
            <AlertsAndInsightsCarousel />
          </CardContainer>
        </Grid>
        <Grid item xs={4}>
          <CardContainer>
            <Typography variant="h5" fontWeight="700">
              Serendipity
            </Typography>
            <Grid container mt={1}>
              {serendipityData.map(({ score, label, icon }, index) => (
                <Grid key={`${label}-${index}`} item xs={12} py={1}>
                  <Box display="flex" alignItems="center">
                    <Box mr={2}>
                      <img src={icon} style={{ minWidth: "20px", maxHeight: "25px" }} />
                    </Box>
                    <Typography>{label}</Typography>
                    <Box width="200px" ml="auto">
                      <CustomSlider
                        aria-label="Score"
                        defaultValue={score}
                        step={1}
                        disabled
                        max={5}
                        min={1}
                        valueLabelDisplay="on"
                      />
                    </Box>
                  </Box>
                  <Divider />
                </Grid>
              ))}
            </Grid>
          </CardContainer>
        </Grid>
        <Grid item xs={4}>
          <CardContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" fontWeight="700">
                Weekly plan
              </Typography>
              <Typography variant="caption">June 13-17</Typography>
            </Box>
            <Box p={2} sx={{ height: "290px", width: "380px", margin: "0 auto" }}>
              <Bar data={barData} options={barOptions} />
            </Box>
          </CardContainer>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box p={1}>
              <img src={CoworkersImage} style={{ width: "100%", maxWidth: "450px" }} />
            </Box>
            <Box pl={3}>
              <Typography>84% of the finance team is using the BWith application.</Typography>
              <Typography>
                <span style={{ textDecoration: "underline" }}>Send invitations</span> to those who still haven’t joined.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SiteAndBuildingPage
        isDrawer
        isOpen={showSiteSidebar}
        setIsOpen={setShowSiteSidebar}
        title="Site and Team"
        showTeam
      />
    </Box>
  );
};

const IndicatorBox = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1),
  justifyContent: "flex-start",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  }
}));

const CustomSlider = styled(Slider)(({ theme, defaultValue }) => ({
  height: theme.spacing(1),

  "& .MuiSlider-rail": {
    backgroundImage: "linear-gradient(270deg, #004D66 17.71%, #99E6FF 84.9%)"
  },

  "& .MuiSlider-track": {
    backgroundImage: "linear-gradient(270deg, #004D66 17.71%, #99E6FF 84.9%)"
  },

  "& .MuiSlider-thumb": {
    width: theme.spacing(0.3),
    borderRadius: 0,
    backgroundColor: "#000",

    "&:hover": {
      boxShadow: "none"
    }
  },

  "& .MuiSlider-valueLabel": {
    background: "none",
    color: defaultValue > 4 ? "#108000" : "#000",
    fontWeight: "700",
    top: theme.spacing(0.6)
  }
}));

TeamPage.propTypes = {
  history: PropTypes.object
};

export default withRouter(TeamPage);
