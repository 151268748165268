import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import DiscussionImage from "shared/assets/images/discussion.svg";

const Illustration = () => {
  return (
    <Grid container spacing={2} p={2} height="100%" alignItems="center">
      <Grid container xs={6}>
        <img src={DiscussionImage} alt="Discussion" />
      </Grid>
      <Grid item xs={6}>
        <Box mb={2}>
          <Chip label="Finance" variant="outlined" />
          <Box component="span" px={1}>
            +
          </Box>
          <Chip label="Wine" variant="outlined" />
        </Box>
        <Typography variant="h6">
          Many people from the finance team have put wine on their interests list. Maybe add a new wine-focused event?
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Illustration;
