import React from "react";
import PropTypes from "prop-types";
import { Circle } from "react-konva";

const MapWorkstationItem = ({ workstation, onClick, onMouseIn, onMouseOut }) => {
  return (
    <Circle
      x={workstation.x}
      y={workstation.y}
      radius={workstation.radius}
      fill={workstation.fill}
      stroke={workstation.strokeColor}
      strokeWidth={workstation.strokeWidth}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";

        onMouseIn(e, workstation);
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";

        onMouseOut();
      }}
      onClick={() => onClick(workstation.id)}
    />
  );
};

MapWorkstationItem.propTypes = {
  workstation: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseIn: PropTypes.func,
  onMouseOut: PropTypes.func
};

export default React.memo(MapWorkstationItem);
