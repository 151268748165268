import React from "react";
import { Box } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";
import WarningIcon from "shared/assets/images/warning.png";

const styles = {
  warningIconStyle: {
    width: "100%"
  }
};

const NotInBWith = () => {
  return (
    <CustomizedTooltip title="Not on BWith" placement="top" className={undefined}>
      <Container>
        <img src={WarningIcon} style={styles.warningIconStyle} />
      </Container>
    </CustomizedTooltip>
  );
};

const Container = styled(Box)(({ theme }) => ({
  width: theme.spacing(1.9),
  height: theme.spacing(1.9),
  marginLeft: theme.spacing(1.25),
  overflow: "hidden",
  position: "relative",
  cursor: "pointer"
}));

const CustomizedTooltip = styled(({ className, ...props }) => (
  <Tooltip title={undefined} {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1)
  }
}));

export default NotInBWith;
