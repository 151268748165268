import { apiSlice } from "core/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: ({ email, notificationType }) => ({
        url: "/employees/api/v1/auth/accessCode",
        method: "POST",
        body: { email, notificationType }
      })
    }),
    confirm: build.mutation({
      query: ({ email, code, deviceName }) => ({
        url: "/employees/api/v1/auth/signIn",
        method: "POST",
        body: { email, accessCode: code, deviceName, source: "DASHBOARD" }
      })
    })
  })
});

export const { useLoginMutation, useConfirmMutation } = authApiSlice;
