import { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_HR, ROLE_TEAM_MANAGER, ROUTE_DIGITAL_SIGN, ROUTE_INDIVIDUAL, RESOURCES_NAMES } from "shared/constants";
import { selectNavigationResources, selectSelectedTeamMember, selectUserRole } from "core/selectors";
import LogoIcon from "shared/assets/images/logo-small.svg";
import LogoHRIcon from "shared/assets/images/logo-small-hr.svg";
import { getNavigationMap } from "shared/lib/getters";
import { useToggle } from "shared/hooks";
import { signOut } from "shared/lib";

const mapStateToProps = (store) => ({
  role: selectUserRole(store),
  selectedTeamMember: selectSelectedTeamMember(store)
});

export default function useNavigationDrawerLogic({ history }) {
  /* ------------------ HOOKs ------------------ */

  const dispatch = useDispatch();
  const digitalSignSidebar = useToggle();

  /* ------------------ STATEs ------------------ */

  const { role, selectedTeamMember } = useSelector(mapStateToProps);
  const [open, setOpen] = useState(true);
  const [showAdminSetting, setShowAdminSetting] = useState(true);
  const [navigationMap, setNavigationMap] = useState([]);

  /* ------------------ SELECTORs ------------------ */

  const navigationResources = useSelector(selectNavigationResources);

  /* ------------------ MEMOs ------------------ */

  const currentPathname = useMemo(() => history?.location?.pathname, [history]);

  const companyLogo = useMemo(() => {
    if (role === ROLE_HR || role === ROLE_TEAM_MANAGER) return LogoHRIcon;

    return LogoIcon;
  }, [role]);

  /* ------------------ HANDLERs ------------------ */

  const navigationResourcesHandler = useCallback(async () => {
    const list = getNavigationMap(navigationResources);
    const isSetting = navigationResources?.includes(RESOURCES_NAMES.adminSettings);

    setShowAdminSetting(isSetting);
    setNavigationMap(list);
  }, [navigationResources]);

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    navigationResourcesHandler();
  }, [navigationResources]);

  const onDrawerClick = () => {
    setOpen(!open);
  };

  /* ------------------ EVENT HANDLERs ------------------ */

  const onItemClick = (route) => {
    if (route === `/${ROUTE_INDIVIDUAL}`) {
      let teamMemberId = 1;

      if (selectedTeamMember) {
        teamMemberId = selectedTeamMember;
      }

      history.push(`${route}/${teamMemberId}`);
      return;
    }

    if (route === `/${ROUTE_DIGITAL_SIGN}`) {
      digitalSignSidebar.on();
      return;
    }

    history.push(route);
  };

  const onSignOut = () => {
    signOut(dispatch);
  };

  return {
    open,
    onDrawerClick,
    role,
    companyLogo,
    navigationMap,
    onSignOut,
    currentPathname,
    onItemClick,
    digitalSignSidebar,
    showAdminSetting
  };
}
