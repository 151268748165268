import React, { useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";

const DataTableLite = ({
  rows,
  columns,
  rowIdFieldName,
  isLoading,
  defaultSort = { field: "name", sort: "asc" },
  autoHeight = false,
  sx = {},
  hideFooter = false
}) => {
  const [sortModel, setSortModel] = useState([defaultSort]);

  const getRowIdHandler = (row) => {
    if (row && rowIdFieldName) return row[rowIdFieldName];

    return row.id;
  };

  const handleSortChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  return (
    <DataGrid
      rows={rows}
      getRowId={getRowIdHandler}
      columns={columns}
      autoHeight={autoHeight}
      disableSelectionOnClick
      disableColumnMenu
      loading={isLoading}
      hideFooterPagination={rows.length <= 100}
      hideFooter={hideFooter}
      sortModel={sortModel}
      onSortModelChange={handleSortChange}
      sx={{
        border: "none",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
          outline: "none"
        },
        "& .MuiDataGrid-columnSeparator--sideRight": {
          display: "none"
        },
        "& .MuiIconButton-root": {
          color: "#00b4f0"
        },
        "& .MuiDataGrid-columnHeaders": {
          background: "#ECEEF1"
        },
        "& .MuiDataGrid-columnHeader--sorted": {
          fontWeight: "700"
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none"
        },
        ...sx
      }}
    />
  );
};

DataTableLite.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowIdFieldName: PropTypes.string,
  defaultSort: PropTypes.object,
  autoHeight: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideFooter: PropTypes.bool,
  sx: PropTypes.object
};

export default DataTableLite;
