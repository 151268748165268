import React from "react";
import { Box, CssBaseline, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { GOOGLE_CAPTCHA_KEY } from "shared/constants";
import LogoIcon from "shared/assets/images/logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {
  CustomizedDiv,
  CustomizedContainer,
  CustomizedIcon,
  CustomizedTextField,
  CustomizedButton,
  WrapCaptchaBox,
  CustomizedErrorText,
  styles
} from "./style/signInPageStyle";
import useSignInPageLogic from "./hooks/useSignInPageLogic";

const SignInPage = (props) => {
  const { email, errorMsg, captcha, isLoading, handleChange, handleCaptcha, handleSubmit } = useSignInPageLogic(props);

  return (
    <CustomizedDiv>
      <CustomizedContainer>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <CustomizedIcon>
            <img src={LogoIcon} alt="Company logo" />
          </CustomizedIcon>
          <Typography component="h1" variant="h3" padding="60px">
            Welcome!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ pt: 1, position: "relative" }}>
            {errorMsg && (
              <CustomizedErrorText>
                <Typography variant="h6" gutterBottom color="red" fontWeight={600}>
                  {errorMsg}
                </Typography>
              </CustomizedErrorText>
            )}
            <CustomizedTextField
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              id="email"
              label=""
              placeholder="Enter your email"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              style={errorMsg?.length ? styles.customizedTextFieldInvalidStyle : {}}
            />
            <CustomizedButton
              type="submit"
              fullWidth
              disabled={!email || isLoading || !captcha}
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              Log In
            </CustomizedButton>

            <WrapCaptchaBox>
              <ReCAPTCHA sitekey={GOOGLE_CAPTCHA_KEY} onChange={handleCaptcha} />
            </WrapCaptchaBox>
          </Box>
        </Box>
      </CustomizedContainer>
    </CustomizedDiv>
  );
};

SignInPage.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignInPage);
