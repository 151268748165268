import React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import { getUserInitials } from "shared/lib/getters";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

const AvatarInitials = ({
  firstName,
  lastName,
  variant = "h4",
  height = "71px",
  width = "71px",
  backgroundColor = "#d4dce8"
}) => {
  const initials = getUserInitials(firstName, lastName);

  return (
    <CustomizedAvatar height={height} width={width} backgroundColor={backgroundColor}>
      <Typography color="#00203E" variant={variant} fontWeight="300">
        {initials}
      </Typography>
    </CustomizedAvatar>
  );
};

const CustomizedAvatar = styled(Avatar)(({ theme, height, width, backgroundColor }) => ({
  width: width,
  height: height,
  marginRight: theme.spacing(1.5),
  backgroundColor: backgroundColor,
  border: "1px solid #00203e"
}));

AvatarInitials.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  variant: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default AvatarInitials;
