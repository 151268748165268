import { createSelector } from "@reduxjs/toolkit";

import {
  selectConfirmationCode,
  selectUserEmail,
  selectAccessToken,
  selectUserRole,
  selectCToken,
  selectNavigationResources
} from "./authSelectors";
import { selectNeighborhoodsList } from "./neighborhoodsSelectors";
import { selectUserManagementList, selectUnassignedUsersList, selectDepartmentsList } from "./userManagementSelectors";
import {
  selectWorkstationsByBuilding,
  selectWorkstationWeeklyPlan,
  selectShowNoAssignmentSidebar
} from "./workstationsSelectors";
import {
  selectSiteFilter,
  selectBuildingFilter,
  selectFloorsFilter,
  selectDepartmentsFilter,
  selectUserManagementFilter,
  selectWorkstationsFilter
} from "./filtersSelectors";

import {
  selectFloorsByBuilding,
  selectDepartmentsByBuilding,
  selectSiteBuildings,
  selectCompanySite
} from "./siteSelectors";

import { selectTeamUsersList, selectSelectedTeamMember } from "./teamUsers";

import { selectRoomsByBuilding } from "./roomSelector";
import {
  selectEmployeeData,
  selectSiteId,
  selectCompanyId,
  selectCompanyName,
  selectCompanyAndSiteSettings
} from "./profileSelectors";

import { selectTableSort } from "./tableSortSelector";

import { isValidAndNotEmptyString, isValidArray, isValidObject } from "shared/lib/validation";

import {
  getCurrentWorkstationsStatus,
  getFilteredList,
  getFiltersCount,
  getFullName,
  getOptions,
  getUserManagementFilteredList,
  getUsersWithoutWorkstations,
  getWorkstationFilteredList,
  getFloorsByBuildingOptions
} from "shared/lib/getters";

import { selectCompanySites, selectNationalHolidays } from "./adminSettingsSelectors";

export {
  selectCompanyName,
  selectCompanySite,
  selectConfirmationCode,
  selectUserEmail,
  selectAccessToken,
  selectUserRole,
  selectNeighborhoodsList,
  selectUserManagementList,
  selectWorkstationsByBuilding,
  selectSiteFilter,
  selectBuildingFilter,
  selectFloorsFilter,
  selectDepartmentsFilter,
  selectUserManagementFilter,
  selectWorkstationsFilter,
  selectFloorsByBuilding,
  selectDepartmentsByBuilding,
  selectSiteBuildings,
  selectTeamUsersList,
  selectSelectedTeamMember,
  selectRoomsByBuilding,
  selectTableSort,
  selectWorkstationWeeklyPlan,
  selectUnassignedUsersList,
  selectDepartmentsList,
  selectEmployeeData,
  selectSiteId,
  selectCompanyId,
  selectShowNoAssignmentSidebar,
  selectCToken,
  selectNavigationResources,
  selectCompanySites,
  selectNationalHolidays,
  selectCompanyAndSiteSettings
};

// Filters
export const selectCurrentSiteAndBuildingFiltersText = createSelector(
  [selectSiteFilter, selectBuildingFilter],
  (site, building) => {
    if (isValidAndNotEmptyString(site) && isValidAndNotEmptyString(building)) {
      return `${site}, ${building}`;
    }
    return "";
  }
);

export const selectCurrentSiteAndBuildingFilters = createSelector(
  [selectSiteFilter, selectBuildingFilter],
  (site, building) => {
    if (isValidAndNotEmptyString(site) && isValidAndNotEmptyString(building)) {
      return { site, building };
    }
    return {};
  }
);

// User Management
export const selectFilteredUserManagementList = createSelector(
  [selectUserManagementList, selectSiteFilter, selectBuildingFilter, selectFloorsFilter, selectUserManagementFilter],
  getUserManagementFilteredList
);

export const selectUserManagementAppliedFiltersCount = createSelector([selectUserManagementFilter], getFiltersCount);

export const selectUsersByBuildingOptions = createSelector(
  [selectUserManagementList, selectBuildingFilter],
  (users, building) => {
    if (!isValidArray(users) || !users?.length || !building) return [];

    const res = users
      .filter((user) => user.building === building)
      .map((user) => ({ id: user.id, value: getFullName(user.firstName, user.lastName) }))
      .sort((a, b) => a.value.localeCompare(b.value));

    return res;
  }
);

export const selectUserDepartmentsList = createSelector([selectUserManagementList], (list) => {
  if (isValidArray(list) && list.length) {
    const mapped = list.map((item) => item.department[0])?.filter((i) => !!i);
    const unique = [...new Set(mapped)];

    return unique
      .map((department) => ({ option: department, value: department }))
      .sort((a, b) => a.value?.localeCompare(b.value));
  }

  return [];
});

export const selectUsersTitleList = createSelector([selectUserManagementList], (list) => {
  if (isValidArray(list) && list.length) {
    const mapped = list.map((item) => item.title)?.filter((i) => !!i);
    const unique = [...new Set(mapped)];

    return unique.map((title) => title).sort((a, b) => a?.localeCompare(b));
  }

  return [];
});

export const selectUsersCityList = createSelector([selectUserManagementList], (list) => {
  if (isValidArray(list) && list.length) {
    const mapped = list.map((item) => item.city)?.filter((i) => !!i);
    const unique = [...new Set(mapped)];

    return unique.map((city) => city).sort((a, b) => a?.localeCompare(b));
  }

  return [];
});

export const selectUserTitleList = createSelector([selectUserManagementList], (list) => {
  if (isValidArray(list) && list.length) {
    const mapped = list.map((item) => item.title)?.filter((i) => !!i);
    const unique = [...new Set(mapped)];

    return unique.map((title) => ({ option: title, value: title })).sort((a, b) => a.value?.localeCompare(b.value));
  }

  return [];
});

// Neighborhoods
export const selectFilteredNeighborhoodsList = createSelector(
  [selectNeighborhoodsList, selectSiteFilter, selectBuildingFilter],
  getFilteredList
);

export const selectMappedNeighborhoodsList = createSelector([selectNeighborhoodsList], (list) => {
  if (isValidArray(list) && list.length) {
    return list.map((item) => ({ option: item.name, value: item.name }));
  }

  return [];
});

// Workstations
export const selectFilteredWorkstationsList = createSelector(
  [selectWorkstationsByBuilding, selectSiteFilter, selectBuildingFilter, selectFloorsFilter, selectWorkstationsFilter],
  getWorkstationFilteredList
);

export const selectWorkstationAppliedFiltersCount = createSelector([selectWorkstationsFilter], getFiltersCount);

export const selectCurrentWorkstationsStatus = createSelector(
  [selectFilteredWorkstationsList],
  getCurrentWorkstationsStatus
);

export const selectUsersWithoutWorkstations = createSelector(
  [selectFilteredWorkstationsList],
  getUsersWithoutWorkstations
);

// Site
export const selectFloorsList = createSelector(
  [selectFloorsByBuilding, selectBuildingFilter],
  getFloorsByBuildingOptions
);

export const selectBuildingsList = createSelector([selectSiteBuildings], getOptions);

export const selectSites = createSelector([selectCompanySites], (companySites) => {
  const list = companySites?.length ? companySites.map((site) => site.name) : [];

  return getOptions(list);
});

// Team Members
export const selectMappedTeamMembersList = createSelector([selectTeamUsersList], (users) => {
  if (isValidArray(users) && users.length) {
    return users.map((user) => ({ option: user.id, value: getFullName(user.firstName, user.lastName) }));
  }

  return [];
});

export const selectAllRoomsList = createSelector([selectRoomsByBuilding], (list) => {
  if (isValidObject(list)) {
    const result = Object.keys(list).map((key) => {
      return list[key];
    });
    return result.flatMap((i) => i).map((item) => ({ option: item.name, value: item.name }));
  }

  return [];
});

// Departments
export const selectMappedDepartments = createSelector([selectDepartmentsList], (list) => {
  if (!list?.length) return [];

  return list.map((department) => {
    return { option: department.toString(), value: department };
  });
});
