import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectCompanySites, selectAccessToken } from "core/selectors";
import { useToggle } from "shared/hooks";
import {
  useDeleteNationalHolidaysMutation,
  useNationalHolidaysQuery
} from "features/adminSettings/adminSettingsApiSlice";

export default function useAdminSettingsHolidays() {
  const holidaySidebar = useToggle();
  const deleteHolidayDialog = useToggle();

  const token = useSelector(selectAccessToken);
  const companySites = useSelector(selectCompanySites);

  const [holidayId, setHolidayId] = useState();

  const { data: holidaysData, isLoading: isHolidaysLoading } = useNationalHolidaysQuery(null, { skip: !token });
  const [deleteNationalHolidays, { isLoading: isLoadingDeleteHoliday }] = useDeleteNationalHolidaysMutation();

  const listHolidays = useMemo(
    () =>
      holidaysData?.length
        ? holidaysData.map(({ siteIds, ...item }) => {
            const sites = siteIds?.map((id) => {
              const site = companySites?.find((item) => item.id === id);

              return site?.name;
            });

            return {
              ...item,
              sites
            };
          })
        : [],
    [holidaysData, companySites]
  );

  const onInfoHoliday = (id) => {
    setHolidayId(id);
    holidaySidebar.on();
  };

  const onDeleteHoliday = (id) => {
    setHolidayId(null);
    deleteNationalHolidays({ id });
  };

  const onSidebarDeleteHoliday = () => {
    holidaySidebar.off();
    deleteHolidayDialog.on();
  };

  const onAddHoliday = () => {
    setHolidayId(null);
    holidaySidebar.on();
  };

  return {
    listHolidays: listHolidays || [],
    onInfoHoliday,
    onDeleteHoliday,
    onAddHoliday,
    isHolidaysLoading: isLoadingDeleteHoliday || isHolidaysLoading,
    holidaySidebar,
    holidayId,
    deleteHolidayDialog,
    onSidebarDeleteHoliday
  };
}
