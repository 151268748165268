import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  background: "#fff",
  width: "100%",
  height: "100%",
  padding: theme.spacing(6, 4, 2, 4),
  borderRadius: "10px",
  position: "relative"
}));

export const CloseButton = styled("button")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  width: theme.spacing(4),
  height: theme.spacing(4),
  background: "none",
  border: "none",
  cursor: "pointer"
}));

export const ControlsContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  display: "flex",
  flexDirection: "column"
}));

export const InButton = styled(Box)(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.2)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(4),
  height: theme.spacing(4),
  padding: "2px",
  marginTop: theme.spacing(2),
  borderRadius: "2px",
  transition: "linear 0.2s",

  "&:hover": {
    background: "rgba(0, 0, 0, 0.6)",
    padding: "0"
  }
}));

export const OutButton = styled(InButton)(() => ({
  padding: "2px",

  "&:hover": {
    padding: "4px"
  }
}));

export const styles = {
  paperPropsStyle: {
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
    background: "transparent",
    boxShadow: "none",
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    padding: "40px",
    margin: 0
  },
  transitionPropsStyle: {
    width: "100%",
    height: "100%"
  },
  closeIconStyle: {
    color: "#000"
  },
  controlIconStyle: { color: "#fff", width: "100%", height: "100%" }
};
