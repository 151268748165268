import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ROUTE_LOGIN,
  STORAGE_FLOORS_FILTERS,
  STORAGE_SELECTED_TEAM_MEMBER,
  STORAGE_SITE_AND_BUILDING,
  STORAGE_USER_INFO,
  STORAGE_ROLE,
  ROUTE_CONFIRM_PLAN_REDIRECT,
  STORAGE_NAVIGATION_RESOURCES,
  DEFAULT_RESOURCES_NAMES,
  STORAGE_COMPANY_SETTINGS
} from "shared/constants";
import { parseJSON, isEmptyObject } from "shared/lib/validation";
import { setNavigationResources, setRole, setUserInfo } from "features/auth/authSlice";
import { setBuildingFilter, setFloorsFilter, setSiteFilter } from "features/filters/filtersSlice";
import { selectSiteFilter, selectUserRole, selectCToken, selectAccessToken } from "core/selectors";
import { useGetSiteInfoQuery } from "features/site/siteApiSlice";
import { setSiteInfo } from "features/site/siteSlice";
import { setSelectedUser } from "features/teamUsers/teamUsersSlice";
import { useCompanySiteSettingsQuery, useGetCompanyAndSiteMutation } from "features/profile/profileApiSlice";
import { setCompanySiteSettings, setSiteAndCompanyData } from "features/profile/profileSlice";
import { getNavigationMap } from "shared/lib/getters";
import { useCompanySitesQuery } from "features/adminSettings/adminSettingsApiSlice";
import { objectValuesToArray } from "shared/lib";

const mapStateToProps = (state) => ({
  site: selectSiteFilter(state),
  role: selectUserRole(state),
  cToken: selectCToken(state),
  accessToken: selectAccessToken(state)
});

export default function useAppRouterLogic({ history, location }) {
  /* ------------------ HOOKs ------------------ */

  const dispatch = useDispatch();

  /* ------------------ STATEs ------------------ */

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [redirectToRoute, setRedirectToRoute] = useState("/");
  const [isWorkstationAccess, setIsWorkstationAccess] = useState(false);

  const { site, role, cToken, accessToken } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  useCompanySitesQuery(null, { skip: !accessToken });
  useCompanySiteSettingsQuery({ cToken, site }, { skip: !cToken || !accessToken || !site });

  const { data: siteInfo, isLoading: isSiteInfoLoading } = useGetSiteInfoQuery(
    { site, cToken },
    { skip: !site || !cToken }
  );
  const [getCompanyAndSite] = useGetCompanyAndSiteMutation();

  /* ------------------ HANDLERs ------------------ */

  const initializationHandler = useCallback(async () => {
    if (location?.pathname?.indexOf(ROUTE_CONFIRM_PLAN_REDIRECT) !== -1) {
      history.push(`/${ROUTE_CONFIRM_PLAN_REDIRECT}`);
    } else {
      const userData = await localStorage.getItem(STORAGE_USER_INFO);
      const parsed = parseJSON(userData);

      if (!accessToken && (isEmptyObject(parsed) || (!isEmptyObject(parsed) && !parsed?.accessToken))) {
        history.push(`/${ROUTE_LOGIN}`);
      } else {
        dispatch(setUserInfo(parsed));

        navigationResourcesHandler();
        teamMembersHandler();
        roleHandler();
        filtersDataHandler();
        setupCompanySiteSettings();
      }
    }
  }, [location, history, dispatch, accessToken]);

  const getCompanyAndSiteHandler = useCallback(
    async (site) => {
      if (!site || !cToken) return;

      try {
        const response = await getCompanyAndSite({ site, cToken }).unwrap();

        dispatch(setSiteAndCompanyData(response));
      } catch (error) {
        console.error("ERROR:: getCompanyAndSiteHandler :: ", { error });
      }
    },
    [site, cToken, getCompanyAndSite, dispatch]
  );

  const teamMembersHandler = useCallback(async () => {
    const teamMemberIdData = await localStorage.getItem(STORAGE_SELECTED_TEAM_MEMBER);

    if (teamMemberIdData) {
      dispatch(setSelectedUser(teamMemberIdData));
    }
  }, [dispatch]);

  const roleHandler = useCallback(async () => {
    const localRole = await localStorage.getItem(STORAGE_ROLE);

    if (localRole) {
      dispatch(setRole(localRole));
    }
  }, [dispatch]);

  const navigationResourcesHandler = useCallback(async () => {
    const data = await localStorage.getItem(STORAGE_NAVIGATION_RESOURCES);
    const parsed = parseJSON(data);
    const resources = parsed || objectValuesToArray(DEFAULT_RESOURCES_NAMES);
    const route = getNavigationMap(resources)[0];
    const isWorkstationEnabled = resources.includes(DEFAULT_RESOURCES_NAMES.workstations);

    setIsWorkstationAccess(isWorkstationEnabled);
    setRedirectToRoute(route.route);
    dispatch(setNavigationResources(resources));

    history.push(route.route);
  }, [dispatch]);

  const filtersDataHandler = useCallback(async () => {
    const buildingsData = await localStorage.getItem(STORAGE_SITE_AND_BUILDING);
    const floorData = await localStorage.getItem(STORAGE_FLOORS_FILTERS);

    const parsedBuildingsData = parseJSON(buildingsData) || {};
    const parsedFloorData = parseJSON(floorData) || [];

    if (isEmptyObject(parsedBuildingsData) || !parsedFloorData?.length) {
      setIsOpenDialog(true);
    } else {
      if (!isEmptyObject(parsedBuildingsData)) {
        dispatch(setSiteFilter(parsedBuildingsData.site));
        dispatch(setBuildingFilter(parsedBuildingsData.building));
      }

      if (parsedFloorData.length) {
        dispatch(setFloorsFilter(parsedFloorData));
      }
    }
  }, [dispatch, accessToken]);

  const setupCompanySiteSettings = async () => {
    const settings = JSON.parse(await localStorage.getItem(STORAGE_COMPANY_SETTINGS));

    settings && dispatch(setCompanySiteSettings(settings));
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    getCompanyAndSiteHandler(site);
  }, [site]);

  useEffect(() => {
    if (siteInfo && !isSiteInfoLoading) {
      dispatch(setSiteInfo(siteInfo));
    }
  }, [siteInfo, isSiteInfoLoading]);

  useEffect(() => {
    initializationHandler();
  }, [accessToken]);

  return {
    isOpenDialog,
    setIsOpenDialog,
    role,
    authenticated: !!accessToken,
    redirectToRoute,
    isWorkstationAccess
  };
}
