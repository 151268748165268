import React from "react";
import PropTypes from "prop-types";
import NavigationDrawer from "../NavigationDrawer";
import Box from "@mui/material/Box";
import { isDefined } from "shared/lib/validation";
import styled from "@mui/material/styles/styled";

const PageContainer = ({ path, component, sidebar }) => {
  const Page = component;
  const Sidebar = sidebar;

  return (
    <NavigationDrawer>
      <Container>
        <Page path={path} />
        {isDefined(Sidebar) ? <Sidebar /> : null}
      </Container>
    </NavigationDrawer>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  width: "100%",
  padding: theme.spacing(1, 4)
}));

PageContainer.propTypes = {
  path: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  sidebar: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default PageContainer;
