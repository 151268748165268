import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Sidebar from "shared/components/Sidebar/Sidebar";
import FormInputText from "shared/components/FormInputs/Text/FormInputText";
import { useAddNeighborhoodMutation, useUpdateNeighborhoodMutation } from "features/neighborhoods/neighborhoodApiSlice";
import { selectCurrentSiteAndBuildingFilters, selectFilteredNeighborhoodsList } from "core/selectors";
import { setFormValues } from "shared/lib/setFormValues";
import { convertStringToKebabCase } from "shared/lib";

const mapStateToProps = (state) => {
  const siteAndBuildingData = selectCurrentSiteAndBuildingFilters(state);
  const neighborhoodsList = selectFilteredNeighborhoodsList(state);

  return {
    siteAndBuildingData,
    neighborhoodsList
  };
};

const NeighborhoodPage = ({ history, isOpen, onClose, neighborhoodName }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid }
  } = useForm({ mode: "onChange", defaultValues: { name: "" } });

  const { siteAndBuildingData, neighborhoodsList } = useSelector(mapStateToProps);

  const [addNeighborhood, { isLoading: isAddLoading, error: addError, isSuccess: isSuccessAdded }] =
    useAddNeighborhoodMutation();
  const [updateNeighborhood, { isLoading: isUpdateLoading, error: updateError, isSuccess: isSuccessUpdated }] =
    useUpdateNeighborhoodMutation();

  const isEdit = useMemo(() => !!neighborhoodName, [neighborhoodName]);

  const neighborhood =
    isEdit &&
    neighborhoodsList.find(({ name }) => convertStringToKebabCase(name) === convertStringToKebabCase(neighborhoodName));

  const sidebarTitle = isEdit ? "Rename Neighborhood" : "Add Neighborhood";

  useEffect(() => {
    if (isEdit && neighborhood) {
      setFormValues({ data: neighborhood, setValue });
    }
  }, [neighborhood]);

  const onSubmit = async (values) => {
    try {
      if (isEdit) {
        await updateNeighborhood({ ...siteAndBuildingData, previousName: neighborhood.name, name: values.name });
      } else {
        await addNeighborhood({ ...siteAndBuildingData, name: values.name });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Sidebar
        history={history}
        onResolve={handleSubmit(onSubmit)}
        isValid={isValid}
        resolveLabel={isEdit ? "Update" : "Add"}
        isSuccess={isSuccessAdded || isSuccessUpdated}
        isLoading={isAddLoading || isUpdateLoading}
        error={addError || updateError}
        isOpen={isOpen}
        setIsOpen={onClose}
        isDrawer={true}
      >
        <Box>
          <Typography variant="h5" fontWeight="700">
            {sidebarTitle}
          </Typography>
        </Box>
        <Box my={2}>
          <Typography>
            {isEdit
              ? `Rename ${neighborhood?.name}`
              : `Add a new neighborhood to ${siteAndBuildingData.site}, ${siteAndBuildingData.building}`}
          </Typography>
        </Box>
        <Box mt={2}>
          <Box>
            <FormInputText
              label="Neighborhood name*"
              control={control}
              name="name"
              rules={{ required: "Name is required" }}
              placeholder={isEdit ? "Enter a new name for this neighborhood" : "Enter new name"}
            />
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
};

NeighborhoodPage.propTypes = {
  history: PropTypes.object,
  neighborhoodName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default withRouter(NeighborhoodPage);
