import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import FormInputText from "shared/components/FormInputs/Text/FormInputText";
import Sidebar from "shared/components/Sidebar/Sidebar";
import FormInputIconCheckbox from "shared/components/FormInputs/IconChecbox/FormInputIconCheckbox";
import { selectCompanyId, selectSiteFilter, selectSiteId } from "core/selectors";
import { setFormValues } from "shared/lib/setFormValues";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveButton from "shared/components/Buttons/RemoveButton/RemoveButton";
import { activitiesIconsOptions } from "pages/ActivitiesPage/utils/activitiesUtils";
import { useAddGroupMutation, useUpdateGroupMutation } from "features/activities/activitiesApiSlice";

const defaultValues = {
  name: "",
  description: "",
  icon: ""
};

const iconStyles = { width: "45px", height: "45px", padding: "8px" };

const GroupSidebar = ({ history, isOpen, setIsOpen, isDrawer, group, isEdit = false }) => {
  const site = useSelector(selectSiteFilter);
  const siteId = useSelector(selectSiteId);
  const companyId = useSelector(selectCompanyId);

  const [addGroup, { isLoading: isLoadingAdd, isSuccess: isSuccessAdd, error: errorAdd }] = useAddGroupMutation();
  const [updateGroup, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate }] =
    useUpdateGroupMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty }
  } = useForm({ mode: "onChange", defaultValues });

  useEffect(() => {
    if (isEdit && group) {
      const { description, name, icon } = group;

      setFormValues({
        data: {
          name,
          description,
          icon
        },
        setValue
      });
    }
  }, [group]);

  const onSubmit = (values) => {
    if (isEdit) {
      updateGroup({ params: values, id: group.id });
    } else {
      addGroup({
        ...values,
        companyId,
        siteId,
        employeeIds: []
      });
    }
  };

  const onDeactivate = () => {
    updateGroup({ params: { active: false }, id: group.id });
  };

  const onActivate = () => {
    updateGroup({ params: { active: true }, id: group.id });
  };

  return (
    <Sidebar
      history={history}
      isOpen={isOpen}
      isDrawer={isDrawer}
      setIsOpen={setIsOpen}
      onResolve={handleSubmit(onSubmit)}
      isDirty={isDirty}
      resolveLabel={isEdit ? "Update" : "ADD"}
      isLoading={isLoadingUpdate || isLoadingAdd}
      error={errorUpdate || errorAdd}
      isSuccess={isSuccessUpdate || isSuccessAdd}
    >
      <Box>
        <Typography variant="h5" fontWeight="700">
          {isEdit ? "Edit Group" : "Add Group"}
        </Typography>
        {!isEdit && (
          <Box py={2}>
            <Typography>Add a new group to {site}</Typography>
          </Box>
        )}
      </Box>
      <Grid container rowSpacing={1} spacing={2} mb={3}>
        <Grid item xs={12}>
          <FormInputText
            label="Group name*"
            control={control}
            name="name"
            rules={{ required: "Group name is required" }}
            placeholder="Enter new name"
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputText
            label="Group description (optional)"
            control={control}
            name="description"
            placeholder="Add description"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputIconCheckbox
            label="Select icon"
            control={control}
            name="icon"
            data={activitiesIconsOptions}
            styles={iconStyles}
            checkedStyles={{ ...iconStyles, filter: "brightness(0) invert(1)" }}
          />
        </Grid>
      </Grid>
      {isEdit ? (
        <>
          <Divider color="#8D9096" />
          <Box mt={3}>
            <Typography variant="h6">Deactivate group</Typography>
            <Box mt={1}>
              <Typography variant="h6" color="#6B6C72">
                If you deactivate this group:
              </Typography>
              <ul>
                <li>
                  <Typography variant="h6" color="#6B6C72">
                    Users will no longer be able to sign up, and you will not be able to schedule new group meetings.
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" color="#6B6C72">
                    You will still be able to view all data for this group such as dates, attendence, statistics, etc.
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" color="#6B6C72">
                    You will be able to reactivate it at any time in the future.
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
          <Box mt={4}>
            {group?.active ? (
              <RemoveButton onClick={onDeactivate}>
                <VisibilityOffOutlinedIcon fontSize="small" sx={{ color: "#D52B1E" }} />
                <Typography color="#D52B1E" pl={1}>
                  Deactivate Group
                </Typography>
              </RemoveButton>
            ) : (
              <RemoveButton onClick={onActivate}>
                <VisibilityIcon fontSize="small" />
                <Typography pl={1}>Activate Group</Typography>
              </RemoveButton>
            )}
          </Box>
        </>
      ) : null}
    </Sidebar>
  );
};

GroupSidebar.propTypes = {
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isDrawer: PropTypes.bool,
  isEdit: PropTypes.bool,
  group: PropTypes.object
};

export default withRouter(GroupSidebar);
