import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const Container = styled(Box)(({ theme }) => ({
  background: "#eceef1",
  padding: theme.spacing(0, 1.25),
  display: "flex",
  flexDirection: "row",
  overflow: "hidden"
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.5),
  color: "#000000",
  padding: theme.spacing(1.75, 0)
}));

export const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: 1
}));

export const CountText = styled(Typography)(({ theme }) => ({
  flex: 1,
  fontSize: theme.spacing(1.5),
  lineHeight: theme.spacing(2.5),
  textAlign: "center",
  padding: theme.spacing(1.75, 0.25),
  color: "#00203E"
}));

export const style = {
  countTextStyle: {
    borderLeft: "1px solid #BABABA",
    borderRight: "1px solid transparent",
    marginLeft: "-1px"
  }
};
