import React from "react";
import styled from "@mui/material/styles/styled";
import { Box, Typography } from "@mui/material";

const styles = {
  homeStyles: {
    background: "#ECEEF1"
  },
  officeStyle: {
    background: "#00B4F0"
  },
  officeNoCheckInStyle: {
    background: "#CCF2FF"
  }
};

const PreviousWeeksAgenda = () => {
  return (
    <Container>
      <Column>
        <StatusContainer>
          <StatusIcon style={styles.homeStyles} />
          <StatusTypography>Work from home</StatusTypography>
        </StatusContainer>

        <StatusContainer>
          <StatusIcon style={styles.officeStyle} />
          <StatusTypography>Office day</StatusTypography>
        </StatusContainer>

        <StatusContainer>
          <StatusIcon style={styles.officeNoCheckInStyle} />
          <StatusTypography>Office day (no check-in)</StatusTypography>
        </StatusContainer>
      </Column>

      <Column>
        <StatusContainer>
          <StatusIcon>
            <StatusIconTypography>t</StatusIconTypography>
          </StatusIcon>
          <StatusTypography>Business travel</StatusTypography>
        </StatusContainer>

        <StatusContainer>
          <StatusIcon>
            <StatusIconTypography>o</StatusIconTypography>
          </StatusIcon>
          <StatusTypography>Day off</StatusTypography>
        </StatusContainer>

        <StatusContainer>
          <StatusIcon>
            <StatusIconTypography>s</StatusIconTypography>
          </StatusIcon>
          <StatusTypography>Sick day</StatusTypography>
        </StatusContainer>
      </Column>

      <Column>
        <StatusContainer>
          <StatusIcon />
          <StatusTypography>Non-working day</StatusTypography>
        </StatusContainer>
      </Column>
    </Container>
  );
};

const StatusContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1.5)
}));

const StatusIcon = styled(Box)(({ theme }) => ({
  background: "#BABEC5",
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  border: "1px solid #E3E5E8",
  marginRight: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StatusTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "300",
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.5),
  color: "#000000"
}));

const StatusIconTypography = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  margin: theme.spacing(3, 0, 0, 1.5)
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginRight: theme.spacing(4)
}));

export default PreviousWeeksAgenda;
