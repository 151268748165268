import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Sidebar from "shared/components/Sidebar/Sidebar";
import FormInputDropdown from "shared/components/FormInputs/Dropdown/FormInputDropdownDeprecated";
import {
  resetWorkstationFilter,
  setBuildingFilter,
  setFloorsFilter,
  setSiteFilter
} from "features/filters/filtersSlice";
import { STORAGE_SITE_AND_BUILDING } from "shared/constants";
import {
  selectBuildingsList,
  selectCurrentSiteAndBuildingFilters,
  selectFloorsByBuilding,
  selectSites
} from "core/selectors";
import { getFloorsByBuildingOptions } from "shared/lib/getters";

const teamsList = [
  { option: "Finance", value: "Finance" },
  { option: "Marketing", value: "Marketing" }
];

const defaultValues = {
  site: "",
  building: ""
};

const mapStateToProps = (state) => {
  const siteList = selectSites(state);
  const buildingList = selectBuildingsList(state);
  const siteAndBuildingData = selectCurrentSiteAndBuildingFilters(state);
  const floorsByBuilding = selectFloorsByBuilding(state);

  return {
    siteList,
    buildingList,
    siteAndBuildingData,
    floorsByBuilding
  };
};

const SiteAndBuildingSidebar = ({
  history,
  isDrawer,
  isOpen,
  setIsOpen,
  title = "Site and Building",
  showTeam = false
}) => {
  // TODO: Will change it after implementign storing filters on the BE
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid }
  } = useForm({ mode: "onChange", defaultValues: showTeam ? { ...defaultValues, team: "" } : defaultValues });
  const { siteList, buildingList, siteAndBuildingData, floorsByBuilding } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setIsSuccess(false);
    };
  }, []);

  useEffect(() => {
    setValue("site", siteAndBuildingData.site);
    setValue("building", siteAndBuildingData.building);
  }, [siteAndBuildingData]);

  const siteValue = watch("site");

  const onSubmit = (values) => {
    const floors = getFloorsByBuildingOptions(floorsByBuilding, values.building);

    localStorage.setItem(STORAGE_SITE_AND_BUILDING, JSON.stringify({ site: values.site, building: values.building }));
    dispatch(setSiteFilter(values.site));
    dispatch(setBuildingFilter(values.building));
    dispatch(setFloorsFilter([floors[0].option]));
    dispatch(resetWorkstationFilter());
    setIsSuccess(true);
  };

  return (
    <div>
      <Sidebar
        history={history}
        isDrawer={isDrawer}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onResolve={handleSubmit(onSubmit)}
        isValid={isValid}
        resolveLabel="Select"
        isSuccess={isSuccess}
      >
        <Box>
          <Typography variant="h5" fontWeight="700">
            {title}
          </Typography>
        </Box>
        <Box mt={2}>
          <FormInputDropdown
            label="Site*"
            control={control}
            name="site"
            rules={{ required: "Site is required" }}
            data={siteList}
            styles={{ maxWidth: "50%" }}
            isMultiple={false}
            placeholder="Select site"
          />
          {siteValue?.length ? (
            <FormInputDropdown
              label="Building*"
              control={control}
              name="building"
              rules={{ required: "Building is required" }}
              data={buildingList}
              styles={{ maxWidth: "50%" }}
              isMultiple={false}
              placeholder="Select building"
            />
          ) : null}
          {siteValue?.length && showTeam ? (
            <FormInputDropdown
              label="Team*"
              control={control}
              name="team"
              rules={{ required: "Team is required" }}
              data={teamsList}
              styles={{ maxWidth: "50%" }}
              isMultiple={false}
              placeholder="Select team"
            />
          ) : null}
        </Box>
      </Sidebar>
    </div>
  );
};

SiteAndBuildingSidebar.propTypes = {
  history: PropTypes.object,
  isDrawer: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  title: PropTypes.string,
  showTeam: PropTypes.bool
};

export default withRouter(SiteAndBuildingSidebar);
