import React, { useCallback } from "react";
import {
  Container,
  TitleBar,
  TitleText,
  SummaryContainer,
  SummaryItem,
  SummaryValue,
  SummaryLabel,
  InfoContainer
} from "./style/attendanceSummaryStyle";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import InfoSvg from "shared/assets/icons/info.svg";
import TooltipText from "shared/components/CustomizedTooltip/TooltipText";
import PropTypes from "prop-types";

const AttendanceSummary = ({ avgEmployeeDays, avgEmployeeArrivals, avgEmployees }) => {
  const renderSummaryItem = useCallback(
    (value, label, title = "", message = "", valueStyle = {}, labelStyle = {}) => (
      <Grid item>
        <SummaryItem>
          <SummaryValue variant="h4" color="#1ED400" style={valueStyle}>
            {value || "No data"}
          </SummaryValue>
          <SummaryLabel color="#1ED400" style={labelStyle}>
            {label}
          </SummaryLabel>

          <Tooltip arrow title={<TooltipText title={title} message={message} />} placement="top">
            <InfoContainer>
              <img src={InfoSvg} />
            </InfoContainer>
          </Tooltip>
        </SummaryItem>
      </Grid>
    ),
    []
  );

  return (
    <Container>
      <TitleBar>
        <TitleText>Averages for current selection</TitleText>
      </TitleBar>

      <SummaryContainer container spacing={2}>
        {renderSummaryItem(avgEmployeeDays, "Avg. employee days per week", "Average employee days per week", [
          "Shows how many days per week  the average employee arrived.",
          "The average calculation does not include holidays and the average of employees who have their own average of less than 1 day."
        ])}
        {renderSummaryItem(avgEmployeeArrivals, "Avg. employee arrivals", "Average employee arrivals", [
          "Shows how many days (in total, not per week) the average employee arrived during this period."
        ])}
        {renderSummaryItem(avgEmployees, "Avg. employees per day", "Average employees per day ", [
          "Shows how many employees arrived to the office on an average day."
        ])}
      </SummaryContainer>
    </Container>
  );
};

AttendanceSummary.propTypes = {
  avgEmployeeDays: PropTypes.number,
  avgEmployeeArrivals: PropTypes.number,
  avgEmployees: PropTypes.number
};

export default React.memo(AttendanceSummary);
