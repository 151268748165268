import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import DataTable from "shared/components/DataTable/DataTable";
import { ContainerBox, BodyBox } from "./style/adminSettingsPageStyle";
import useAdminSettingsUsers from "./hooks/useAdminSettingsUsers";
import useAdminSettingsHolidays from "./hooks/useAdminSettingsHolidays";
import { columnsUsers, deleteUserModalContent } from "./utils/adminSettingsPageUserDictionary";
import { columnsHolidays, deleteHolidayModalContent } from "./utils/adminSettingsPageHolidayDictionary";
import PageTitle from "shared/components/PageTitle";
import AdminSettingsHolidaySidebar from "./components/AdminSettingsHolidaySidebar";
import AdminSettingsUserSidebar from "./components/AdminSettingsUserSidebar";
import ConfirmDialog from "shared/components/Dialogs/ConfirmDialog";

const AdminSettingsListingPage = () => {
  const { listAdmins, onEditUser, onDeleteUser, onAddUser, isUserLoading, userSidebar, userId, onCloseUserSidebar } =
    useAdminSettingsUsers();

  const {
    listHolidays,
    onInfoHoliday,
    onDeleteHoliday,
    onAddHoliday,
    isHolidaysLoading,
    holidaySidebar,
    holidayId,
    deleteHolidayDialog,
    onSidebarDeleteHoliday
  } = useAdminSettingsHolidays();

  const renderSidebars = useMemo(
    () => (
      <>
        <AdminSettingsUserSidebar isOpen={userSidebar.enable} onClose={onCloseUserSidebar} userId={userId} />
        <AdminSettingsHolidaySidebar
          isOpen={holidaySidebar.enable}
          onClose={holidaySidebar.set}
          isInfo={!!holidayId}
          holidayId={holidayId}
          onDelete={onSidebarDeleteHoliday}
        />
      </>
    ),
    [holidaySidebar, holidayId, userSidebar, userId, onSidebarDeleteHoliday, onCloseUserSidebar]
  );

  const renderDialogs = useMemo(
    () => (
      <>
        <ConfirmDialog
          showDialog={deleteHolidayDialog.enable}
          resolve={() => onDeleteHoliday(holidayId)}
          reject={deleteHolidayDialog.off}
          acceptLabel={"OK"}
          title={deleteHolidayModalContent.title}
          text={deleteHolidayModalContent.body}
          rowName={null}
          rowId={null}
          setShowDialog={deleteHolidayDialog.off}
        />
      </>
    ),
    [deleteHolidayDialog, onDeleteHoliday, holidayId]
  );

  return (
    <ContainerBox>
      <PageTitle title="Admin Settings" />

      <BodyBox>
        <DataTable
          rows={listAdmins}
          columns={columnsUsers}
          tableName="Dashboard Users"
          addNewText="Add Dashboard User"
          onEdit={onEditUser}
          onDelete={onDeleteUser}
          onAdd={onAddUser}
          deleteTitle={deleteUserModalContent.title}
          deleteContentText={deleteUserModalContent.body}
          deleteWarningText={deleteUserModalContent.warningText}
          showInfoColumn={false}
          isLoading={isUserLoading}
        />

        <DataTable
          rows={listHolidays}
          columns={columnsHolidays}
          tableName="National Holidays"
          addNewText="Add Holiday"
          onInfo={onInfoHoliday}
          onDelete={onDeleteHoliday}
          onAdd={onAddHoliday}
          deleteTitle={deleteHolidayModalContent.title}
          deleteContentText={deleteHolidayModalContent.body}
          isLoading={isHolidaysLoading}
          showEditColumn={false}
        />
      </BodyBox>

      {renderSidebars}
      {renderDialogs}
    </ContainerBox>
  );
};

AdminSettingsListingPage.propTypes = {
  history: PropTypes.object,
  path: PropTypes.string
};

export default withRouter(AdminSettingsListingPage);
