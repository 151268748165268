import React, { useEffect, useMemo, useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectBuildingFilter, selectSiteFilter } from "core/selectors";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  useAssignDailySeatMutation,
  useUnassignedEmployeesQuery,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";
import { format } from "date-fns";
import { setUnassignedUsers } from "features/userManagement/userManagementSlice";
import { isEmptyObject } from "shared/lib/validation";
import { getWorkstationsOptions } from "shared/lib/getters";
import { DAY_TYPES } from "shared/constants";

const dateToday = format(new Date(), "yyyy-MM-dd");

const mapStateToProps = (state) => ({
  building: selectBuildingFilter(state),
  site: selectSiteFilter(state),
  token: selectAccessToken(state)
});

const WorkstationEditInputCell = ({ id, value, field }) => {
  /* ------------------ HOOKs ------------------ */

  const dispatch = useDispatch();

  /* ------------------ STATEs ------------------ */

  const apiRef = useGridApiContext();
  const [selectedWorkstation, setSelectedWorkstation] = useState({});
  const { building, site, token } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const {
    data: usersWithoutWorkstation,
    isLoading: isUsersLoading,
    refetch
  } = useUnassignedEmployeesQuery({ site, date: dateToday, building }, { skip: !site || !building });

  const { data: workstationByBuildingsData, isLoading: isWorkstationByBuildingsLoading } =
    useWorkstationsByBuildingsQuery(
      { site, building, date: format(new Date(), "yyyy-MM-dd") },
      { skip: !token || !site || !building }
    );

  const [assignDailySeat, { isLoading: isAssignLoading }] = useAssignDailySeatMutation();

  /* ------------------ MEMOs ------------------ */

  const workstationsByBuildingOptions = useMemo(() => {
    if (
      isEmptyObject(workstationByBuildingsData) ||
      isWorkstationByBuildingsLoading ||
      !building ||
      !workstationByBuildingsData[building]?.length
    )
      return [];

    return getWorkstationsOptions(workstationByBuildingsData[building]);
  }, [workstationByBuildingsData, isWorkstationByBuildingsLoading]);

  /* ------------------ HANDLERs ------------------ */

  const handleRef = (element) => {
    if (element) {
      const input = element.querySelector(`input[value="${value}"]`);

      input?.focus();
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedWorkstation(newValue);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (usersWithoutWorkstation && !isUsersLoading) {
      dispatch(setUnassignedUsers(usersWithoutWorkstation.employees));
    }
  }, [usersWithoutWorkstation, isUsersLoading]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const handleSubmit = async () => {
    try {
      await assignDailySeat({
        date: dateToday,
        seatId: selectedWorkstation.id,
        employeeId: id,
        dayType: DAY_TYPES.office
      }).unwrap();

      handleChange(null, null);

      refetch();
    } catch (e) {
      console.error("ERROR", e);
    }
  };

  return (
    <ContainerBox>
      <Autocomplete
        ref={handleRef}
        forcePopupIcon={false}
        options={workstationsByBuildingOptions}
        getOptionLabel={(option) => (option.value ? option.value : "")}
        getOptionDisabled={(option) => option?.disabled}
        onChange={handleChange}
        size="small"
        value={value}
        fullWidth
        renderInput={(params) => <TextField {...params} label="" placeholder="Search workstation" />}
      />

      <SubmitButton
        variant={!selectedWorkstation?.id ? "outlined" : "contained"}
        onClick={handleSubmit}
        disabled={!selectedWorkstation?.id || isAssignLoading}
      >
        <Typography>Assign</Typography>
      </SubmitButton>
    </ContainerBox>
  );
};

const ContainerBox = styled(Box)(() => ({
  flex: 1,
  flexDirection: "row",
  display: "flex"
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(2.25),
  padding: theme.spacing(0.2, 3.5),
  color: "#fff",
  fontWeight: "700",
  marginLeft: theme.spacing(1.2),
  boxShadow: "none"
}));

WorkstationEditInputCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.string,
  field: PropTypes.string
};

export default React.memo(WorkstationEditInputCell);
