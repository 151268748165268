import { getFullName } from "shared/lib/getters";

export const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => getFullName(params.row.firstName, params.row.lastName)
  },
  {
    field: "department",
    headerName: "Department",
    flex: 1
  }
];

export const barChartOptions = {
  indexAxis: "y",
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        // display: false,
        precision: 0
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  }
};
