import React from "react";
import PropTypes from "prop-types";
import { Box, ListItemText, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled, useTheme } from "@mui/material/styles";

// TODO: Deprecated

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const getStyles = (option, optionsList, theme) => {
  const isSelected = optionsList?.length && optionsList?.indexOf(option) === -1;

  return {
    fontWeight: isSelected ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    backgroundColor: isSelected ? "#ffffff" : "#00203E",
    color: isSelected ? "#000000" : "#ffffff"
  };
};

const CustomizedSelect = ({
  isMultiple = true,
  handleChange,
  placeholder,
  values,
  name,
  selectedValue = [],
  defaultValue = [],
  defaultLabel,
  isTableHeaderSelect = false,
  helperText,
  error,
  disabled,
  readonly,
  styles,
  onClose
}) => {
  const theme = useTheme();
  const data = selectedValue?.length ? selectedValue : defaultValue;

  const onClear = () => {
    handleChange([]);
  };

  return (
    <Container position="relative" helperText={helperText}>
      <Select
        multiple={isMultiple}
        displayEmpty
        required
        value={data || []}
        defaultValue={values || []}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        onClose={onClose}
        name={name}
        sx={{ ...styles, minHeight: "20px" }}
        renderValue={(selected) => {
          if (selected?.length === 0 && defaultLabel?.length) {
            return <Typography>{defaultLabel}</Typography>;
          }

          if (selected?.length === 0 || selected[0] === "") {
            return <Typography color="#BABEC5">{placeholder}</Typography>;
          }

          if (name === "userId") {
            const item = values.find((value) => value.option === selected[0]);
            return item?.value;
          }

          return selected?.join(", ");
        }}
        input={<CustomizedOutlineInput label="" size="small" isTableHeaderSelect={isTableHeaderSelect} error={error} />}
        MenuProps={MenuProps}
      >
        {defaultLabel?.length ? (
          <CustomizedMenuItem onMouseDown={onClear} checked={data?.length === 0} disabled={readonly}>
            {data?.length === 0 ? <CheckIcon sx={{ color: "#fff", paddingRight: "8px" }} fontSize="medium" /> : null}
            <ListItemText primary={defaultLabel} />
          </CustomizedMenuItem>
        ) : null}

        {values?.map(({ option, value }) => (
          <MenuItem key={option} value={option} style={getStyles(option, data, theme)} disabled={readonly}>
            {data?.indexOf(option) > -1 && <CheckIcon sx={{ color: "#fff", paddingRight: "8px" }} fontSize="medium" />}
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <HelperText>
          <Typography variant="caption">{helperText}</Typography>
        </HelperText>
      )}
    </Container>
  );
};

const Container = styled(Box)(({ theme, helperText }) => ({
  paddingBottom: helperText && theme.spacing(2),
  width: "100%"
}));

const CustomizedOutlineInput = styled(OutlinedInput)(({ theme, isTableHeaderSelect }) => ({
  background: "#FFF",
  color: "#00203E",
  border: isTableHeaderSelect && "1px solid #000",
  width: isTableHeaderSelect && "120px",
  borderRadius: isTableHeaderSelect && theme.spacing(2.3),
  "& .MuiOutlinedInput-root": {
    "& > fieldset": {
      borderColor: "#6B6C72",
      borderWidth: "1px"
    }
  }
}));

const HelperText = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(5),
  color: "#d32f2f"
}));

const CustomizedMenuItem = styled(MenuItem)(({ theme, checked }) => ({
  "&, &:hover": {
    fontWeight: checked ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
    backgroundColor: checked ? "#00203E !important" : "#ffffff",
    color: checked ? "#ffffff !important" : "#000000"
  }
}));

CustomizedSelect.propTypes = {
  isMultiple: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.array,
  defaultValue: PropTypes.array,
  defaultLabel: PropTypes.string,
  isTableHeaderSelect: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onClose: PropTypes.func
};

export default CustomizedSelect;
