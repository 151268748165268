import React from "react";
import PropTypes, { bool } from "prop-types";
import Dialog from "@mui/material/Dialog";
import { Container, CloseButton, ControlsContainer, InButton, OutButton, styles } from "./style/fullscreenModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

function FullscreenModal({ visible, onClose, children, onZoomIn, onZoomOut, hideControls }) {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <div>
      <Dialog
        open={visible}
        onClose={handleClose}
        PaperProps={{
          style: styles.paperPropsStyle
        }}
        TransitionProps={{
          style: styles.transitionPropsStyle
        }}
      >
        <Container>
          {children}

          <CloseButton onClick={handleClose}>
            <CloseIcon sx={styles.closeIconStyle} />
          </CloseButton>

          {!hideControls && (
            <ControlsContainer>
              {onZoomIn && (
                <InButton onClick={onZoomIn}>
                  <ZoomInIcon sx={styles.controlIconStyle} />
                </InButton>
              )}
              {onZoomOut && (
                <OutButton onClick={onZoomOut}>
                  <ZoomOutIcon sx={styles.controlIconStyle} />
                </OutButton>
              )}
              <OutButton onClick={onClose}>
                <ZoomInMapIcon sx={styles.controlIconStyle} />
              </OutButton>
            </ControlsContainer>
          )}
        </Container>
      </Dialog>
    </div>
  );
}

FullscreenModal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.element,
  onClose: PropTypes.func,
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
  hideControls: bool
};

export default FullscreenModal;
