import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import styled from "@mui/material/styles/styled";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";

const filterOptions = createFilterOptions({
  stringify: (option) => option.value
});

const FormInputAutocomplete = ({ name, control, label, rules, placeholder, data, styles, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {label && <CustomizedLabel text={label} error={!!error} />}
          <Autocomplete
            disablePortal
            forcePopupIcon={false}
            options={data}
            disabled={disabled}
            filterOptions={filterOptions}
            getOptionLabel={(option) => (option.value ? option.value : "")}
            getOptionDisabled={(option) => option?.disabled}
            onChange={(event, item, reason) => {
              if (reason === "clear") {
                onChange({});
              } else {
                onChange(item);
              }
            }}
            size="small"
            value={value}
            fullWidth
            style={styles}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id || option.option}>
                  {option.value}
                </li>
              );
            }}
            renderInput={(params) => (
              <CustomizedTextField
                {...params}
                label=""
                placeholder={placeholder}
                helperText={error ? error.message : null}
                error={!!error}
                // sx={styles}
              />
            )}
          />
        </>
      )}
    />
  );
};

const CustomizedTextField = styled(TextField)({
  color: "#00203E",
  background: "#FFFFFF",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#8D9096"
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#f8fafe"
  }
});

FormInputAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  styles: PropTypes.object,
  disabled: PropTypes.bool
};

export default FormInputAutocomplete;
