import { useEffect, useMemo, useState } from "react";
import { setDepartmentsList } from "features/userManagement/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetDepartmentsListQuery } from "features/userManagement/userManagementApiSlice";
import { selectAccessToken } from "core/selectors";

export default function useAttendanceLogic() {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const [currentWeekSummary, setCurrentWeekSummary] = useState({ users: 0, days: 0 });
  const [previousWeeksSummary, setPreviousWeeksSummary] = useState({ users: 0, days: 0 });

  const token = useSelector(selectAccessToken);

  const { data: departmentsData, isLoading: isDepartmentsLoading } = useGetDepartmentsListQuery(null, { skip: !token });

  const summary = useMemo(
    () => (tab === 0 ? currentWeekSummary : previousWeeksSummary),
    [tab, currentWeekSummary, previousWeeksSummary]
  );

  useEffect(() => {
    if (departmentsData && !isDepartmentsLoading) {
      dispatch(setDepartmentsList(departmentsData.departments));
    }
  }, [departmentsData, isDepartmentsLoading]);

  const onChangeTab = (_event, newTab) => {
    setTab(newTab);
  };

  return {
    summary,
    setCurrentWeekSummary,
    setPreviousWeeksSummary,
    tab,
    onChangeTab
  };
}
