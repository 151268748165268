import React from "react";
import styled from "@mui/material/styles/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import InfoIcon from "shared/assets/icons/info.svg";

const CustomizedTooltip = ({ tooltipText, placement = "left-end" }) => {
  return (
    <TooltipComponent title={tooltipText} placement={placement} TransitionProps={{ timeout: 400 }} arrow>
      <img src={InfoIcon} />
    </TooltipComponent>
  );
};

const TooltipComponent = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: "#00203E",
      maxWidth: 700
    }
  })
);

CustomizedTooltip.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  placement: PropTypes.string
};

export default CustomizedTooltip;
