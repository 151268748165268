import React from "react";
import BandIcon from "shared/assets/icons/groups/band.svg";
import BasketballIcon from "shared/assets/icons/groups/basketball.svg";
import BoardgamesIcon from "shared/assets/icons/groups/boardgames.svg";
import BookIcon from "shared/assets/icons/groups/book.svg";
import BurgerIcon from "shared/assets/icons/groups/burger.svg";
import ChampagneIcon from "shared/assets/icons/groups/champagne.svg";
import CocktailIcon from "shared/assets/icons/groups/cocktail.svg";
import CoffeeIcon from "shared/assets/icons/groups/coffee.svg";
import DanceIcon from "shared/assets/icons/groups/dance.svg";
import DjIcon from "shared/assets/icons/groups/dj.svg";
import FilmReelIcon from "shared/assets/icons/groups/film-reel.svg";
import FinanceIcon from "shared/assets/icons/groups/finance.svg";
import FitnessIcon from "shared/assets/icons/groups/fitness.svg";
import GongIcon from "shared/assets/icons/groups/gong.svg";
import GymIcon from "shared/assets/icons/groups/gym.svg";
import IceCreamIcon from "shared/assets/icons/groups/ice-cream.svg";
import JoggingIcon from "shared/assets/icons/groups/jogging.svg";
import LessonIcon from "shared/assets/icons/groups/lesson.svg";
import MartiniIcon from "shared/assets/icons/groups/martini.svg";
import MeditationIcon from "shared/assets/icons/groups/meditation.svg";
import MilkshakeIcon from "shared/assets/icons/groups/milkshake.svg";
import MusicIcon from "shared/assets/icons/groups/music.svg";
import PizzaIcon from "shared/assets/icons/groups/pizza.svg";
import PopcornIcon from "shared/assets/icons/groups/popcorn.svg";
import PushUpIcon from "shared/assets/icons/groups/push-up.svg";
import SaladIcon from "shared/assets/icons/groups/salad.svg";
import CelebrationIcon from "shared/assets/icons/groups/celebration.svg";
import SoccerIcon from "shared/assets/icons/groups/soccer.svg";
import SpeechIcon from "shared/assets/icons/groups/speech.svg";
import SwimmingIcon from "shared/assets/icons/groups/swimming.svg";
import TeacherIcon from "shared/assets/icons/groups/teacher.svg";
import TeamWinIcon from "shared/assets/icons/groups/team-win.svg";
import WinnerIcon from "shared/assets/icons/groups/winner.svg";
import YogaIcon from "shared/assets/icons/groups/yoga.svg";

const icons = {
  band: "band.png",
  basketball: "basketball.png",
  boardgames: "boardgames.png",
  book: "book.png",
  burger: "burger.png",
  champagne: "champagne.png",
  cocktail: "cocktail.png",
  coffee: "coffee.png",
  dance: "dance.png",
  dj: "dj.png",
  filmReel: "film-reel.png",
  finance: "finance.png",
  fitness: "fitness.png",
  gong: "gong.png",
  gym: "gym.png",
  iceCream: "ice-cream.png",
  jogging: "jogging.png",
  lesson: "lesson.png",
  martini: "martini.png",
  meditation: "meditation.png",
  milkshake: "milkshake.png",
  music: "music.png",
  pizza: "pizza.png",
  popcorn: "popcorn.png",
  pushUp: "push-up.png",
  salad: "salad.png",
  celebration: "celebration.png",
  soccer: "soccer.png",
  speech: "speech.png",
  swimming: "swimming.png",
  teacher: "teacher.png",
  teamWin: "team-win.png",
  winner: "winner.png",
  yoga: "yoga.png"
};

export const activitiesIconsMap = {
  [icons.band]: BandIcon,
  [icons.basketball]: BasketballIcon,
  [icons.boardgames]: BoardgamesIcon,
  [icons.book]: BookIcon,
  [icons.burger]: BurgerIcon,
  [icons.champagne]: ChampagneIcon,
  [icons.cocktail]: CocktailIcon,
  [icons.coffee]: CoffeeIcon,
  [icons.dance]: DanceIcon,
  [icons.dj]: DjIcon,
  [icons.filmReel]: FilmReelIcon,
  [icons.finance]: FinanceIcon,
  [icons.fitness]: FitnessIcon,
  [icons.gong]: GongIcon,
  [icons.gym]: GymIcon,
  [icons.iceCream]: IceCreamIcon,
  [icons.jogging]: JoggingIcon,
  [icons.lesson]: LessonIcon,
  [icons.martini]: MartiniIcon,
  [icons.meditation]: MeditationIcon,
  [icons.milkshake]: MilkshakeIcon,
  [icons.music]: MusicIcon,
  [icons.pizza]: PizzaIcon,
  [icons.popcorn]: PopcornIcon,
  [icons.pushUp]: PushUpIcon,
  [icons.salad]: SaladIcon,
  [icons.celebration]: CelebrationIcon,
  [icons.soccer]: SoccerIcon,
  [icons.speech]: SpeechIcon,
  [icons.swimming]: SwimmingIcon,
  [icons.teacher]: TeacherIcon,
  [icons.teamWin]: TeamWinIcon,
  [icons.winner]: WinnerIcon,
  [icons.yoga]: YogaIcon
};

export const activitiesIconsOptions = [
  {
    checkboxValue: icons.band,
    icon: activitiesIconsMap[icons.band]
  },
  {
    checkboxValue: icons.basketball,
    icon: activitiesIconsMap[icons.basketball]
  },
  {
    checkboxValue: icons.boardgames,
    icon: activitiesIconsMap[icons.boardgames]
  },
  {
    checkboxValue: icons.book,
    icon: activitiesIconsMap[icons.book]
  },
  {
    checkboxValue: icons.burger,
    icon: activitiesIconsMap[icons.burger]
  },
  {
    checkboxValue: icons.champagne,
    icon: activitiesIconsMap[icons.champagne]
  },
  {
    checkboxValue: icons.cocktail,
    icon: activitiesIconsMap[icons.cocktail]
  },
  {
    checkboxValue: icons.coffee,
    icon: activitiesIconsMap[icons.coffee]
  },
  {
    checkboxValue: icons.dance,
    icon: activitiesIconsMap[icons.dance]
  },
  {
    checkboxValue: icons.dj,
    icon: activitiesIconsMap[icons.dj]
  },
  {
    checkboxValue: icons.filmReel,
    icon: activitiesIconsMap[icons.filmReel]
  },
  {
    checkboxValue: icons.finance,
    icon: activitiesIconsMap[icons.finance]
  },
  {
    checkboxValue: icons.fitness,
    icon: activitiesIconsMap[icons.fitness]
  },
  {
    checkboxValue: icons.gong,
    icon: activitiesIconsMap[icons.gong]
  },
  {
    checkboxValue: icons.gym,
    icon: activitiesIconsMap[icons.gym]
  },
  {
    checkboxValue: icons.iceCream,
    icon: activitiesIconsMap[icons.iceCream]
  },
  {
    checkboxValue: icons.jogging,
    icon: activitiesIconsMap[icons.jogging]
  },
  {
    checkboxValue: icons.lesson,
    icon: activitiesIconsMap[icons.lesson]
  },
  {
    checkboxValue: icons.martini,
    icon: activitiesIconsMap[icons.martini]
  },
  {
    checkboxValue: icons.meditation,
    icon: activitiesIconsMap[icons.meditation]
  },
  {
    checkboxValue: icons.milkshake,
    icon: activitiesIconsMap[icons.milkshake]
  },
  {
    checkboxValue: icons.music,
    icon: activitiesIconsMap[icons.music]
  },
  {
    checkboxValue: icons.pizza,
    icon: activitiesIconsMap[icons.pizza]
  },
  {
    checkboxValue: icons.popcorn,
    icon: activitiesIconsMap[icons.popcorn]
  },
  {
    checkboxValue: icons.pushUp,
    icon: activitiesIconsMap[icons.pushUp]
  },
  {
    checkboxValue: icons.salad,
    icon: activitiesIconsMap[icons.salad]
  },
  {
    checkboxValue: icons.celebration,
    icon: activitiesIconsMap[icons.celebration]
  },
  {
    checkboxValue: icons.soccer,
    icon: activitiesIconsMap[icons.soccer]
  },
  {
    checkboxValue: icons.speech,
    icon: activitiesIconsMap[icons.speech]
  },
  {
    checkboxValue: icons.swimming,
    icon: activitiesIconsMap[icons.swimming]
  },
  {
    checkboxValue: icons.teacher,
    icon: activitiesIconsMap[icons.teacher]
  },
  {
    checkboxValue: icons.teamWin,
    icon: activitiesIconsMap[icons.teamWin]
  },
  {
    checkboxValue: icons.winner,
    icon: activitiesIconsMap[icons.winner]
  },
  {
    checkboxValue: icons.yoga,
    icon: activitiesIconsMap[icons.yoga]
  }
];

export const sendReminderDialogContent = {
  title: "Send reminder?",
  body: (
    <div>
      <span>An email reminder will be sent to all invitees (unless they declined)</span>
    </div>
  ),
  warningText: ""
};
