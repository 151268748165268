import { createSlice } from "@reduxjs/toolkit";
import { adminSettingsApiSlice } from "./adminSettingsApiSlice";

const initialState = {
  companySites: [],
  nationalHolidays: [],
  roles: [],
  routeResources: []
};

export const adminSettingsSlice = createSlice({
  name: "adminSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(adminSettingsApiSlice.endpoints.companySites.matchFulfilled, (state, action) => {
      state.companySites = action.payload;
    });

    builder.addMatcher(adminSettingsApiSlice.endpoints.nationalHolidays.matchFulfilled, (state, action) => {
      state.nationalHolidays = action.payload;
    });

    builder.addMatcher(adminSettingsApiSlice.endpoints.roles.matchFulfilled, (state, action) => {
      state.roles = action.payload;
    });

    builder.addMatcher(adminSettingsApiSlice.endpoints.routeResources.matchFulfilled, (state, action) => {
      state.routeResources = action.payload;
    });
  }
});

export default adminSettingsSlice.reducer;
