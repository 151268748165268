import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectAllRoomsList, selectBuildingFilter, selectSiteFilter } from "core/selectors";
import FormInputText from "../FormInputs/Text/FormInputText";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Sidebar from "../Sidebar/Sidebar";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { useRoomsMapQuery } from "features/rooms/roomsApiSlice";
import { setRoomsByBuilding } from "features/rooms/roomsSlice";
import FormInputAutocomplete from "../FormInputs/Autocomplete/FormInputAutocomplete";
import RefreshIcon from "shared/assets/icons/refresh.svg";
import TrashIcon from "shared/assets/icons/trash.svg";

const mapStateToProps = (state) => {
  const site = selectSiteFilter(state);
  const building = selectBuildingFilter(state);
  const roomsList = selectAllRoomsList(state);

  return {
    roomsList,
    site,
    building
  };
};

const DigitalSignSidebar = ({ history, isOpen, setIsOpen }) => {
  const [showDeleteDialog, setShowDialog] = useState(false);
  const { roomsList, site, building } = useSelector(mapStateToProps);

  const { control } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const { data: roomsData, isLoading: isRoomsLoading } = useRoomsMapQuery(
    { site, building },
    { skip: !site || !site || !building }
  );

  useEffect(() => {
    if (roomsData && !isRoomsLoading && building) {
      dispatch(setRoomsByBuilding(roomsData[building]));
    }
  }, [roomsData, isRoomsLoading, building]);

  const onDelete = () => {};

  return (
    <div>
      <Sidebar
        history={history}
        isDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        showCancelButton={false}
        resolveLabel="OK"
        shouldValidate
        disabled={isRoomsLoading}
      >
        <Box>
          <Typography variant="h5" fontWeight="700">
            Digital signs
          </Typography>
          <Box py={2}>
            <Typography>Directly control the digital signs</Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <FormInputAutocomplete
            label="Specific room"
            control={control}
            name="room"
            data={roomsList}
            placeholder="Select room"
            styles={{ maxWidth: "50%" }}
          />
        </Box>
        <Box mt={4}>
          <FormInputText label="Display name 1" control={control} name="name1" styles={{ maxWidth: "50%" }} />
          <UpdateButton onClick={() => {}}>
            <img src={RefreshIcon} />
            <Typography ml={1}>Update</Typography>
          </UpdateButton>
        </Box>
        <Box mt={2}>
          <FormInputText label="Display name 2" control={control} name="name2" styles={{ maxWidth: "50%" }} />
          <UpdateButton onClick={() => {}}>
            <img src={RefreshIcon} />
            <Typography ml={1}>Update</Typography>
          </UpdateButton>
        </Box>
        <Box mt={2}>
          <FormInputText label="Display name 3" control={control} name="name3" styles={{ maxWidth: "50%" }} />
          <UpdateButton onClick={() => {}}>
            <img src={RefreshIcon} />
            <Typography ml={1}>Update</Typography>
          </UpdateButton>
        </Box>
        <Box my={5}>
          <Divider color="#BABEC5" />
        </Box>
        <Box display="flex" justifyContent="center">
          <ActionButton onClick={() => setShowDialog(true)}>
            <CustomizedIcon src={TrashIcon} />
            <Typography>Delete all signs</Typography>
          </ActionButton>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <ActionButton onClick={() => {}}>
            <CustomizedIcon src={RefreshIcon} />
            <Typography>Resend all signs</Typography>
          </ActionButton>
        </Box>
      </Sidebar>
      <ConfirmDialog
        showDialog={showDeleteDialog}
        resolve={onDelete}
        reject={() => setShowDialog(false)}
        title="Delete All signs?"
        setShowDialog={setShowDialog}
      />
    </div>
  );
};

const UpdateButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  alignItems: "center",
  justifyContent: "flex-start",
  margin: theme.spacing(0, 2),
  padding: theme.spacing(1, 2),
  display: "inline-flex"
}));

const ActionButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
  padding: theme.spacing(1, 2, 1, 5),
  margin: theme.spacing(1, 0),
  position: "relative"
}));

const CustomizedIcon = styled("img")(({ theme }) => ({
  paddingRight: theme.spacing(1.5)
}));

DigitalSignSidebar.propTypes = {
  history: PropTypes,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default DigitalSignSidebar;
