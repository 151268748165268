import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  site: "",
  buildings: "",
  floorsByBuilding: null,
  departmentsByBuilding: null,
  companyId: ""
};

export const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setSiteInfo: (state, action) => {
      const { site, buildings, companyId, departmentsByBuildingMap, floorsByBuildingMap } = action.payload;
      state.site = site;
      state.buildings = buildings;
      state.floorsByBuilding = floorsByBuildingMap;
      state.departmentsByBuilding = departmentsByBuildingMap;
      state.companyId = companyId;
    },
    setCompanySite: (state, action) => {
      state.site = action.payload;
    }
  }
});

export const { setSiteInfo, setCompanySite } = siteSlice.actions;

export default siteSlice.reducer;
