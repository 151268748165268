import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  position: "relative"
}));

export const ZoomControlContainer = styled(Box)(() => ({
  background: "#454545",
  borderRadius: "8px",
  bottom: "25px",
  right: "25px",
  position: "absolute",
  zIndex: "20"
}));

export const ZoomButton = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  "&:hover": {
    cursor: "pointer"
  }
}));

export const TooltipContainer = styled(Box)(({ theme }) => ({
  visibility: "hidden",
  opacity: 0,
  position: "fixed",
  zIndex: "21",
  minWidth: 180,
  backgroundColor: "#00203E",
  transition: "opacity .2s ease-in-out",
  color: "#fff",
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),

  "& p": {
    margin: 0
  },

  "& .tooltip-template-room-name": {
    paddingBottom: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2.5)
  },

  "& .tooltip-template-user-name": {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(2)
  },
  "&:before": {
    content: "''",
    width: 0,
    height: 0,
    borderLeft: `${theme.spacing(1)} solid transparent`,
    borderRight: `${theme.spacing(1)} solid transparent`,
    borderTop: `${theme.spacing(1)} solid #00203E`,
    position: "absolute",
    top: "calc(100% - 1px)",
    right: `calc(50% - ${theme.spacing(1)})`,
    zIndex: 22
  }
}));
