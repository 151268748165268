import React from "react";
import { Stage, Layer, Image } from "react-konva";
import useImage from "use-image";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PropTypes from "prop-types";
import AddWorkstationIcon from "shared/assets/icons/add-workstation.svg";
import Divider from "@mui/material/Divider";
import MapWorkstationItem from "./components/MapWorkstationItem";
import useMainStageLogic, { TooltipClassName } from "./hooks/useMainStageLogic";
import { ZoomButton, ZoomControlContainer, Container, TooltipContainer } from "./style/mainStage";

function isTouchEnabled() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

const MainStage = ({
  workstationsMapList,
  notAssignedRoomsList,
  onEmptyRoomClick,
  onWorkstationClick,
  buildingPlan,
  ...props
}) => {
  const {
    width,
    height,
    zoomStage,
    handleTouch,
    handleTouchEnd,
    stage,
    stageRef,
    scaleRelativeToPoint,
    onMouseIn,
    onMouseOut,
    onWatchTooltip
  } = useMainStageLogic(props);

  // eslint-disable-next-line react/prop-types
  const PlusImage = ({ cx, cy }) => {
    const [image] = useImage(AddWorkstationIcon);
    return (
      <Image
        image={image}
        x={cx}
        y={cy}
        offsetX={image ? image.width * 2.7 : 0}
        offsetY={image ? image.height * 2.7 : 0}
        width={65}
        height={65}
      />
    );
  };

  const BuildingImage = () => {
    const [image] = useImage(buildingPlan);
    return <Image image={image} />;
  };

  return (
    <Container height="auto" width="100%" onMouseMove={onWatchTooltip}>
      <ZoomControlContainer>
        <ZoomButton
          onClick={() => {
            scaleRelativeToPoint(
              {
                x: width / 2,
                y: height / 2
              },
              true
            );
          }}
        >
          <AddIcon sx={{ color: "white" }} />
        </ZoomButton>
        <Divider />
        <ZoomButton
          onClick={() => {
            scaleRelativeToPoint(
              {
                x: width / 2,
                y: height / 2
              },
              false
            );
          }}
        >
          <RemoveIcon sx={{ color: "white" }} />
        </ZoomButton>
      </ZoomControlContainer>
      <Stage
        width={width}
        height={height}
        draggable={!isTouchEnabled()}
        onWheel={zoomStage}
        onTouchMove={handleTouch}
        onTouchEnd={handleTouchEnd}
        scaleX={stage.scale}
        scaleY={stage.scale}
        x={stage.x}
        y={stage.y}
        ref={stageRef}
      >
        <Layer>
          <BuildingImage />
          {workstationsMapList?.length
            ? workstationsMapList.map((workstation, index) => (
                <MapWorkstationItem
                  workstation={workstation}
                  key={index}
                  onClick={onWorkstationClick}
                  onMouseIn={onMouseIn}
                  onMouseOut={onMouseOut}
                />
              ))
            : null}
          {notAssignedRoomsList?.length
            ? notAssignedRoomsList.map((room) => (
                <React.Fragment key={room.roomName}>
                  <div onClick={() => onEmptyRoomClick(room.roomName)}>
                    <PlusImage cx={room.x + 40} cy={room.y + 30} />
                  </div>
                </React.Fragment>
              ))
            : null}
        </Layer>
      </Stage>

      <TooltipContainer className={TooltipClassName} />
    </Container>
  );
};

MainStage.propTypes = {
  workstationsMapList: PropTypes.array,
  notAssignedRoomsList: PropTypes.array,
  onEmptyRoomClick: PropTypes.func,
  onWorkstationClick: PropTypes.func,
  buildingPlan: PropTypes.string,
  xCoord: PropTypes.number,
  yCoord: PropTypes.number,
  containerWidth: PropTypes.number,
  stageScale: PropTypes.number
};

export default React.memo(MainStage);
