import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import FormInputDropdown from "shared/components/FormInputs/Dropdown/FormInputDropdownDeprecated";
import { setBuildingFilter, setFloorsFilter, setSiteFilter } from "features/filters/filtersSlice";
import { STORAGE_FLOORS_FILTERS, STORAGE_SITE_AND_BUILDING, STORAGE_USER_INFO } from "shared/constants";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";
import { selectBuildingsList, selectCToken, selectFloorsList, selectCompanySite, selectSites } from "core/selectors";
import { useGetSiteInfoQuery } from "features/site/siteApiSlice";
import { setSiteInfo } from "features/site/siteSlice";
import { parseJSON } from "shared/lib/validation";

const defaultValues = {
  site: "",
  building: "",
  floor: []
};

const mapStateToProps = (state) => ({
  siteList: selectSites(state),
  buildingList: selectBuildingsList(state),
  floorList: selectFloorsList(state),
  cToken: selectCToken(state),
  site: selectCompanySite(state)
});

const FiltersDialog = ({ isOpen, setIsOpen }) => {
  /* ------------------ HOOKs ------------------ */

  const dispatch = useDispatch();
  const { handleSubmit, control, watch, setValue } = useForm({ defaultValues });

  /* ------------------ STATEs ------------------ */

  const { siteList, buildingList, floorList, cToken, site } = useSelector(mapStateToProps);
  const [selectedSite, selectedBuilding] = watch(["site", "building"]);

  /* ------------------ APIs ------------------ */

  const { data: siteInfo, isLoading: isSiteInfoLoading } = useGetSiteInfoQuery(
    { site: selectedSite, cToken },
    { skip: !cToken || !selectedSite }
  );

  /* ------------------ HANDLERs ------------------ */

  const setDefaultSiteHandler = async () => {
    if (selectedSite?.length) return;

    if (!site) {
      try {
        const storageUserItem = await localStorage.getItem(STORAGE_USER_INFO);
        const parsedUserInfo = parseJSON(storageUserItem) || {};

        setValue("site", parsedUserInfo.site);
      } catch (error) {
        console.error("ERROR: setDefaultSiteHandler", { error });
      }
    } else {
      setValue("site", site);
    }
  };

  /* ------------------ EFFECTSs ------------------ */

  useEffect(() => {
    if (siteInfo && !isSiteInfoLoading) {
      dispatch(setSiteInfo(siteInfo));
    }
  }, [siteInfo, isSiteInfoLoading]);

  useEffect(() => {
    dispatch(setBuildingFilter(selectedBuilding));
  }, [selectedBuilding]);

  useEffect(() => {
    setDefaultSiteHandler();
  }, [site, selectedSite]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onSubmit = (values) => {
    const floors = Array.isArray(values.floor) ? values.floor : [values.floor];

    localStorage.setItem(STORAGE_SITE_AND_BUILDING, JSON.stringify({ site: values.site, building: values.building }));
    localStorage.setItem(STORAGE_FLOORS_FILTERS, JSON.stringify(floors));
    dispatch(setSiteFilter(values.site));
    dispatch(setBuildingFilter(values.building));
    dispatch(setFloorsFilter(floors));
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        backdropFilter: "blur(5px)"
      }}
      PaperProps={{
        style: { borderRadius: "12px", maxWidth: "500px" }
      }}
    >
      <Box display="flex" justifyContent="center">
        <DialogTitle variant="h5">Hello!</DialogTitle>
      </Box>
      <DialogContent sx={{ padding: "8px 24px" }}>
        <Box>
          <DialogContentText variant="body1">
            To start using BWith, please select the site, building and floor you would like to view:
          </DialogContentText>
        </Box>
        <Grid container spacing={1} width="70%" flexDirection="column" margin="0 auto">
          <Grid item>
            <FormInputDropdown
              label="Site*"
              control={control}
              name="site"
              rules={{ required: "Site is required" }}
              data={siteList}
              isMultiple={false}
              placeholder="Select site"
            />
          </Grid>
          <Grid item>
            <FormInputDropdown
              label="Building*"
              control={control}
              name="building"
              rules={{ required: "Building is required" }}
              data={buildingList}
              isMultiple={false}
              placeholder="Select building"
              disabled={!selectedSite?.length}
            />
          </Grid>
          <Grid item>
            <FormInputDropdown
              label="Floor*"
              control={control}
              name="floor"
              rules={{ required: "Floor is required" }}
              data={floorList}
              isMultiple={false}
              placeholder="Select floor"
              disabled={!selectedBuilding?.length}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 8px" }}>
        <ResolveButton text="OK" onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};

FiltersDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default React.memo(FiltersDialog);
