import React from "react";
import styled from "@mui/material/styles/styled";
import Badge from "@mui/material/Badge";
import PropTypes from "prop-types";

const CustomizedBadge = ({ children, color = "secondary", invisible = false, badgeContent, positionRight }) => {
  return (
    <BadgeComponent
      color={color}
      variant="dot"
      invisible={invisible}
      badgeContent={badgeContent}
      positionRight={positionRight}
    >
      {children}
    </BadgeComponent>
  );
};

const BadgeComponent = styled(Badge)(({ theme, positionRight }) => ({
  "& .MuiBadge-badge": {
    right: positionRight || theme.spacing(0.6)
  }
}));

CustomizedBadge.propTypes = {
  children: PropTypes.object.isRequired,
  color: PropTypes.string,
  invisible: PropTypes.bool,
  badgeContent: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  positionRight: PropTypes.string
};

export default CustomizedBadge;
