import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  site: null,
  building: null,
  floors: [],
  departments: [],
  userManagement: {},
  workstation: {}
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSiteFilter: (state, action) => {
      state.site = action.payload;
    },
    setBuildingFilter: (state, action) => {
      if (state.building !== action.payload) state.building = action.payload;
    },
    setFloorsFilter: (state, action) => {
      state.floors = action.payload;
    },
    setDepartmentsFilter: (state, action) => {
      state.departments = action.payload;
    },
    setUserManagementFilter: (state, action) => {
      state.userManagement = action.payload;
    },
    setWorkstationFilter: (state, action) => {
      state.workstation = action.payload;
    },
    resetUserManagementFilter: (state) => {
      state.userManagement = {};
    },
    resetWorkstationFilter: (state) => {
      state.workstation = {};
    }
  }
});

export const {
  setSiteFilter,
  setBuildingFilter,
  setFloorsFilter,
  setDepartmentsFilter,
  setUserManagementFilter,
  setWorkstationFilter,
  resetUserManagementFilter,
  resetWorkstationFilter
} = filtersSlice.actions;

export default filtersSlice.reducer;
