import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export const BoxHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled("div")`
  display: grid;
  row-gap: 8px;
  column-gap: 16px;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 20px;
`;

export const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#00203E",
  padding: theme.spacing(1, 3),
  borderRadius: theme.spacing(2),
  fontWeight: "700",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#003f77",
    color: "#fff"
  }
}));

export const TeamIndicatorContainer = styled(Box)(({ theme }) => ({
  background: "#fff",
  borderRadius: theme.spacing(1),
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
  overflow: "hidden",
  height: "100%",
  maxHeight: "700px",

  [theme.breakpoints.up("lg")]: {
    maxHeight: "450px"
  },

  [theme.breakpoints.up("xl")]: {
    maxHeight: "390px"
  },

  [theme.breakpoints.up("xxl")]: {
    maxHeight: "510px"
  },

  [theme.breakpoints.up("xxxl")]: {
    maxHeight: "740px"
  }
}));
