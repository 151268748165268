import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { selectUserManagementList } from "core/selectors";
import {
  useAllowedDepartmentsMutation,
  useDepartmentsQuery,
  useRolesQuery,
  useRouteResourcesQuery,
  useUpdateAllowedDepartmentsMutation
} from "features/adminSettings/adminSettingsApiSlice";
import { camelCaseToTitleCase, getRoleName } from "shared/lib";
import { useUpdateUserMutation } from "features/userManagement/userManagementApiSlice";
import { getFullName } from "shared/lib/getters";
import { ROLE_USER } from "shared/constants";

const defaultValues = {
  user: {},
  role: "",
  resources: [],
  departments: []
};

export default function useAdminSettingsUserSidebarLogic({ userId }) {
  /* ------------------ HOOKs ------------------ */

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { isDirty }
  } = useForm({ mode: "onChange", defaultValues });

  /* ------------------ SELECTORs ------------------ */

  const users = useSelector(selectUserManagementList);

  /* ------------------ STATEs ------------------ */

  const [selectedRole, selectedUser] = watch(["role", "user"]);

  /* ------------------ APIs ------------------ */

  const { data: rolesData } = useRolesQuery();
  const { data: resourcesData } = useRouteResourcesQuery();
  const { data: departmentsData, isLoading: isLoadingDepartments } = useDepartmentsQuery();
  const [allowedDepartments, { isLoading: isLoadingAllowedDepartments }] = useAllowedDepartmentsMutation();
  const [updateUser, { isLoading: isUpdateUserLoading, error: updateUserError, isSuccess: isSuccessUpdateUser }] =
    useUpdateUserMutation();
  const [
    updateAllowedDepartments,
    {
      isLoading: isUpdateAllowedDepartmentsLoading,
      error: updateAllowedDepartmentsError,
      isSuccess: isSuccessUpdateAllowedDepartments
    }
  ] = useUpdateAllowedDepartmentsMutation();

  /* ------------------ HANDLERs ------------------ */

  const allowedDepartmentsHandler = async (id) => {
    try {
      const data = await allowedDepartments({ id }).unwrap();
      const departmentValues = data?.length ? data.map(({ id }) => id) : [];

      setValue("departments", departmentValues);
    } catch (error) {
      console.error("ERROR: allowedDepartmentsHandler :", { error });
    }
  };

  /* ------------------ MEMOs ------------------ */

  const usersOptions = useMemo(
    () =>
      users?.length
        ? users
            .map((item) => ({ id: item.id, value: getFullName(item.firstName, item.lastName), role: item.role }))
            .sort((a, b) => a.value.localeCompare(b.value))
        : [],
    [users]
  );

  const departmentsOptions = useMemo(
    () => (departmentsData?.length ? departmentsData.map(({ name, id }) => ({ value: id, option: name })) : []),
    [departmentsData]
  );

  const rolesOptions = useMemo(
    () => (rolesData?.length ? rolesData.map((role) => ({ value: role.name, option: getRoleName(role?.name) })) : []),
    [rolesData]
  );

  const resourcesOptions = useMemo(
    () =>
      resourcesData?.length
        ? resourcesData.map((resource) => ({
            checkboxValue: resource.name,
            checkboxLabel: camelCaseToTitleCase(resource.name)
          }))
        : [],
    [resourcesData]
  );

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!userId && !selectedUser?.id) return;

		allowedDepartmentsHandler(userId || selectedUser.id);
  }, [selectedUser]);

  useEffect(() => {
    if (!userId) return;

    const user = users.find((item) => item.id === userId);
    const userOption = user
      ? {
          id: user.id,
          value: `${user.firstName} ${user.lastName}`,
          role: user.role
        }
      : {};

    setValue("user", userOption);
  }, [userId, users, setValue]);

  useEffect(() => {
    if (!selectedUser?.role?.length) return;

    setValue("role", selectedUser.role);
  }, [selectedUser, setValue]);

  useEffect(() => {
    if (!resourcesData?.length || !rolesData?.length) return [];
    const role = rolesData.find((item) => item.name === selectedRole);

    const res = resourcesData
      ?.filter((resource) => role?.resources?.includes(resource.name))
      ?.map((resource) => resource.name);

    setValue("resources", res);
  }, [resourcesData, rolesData, selectedRole]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onSubmit = useCallback(
    handleSubmit(async (values) => {
      const id = userId || values?.user?.id;

      if (!id) return;

      const departmentsIds = values?.departments?.length ? values.departments : [];

      updateAllowedDepartments({ id, departmentsIds });
      updateUser({
        id,
        params: {
          role: values?.role
        }
      });
    }),
    [handleSubmit, users, userId]
  );

  return {
    onSubmit,
    reset,
    control,
    usersOptions,
    isDirty,
    resourcesOptions,
    rolesOptions,
    isSuccess: isSuccessUpdateAllowedDepartments || isSuccessUpdateUser,
    isLoading: isUpdateAllowedDepartmentsLoading || isUpdateUserLoading,
    error: updateUserError || updateAllowedDepartmentsError,
    isDisableDepartments: isLoadingDepartments || isLoadingAllowedDepartments || selectedRole === ROLE_USER,
    departmentsOptions,
    isEdit: userId || (selectedUser?.role && selectedUser?.role !== ROLE_USER)
  };
}
