import React from "react";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const RemoveButton = ({ children, onClick }) => {
  return <CustomizedButton onClick={onClick}>{children}</CustomizedButton>;
};

const CustomizedButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  minWidth: "200px",
  justifyContent: "flex-start",
  padding: theme.spacing(1, 2, 1, 5),
  margin: "0 auto",
  "&:hover": {
    background: "rgba(255,3,3,0.04)"
  }
}));

RemoveButton.propTypes = {
  children: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default RemoveButton;
