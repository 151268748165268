import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AvatarInitials from "shared/components/AvatarInitials/AvatarInitials";
import { getFullName, getScoreColor } from "shared/lib/getters";
import styled from "@mui/material/styles/styled";

const TeamMemberOverlap = ({ items, onClick }) => {
  return (
    <>
      {items.map(({ id, firstName, lastName, teamMemberOverlap = null, department = null }, index) => (
        <Box key={`${firstName}-${index}`} display="flex" alignItems="center" py={1}>
          <AvatarInitials
            firstName={firstName}
            lastName={lastName}
            variant="h6"
            height="35px"
            width="35px"
            backgroundColor="#BDDBDB"
          />
          <UserNameContainer variant="h6" fontWeight="200" pr={2} onClick={() => onClick(id)}>
            {getFullName(firstName, lastName)}
          </UserNameContainer>
          {teamMemberOverlap ? (
            <Typography variant="h5" color={getScoreColor(teamMemberOverlap)}>
              {teamMemberOverlap}
            </Typography>
          ) : (
            <Typography fontWeight="200" variant="h6" marginLeft="auto" paddingRight="16px">
              {department}
            </Typography>
          )}
        </Box>
      ))}
    </>
  );
};

const UserNameContainer = styled(Typography)(() => ({
  cursor: "pointer",
  "&:hover": {
    fontWeight: "500"
  }
}));

TeamMemberOverlap.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func
};

export default TeamMemberOverlap;
