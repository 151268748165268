import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { isValidFunction } from "shared/lib/validation";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";
import RejectButton from "shared/components/Buttons/RejectButton/RejectButton";

const UnsavedChangesDialog = (props) => {
  const { resolve, reject, acceptLabel = "No", rejectLabel = "Discard", showDialog = false, setShowDialog } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(showDialog);
  }, [showDialog]);

  const onResolve = () => {
    if (isValidFunction(resolve)) {
      resolve();
    }
    setOpen(false);
    setShowDialog(false);
  };

  const onReject = () => {
    if (isValidFunction(reject)) {
      reject();
    }
    setOpen(false);
    setShowDialog(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "16px" }}
        PaperProps={{
          style: { borderRadius: "12px" }
        }}
      >
        <DialogTitle id="alert-dialog-title">Do you really want to discard unsaved changed?</DialogTitle>
        <DialogActions sx={{ justifyContent: "center" }}>
          <RejectButton onClick={onResolve} text={rejectLabel} />
          <ResolveButton text={acceptLabel} onClick={onReject} autoFocus />
        </DialogActions>
      </Dialog>
    </div>
  );
};

UnsavedChangesDialog.propTypes = {
  resolve: PropTypes.func.isRequired,
  reject: PropTypes.func,
  acceptLabel: PropTypes.string,
  rejectLabel: PropTypes.string,
  showDialog: PropTypes.bool,
  setShowDialog: PropTypes.func
};

export default UnsavedChangesDialog;
