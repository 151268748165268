import { apiSlice } from "core/api/apiSlice";

export const workstationApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    workstationsByBuildings: build.query({
      query: ({ site, building, date }) => ({
        url: "/seats/dailySeat/mapByBuilding",
        method: "GET",
        params: { site, building, date }
      }),
      providesTags: ["Workstation"]
    }),
    getMapViewWorkstations: build.query({
      query: (args) => ({
        url: "/seats/dailySeat/map-view",
        method: "GET",
        params: args,
        keepUnusedDataFor: 10
      }),
      providesTags: ["MapView"]
    }),
    addWorkstation: build.mutation({
      query: (args) => ({
        url: "/seats/workstations/create",
        method: "POST",
        body: args
      }),
      invalidatesTags: ["Workstation", "MapView"]
    }),
    updateWorkstation: build.mutation({
      query: (args) => ({
        url: "/seats/workstations/update",
        method: "PUT",
        body: args
      }),
      invalidatesTags: ["Workstation", "MapView"]
    }),
    deleteWorkstation: build.mutation({
      query: (args) => ({
        url: "/seats/workstations/delete",
        method: "DELETE",
        body: args
      }),
      invalidatesTags: ["Workstation", "MapView"]
    }),
    filterWorkstations: build.mutation({
      query: (args) => ({
        url: "/seats/dailySeat/filter",
        method: "POST",
        body: args
      })
    }),
    weeklyPlan: build.mutation({
      query: ({ employeeId, from, to }) => ({
        url: `/scheduler/plan/employees/${employeeId}`,
        method: "GET",
        params: { from, to },
        keepUnusedDataFor: 10
      }),
      providesTags: ["WeeklyPlan"]
    }),
    assignDailySeat: build.mutation({
      query: ({ date, employeeId, dayType, seatId = null }) => ({
        url: `/scheduler/plan/${date}/employees/${employeeId}`,
        method: "PUT",
        body: {
          dayType,
          seatId
        }
      }),
      invalidatesTags: ["Workstation", "WeeklyPlan"]
    }),

    removeDailyAssignment: build.mutation({
      query: ({ date, workstationId }) => ({
        url: `/scheduler/plan/${date}/workstations/${workstationId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["WeeklyPlan"]
    }),
    removeDailySeat: build.mutation({
      query: ({ date, employeeId }) => ({
        url: `/scheduler/plan/${date}/employees/${employeeId}/workstations`,
        method: "DELETE"
      }),
      invalidatesTags: ["WeeklyPlan"]
    }),
    getWorkstationDailySeatPlan: build.query({
      query: (args) => ({
        url: "/scheduler/plan/weeklyWorkstationPlan",
        method: "GET",
        params: args
      })
    }),
    unassignedEmployees: build.query({
      query: (args) => ({
        url: "/scheduler/unassigned-employees",
        method: "GET",
        params: args
      })
    }),
    workstationByIds: build.mutation({
      query: ({ ids }) => ({
        url: "seats/workstations/search",
        method: "GET",
        params: { ids }
      })
    })
  })
});

export const {
  useWorkstationsByBuildingsQuery,
  useAddWorkstationMutation,
  useUpdateWorkstationMutation,
  useDeleteWorkstationMutation,
  useAssignDailySeatMutation,
  useRemoveDailyAssignmentMutation,
  useRemoveDailySeatMutation,
  useWeeklyPlanMutation,
  useFilterWorkstationsMutation,
  useGetMapViewWorkstationsQuery,
  useGetWorkstationDailySeatPlanQuery,
  useUnassignedEmployeesQuery,
  useWorkstationByIdsMutation
} = workstationApiSlice;
