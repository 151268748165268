import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getFullName } from "shared/lib/getters";
import { Row } from "../style/previousWeeksTabStyle";
import AttendanceWeeksTitle from "../components/AttendanceWeeksTitle";
import AttendanceWeeks from "../components/AttendanceWeeks";
import NotInBWith from "../../NotInBWith";
import { DAY_TYPES } from "shared/constants";

export default (headItem, numberOfWeeks) => [
  {
    field: "name",
    headerName: "Name",
    width: 140,
    valueGetter: (params) => getFullName(params.row.firstName, params.row.lastName),
    renderCell: (params) => (
      <Row>
        <Typography>{getFullName(params.row.firstName, params.row.lastName)}</Typography>

        {params.row.status !== "ACTIVE" ? <NotInBWith /> : null}
      </Row>
    )
  },
  {
    field: "department",
    headerName: "Department",
    width: 140
  },
  {
    field: "title",
    headerName: "Title",
    width: 140
  },
  {
    field: "officeDays",
    width: 80,
    renderHeader: () => {
      return (
        <Box>
          <Typography>Days</Typography>
          <Typography>/week</Typography>
        </Box>
      );
    }
  },
  {
    field: "avgOfficeDaysPerWeek",
    headerName: "Avg.days",
    width: 80,
    renderHeader: () => {
      return (
        <Box>
          <Typography>Avg.</Typography>
          <Typography>days</Typography>
        </Box>
      );
    },
    valueGetter: (params) => {
      if (!params?.value) return null;

      return parseFloat(params.value).toFixed(1);
    }
  },
  {
    field: "total",
    headerName: "Actual arrivals",
    width: 80,
    renderHeader: () => {
      return (
        <Box>
          <Typography>Actual</Typography>
          <Typography>arrivals</Typography>
        </Box>
      );
    },
    valueGetter: (params) => {
      if (!params?.row?.schedule) return null;

      let count = 0;

      for (let i = 0; i < params.row.schedule.length; i++) {
        if (params.row.schedule[i].dayType?.toLowerCase() === DAY_TYPES.office && params.row.schedule[i].hasCheckedIn) {
          count = count + 1;
        }
      }

      return count;
    }
  },
  {
    field: "schedule",
    sortable: false,
    flex: 1,
    headerClassName: "work-days-table-header",
    minWidth: 480,
    renderHeader: (param) => {
      if (!headItem || !headItem[param.field]) return null;

      return <AttendanceWeeksTitle schedule={headItem[param.field]} />;
    },
    renderCell: (params) => {
      if (!params?.value?.length) return null;

      return <AttendanceWeeks schedule={params.value} numberOfWeeks={numberOfWeeks} />;
    }
  }
];
