import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import FormInputDropdown from "shared/components/FormInputs/Dropdown/FormInputDropdownDeprecated";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";
import { selectMappedTeamMembersList } from "core/selectors";
import { STORAGE_SELECTED_TEAM_MEMBER } from "shared/constants";
import { setSelectedUser } from "features/teamUsers/teamUsersSlice";

const TeamMemberDialog = ({ isOpen, setIsOpen }) => {
  const teamMembersList = useSelector(selectMappedTeamMembersList);
  const { handleSubmit, control } = useForm({ defaultValues: { userId: "" } });

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    localStorage.setItem(STORAGE_SELECTED_TEAM_MEMBER, values.userId);
    dispatch(setSelectedUser(values.userId));
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        backdropFilter: "blur(5px)"
      }}
      PaperProps={{
        style: { borderRadius: "12px", maxWidth: "500px" }
      }}
    >
      <Box display="flex" justifyContent="center">
        <DialogTitle variant="h5">Select team member</DialogTitle>
      </Box>
      <DialogContent sx={{ padding: "8px 24px" }}>
        <Box>
          <DialogContentText variant="body1">
            To view individual insights, please select a team member:
          </DialogContentText>
        </Box>
        <Grid container spacing={1} width="70%" flexDirection="column" margin="0 auto">
          <Grid item>
            <FormInputDropdown
              label="Team member*"
              control={control}
              name="userId"
              rules={{ required: "Team member is required" }}
              data={teamMembersList}
              isMultiple={false}
              placeholder="Select"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 8px" }}>
        <ResolveButton text="OK" onClick={handleSubmit(onSubmit)} />
      </DialogActions>
    </Dialog>
  );
};

TeamMemberDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default TeamMemberDialog;
