import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomsByBuilding: {}
};

export const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    setRoomsByBuilding: (state, action) => {
      state.roomsByBuilding = action.payload;
    }
  }
});

export const { setRoomsByBuilding } = roomSlice.actions;

export default roomSlice.reducer;
