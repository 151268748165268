import { apiSlice } from "core/api/apiSlice";

export const relationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    relationsBySite: build.query({
      query: ({ cToken, site }) => ({
        url: "/preferences/getRelationsBySite",
        method: "GET",
        params: { cToken, site }
      })
    }),
    relationsByEmployeeId: build.mutation({
      query: ({ cToken, id }) => ({
        url: "/preferences/getRelations",
        method: "GET",
        params: { cToken, employeeId: id }
      })
    })
  })
});

export const { useRelationsBySiteQuery, useRelationsByEmployeeIdMutation } = relationsApiSlice;
