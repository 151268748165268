import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  typography: {
    fontFamily: ["Helvetica", "sans-serif"].join(","),
    button: {
      textTransform: "none"
    },
    body1: {
      "@media (max-width:1600px)": {
        fontSize: "0.8rem"
      }
    },
    h6: {
      fontSize: "1rem"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1850,
      xxxl: 2000
    }
  },
  units: {
    "global-px-unit": "8px"
  },
  palette: {
    secondary: {
      main: "#000"
    },
    primary: {
      main: "#65D9FF"
    },
    success: {
      main: "#2CA01C"
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#00203E"
        },
        arrow: {
          color: "#00203E"
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
