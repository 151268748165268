import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  width: "100%",
  overflow: "hidden",
  height: "100%",
  paddingBottom: theme.spacing(3)
}));

export const Grid = styled("div")`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
`;

export const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

export const InfoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(-0.25),
  right: theme.spacing(1.5)
}));

export const GridItemTable = styled(Box)(() => ({
  gridColumn: "1 / 9",
  gridRow: 1
}));

export const GridItemChart = styled(Box)(() => ({
  gridColumn: "9 / 13",
  gridRow: 1
}));

export const RelationChartContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "400px"
}));

export const LoaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(255,255,255, 0.6)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  paddingBottom: theme.spacing(3)
}));

export const FullscreenButton = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.2)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(4),
  height: theme.spacing(4),
  padding: "3px",
  borderRadius: "2px",
  transition: "linear 0.2s",

  "&:hover": {
    background: "rgba(0, 0, 0, 0.6)",
    padding: "0px"
  }
}));

export const styles = {
  tableContainerStyle: {
    minHeight: "100%"
  },

  tableStyle: {
    "& .work-days-table-header > div > div > div": {
      width: "100%",
      justifyContent: "space-between"
    }
  },
  fullscreenIconStyle: { color: "#fff", width: "100%", height: "100%" },
  cardContainer: {
    position: "relative"
  }
};
