import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { ListItemText, MenuItem, Typography, Select as MuiSelect } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { Container, OutlineInput, HelperText, CheckIcon, themedStyles, menuStyleProps } from "./style/selectStyle";
import useSelectLogic from "./hooks/useSelectLogic";
import { nonZero } from "shared/lib";

const Select = ({
  isMultiple,
  handleChange,
  placeholder,
  values,
  name,
  selectedValue = [],
  defaultValue = [],
  defaultLabel,
  helperText,
  error,
  disabled,
  readonly,
  selectStyles,
  onClose,
  fullWidth = true,
  displayEmpty = true,
  required = true,
  containerStyles,
  ...props
}) => {
  const theme = useTheme();
  const styles = themedStyles(theme);
  const { data, onClear } = useSelectLogic({ handleChange, selectedValue, defaultValue });

  const renderDefaultItem = useMemo(() => {
    if (!defaultLabel?.length) return null;

    const checked = data?.length === 0;

    return (
      <MenuItem
        onMouseDown={onClear}
        sx={checked ? styles.menuItemDefaultStyle : styles.menuItemCheckedStyle}
        disabled={readonly}
      >
        {checked ? <CheckIcon fontSize="medium" /> : null}
        <ListItemText primary={defaultLabel} />
      </MenuItem>
    );
  }, [defaultLabel, data, readonly, onClear, styles]);

  const renderDisplayValue = useCallback(
    (selected) => {
      if (!selected || selected?.length === 0) {
        return defaultLabel?.length ? (
          <Typography>{defaultLabel}</Typography>
        ) : (
          <Typography color="#BABEC5">{placeholder}</Typography>
        );
      }

      return selected
        .map((value) => {
          const item = values?.find((item) => item.value === value);

          return item?.option;
        })
        ?.join(", ");
    },
    [values, defaultLabel, placeholder]
  );

  const renderHelperText = useMemo(() => {
    if (!helperText?.length) return null;

    return (
      <HelperText>
        <Typography variant="caption">{helperText}</Typography>
      </HelperText>
    );
  }, [helperText]);

  const renderMenuItem = useCallback(
    ({ value, option }) => {
      if (nonZero(value) && nonZero(option)) return null;

      const checked = data?.length && data.findIndex((item) => item === value) !== -1;

      return (
        <MenuItem
          key={option}
          value={value || option}
          sx={checked ? styles.menuItemCheckedStyle : styles.menuItemDefaultStyle}
          disabled={readonly}
        >
          {checked ? <CheckIcon fontSize="medium" /> : null}

          <ListItemText primary={option} />
        </MenuItem>
      );
    },
    [data]
  );

  return (
    <Container
      sx={[helperText?.length ? styles.containerWithHelperStyles : {}, containerStyles]}
      className={[disabled ? "select-disabled" : ""]}
    >
      <CostomSelect
        multiple={isMultiple}
        displayEmpty={displayEmpty}
        required={required}
        value={data || []}
        defaultValue={values || []}
        onChange={handleChange}
        fullWidth={fullWidth}
        disabled={disabled}
        onClose={onClose}
        name={name}
        sx={{ ...styles.selectStyles, ...selectStyles }}
        renderValue={renderDisplayValue}
        input={<OutlineInput label="" size="small" error={error} />}
        MenuProps={menuStyleProps}
        {...props}
      >
        {renderDefaultItem}

        {values?.map((item) => renderMenuItem(item))}
      </CostomSelect>

      {renderHelperText}
    </Container>
  );
};

const CostomSelect = styled(MuiSelect)(() => ({
  border: "1px solid rgba(0, 0, 0, 0.26)",

  "& fieldset": {
    border: "none"
  }
}));

Select.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isMultiple: PropTypes.bool,
  selectedValue: PropTypes.array,
  defaultValue: PropTypes.array,
  defaultLabel: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  selectStyles: PropTypes.object,
  containerStyles: PropTypes.object,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  onClose: PropTypes.func
};

export default Select;
