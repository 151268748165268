import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workstationsByBuilding: [],
  dailyWorkstationsList: [],
  workstationWeeklyPlan: [],
  showNoAssignmentSidebar: false
};

export const workstationsSlice = createSlice({
  name: "workstations",
  initialState,
  reducers: {
    setWorkstationsByBuildings: (state, action) => {
      state.workstationsByBuilding = action.payload;
    },
    setWorkstationWeeklyPlanList: (state, action) => {
      state.workstationWeeklyPlan = action.payload;
    },
    setShowNoAssignmentSidebar: (state, action) => {
      state.showNoAssignmentSidebar = action.payload;
    }
  }
});

export const { setWorkstationsByBuildings, setWorkstationWeeklyPlanList, setShowNoAssignmentSidebar } =
  workstationsSlice.actions;

export default workstationsSlice.reducer;
