import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import WorkstationSidebar from "shared/components/WorkstationSidebar/WorkstationSidebar";
import { setWorkstationFilter } from "features/filters/filtersSlice";
import { getMappedResponse } from "shared/lib/getters";
import { setNeighborhoodsList } from "features/neighborhoods/neighborhoodsSlice";
import { useNeighborhoodsQuery } from "features/neighborhoods/neighborhoodApiSlice";
import { selectAccessToken, selectSiteFilter } from "core/selectors";
import { useUsersQuery } from "features/userManagement/userManagementApiSlice";
import { setUserManagementList } from "features/userManagement/userManagementSlice";

const FilterWorkstationSidebar = ({ history, isDrawer, isOpen, setIsOpen }) => {
  const site = useSelector(selectSiteFilter);
  const token = useSelector(selectAccessToken);

  const dispatch = useDispatch();

  const { data: neighborhoodData, isLoading: isNeighborhoodLoading } = useNeighborhoodsQuery({ site }, { skip: !site });
  const { data: usersData, isLoading: isUsersLoading } = useUsersQuery(null, { skip: !token });

  useEffect(() => {
    if (neighborhoodData && !isNeighborhoodLoading) {
      const mappedData = getMappedResponse(neighborhoodData.neighborhoods);
      dispatch(setNeighborhoodsList(mappedData));
    }
  }, [neighborhoodData, isNeighborhoodLoading]);

  useEffect(() => {
    if (usersData && !isUsersLoading) {
      const mapped = usersData.employees.map((item) => ({ ...item, department: item.department.split(", ") }));

      dispatch(setUserManagementList(mapped));
    }
  }, [usersData, isUsersLoading]);

  const handleFilterSubmit = (values) => {
    dispatch(setWorkstationFilter(values));
  };

  return (
    <WorkstationSidebar
      history={history}
      isDrawer={isDrawer}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      headerText="Filter Workstations"
      contentText="Search for workstations"
      onSubmit={handleFilterSubmit}
      resolveLabel="Apply Filter"
      isFilter
    />
  );
};

FilterWorkstationSidebar.propTypes = {
  history: PropTypes.object,
  isDrawer: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default withRouter(FilterWorkstationSidebar);
