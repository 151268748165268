import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "@mui/material/styles/styled";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { isValidArray, isValidFunction } from "shared/lib/validation";
import SiteAndBuildingPage from "./components/SiteAndBuildingSidebar/SiteAndBuildingSidebar";
import {
  NEIGHBORHOODS_PAGE_NAME,
  ROUTE_WORKSTATIONS,
  USER_MANAGEMENT_TABLE_NAME,
  WORKSTATION_TABLE_NAME
} from "shared/constants";
import FilterWorkstationSidebar from "./components/FilterWorkstationSidebar/FilterWorkstationSidebar";
import SiteAndBuilding from "../Filters/SiteAndBulding/SiteAndBulding";
import FloorSelector from "../Filters/Floor/Floor";
import DepartmentSelector from "../Filters/Department";
import Filter from "../Filters/Filter/Filter";
import { selectTableSort } from "core/selectors";
import { convertStringToKebabCase } from "shared/lib";
import { getPluralizedString } from "shared/lib/getters";
import { setTableSort } from "features/tableSort/tableSortSlice";
import EditIcon from "shared/assets/icons/edit.svg";
import DeleteIcon from "shared/assets/icons/delete.svg";
import InfoIcon from "shared/assets/icons/info.svg";
import NoAssignment from "shared/components/NoAssignment/NoAssignment";
import MonthPicker from "shared/components/MonthPicker";
import DateTableFooter from "./components/DateTableFooter";
import PropTypes from "prop-types";
import PageTitle from "../PageTitle";
import { ReactComponent as ExportSvg } from "shared/assets/icons/export.svg";

const FilterSidebar = React.lazy(() => import("./components/FilterSidebar/FilterSidebar"));

const LIST_VIEW = "list";

const mapStateToProps = (state) => {
  const tableSort = selectTableSort(state);

  return {
    tableSort
  };
};

const DataTable = ({
  rows,
  columns,
  rowIdFieldName,
  tableName,
  pageName,
  addNewText,
  onAdd,
  onDelete,
  onEdit,
  onInfo,
  deleteTitle,
  deleteContentText,
  showSiteAndBuilding = false,
  showFilterButton = false,
  showEditColumn = true,
  showDeleteColumn = true,
  showInfoColumn = true,
  showFloorSelector = false,
  showDepartmentSelector = false,
  defaultSort = { field: "name", sort: "asc" },
  showSearch = false,
  showMapViewSwitcher = false,
  isMapEnabled = false,
  showNoAssignment = false,
  showMonthPicker,
  monthPickerProps,
  autoHeight = true,
  sx = {},
  containerStyle = {},
  isLoading,
  renderFooterComponent,
  renderToolbarDetailsComponent,
  onTableStateChange,
  deleteWarningText,
  onExportFile
}) => {
  const [showDeleteDialog, setShowDialog] = useState(false);
  const [rowName, setRowName] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [view, setView] = useState(LIST_VIEW);
  const [sortModel, setSortModel] = useState([defaultSort]);
  const { tableSort } = useSelector(mapStateToProps);

  const dispatch = useDispatch();
  const history = useHistory();

  const showRowsCount = pageName === USER_MANAGEMENT_TABLE_NAME || pageName === WORKSTATION_TABLE_NAME;

  const getNounName = () => {
    let noun = "";

    if (pageName === USER_MANAGEMENT_TABLE_NAME) {
      noun = "user";
    }

    if (pageName === WORKSTATION_TABLE_NAME) {
      noun = "workstation";
    }

    return noun;
  };

  useEffect(() => {
    const currentSort = tableSort.find((item) => item.tableName === pageName);
    if (currentSort) {
      setSortModel(currentSort.sort);
    }
  }, []);

  const handleChangeView = (event) => {
    setView(event.target.value);
    history.replace(`/${ROUTE_WORKSTATIONS}/map-view`);
  };

  const handleSortChange = (newSortModel) => {
    setSortModel(newSortModel);
    dispatch(setTableSort({ tableName: pageName, sort: newSortModel }));
  };

  const onAddRow = () => {
    if (onAdd && isValidFunction(onAdd)) {
      onAdd();
    }
  };

  const getRowIdHandler = (row) => {
    if (row && rowIdFieldName) return row[rowIdFieldName];

    return row.id;
  };

  useEffect(() => {
    setRowsCount(rows?.length);
  }, [rows]);

  const onFilterChange = () => {
    setTimeout(() => {
      const count = document.querySelectorAll('[role="row"]')?.length;
      if (count) {
        setRowsCount(count - 1);
      }
    }, 250);
  };

  const onSiteAndBuildingFilterClick = () => {
    setShowSidebar(true);
  };

  const onFilterClick = () => {
    setShowFilterSidebar(true);
  };

  const onTableStateEvent = (params, event, details) => {
    onTableStateChange && onTableStateChange({ params, event, details });
  };

  const renderEditButton = (params) => {
    const { id, row } = params;
    const onEditClick = () => {
      if (onEdit && isValidFunction(onEdit)) {
        if (pageName === NEIGHBORHOODS_PAGE_NAME) {
          onEdit(convertStringToKebabCase(row.name));
          return;
        }
        onEdit(id);
      }
    };

    const isDisabled = pageName === NEIGHBORHOODS_PAGE_NAME && row.name === "General";

    return (
      <ActionButton onClick={onEditClick} disabled={isDisabled}>
        <span>
          <img src={EditIcon} />
        </span>
      </ActionButton>
    );
  };

  const renderDeleteButton = (params) => {
    const onDeleteClick = () => {
      setRowName(params?.row?.name);
      if (pageName === NEIGHBORHOODS_PAGE_NAME) {
        setRowId(params?.row?.name);
      } else {
        setRowId(params.id);
      }
      setShowDialog(true);
    };
    const isDisabled = pageName === NEIGHBORHOODS_PAGE_NAME && params.row.name === "General";

    return (
      <ActionButton onClick={onDeleteClick} disabled={isDisabled}>
        <span>
          <img src={DeleteIcon} />
        </span>
      </ActionButton>
    );
  };

  const renderInfoButton = (params) => {
    const { id, row } = params;
    const onInfoClick = () => {
      if (onInfo && isValidFunction(onInfo)) {
        onInfo(id, row);
      }
    };

    return (
      <ActionButton onClick={onInfoClick}>
        <span>
          <img src={InfoIcon} />
        </span>
      </ActionButton>
    );
  };

  const getUpdatedColumns = ({ columns, showDeleteColumn, showEditColumn, showInfoColumn }) => {
    let updatedColumns = [...columns];
    if (isValidArray(columns) && columns.length) {
      if (showInfoColumn) {
        updatedColumns = [
          ...updatedColumns,
          {
            field: "infoAction",
            headerName: "",
            width: 50,
            editable: false,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: renderInfoButton
          }
        ];
      }

      if (showEditColumn) {
        updatedColumns = [
          ...updatedColumns,
          {
            field: "editAction",
            headerName: "",
            width: 50,
            editable: false,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: renderEditButton
          }
        ];
      }

      if (showDeleteColumn) {
        updatedColumns = [
          ...updatedColumns,
          {
            field: "deleteAction",
            headerName: "",
            width: 50,
            editable: false,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: renderDeleteButton
          }
        ];
      }
    }

    return updatedColumns;
  };

  const QuickSearchToolbar = () => {
    return (
      <Box>
        <Search placeholder="Enter name to search" variant="outlined" />
      </Box>
    );
  };

  const CustomToolbar = () => {
    return (
      <Box py={2}>
        <Grid container display="flex" alignItems="flex-start" spacing={2}>
          {tableName && (
            <Grid item>
              <Typography sx={{ flex: "1 1 100%" }} variant="h5" id="tableTitle" component="div" fontWeight="700">
                {tableName}
              </Typography>
            </Grid>
          )}
          {showSiteAndBuilding && (
            <Grid item>
              <SiteAndBuilding onClick={onSiteAndBuildingFilterClick} />
            </Grid>
          )}
          {showFloorSelector && (
            <Grid item>
              <FloorSelector />
            </Grid>
          )}
          {showDepartmentSelector && (
            <Grid item>
              <DepartmentSelector />
            </Grid>
          )}
          {showFilterButton && (
            <Grid item>
              <Filter onClick={onFilterClick} pageName={pageName} />
            </Grid>
          )}

          {showNoAssignment && (
            <Grid item>
              <NoAssignment />
            </Grid>
          )}

          {showSearch && (
            <Grid item>
              <QuickSearchToolbar />
            </Grid>
          )}
          {showMonthPicker && monthPickerProps ? (
            <Grid item sx={{ marginLeft: "auto" }}>
              <MonthPicker {...monthPickerProps} />
            </Grid>
          ) : null}
          {onExportFile ? (
            <Grid item sx={{ alignSelf: "center", paddingLeft: "8px !important" }}>
              <ExportButton onClick={onExportFile}>
                <ExportIcon />
              </ExportButton>
            </Grid>
          ) : null}
          {showMapViewSwitcher && (
            <Grid item sx={{ marginLeft: "auto" }}>
              <FormControl>
                <RadioGroup row value={view} onChange={handleChangeView}>
                  <FormControlLabel control={<Radio color="success" />} label="List" value="list" />

                  {isMapEnabled && <FormControlLabel control={<Radio color="success" />} label="Map" value="map" />}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {addNewText && (
            <Grid item sx={{ marginLeft: showMapViewSwitcher ? "none" : "auto" }}>
              <AddNewButton onClick={onAddRow}>{addNewText}</AddNewButton>
            </Grid>
          )}
        </Grid>

        {renderToolbarDetailsComponent && renderToolbarDetailsComponent()}
      </Box>
    );
  };

  const renderCustomizedFooter = () => (
    <DateTableFooter hideFooterPagination={rows.length <= 100} renderFooterComponent={renderFooterComponent} />
  );

  return (
    <Container sx={containerStyle}>
      {pageName && <PageTitle title={pageName} />}
      {showRowsCount && rowsCount ? (
        <Box position="absolute" right="0" top="32px">
          <Typography color="#6B6C72">Showing {getPluralizedString(rowsCount, getNounName())}</Typography>
        </Box>
      ) : null}
      <PaperContainer>
        <TableContainer>
          <DataGrid
            rows={rows}
            getRowId={getRowIdHandler}
            columns={getUpdatedColumns({ columns, showDeleteColumn, showEditColumn, showInfoColumn })}
            autoHeight={autoHeight}
            disableColumnMenu
            loading={isLoading}
            onFilterModelChange={onFilterChange}
            sortModel={sortModel}
            onSortModelChange={handleSortChange}
            onStateChange={onTableStateEvent}
            slots={{
              toolbar: CustomToolbar,
              footer: renderCustomizedFooter
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } }
            }}
            sx={{
              border: "none",
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none"
              },
              "& .MuiDataGrid-columnSeparator--sideRight": {
                display: "none"
              },
              "& .MuiIconButton-root": {
                color: "#00b4f0"
              },
              "& .MuiDataGrid-columnHeaders": {
                background: "#ECEEF1"
              },
              "& .MuiDataGrid-columnHeader--sorted": {
                fontWeight: "700"
              },
              ...sx
            }}
          />
        </TableContainer>
      </PaperContainer>
      <ConfirmDialog
        showDialog={showDeleteDialog}
        resolve={onDelete}
        reject={() => setShowDialog(false)}
        title={deleteTitle}
        text={deleteContentText}
        rowName={rowName}
        rowId={rowId}
        setShowDialog={setShowDialog}
        warningText={deleteWarningText}
      />
      <SiteAndBuildingPage isDrawer isOpen={showSidebar} setIsOpen={setShowSidebar} />
      {pageName === USER_MANAGEMENT_TABLE_NAME ? (
        <Suspense fallback={<div>Loading...</div>}>
          <FilterSidebar isDrawer isOpen={showFilterSidebar} setIsOpen={setShowFilterSidebar} />
        </Suspense>
      ) : (
        <FilterWorkstationSidebar isDrawer isOpen={showFilterSidebar} setIsOpen={setShowFilterSidebar} />
      )}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  position: "relative",

  "& + &": {
    marginLeft: theme.spacing(2.5)
  }
}));

const PaperContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  padding: theme.spacing(3, 0)
}));

const TableContainer = styled(Container)(({ theme }) => ({
  margin: theme.spacing(0, 4)
}));

const ExportButton = styled(Button)(({ theme }) => ({
  width: "42px",
  height: "42px",
  padding: theme.spacing(1),
  border: "1px solid #000",
  borderRadius: "50%",
  overflow: "hidden",
  minWidth: "unset",
  boxSizing: "border-box",

  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  }
}));

const ExportIcon = styled(ExportSvg)`
  color: #00203e;
`;

const Search = styled(GridToolbarQuickFilter)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
    "&.Mui-focused fieldset": {
      borderColor: "#6B6C72"
    }
  },
  "& .MuiSvgIcon-root": {
    display: "none"
  },
  "& input": {
    padding: theme.spacing(1)
  },
  "& .MuiButtonBase-root": {
    "& .MuiSvgIcon-root": {
      display: "block",
      color: "#6B6C72"
    }
  }
}));

const AddNewButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#00203E",
  padding: theme.spacing(1, 3),
  borderRadius: theme.spacing(2),
  fontWeight: "700",
  color: "#fff",
  "&.Mui-disabled": {
    background: "#cccccc"
  },
  "&:hover": {
    backgroundColor: "#003f77",
    color: "#fff"
  }
}));

const ActionButton = styled(Button)({
  minWidth: 40,
  "&:focus": {
    outline: "none"
  }
});

DataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowIdFieldName: PropTypes.string,
  tableName: PropTypes.string,
  pageName: PropTypes.string,
  addNewText: PropTypes.string,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onInfo: PropTypes.func,
  onDelete: PropTypes.func,
  deleteTitle: PropTypes.string,
  deleteWarningText: PropTypes.string,
  view: PropTypes.string,
  deleteContentText: PropTypes.object,
  showSiteAndBuilding: PropTypes.bool,
  showFilterButton: PropTypes.bool,
  showDeleteColumn: PropTypes.bool,
  showEditColumn: PropTypes.bool,
  showInfoColumn: PropTypes.bool,
  showFloorSelector: PropTypes.bool,
  showDepartmentSelector: PropTypes.bool,
  defaultSort: PropTypes.object,
  showSearch: PropTypes.bool,
  showMapViewSwitcher: PropTypes.bool,
  isMapEnabled: PropTypes.bool,
  showMonthPicker: PropTypes.bool,
  monthPickerProps: PropTypes.object,
  showNoAssignment: PropTypes.bool,
  autoHeight: PropTypes.bool,
  isLoading: PropTypes.bool,
  sx: PropTypes.object,
  containerStyle: PropTypes.object,
  renderToolbarDetailsComponent: PropTypes.func,
  renderFooterComponent: PropTypes.func,
  onTableStateChange: PropTypes.func,
  onExportFile: PropTypes.func
};

export default DataTable;
