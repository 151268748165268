import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import styled from "@mui/material/styles/styled";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";

const FormInputIconCheckbox = ({ name, control, label, rules, data, styles, checkedStyles }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormGroup row>
          {label && (
            <Box width="100%">
              <CustomizedLabel text={label} error={!!error} />
            </Box>
          )}
          {data.map(({ checkboxValue, icon }) => (
            <FormControlLabel
              key={checkboxValue}
              sx={{ marginRight: 0 }}
              control={
                <IconCheckbox
                  value={checkboxValue}
                  checked={field.value === checkboxValue}
                  onChange={(event) => field.onChange(event.target.value)}
                  icon={<img src={icon} style={styles} />}
                  checkedIcon={
                    <Box sx={{ backgroundColor: "#00203E", display: "inline-flex" }}>
                      <img src={icon} style={checkedStyles} />
                    </Box>
                  }
                />
              }
            />
          ))}
        </FormGroup>
      )}
    />
  );
};

const IconCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .Mui-checked": {
    background: "orange"
  }
}));

FormInputIconCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  data: PropTypes.array,
  styles: PropTypes.object,
  checkedStyles: PropTypes.object
};

export default FormInputIconCheckbox;
