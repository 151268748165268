import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

export const TooltipContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.2),
  maxWidth: "700px"
}));

export const TooltipTitleText = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2.5)
}));

export const TooltipList = styled("ul")(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingLeft: theme.spacing(3)
}));

export const TooltipListItem = styled("li")(({ theme }) => ({
  textIndent: 0
}));

export const TooltipItemText = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.25)
}));
