import { apiSlice } from "core/api/apiSlice";

export const preferencesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    confirmUserPlan: build.mutation({
      query: ({ id }) => ({
        url: `/wizard/planSolution/${id}/confirm`,
        method: "POST"
      })
    })
  })
});

export const { useConfirmUserPlanMutation } = preferencesApiSlice;
