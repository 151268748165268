import styled from "@mui/material/styles/styled";
import { Drawer, ListItemButton, ListItemText } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getNavigationDrawlerBackground } from "../utils/gettersDrawler";

const DRAWER_WIDTH = 240;

export const CustomizedDrawer = styled(Drawer)(({ role }) => ({
  display: "flex",
  height: "100vh",
  width: DRAWER_WIDTH,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: DRAWER_WIDTH,
    boxSizing: "border-box",
    background: getNavigationDrawlerBackground(role)
  }
}));

export const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  width: "auto",
  padding: theme.spacing(1, 0),
  marginTop: theme.spacing(1),
  backgroundColor: "#F4F5F8",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  backgroundColor: "#ECEEF1",
  padding: theme.spacing(0, 1),
  boxShadow: "none",
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

export const CompanyLogo = styled("img")(({ theme }) => ({
  width: "160px",
  height: "auto",
  padding: theme.spacing(2, 0, 3, 1)
}));

export const CustomizedListItem = styled(ListItemButton)`
  padding-top: 0;
  padding-bottom: 0;
  color: #d4d7dc;
  &:hover {
    background: #000;
    color: #fff;
    & > svg {
      color: #fff;
    }
  }
`;

export const CustomizedListItemText = styled(ListItemText)(() => ({
  color: "#d4d7dc",
  "& > span": {
    fontSize: "1.1rem"
  }
}));

export const RightArrowIcon = styled(ChevronRightIcon)`
  color: #6b6c72;
`;
