import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { URL_CONGRATULATION_CONFIRM_PLAN } from "shared/constants";
import { useConfirmUserPlanMutation } from "features/preferences/preferencesApiSlice";
import { getJsonFromUrl } from "shared/lib";

const ConfirmPlan = (props) => {
  const [confirmUserPlan] = useConfirmUserPlanMutation();

  const confirmPlanHandler = async () => {
    if (props?.location) {
      const parsedUrl = getJsonFromUrl(props.location);

      try {
        if (parsedUrl?.id) {
          await confirmUserPlan({ id: parsedUrl.id }).unwrap();
        }
      } catch (error) {
        console.error("ERROR:: confirmPlanHandler :: ", { error });
      } finally {
        window.location.href = URL_CONGRATULATION_CONFIRM_PLAN;
      }
    }
  };

  useEffect(() => {
    confirmPlanHandler();
  }, []);

  return <div>Loading...</div>;
};

ConfirmPlan.propTypes = {
  location: PropTypes.object
};

export default withRouter(ConfirmPlan);
