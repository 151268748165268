import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import styled from "@mui/material/styles/styled";
import { makeStyles } from "@mui/styles";
import Sidebar from "../Sidebar/Sidebar";
import WorkstationEditInputCell from "../WorkstationEditInputCell/WorkstationEditInputCell";
import { getFullName } from "shared/lib/getters";
import UserStatus from "../UserStatus/UserStatus";
import { useDispatch, useSelector } from "react-redux";
import { selectSiteFilter, selectBuildingFilter, selectAccessToken } from "core/selectors";
import { useAssignDailySeatMutation, useUnassignedEmployeesQuery } from "features/workstations/workstationApiSlice";
import { format } from "date-fns";
import { setUnassignedUsers } from "features/userManagement/userManagementSlice";
import { DAY_TYPES } from "shared/constants";

const useStyles = makeStyles({
  dataGridRoot: {
    "& .MuiDataGrid-row .MuiDataGrid-cell": {
      outline: "none"
    }
  }
});

const renderWorkstationEditInputCell = (params) => {
  return <WorkstationEditInputCell {...params} />;
};

const columns = [
  {
    field: "name",
    headerName: "Name",
    editable: false,
    flex: 1,
    renderCell: (params) => (
      <Box>
        {getFullName(params.row.firstName, params.row.lastName)}
        {params.row.status && (
          <Box>
            <UserStatus>{params.row.status}</UserStatus>
          </Box>
        )}
      </Box>
    )
  },
  {
    field: "department",
    headerName: "Department",
    editable: false,
    flex: 1
  },
  {
    field: "workstation",
    headerName: "Assign a daily room",
    editable: false,
    renderCell: renderWorkstationEditInputCell,
    flex: 2
  }
];

const dateToday = format(new Date(), "yyyy-MM-dd");

const NotAssignedUsers = ({ history, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [updatedRowData, setUpdatedRowData] = useState([]);
  const site = useSelector(selectSiteFilter);
  const building = useSelector(selectBuildingFilter);
  const token = useSelector(selectAccessToken);

  const {
    data: usersWithoutWorkstation,
    refetch,
    isLoading: isUsersLoading
  } = useUnassignedEmployeesQuery({ site, date: dateToday, building }, { skip: !site || !building || !token });

  const [assignDailySeat, { isLoading: isAssignLoading, error: assignError, isSuccess: isSuccessAssigned }] =
    useAssignDailySeatMutation();

  useEffect(() => {
    if (usersWithoutWorkstation && !isUsersLoading) {
      dispatch(setUnassignedUsers(usersWithoutWorkstation.employees));
    }
  }, [usersWithoutWorkstation, isUsersLoading]);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    setUpdatedRowData(() => {
      const newData = { userId: updatedRow?.id, workstationId: updatedRow?.workstation?.id };

      if (
        updatedRowData?.some((value) => value?.userId === newRow?.id && value?.workstationId !== newRow.workstation?.id)
      ) {
        return updatedRowData.map((value) => {
          if (value?.workstationId !== updatedRow?.workstation?.id) {
            return { ...value, workstationId: updatedRow?.workstation?.id };
          } else {
            return value;
          }
        });
      }

      return [...updatedRowData, newData];
    });

    return updatedRow;
  };

  const fetchData = async (payload) => {
    try {
      await assignDailySeat(payload);
    } catch (e) {
      console.error("ERROR", e);
    }
  };

  function getAllData(URLs) {
    return Promise.allSettled(URLs.map(fetchData)).then(() => refetch());
  }

  const handleSubmit = () => {
    if (updatedRowData.length) {
      const mappedData = updatedRowData.map((room) => {
        return { date: dateToday, seatId: room.workstationId, employeeId: room.userId, dayType: DAY_TYPES.office };
      });
      getAllData(mappedData);
    }
  };

  return (
    <div>
      <Sidebar
        history={history}
        isDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disabled={isAssignLoading}
        error={assignError}
        isSuccess={isSuccessAssigned}
        onResolve={handleSubmit}
      >
        <Box>
          <Typography variant="h4" fontWeight="700">
            Users with no workstations
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1">
            The following users are planned to be in the office today, but currently have no workstation assigned. Click
            each user to assign a workstation.
          </Typography>
        </Box>
        <Box mt={4} width="100%">
          <Paper sx={{ width: "100%", mb: 3, mt: 3 }}>
            <Box pt={1}>
              <Table
                className={classes.dataGridRoot}
                rows={usersWithoutWorkstation?.employees ? usersWithoutWorkstation.employees : []}
                columns={columns}
                autoHeight
                hideFooterSelectedRowCount
                disableSelectionOnClick
                disableColumnMenu
                processRowUpdate={processRowUpdate}
                hideFooterPagination
                experimentalFeatures={{ newEditingApi: true }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "name", sort: "asc" }]
                  }
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Sidebar>
    </div>
  );
};

const Table = styled(DataGrid)(() => ({
  border: "none",
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none"
  },
  "& .MuiDataGrid-columnSeparator--sideRight": {
    display: "none"
  },
  "& .MuiIconButton-root": {
    color: "#00b4f0"
  },
  "& .MuiDataGrid-columnHeaders": {
    background: "#ECEEF1"
  },
  "& .MuiDataGrid-columnHeader--sorted": {
    fontWeight: "700"
  },
  "& .MuiDataGrid-cell": {
    boxShadow: "none !important"
  }
}));

NotAssignedUsers.propTypes = {
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default withRouter(NotAssignedUsers);
