import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const TitleBar = styled(Box)(({ theme }) => ({
  backgroundColor: "#ECEEF1",
  padding: theme.spacing(2, 1)
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase"
}));

export const SummaryContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingTop: theme.spacing(2)
}));

export const SummaryItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minWidth: "180px",
  justifyContent: "center",
  border: "1px solid #6B6C72",
  borderRadius: "18px",
  padding: theme.spacing(1, 3),
  position: "relative"
}));

export const SummaryValue = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.spacing(4.5),
  lineHeight: theme.spacing(5.5),
  color: "#00203E"
}));

export const SummaryLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.5),
  color: "#00203E"
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1.25),
  right: theme.spacing(1.5)
}));
