import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getFullName } from "shared/lib/getters";
import NotInBWith from "../../NotInBWith";
import AttendanceDaysTitle from "../components/AttendanceDaysTitle";
import AttendanceDays from "../components/AttendanceDays";
import { Row } from "../style/currentWeekTabStyle";

export default (firstTableItem) => [
  {
    field: "name",
    headerName: "Name",
    width: 140,
    valueGetter: (params) => getFullName(params.row.firstName, params.row.lastName),
    renderCell: (params) => (
      <Row>
        <Typography>{getFullName(params.row.firstName, params.row.lastName)}</Typography>

        {params.row.status !== "ACTIVE" ? <NotInBWith /> : null}
      </Row>
    )
  },
  {
    field: "department",
    headerName: "Department",
    width: 140
  },
  {
    field: "title",
    headerName: "Title",
    width: 140
  },
  {
    field: "officeDays",
    width: 90,
    renderHeader: () => {
      return (
        <Box>
          <Typography>Days</Typography>
          <Typography>/week</Typography>
        </Box>
      );
    }
  },
  {
    field: "schedule",
    sortable: false,
    flex: 1,
    headerClassName: "work-days-table-header",
    minWidth: 480,
    renderHeader: (param) => {
      if (!firstTableItem) return null;

      return (
        <>
          {firstTableItem[param.field].map((item, index) => (
            <AttendanceDaysTitle item={item} key={index} />
          ))}
        </>
      );
    },
    renderCell: (params) => {
      if (!params?.value?.length) return null;

      return (
        <>
          {params.value.map((item, index) => {
            return <AttendanceDays item={item} key={index} />;
          })}
        </>
      );
    }
  }
];
