import React from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import AvatarInitials from "shared/components/AvatarInitials/AvatarInitials";
import UserStatus from "shared/components/UserStatus/UserStatus";
import { getFullName } from "shared/lib/getters";

const UserInfo = ({ firstName, lastName, floor, phone, email, avatar, status }) => {
  const fullName = getFullName(firstName, lastName);

  return (
    <Grid container spacing={2}>
      <Grid item position="relative" pb={3}>
        {avatar ? (
          <Avatar src={avatar} alt="User Image" sx={{ width: 80, height: 80 }} />
        ) : (
          <AvatarInitials firstName={firstName} lastName={lastName} />
        )}
        {status && <UserStatus isUserInfo>{status}</UserStatus>}
      </Grid>
      <Grid item>
        <InfoBoxContainer>
          <Typography variant="h6" component="p" fontWeight="700">
            {fullName}
          </Typography>
          <Typography variant="body1">{floor}</Typography>
        </InfoBoxContainer>
      </Grid>
      <Grid item>
        <InfoBoxContainer>
          <Typography variant="body1" sx={{ marginTop: "10px !important" }}>
            {phone}
          </Typography>
          <Typography variant="body1">{email}</Typography>
        </InfoBoxContainer>
      </Grid>
    </Grid>
  );
};

const InfoBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "flex-start",
  "& > p": {
    marginTop: theme.spacing(1)
  }
}));

UserInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  floor: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  status: PropTypes.string
};

export default UserInfo;
