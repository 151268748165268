import { v4 } from "uuid";

export async function downloadFile(response, fileName) {
  const $a = document.createElement("a");
  const url = window.URL.createObjectURL(await response.blob());

  $a.href = url;
  $a.download = fileName || v4();
  $a.click();
}
