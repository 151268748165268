import { apiSlice } from "core/api/apiSlice";

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEmployeeData: build.query({
      query: ({ email, cToken }) => ({
        url: "/employees/getEmployeeData",
        method: "GET",
        params: { cToken, email }
      })
    }),
    getCompanyAndSite: build.mutation({
      query: ({ site, cToken }) => ({
        method: "GET",
        url: "/employees/getCompanyAndSite",
        params: { cToken, site }
      })
    }),
    companySiteSettings: build.query({
      query: ({ site, cToken }) => ({
        method: "GET",
        url: "/employees/getCompanySiteSettings",
        params: { cToken, site }
      })
    })
  })
});

export const { useGetEmployeeDataQuery, useGetCompanyAndSiteMutation, useCompanySiteSettingsQuery } = profileApiSlice;
