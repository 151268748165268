import { createSlice } from "@reduxjs/toolkit";
import { ROLE_USER } from "shared/constants";

const initialState = {
  email: null,
  confirmationCode: null,
  refreshToken: null,
  token: null,
  cToken: "SI-sa9Ce4H-r3poGgPiWaFZy75p_ttfSIUsHNYYrYbU", // TODO: remove (Shibolet only)
  role: ROLE_USER,
  navigationResources: []
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setConfirmationCode: (state, action) => {
      const { confirmationCode, email } = action.payload;
      state.email = email;
      state.confirmationCode = confirmationCode;
    },
    setUserInfo: (state, action) => {
      const { accessToken, refreshToken, email, cToken } = action.payload;
      state.token = accessToken;
      state.refreshToken = refreshToken;

      if (email) state.email = email;
      if (cToken) state.cToken = cToken;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setNavigationResources: (state, action) => {
      state.navigationResources = action.payload;
    }
  }
});

export const { setConfirmationCode, setUserInfo, setRole, setNavigationResources } = authSlice.actions;

export default authSlice.reducer;
