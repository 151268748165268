import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { isValidFunction } from "shared/lib/validation";
import UnsavedChangesDialog from "shared/components/Dialogs/UnsavedChangesDialog/UnsavedChangesDialog";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";
import RejectButton from "shared/components/Buttons/RejectButton/RejectButton";
import { capitalizeFirstLetter } from "shared/lib/stringsLib";

const Sidebar = ({
  history,
  children,
  isDrawer = false,
  isOpen = true,
  setIsOpen,
  rejectLabel = "Cancel",
  resolveLabel = "Add",
  showClearButton = false,
  onClearClick,
  onResolve,
  shouldValidate = false,
  isSuccess,
  isLoading,
  error,
  isDirty = false,
  resetForm,
  showCancelButton = true,
  showResolveLabel = true
}) => {
  const [open, setOpen] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [isCloseClicked, setIsCloseClicked] = useState(false);

  const sidebarRef = useRef();

  useEffect(() => {
    if (isCloseClicked) {
      if (isDirty) {
        return setShowAlertDialog(true);
      }
      handleClose();
    }

    return () => {
      setShowAlertDialog(false);
    };
  }, [isDirty, isCloseClicked]);

  useEffect(() => {
    if (isDrawer) {
      setOpen(isOpen);
    }
  }, [isOpen, isDrawer]);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      handleClose();
      toast.success("Done.");
    }
  }, [isSuccess, isLoading]);

  useEffect(() => {
    if (error) {
      const errorMessage = error?.data?.details?.length
        ? capitalizeFirstLetter(`${error?.data?.details[0].field} - ${error?.data?.details[0].errorMessage}`)
        : error?.data?.message
        ? error.data.message
        : error;

      toast.error(errorMessage, { style: { right: sidebarRef?.current?.offsetWidth } });
    }
  }, [error]);

  const handleClose = () => {
    setOpen(false);
    if (!isDrawer) {
      history.goBack();
      return;
    }

    if (isValidFunction(resetForm)) {
      resetForm();
    }

    setIsOpen(false);
    setIsCloseClicked(false);
  };

  const onAdd = () => {
    if (isValidFunction(onResolve)) {
      onResolve();
      if (isDrawer && !shouldValidate) {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const onClear = () => {
    if (isValidFunction(onClearClick)) {
      onClearClick();
      setIsCloseClicked(true);
    }
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setIsCloseClicked(true)}
        PaperProps={{
          ref: sidebarRef,
          style: { width: "37.333%" }
        }}
      >
        <Box role="presentation" sx={{ background: "#f8fafe" }}>
          <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Box display="flex" justifyContent="space-between" py={2} px={2}>
              <BackButton onClick={() => setIsCloseClicked(true)}>
                <ChevronLeftIcon fontSize="medium" color="#6B6C72" />
                <Typography variant="h6">Back</Typography>
              </BackButton>
              <CloseIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => setIsCloseClicked(true)} />
            </Box>
            <Box sx={{ padding: "0 24px 40px 24px", overflowX: "auto" }}>
              <Box>{children}</Box>
            </Box>
            <FooterContainer>
              {showClearButton && (
                <Button sx={{ color: "#0077C5", marginRight: "auto" }} onClick={onClear}>
                  <Typography>Clear Filters</Typography>
                </Button>
              )}
              {showCancelButton && <RejectButton onClick={() => setIsCloseClicked(true)} text={rejectLabel} />}
              {showResolveLabel && <ResolveButton text={resolveLabel} onClick={onAdd} disabled={isLoading} />}
            </FooterContainer>
          </Box>
        </Box>
      </Drawer>
      <UnsavedChangesDialog
        resolve={handleClose}
        reject={() => setIsCloseClicked(false)}
        showDialog={showAlertDialog}
        setShowDialog={setShowAlertDialog}
      />
    </div>
  );
};

const BackButton = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  color: "#393A3D",
  alignItems: "center",
  "&:hover": {
    color: "#000"
  }
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "#E3E5E8",
  marginTop: "auto",
  padding: theme.spacing(2, 1)
}));

Sidebar.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
  isDrawer: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  rejectLabel: PropTypes.string,
  resolveLabel: PropTypes.string,
  showClearButton: PropTypes.bool,
  onClearClick: PropTypes.func,
  onResolve: PropTypes.func,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  isDirty: PropTypes.bool,
  resetForm: PropTypes.func,
  showCancelButton: PropTypes.bool,
  showResolveLabel: PropTypes.bool,
  shouldValidate: PropTypes.bool
};

export default withRouter(Sidebar);
