import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataTable from "shared/components/DataTable/DataTable";
import { getTableStatusColumnColor } from "shared/lib/tableHelpers";
import {
  selectAccessToken,
  selectFilteredUserManagementList,
  selectSiteFilter,
  selectBuildingFilter
} from "core/selectors";
import { USER_MANAGEMENT_TABLE_NAME } from "shared/constants";
import { useGetDepartmentsListQuery, useUsersQuery } from "features/userManagement/userManagementApiSlice";
import { setDepartmentsList, setUserManagementList } from "features/userManagement/userManagementSlice";
import { useWorkstationsByBuildingsQuery } from "features/workstations/workstationApiSlice";
import { getFormattedDate, getFullName, getMappedResponse } from "shared/lib/getters";
import { setNeighborhoodsList } from "features/neighborhoods/neighborhoodsSlice";
import { useNeighborhoodsQuery } from "features/neighborhoods/neighborhoodApiSlice";
import { capitalizeFirstLetter } from "shared/lib/stringsLib";
import UserManagementInfoPage from "../UserManagementInfoPage/";
import UserManagementPage from "../UserManagementPage";

const getColumns = (workstationByBuildings) => [
  {
    field: "name",
    headerName: "Name",
    editable: false,
    flex: 1,
    valueGetter: (params) => getFullName(params.row.firstName, params.row.lastName)
  },
  {
    field: "status",
    headerName: "Status",
    editable: false,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography color={getTableStatusColumnColor(value)}>{capitalizeFirstLetter(value)}</Typography>
    )
  },
  {
    field: "building",
    headerName: "Building",
    editable: false,
    flex: 1
  },
  {
    field: "site",
    headerName: "Site",
    editable: false,
    flex: 1
  },
  {
    field: "department",
    headerName: "Department",
    editable: false,
    flex: 1
  },
  {
    field: "floor",
    headerName: "Floor",
    editable: false,
    flex: 1
  },
  {
    field: "title",
    headerName: "Title",
    editable: false,
    flex: 1
  },
  {
    field: "officeDays",
    headerName: "Office days",
    editable: false,
    flex: 1
  },
  {
    field: "permanentOffice",
    headerName: "Permanent office",
    editable: false,
    flex: 1,
    valueGetter: (params) => {
      const workstation = workstationByBuildings?.find((item) => item.workstationId === params?.row?.workstationId);

      return workstation?.workstationName || "";
    }
  }
];

const mapStateToProps = (state) => ({
  list: selectFilteredUserManagementList(state),
  token: selectAccessToken(state),
  site: selectSiteFilter(state),
  building: selectBuildingFilter(state)
});

const UserManagementListingPage = ({ history }) => {
  const [showInfoSidebar, setShowInfoSidebar] = useState(false);
  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const [showAddSidebar, setShowAddSidebar] = useState(false);
  const [infoId, setInfoId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { list, token, site, building } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const { data: usersData, isLoading: isUsersLoading } = useUsersQuery(null, { skip: !token });
  const { data: neighborhoodData, isLoading: isNeighborhoodLoading } = useNeighborhoodsQuery({ site }, { skip: !site });
  const { data: workstationByBuildingsData, isLoading: isWorkstationByBuildingsLoading } =
    useWorkstationsByBuildingsQuery(
      { site, building, date: getFormattedDate(new Date(), "yyyy-MM-dd") },
      { skip: !token || !site || !building }
    );
  const { data: departmentsList, isLoading: isDepartmentsLoading } = useGetDepartmentsListQuery(null, { skip: !token });

  const tableColumns = useMemo(
    () =>
      workstationByBuildingsData && workstationByBuildingsData[building]?.length
        ? getColumns(workstationByBuildingsData[building])
        : [],
    [workstationByBuildingsData, building]
  );

  useEffect(() => {
    if (
      usersData &&
      !isUsersLoading &&
      workstationByBuildingsData &&
      !isWorkstationByBuildingsLoading &&
      building &&
      workstationByBuildingsData[building]?.length
    ) {
      const mapped = usersData.employees.map((user) => {
        const workstation = workstationByBuildingsData[building].find(
          (workstation) => workstation.workstationId === user.workstationId
        );
        return { ...user, department: user.department.split(", "), permanentOffice: workstation?.room || "" };
      });

      dispatch(setUserManagementList(mapped));
    }
  }, [usersData, isUsersLoading, workstationByBuildingsData, isWorkstationByBuildingsLoading, building]);

  useEffect(() => {
    if (departmentsList && !isDepartmentsLoading) {
      dispatch(setDepartmentsList(departmentsList.departments));
    }
  }, [departmentsList, isDepartmentsLoading]);

  useEffect(() => {
    if (neighborhoodData && !isNeighborhoodLoading) {
      const mappedData = getMappedResponse(neighborhoodData.neighborhoods);
      dispatch(setNeighborhoodsList(mappedData));
    }
  }, [neighborhoodData, isNeighborhoodLoading]);

  const onEdit = (id) => {
    setEditId(id);
    setIsEdit(true);
    setShowEditSidebar(true);
  };

  const onInfo = (id) => {
    setInfoId(id);
    setShowInfoSidebar(true);
  };

  const onAdd = () => {
    setIsEdit(false);
    setShowAddSidebar(true);
  };

  const onSwitchFromInfoToEdit = (id) => {
    onEdit(id);
    setShowInfoSidebar(false);
  };

  return (
    <Box overflow="hidden">
      <DataTable
        rows={list}
        columns={tableColumns}
        pageName={USER_MANAGEMENT_TABLE_NAME}
        addNewText="Add User"
        onEdit={onEdit}
        onInfo={onInfo}
        onAdd={onAdd}
        showFilterButton
        showDeleteColumn={false}
        showSiteAndBuilding
        showFloorSelector
        showSearch
        isLoading={isUsersLoading || isWorkstationByBuildingsLoading}
      />
      {showInfoSidebar && (
        <UserManagementInfoPage
          history={history}
          setIsOpen={setShowInfoSidebar}
          isOpen={showInfoSidebar}
          userId={infoId}
          onEditUser={onSwitchFromInfoToEdit}
        />
      )}
      {showEditSidebar && (
        <UserManagementPage
          history={history}
          setIsOpen={setShowEditSidebar}
          isOpen={showEditSidebar}
          userId={editId}
          isEdit={isEdit}
        />
      )}
      {showAddSidebar && (
        <UserManagementPage history={history} setIsOpen={setShowAddSidebar} isOpen={showAddSidebar} isEdit={isEdit} />
      )}
    </Box>
  );
};

UserManagementListingPage.propTypes = {
  history: PropTypes.object,
  path: PropTypes.string
};

export default withRouter(UserManagementListingPage);
