import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Container, Image } from "./style/overlapsStyles";
import Overlap100Icon from "shared/assets/icons/overlaps/overlaps100.svg";
import Overlap80Icon from "shared/assets/icons/overlaps/overlaps80.svg";
import Overlap60Icon from "shared/assets/icons/overlaps/overlaps60.svg";
import Overlap40Icon from "shared/assets/icons/overlaps/overlaps40.svg";
import Overlap20Icon from "shared/assets/icons/overlaps/overlaps20.svg";
import Overlap0Icon from "shared/assets/icons/overlaps/overlaps0.svg";

const Overlaps = ({ value, min = 0, max = 1, containerStyle }) => {
  const renderIcon = useMemo(() => {
    if (value > max * 0.8) return <Image src={Overlap100Icon} alt="overlap from 80% to 100%" />;

    if (value > max * 0.6 && value <= max * 0.8) return <Image src={Overlap80Icon} alt="overlap from 60% to 80%" />;

    if (value > max * 0.4 && value <= max * 0.6) return <Image src={Overlap60Icon} alt="overlap from 40% to 60%" />;

    if (value > max * 0.2 && value <= max * 0.4) return <Image src={Overlap40Icon} alt="overlap from 20% to 40%" />;

    if (value > min && value <= max * 0.2) return <Image src={Overlap20Icon} alt="overlap from 00% to 20%" />;

    return <Image src={Overlap0Icon} alt="overlap 0%" />;
  }, [value, min, max]);

  return <Container sx={containerStyle}>{renderIcon}</Container>;
};

Overlaps.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  containerStyle: PropTypes.object
};

export default React.memo(Overlaps);
