import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import {
  selectAccessToken,
  selectBuildingFilter,
  selectFilteredUserManagementList,
  selectSiteFilter
} from "core/selectors";
import { getDataEntityItemById } from "shared/lib/getters";
import {
  useAssignDailySeatMutation,
  useWeeklyPlanMutation,
  useRemoveDailySeatMutation,
  useWorkstationByIdsMutation,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";
import { capitalizeFirstLetter } from "shared/lib/stringsLib";
import { upsert } from "shared/lib/upsert";
import moment from "moment";
import { isEmptyObject } from "shared/lib/validation";

const defaultValues = {
  dailyRoom: "",
  dayType: ""
};

export const WEEKLY_TAB = {
  thisWeek: 1,
  nextWeek: 2
};

export const START_DATE = moment().startOf("week").toDate();
export const END_DATE = moment().endOf("week").toDate();

export const NEXT_START_DATE = moment().startOf("week").add(1, "week").toDate();
export const NEXT_END_DATE = moment().endOf("week").add(1, "week").toDate();

const mapStateToProps = (state) => ({
  userManagementList: selectFilteredUserManagementList(state),
  site: selectSiteFilter(state),
  building: selectBuildingFilter(state),
  token: selectAccessToken(state)
});

export default function useUserManagementInfoPageLogic({ userId, onEditUser, setIsOpen }) {
  /* ------------------ HOOKs ------------------ */

  const { handleSubmit } = useForm({ mode: "onChange", defaultValues });

  /* ------------------ STATEs ------------------ */

  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [dayTypes, setDayTypes] = useState([]);
  const [isCloseOnResolve, setCloseOnResolve] = useState(true);
  const [weekPlanData, setWeekPlanData] = useState([]);
  const [tab, setTab] = useState(WEEKLY_TAB.thisWeek);
  const { userManagementList, site, token, building } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: workstationByBuildingsData, isLoading: isWorkstationByBuildingsLoading } =
    useWorkstationsByBuildingsQuery(
      { site, building, date: format(new Date(), "yyyy-MM-dd") },
      { skip: !token || !site || !building }
    );

  const [weeklyPlan, { data: weeklyPlanData, isLoading: isWeeklyDataLoading }] = useWeeklyPlanMutation();

  const [assignDailySeat, { isLoading: isAssignLoading, error: assignError, isSuccess: isSuccessAssigned }] =
    useAssignDailySeatMutation();
  const [removeDailySeat, { isLoading: isRemoveLoading, error: removeError }] = useRemoveDailySeatMutation();
  const [workstationByIds, { error: errorWorkstationByIds, isLoading: isLoadingWorkstationsByIds }] =
    useWorkstationByIdsMutation();

  /* ------------------ MEMOs ------------------ */

  const user = useMemo(() => getDataEntityItemById(userManagementList, userId), [userManagementList, userId]);
  const permanentSeat = useMemo(() => {
    if (
      !user?.workstationId ||
      isWorkstationByBuildingsLoading ||
      isEmptyObject(workstationByBuildingsData) ||
      !building ||
      !workstationByBuildingsData[building]?.length
    )
      return "No room assigned";

    const seat = workstationByBuildingsData[building].find((item) => item.workstationId === user.workstationId);

    return seat?.workstationName ? `Room ${seat.workstationName}` : "No room assigned";
  }, [user?.workstationId, isWorkstationByBuildingsLoading, workstationByBuildingsData, building]);

  /* ------------------ HANDLERs ------------------ */

  const weekPlanHandler = async () => {
    const ids = weeklyPlanData?.filter((item) => item.seatId).map((item) => item.seatId);

    if (!ids?.length) {
      setWeekPlanData(weeklyPlanData.map((item) => ({ ...item, workstationName: "" })));
    } else {
      try {
        const workstationsDetails = await workstationByIds({ ids }).unwrap();
        const mapped = weeklyPlanData.map((day) => {
          const details = workstationsDetails.find((item) => item.workstationId === day.seatId);

          return { ...day, workstationName: details?.workstationName };
        });

        setWeekPlanData(mapped);
      } catch (error) {
        console.error("ERROR:: weekPlanHandler :: ", { error });
      }
    }
  };

  const weeklyPlanByTabHandler = useCallback(
    (tab) => {
      if (tab === WEEKLY_TAB.thisWeek) {
        weeklyPlan({
          employeeId: userId,
          from: format(START_DATE, "yyyy-MM-dd"),
          to: format(END_DATE, "yyyy-MM-dd")
        });
      } else if (tab === WEEKLY_TAB.nextWeek) {
        weeklyPlan({
          employeeId: userId,
          from: format(NEXT_START_DATE, "yyyy-MM-dd"),
          to: format(NEXT_END_DATE, "yyyy-MM-dd")
        });
      }
    },
    [weeklyPlan, userId]
  );

  const fetchData = async (payload) => {
    try {
      await assignDailySeat(payload);
    } catch (e) {
      console.error("ERROR", e);
    }
  };

  function getAllData(URLs) {
    return Promise.allSettled(URLs.map(fetchData));
  }

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    weeklyPlanByTabHandler(tab);
  }, [tab]);

  useEffect(() => {
    console.log({ weeklyPlanData, isWeeklyDataLoading });

    if (weeklyPlanData && !isWeeklyDataLoading) {
      weekPlanHandler();
    }
  }, [weeklyPlanData, isWeeklyDataLoading]);

  useEffect(() => {
    const mappedDayTypes = weekPlanData.map((day) => {
      const data = { type: capitalizeFirstLetter(day.dayType), date: day.date };
      return data;
    });
    setDayTypes(mappedDayTypes);
  }, [weekPlanData]);

  useEffect(() => {}, [user?.workstationId]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onEditClick = () => {
    onEditUser && onEditUser(userId);
  };

  const onRemoveAssignmentClick = async (date) => {
    try {
      await removeDailySeat({ date, employeeId: userId });
      weeklyPlanByTabHandler(tab);
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = () => {
    setCloseOnResolve(true);

    if (selectedRooms.length) {
      const mappedData = selectedRooms.map((room) => {
        return { date: room?.date, seatId: room?.item?.id, employeeId: userId, dayType: room?.dayType };
      });
      getAllData(mappedData);
    } else {
      setIsOpen(false);
    }
  };

  const onChangeTab = (_event, newTab) => {
    setTab(newTab);
  };

  const handleChangeRoom = (item, date, reason, dayType) => {
    const data = { item, date, dayType };
    upsert({ array: selectedRooms, element: data, setState: setSelectedRooms, reason });
  };

  const handleChangeDayType = (event, date) => {
    setCloseOnResolve(false);

    const {
      target: { value }
    } = event;

    const data = { type: value, date };
    upsert({ array: dayTypes, element: data, setState: setDayTypes });
  };

  const handleApplySelection = async (workstation, date, dayType) => {
    try {
      setCloseOnResolve(false);

      if (workstation?.id) {
        handleChangeRoom(workstation, date, "clear");
      }

      try {
        await assignDailySeat({
          date,
          employeeId: userId,
          dayType,
          seatId: workstation?.id
        });
      } catch (e) {
        console.error("ERROR", e);
      }
      weeklyPlanByTabHandler(tab);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    handleSubmit,
    onSubmit,
    error: assignError || removeError || errorWorkstationByIds,
    isSuccess: isSuccessAssigned && isCloseOnResolve,
    isLoading: isAssignLoading || isRemoveLoading,
    isLoadingWeekPlan: isWeeklyDataLoading || isAssignLoading || isLoadingWorkstationsByIds,
    disableWeekPlan: isAssignLoading,
    user,
    weekPlanData,
    selectedIndex,
    setSelectedIndex,
    selectedRooms,
    handleChangeRoom,
    handleChangeDayType,
    handleApplySelection,
    onRemoveAssignmentClick,
    dayTypes,
    onEditClick,
    permanentSeat,
    tab,
    onChangeTab
  };
}
