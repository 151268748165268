import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import CustomizedTextField from "shared/components/CustomizedTextField/CustomizedTextField";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";

const FormInputText = ({ name, control, label, rules, placeholder, styles, disabled, multiline = false }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <CustomizedLabel text={label} error={!!error} />
          <CustomizedTextField
            helperText={error ? error.message : null}
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            placeholder={placeholder}
            styles={styles}
            disabled={disabled}
            multiline={multiline}
          />
        </>
      )}
    />
  );
};

FormInputText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool
};

export default FormInputText;
