import { RGX_EMAIL } from "../constants";

/**
 * @method isValidString
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidString = (value) => typeof value === "string";

/**
 * @method isValidAndNotEmptyString
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidAndNotEmptyString = (value) => isValidString(value) && value.trim().length > 0;

/**
 * @method isValidEmail
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidEmail = (value) => {
  if (!isValidString(value)) {
    return false;
  }
  return RGX_EMAIL.test(value);
};

/**
 * @method isDefined
 * @param  {*} value
 * @return {Boolean}
 */
export const isDefined = (value) => typeof value !== "undefined";

/**
 * @method isValidFunction
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidFunction = (value) => typeof value === "function";

/**
 * @method isValidArray
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidArray = (value) => Array.isArray(value);

/**
 * @method isValidNumber
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidNumber = (value) => typeof value === "number";

/**
 * @type {RegExp}
 */
export const RGX_PHONE_NUMBER = /^\+?(?:[0-9] ?){6,14}[0-9]$/;

/**
 * @method isValidPhoneNumber
 * Accepts all international and local phone numbers,
 * but without any special characters,
 * except `+` and ` `.
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidPhoneNumber = (value) => {
  if (!isValidString(value)) {
    return false;
  }

  // Remove braces, dots, hyphens.
  const clean = value.replace(/[().-]/g, "");

  return RGX_PHONE_NUMBER.test(clean);
};

export const parseJSON = (jsonString) => {
  if (!jsonString || jsonString === "undefined" || jsonString === "null") return;

  return JSON.parse(jsonString);
};

/**
 * @method isValidObject
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidObject = (value) => typeof value === "object" && !isValidArray(value) && value !== null;

/**
 * @method isNull
 * @param  {*} value
 * @return {Boolean}
 */
export const isNull = (value) => {
  return value === null;
};

/**
 * @method isEmptyObject
 * @param  {*} value
 * @return {Boolean}
 */
export const isEmptyObject = (value) => {
  return (
    !value ||
    (isValidObject(value) && !isNull(value) && value.constructor === Object && Object.keys(value).length === 0)
  );
};

/**
 * @method isValidDate
 * @param  {*} value
 * @return {Boolean}
 */
export const isValidDate = (value) => {
  return value instanceof Date && !isNaN(value);
};

/**
 * @method isObjectHasProperty
 * @param  {object} obj
 * @param  {string} propertyName
 * @return {Boolean}
 */
export const isObjectHasProperty = (obj, propertyName) => {
  return Object.prototype.hasOwnProperty.call(obj, propertyName);
};
