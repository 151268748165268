import { selectCompanySites, selectNationalHolidays } from "core/selectors";
import { useCreateNationalHolidaysMutation } from "features/adminSettings/adminSettingsApiSlice";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MOMENT_DATE_FORMAT } from "shared/constants";
import { setFormValues } from "shared/lib/setFormValues";

const defaultValues = {
  sites: [],
  name: "",
  date: new Date()
};

export default function useAdminSettingsHolidaySidebarLogic({ holidayId }) {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty }
  } = useForm({ defaultValues });

  const [holiday, setHoliday] = useState({});

  const companySites = useSelector(selectCompanySites);
  const nationalHolidays = useSelector(selectNationalHolidays);

  const [
    createHoliday,
    { isLoading: isLoadingCreateHoliday, isSuccess: isSuccessCreateHoliday, error: errorCreateHoliday }
  ] = useCreateNationalHolidaysMutation();

  const sitesOptions = useMemo(
    () => (companySites?.length ? companySites.map(({ name }) => ({ option: name, value: name })) : []),
    [companySites]
  );

  useEffect(() => {
    const holiday = [...nationalHolidays]?.find((item) => item.id === holidayId);
    const date = holiday?.date ? new Date(holiday.date) : new Date();
    const sites = holiday?.siteIds?.length
      ? holiday.siteIds.map((id) => {
          const site = [...companySites]?.find((item) => item.id === id);

          return site?.name;
        })
      : [];

    setFormValues({
      data: {
        name: holiday?.name || "",
        date: new Date(date),
        sites
      },
      setValue
    });

    setHoliday(holiday);
  }, [nationalHolidays, holidayId, companySites, setValue]);

  const onSubmit = useCallback(
    handleSubmit(({ sites, name, date }) => {
      const siteIds = [...companySites]?.filter(({ name }) => sites.includes(name))?.map(({ id }) => id);

      createHoliday({ siteIds, name, date: moment(date).format(MOMENT_DATE_FORMAT) });
    }),
    [companySites, handleSubmit, createHoliday]
  );

  return {
    holiday,
    onSubmit,
    reset,
    isDirty,
    control,
    sitesOptions,
    isLoading: isLoadingCreateHoliday,
    isSuccess: isSuccessCreateHoliday,
    error: errorCreateHoliday
  };
}
