export const upsert = ({ array, setState, element, reason }) => {
  const index = array.findIndex((item) => item.date === element.date);

  if (reason === "clear") {
    setState((prevState) => prevState.filter((item) => item.date !== element.date));
    return;
  }

  if (index > -1) {
    const newArr = [...array];
    newArr[index] = element;
    setState(newArr);
    return;
  }

  setState([...array, element]);
};
