import { createSlice } from "@reduxjs/toolkit";
import FeynmanImage from "shared/assets/images/richard-feynman.jpeg";
import ArmstrongImage from "shared/assets/images/armstrong.jpg";
import JourneyImage from "shared/assets/images/journey.jpeg";

const mockedList = [
  {
    id: "1",
    firstName: "Richard",
    lastName: "Feynman",
    email: "feynman@company.com",
    phone: "+972544488717",
    site: "Tel Aviv",
    building: "Museem Tower",
    department: "Finance",
    manager: "Benny Golson",
    profession: "Controller",
    officeDays: 3,
    teamMemberOverlap: 9.4,
    image: FeynmanImage
  },
  {
    id: "2",
    firstName: "Louis",
    lastName: "Armstrong",
    email: "armstrong@demo.com",
    phone: "+972544445126",
    site: "Tel Aviv",
    building: "Museem Tower",
    department: "Marketing",
    manager: "Alan Touring",
    profession: "Controller",
    officeDays: 5,
    teamMemberOverlap: 8.6,
    image: ArmstrongImage
  },
  {
    id: "3",
    firstName: "The",
    lastName: "Journey",
    email: "journey@demo.com",
    phone: "+972544445126",
    site: "Tel Aviv",
    building: "Museem Tower",
    department: "Marketing",
    manager: "Ivo Bobul",
    profession: "Controller",
    officeDays: 4,
    teamMemberOverlap: 8.0,
    image: JourneyImage
  }
];

const initialState = {
  list: mockedList,
  selectedUser: null
};

export const teamUsersSlice = createSlice({
  name: "teamUsers",
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    }
  }
});

export const { setSelectedUser } = teamUsersSlice.actions;

export default teamUsersSlice.reducer;
