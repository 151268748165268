/**
 *
 * @param {object} obj
 * @returns {array}
 */
export function objectValuesToArray(obj) {
  if (!obj || typeof obj !== "object") return [];

  const keysArr = Object.keys(obj);

  return keysArr?.length ? keysArr.map((key) => obj[key]) : [];
}
