import { useMemo } from "react";

export default function useSelectLogic({ handleChange, selectedValue, defaultValue }) {
  const data = useMemo(() => (selectedValue?.length ? selectedValue : defaultValue), [selectedValue, defaultValue]);

  const onClear = () => {
    handleChange([]);
  };

  return { onClear, data };
}
