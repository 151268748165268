import { capitalizeFirstLetter, dashCaseToText } from "./stringsLib";

/**
 * @param {[*]} users
 * @param {[*]} roles
 *
 * @returns {[*]}
 */
export function getAdminUsers(users, roles) {
  const adminRoles = [...roles].filter((role) => role?.resources?.length).map((role) => role.name);

  return [...users].filter((user) => adminRoles.includes(user.role));
}

export function getRoleName(value) {
  if (!value) return "";

  const text = dashCaseToText(value);
  return text?.length > 3 ? capitalizeFirstLetter(text) : text.toUpperCase();
}
