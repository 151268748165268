import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  marginRight: theme.spacing(-4),
  minWidth: "1460px"
}));

export const Content = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(4),
  flexGrow: 1,
  height: "100%"
}));

export const Summary = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  marginLeft: "auto",
  paddingBottom: theme.spacing(0.75),
  paddingRight: theme.spacing(4)
}));

export const SummaryText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(3),
  color: "#6b6c72"
}));

export const styles = {
  tabsContainerStyle: {
    width: "100%",
    paddingBottom: "24px"
  },

  tabsStyle: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "4px solid #ccc"
    },

    "& .MuiTabs-indicator": {
      height: "4px"
    }
  },

  tabItemStyle: {
    fontSize: "1.1rem",

    "&&.MuiTab-root": {
      padding: "20px 24px",
      minWidth: "140px"
    }
  }
};
