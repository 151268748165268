import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RunningIcon from "shared/assets/icons/running.svg";

const tagsList = ["Finance", "Legal", "HR", "Planning", "AI/ML", "Back End", "Algo", "Q/A", "Administration"];

const Tags = () => {
  return (
    <Grid container spacing={2} p={2} height="100%" alignItems="center" justifyContent="center">
      <Grid item xs={6}>
        {tagsList.map((tag, index) => (
          <Chip key={index} label={tag} variant="outlined" sx={{ margin: "4px" }} />
        ))}
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <img src={RunningIcon} alt="Tag icon" />
          <Typography>Running</Typography>
        </Box>
        <Typography variant="h6">
          The running group is attracting people from a wide variaty of teams across the company. Consider adding more
          occurences, or similar groups.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Tags;
