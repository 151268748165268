import React from "react";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import Divider from "@mui/material/Divider";

const CustomizedDivider = ({ bottomWidth, hrColor }) => {
  return <CustomDivider bottomWidth={bottomWidth} hrColor={hrColor} />;
};

const CustomDivider = styled(Divider)(({ theme, bottomWidth, hrColor }) => ({
  margin: theme.spacing(2, 0),
  borderBottomWidth: bottomWidth || "1px",
  color: hrColor || "#E3E5E8"
}));

CustomizedDivider.propTypes = {
  bottomWidth: PropTypes.string,
  hrColor: PropTypes.string
};

export default CustomizedDivider;
