import { Box, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";

export const ActionButton = styled(Button)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#00203E",
  padding: theme.spacing(1, 3),
  borderRadius: theme.spacing(2),
  fontWeight: "700",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#003f77",
    color: "#fff"
  }
}));

export const GroupIcon = styled("img")(({ theme }) => ({
  width: "50px",
  height: "50px",
  marginRight: theme.spacing(2)
}));

export const MembersList = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  paddingTop: theme.spacing(0.5),
  marginTop: theme.spacing(2),
  height: theme.spacing(41),
  borderRadius: "3px",
  border: "1px solid #E3E5E8"
}));

export const EditButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  minWidth: "191px",
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
  position: "relative"
}));

export const ActionContainer = styled(Box)(() => ({
  transition: "margin-top 700ms ease",
  zIndex: 5
}));

export const SessionTypography = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(5)
}));

export const SessionsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1
}));

export const SessionItem = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between"
}));

export const CustomizedIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(1.3)
}));
