import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Sidebar from "shared/components/Sidebar/Sidebar";
import styled from "@mui/material/styles/styled";
import TrashIcon from "shared/assets/icons/trash.svg";
import CustomizedTextField from "shared/components/CustomizedTextField/CustomizedTextField";

const InterestsSidebar = ({ history, isOpen, setIsOpen, isDrawer, isPersonal, list, onAdd, onDelete }) => {
  const [newInterest, setInterest] = useState("");

  const onChangeInterest = (e) => {
    setInterest(e.target.value);
  };

  const onAddInterest = () => {
    onAdd(newInterest);

    setInterest("");
  };

  const onResolve = () => {
    setInterest("");
    setIsOpen(false);
  };

  return (
    <Sidebar
      history={history}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onResolve={onResolve}
      isDrawer={isDrawer}
      resolveLabel={"Ok"}
      showCancelButton={false}
    >
      <Box>
        <Typography variant="h5" fontWeight="700" mb={3}>
          {isPersonal ? "Personal interests" : "Professional interests"}
        </Typography>
      </Box>
      <Grid container rowSpacing={1} spacing={2} mb={3}>
        <Grid item xs={10}>
          <CustomizedTextField
            onChange={onChangeInterest}
            fullWidth={true}
            label="Add interest"
            placeholder="Enter interest name"
            name="name"
            styles={{
              marginBottom: 0
            }}
            labelStyles={{ color: "#6B6C72" }}
          />
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center">
          <AddButton onClick={onAddInterest}>Add</AddButton>
        </Grid>
      </Grid>
      <Divider color="#8D9096" />
      <Table sx={{ width: "60%" }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography color="#8D9096">Interest</Typography>
            </TableCell>
            <TableCell>
              <Typography color="#8D9096">Users</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.length
            ? list.map((row) => (
                <TableRow key={row.id} hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center" padding="none" width="60px" sx={{ borderBottom: "none" }}>
                    <ActionButton onClick={() => onDelete(row.name)}>
                      <img src={TrashIcon} />
                    </ActionButton>
                  </TableCell>
                  <TableCell padding="none" sx={{ borderBottom: "none" }}>
                    {row.name}
                  </TableCell>
                  <TableCell padding="none" sx={{ borderBottom: "none" }}>
                    {row?.employeeIds?.length ? row.employeeIds.length : 0}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Sidebar>
  );
};

const AddButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.7, 1),
  width: "100%"
}));

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: "40px",
  color: "#000",
  "&:focus": {
    outline: "none"
  }
}));

InterestsSidebar.propTypes = {
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  isDrawer: PropTypes.bool,
  isPersonal: PropTypes.bool,
  setIsOpen: PropTypes.func,
  list: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func
};

export default withRouter(InterestsSidebar);
