import React, { useState, useEffect, memo } from "react";
import { PropTypes } from "prop-types";
import { Box, Typography } from "@mui/material";
import { parse, format, isToday } from "date-fns";
import styled from "@mui/material/styles/styled";

const AttendanceDaysTitle = ({ item }) => {
  const [workDay, setWorkDay] = useState("");
  const [date, setDate] = useState("");
  const [today, setToday] = useState(false);

  useEffect(() => {
    if (!item) return null;

    const date = item.date;
    const parsed = parse(date, "yyyy-MM-dd", new Date());

    setToday(isToday(parsed));
    setWorkDay(format(parsed, "EEEE"));
    setDate(format(parsed, "dd.MM"));
  }, [item]);

  return (
    <Container>
      <Typography fontWeight={today ? "bold" : "normal"}>{workDay}</Typography>
      <Typography fontWeight={today ? "bold" : "normal"}>{date}</Typography>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  width: "20%",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: theme.spacing(1)
}));

AttendanceDaysTitle.propTypes = {
  item: PropTypes.object
};

export default memo(AttendanceDaysTitle);
