import React, { memo } from "react";
import { PropTypes } from "prop-types";
import { Box, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { capitalizeFirstLetter } from "shared/lib/stringsLib";
import { DAY_TYPES } from "shared/constants";

const styles = {
  getContainerStyle: (isOffice, hasCheckedIn) => ({
    borderColor: isOffice ? (hasCheckedIn ? "#00B4F0" : "#66D9FF") : "#ECEEF1",
    background: isOffice && hasCheckedIn ? "#CCF2FF" : "#ECEEF1"
  }),
  getOfficeDecorationStyle: (hasCheckedIn) => ({
    background: hasCheckedIn ? "#00B4F0" : "#66D9FF"
  }),
  getOfficeTypographyStyle: (hasCheckedIn) => ({
    color: hasCheckedIn ? "#00B4F0" : "#66D9FF"
  })
};

const AttendanceDays = ({ item }) => {
  const renderTypography = () => {
    const text = capitalizeFirstLetter(item.dayType);

    if (item.dayType?.toLowerCase() === DAY_TYPES.office)
      return <OfficeTypography style={styles.getOfficeTypographyStyle(item.hasCheckedIn)}>{text}</OfficeTypography>;

    if (item.dayType?.toLowerCase() === DAY_TYPES.home) return <HomeTypography>{text}</HomeTypography>;

    return <DayTypeTypography>{text}</DayTypeTypography>;
  };

  return item ? (
    <Container style={styles.getContainerStyle(item.dayType?.toLowerCase() === DAY_TYPES.office, item.hasCheckedIn)}>
      {item.dayType?.toLowerCase() === DAY_TYPES.office ? (
        <OfficeDayTypeDecoration style={styles.getOfficeDecorationStyle(item.hasCheckedIn)} />
      ) : null}

      {renderTypography()}
    </Container>
  ) : null;
};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.spacing(1.25),
  padding: theme.spacing(1.25, 5),
  minWidth: theme.spacing(10),
  width: "20%",
  marginRight: theme.spacing(0.75),
  position: "relative",
  overflow: "hidden",
  borderWidth: "1px",
  borderStyle: "solid"
}));

const OfficeDayTypeDecoration = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: "-1px",
  bottom: 0,
  width: theme.spacing(1.7)
}));

const DayTypeTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.5),
  color: "#8D9096"
}));

const OfficeTypography = styled(DayTypeTypography)(({ theme }) => ({
  position: "relative",
  color: "#00B4F0",
  fontWeight: "bold"
}));

const HomeTypography = styled(DayTypeTypography)(({ theme }) => ({
  color: "#000000"
}));

AttendanceDays.propTypes = {
  item: PropTypes.object
};

export default memo(AttendanceDays);
