import React, { useMemo } from "react";
import PropTypes from "prop-types";
import DataTable from "shared/components/DataTable/DataTable";
import {
  Container,
  Grid,
  GridItemTable,
  GridItemChart,
  RelationChartContainer,
  FullscreenButton,
  LoaderContainer,
  styles
} from "./style/insightsTabStyle";
import useInsightsTabLogic from "./hooks/useInsightsTabLogic";
import {
  insightsTableColumns,
  arrivalsChartData,
  arrivalsChartOptions,
  avgEmployeeDayChartData,
  avgDaysPerWeekChartData,
  lineChartOptions
} from "./utils/insightsComponentsOptions";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CardContainer from "shared/components/CardContainer/CardContainer";
import { Typography } from "@mui/material";
import RelationChart from "./components/RelationChart";
import FullscreenModal from "shared/components/FullscreenModal";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import CircularProgress from "@mui/material/CircularProgress";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const InsightsTab = () => {
  const {
    rows,
    isLoading,
    chartOptions,
    relationChartModal,
    relationData,
    users,
    isLoadingRelations,
    avgEmployeesPerDayData,
    avgDaysPerWeekData,
    isLoadingAvgEmployee,
    isLoadingAvgDays,
    isLoadingAttendance
  } = useInsightsTabLogic();

  const renderChart = useMemo(
    () => <RelationChart relations={relationData} users={users} isLoading={isLoadingRelations} />,
    [relationData, users, isLoadingRelations]
  );

  return (
    <Container>
      <Grid>
        <GridItemTable>
          <DataTable
            rows={rows}
            columns={insightsTableColumns}
            showDeleteColumn={false}
            showEditColumn={false}
            showInfoColumn={false}
            showSiteAndBuilding
            showFloorSelector
            showDepartmentSelector
            showSearch
            autoHeight={false}
            isLoading={isLoading}
            sx={styles.tableStyle}
            containerStyle={styles.tableContainerStyle}
          />
        </GridItemTable>

        <GridItemChart>
          <CardContainer style={styles.cardContainer}>
            {isLoadingAttendance && (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            )}

            <Typography variant="h5" fontWeight="700" mb={2}>
              Arrival forecast
            </Typography>

            <Bar data={arrivalsChartData(chartOptions)} plugins={[ChartDataLabels]} options={arrivalsChartOptions} />
          </CardContainer>

          <CardContainer mt={3} style={styles.cardContainer}>
            {isLoadingAvgEmployee && (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            )}
            <Typography variant="h5" fontWeight="700" mb={2}>
              Average employees per day
            </Typography>

            <Line options={lineChartOptions} data={avgEmployeeDayChartData(avgEmployeesPerDayData)} />
          </CardContainer>

          <CardContainer mt={3} style={styles.cardContainer}>
            {isLoadingAvgDays && (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            )}

            <Typography variant="h5" fontWeight="700" mb={2}>
              Average days per week
            </Typography>

            <Line options={lineChartOptions} data={avgDaysPerWeekChartData(avgDaysPerWeekData)} />
          </CardContainer>

          <CardContainer mt={3}>
            <Typography variant="h5" fontWeight="700" mb={2}>
              Social graph
            </Typography>

            <RelationChartContainer>
              {renderChart}

              <FullscreenButton onClick={relationChartModal.on}>
                <ZoomOutMapIcon sx={styles.fullscreenIconStyle} />
              </FullscreenButton>
            </RelationChartContainer>
          </CardContainer>
        </GridItemChart>
      </Grid>

      <FullscreenModal
        visible={relationChartModal.enable}
        onClose={relationChartModal.off}
        onZoomIn={() => {}}
        onZoomOut={() => {}}
      >
        <>{renderChart}</>
      </FullscreenModal>
    </Container>
  );
};

InsightsTab.propTypes = {
  setSummary: PropTypes.func
};

export default InsightsTab;
