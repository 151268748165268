import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAccessToken,
  selectBuildingFilter,
  selectCompanyAndSiteSettings,
  selectDepartmentsFilter,
  selectFloorsFilter
} from "core/selectors";
import { useAttendanceQuery } from "features/attendance/attendanceApiSlice";
import moment from "moment";
import currentWeekTableColumns from "../utils/currentWeekTableColumns";
import { fillEmployeesSchedules } from "pages/Attendance/helpers";
import { MOMENT_DATE_FORMAT, WORK_DAYS_COUNT } from "shared/constants";

export const START_DATE = moment().startOf("week").format(MOMENT_DATE_FORMAT);
export const END_DATE = moment().endOf("week").format(MOMENT_DATE_FORMAT);

const mapStateToProps = (state) => [
  selectAccessToken(state),
  selectCompanyAndSiteSettings(state),
  selectBuildingFilter(state),
  selectFloorsFilter(state),
  selectDepartmentsFilter(state)
];

export default function useCurrentWeekTabLogic({ setSummary }) {
  /* ------------------ STATEs ------------------ */

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(currentWeekTableColumns(null));
  const [token, companyAndSiteSettings, building, floors, departments] = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: attendanceData, isLoading } = useAttendanceQuery(
    {
      fromDate: START_DATE,
      toDate: END_DATE,
      building,
      departments,
      floors
    },
    { skip: !token }
  );

  /* ------------------ HANDLERs ------------------ */

  const setOfficeDaysColumnHeaders = (data) => {
    if (!data) return;

    const firstTableItem = data[0];

    setColumns(currentWeekTableColumns(firstTableItem));
  };

  const getAttendanceHandler = () => {
    const data = fillEmployeesSchedules(attendanceData.data, {
      startDate: START_DATE,
      endDate: END_DATE,
      sundayFirst: companyAndSiteSettings.first_day_of_week === "Sunday",
      addWeekends: false
    });

    setOfficeDaysColumnHeaders(data);
    setRows(data);
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!attendanceData || isLoading) return;

    getAttendanceHandler();
  }, [attendanceData, isLoading]);

  useEffect(() => {
    setSummary({
      users: rows?.length,
      days: WORK_DAYS_COUNT
    });
  }, [rows?.length]);

  return {
    columns,
    rows,
    isLoading
  };
}
