import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import styled from "@mui/material/styles/styled";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";
import "react-phone-input-2/lib/style.css";

const ONLY_COUNTRIES_LIST = ["us", "il"];

const FormInputPhone = ({ name, control, label, rules, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Container>
          <CustomizedLabel text={label} error={!!error} />
          <Input
            country="il"
            onlyCountries={ONLY_COUNTRIES_LIST}
            onChange={onChange}
            value={value}
            disabled={disabled}
            isValid={() => {
              if (error?.message) {
                return error.message;
              } else {
                return true;
              }
            }}
          />
        </Container>
      )}
    />
  );
};

const Container = styled("div")(({ theme }) => ({
  width: "100%"
}));

const Input = styled(PhoneInput)(({ theme }) => ({
  color: "#00203E",
  "& .form-control": {
    width: "100%"
  },
  "& input.form-control": {
    width: "100%"
  },
  "& .invalid-number-message": {
    top: theme.spacing(5),
    left: 0,
    background: "#f8fafe",
    fontSize: "0.75rem"
  },
  "& :disabled": {
    opacity: 0.5
  }
}));

FormInputPhone.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  disabled: PropTypes.bool
};

export default FormInputPhone;
