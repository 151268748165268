import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import FormInputText from "shared/components/FormInputs/Text/FormInputText";
import FormInputDate from "shared/components/FormInputs/Date/FormInputDate";
import FormInputTime from "shared/components/FormInputs/TIme/FormInputTime";
import Sidebar from "shared/components/Sidebar/Sidebar";
import DataTableLite from "shared/components/DataTable/DataTableLite";
import { getFullName } from "shared/lib/getters";
import moment from "moment";
import RemoveButton from "shared/components/Buttons/RemoveButton/RemoveButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "shared/components/Dialogs/ConfirmDialog";
import { MembersList, ConfirmationText, TitleRow, CopyPreviousButton, ActionButton } from "./style/sessionSidebarStyle";
import useSessionSidebarLogic from "./hooks/useSessionSidebarLogic";
import { format } from "date-fns";

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => getFullName(params.row.firstName, params.row.lastName)
  },
  {
    field: "department",
    headerName: "Department",
    flex: 1
  }
];

const confirmDialogText = () => (
  <div>
    <ConfirmationText variant="span">
      {
        "You are about to delete this session. This action cannot be undone. A cancelation message will be sent to all attendees."
      }
    </ConfirmationText>
  </div>
);

const SessionSidebar = ({ history, isOpen, setIsOpen, group, session, isEdit, isDrawer, onEditSession }) => {
  const {
    statusList,
    handleSubmit,
    onSubmit,
    isDirty,
    onCopyPrevious,
    control,
    rows,
    onDelete,
    showConfirmDialog,
    onApplyDialog,
    onCancelDialog,
    setShowConfirmDialog,
    isLoadingUserByIds,
    isLoading,
    error,
    isSuccess,
    isNew
  } = useSessionSidebarLogic({
    setIsOpen,
    group,
    session,
    isEdit
  });

  const renderTitle = useMemo(() => {
    if (isNew) return `Add Session`;

    if (isEdit) return `Edit Session`;

    return `${group?.name} session, ${format(new Date(session?.startTime), "MMMM dd")}`;
  }, [session, isEdit, group, isNew]);

  const renderSubtitle = useMemo(() => {
    if (!isNew && !isEdit) return null;

    return (
      <Box mt={2}>
        <Typography>
          {isEdit ? `Edit this session of ${group?.name}` : `Add a new session to ${group?.name}`}
        </Typography>
      </Box>
    );
  }, [isEdit, group, isNew]);

  const renderStatusesCount = useMemo(() => {
    if (isNew || !statusList?.length) return null;

    return (
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexWrap: "wrap" }} mt={2}>
        {statusList.map(({ value, label, color }, index) => (
          <Box
            key={`${label}-${index}`}
            textAlign="center"
            minWidth="90px"
            sx={{ marginLeft: "0 !important", paddingLeft: index >= 1 && "24px" }}
          >
            <Typography variant="h6" color={color}>
              {value}
            </Typography>
            <Typography variant="h6">{label}</Typography>
          </Box>
        ))}
      </Stack>
    );
  }, [isNew, statusList]);

  return (
    <div>
      <Sidebar
        history={history}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onResolve={handleSubmit(onSubmit)}
        isDirty={isDirty}
        resolveLabel={isEdit ? "Update" : isNew ? "Add" : "Ok"}
        isDrawer={isDrawer}
        shouldValidate
        isLoading={isLoading}
        error={error}
        isSuccess={isSuccess}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitleRow mt={2}>
              <Typography variant="h5" fontWeight="700">
                {renderTitle}
              </Typography>

              {isNew ? (
                <CopyPreviousButton onClick={onCopyPrevious} text={"Copy from prev."} disabled={!group?.previous} />
              ) : null}

              {!isNew && !isEdit ? <ActionButton onClick={() => onEditSession(session)}>Edit</ActionButton> : null}
            </TitleRow>

            {renderSubtitle}

            {renderStatusesCount}
          </Grid>
        </Grid>

        <Grid container rowSpacing={1} spacing={2} mt={3}>
          <Grid item xs={4} style={{ zIndex: 10 }}>
            <FormInputDate
              label="Session date*"
              rules={{ required: "Session date is required" }}
              control={control}
              name="sessionDate"
              minDate={moment().toDate()}
              disabled={!isNew && !isEdit}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputTime
              label="Start time*"
              rules={{ required: "Start time is required" }}
              control={control}
              name="startTime"
              disabled={!isNew && !isEdit}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputTime
              label="End time*"
              rules={{ required: "End time is required" }}
              control={control}
              name="endTime"
              disabled={!isNew && !isEdit}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInputText
              label="Session name*"
              control={control}
              name="name"
              rules={{
                required: "Session name is required",
                maxLength: { value: 32, message: "Maximum 96 characters required" }
              }}
              placeholder="Enter new name"
              disabled={!isNew && !isEdit}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInputText
              label="Session description and time*"
              control={control}
              name="description"
              rules={{
                required: "Description is required",
                maxLength: { value: 96, message: "Maximum 96 characters required" }
              }}
              placeholder="Enter description"
              multiline
              disabled={!isNew && !isEdit}
            />
          </Grid>
        </Grid>

        {!isNew ? (
          <>
            <Divider color="#BABEC5" sx={{ marginTop: "36px" }} />

            <Box mt={3}>
              <Typography variant="h6">Who signed up</Typography>
              <MembersList>
                <DataTableLite columns={columns} rows={rows} hideFooter={true} isLoading={isLoadingUserByIds} />
              </MembersList>
            </Box>

            <Divider color="#BABEC5" sx={{ marginTop: "36px" }} />

            <Box mt={3}>
              <RemoveButton onClick={onDelete}>
                <DeleteIcon fontSize="small" sx={{ color: "#D52B1E" }} />
                <Typography color="#D52B1E" pl={1}>
                  Delete session
                </Typography>
              </RemoveButton>
            </Box>
          </>
        ) : null}
      </Sidebar>

      <ConfirmDialog
        showDialog={showConfirmDialog}
        resolve={onApplyDialog}
        reject={onCancelDialog}
        acceptLabel={"OK"}
        title={"Delete session"}
        warningText={""}
        text={confirmDialogText()}
        rowName={null}
        rowId={null}
        setShowDialog={setShowConfirmDialog}
      />
    </div>
  );
};

SessionSidebar.propTypes = {
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  isDrawer: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onEditSession: PropTypes.func,
  isEdit: PropTypes.bool,
  group: PropTypes.object,
  session: PropTypes.object
};

export default withRouter(SessionSidebar);
