import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "shared/components/DataTable/DataTable";
import { selectBuildingFilter, selectFilteredNeighborhoodsList, selectSiteFilter } from "core/selectors";
import { useDeleteNeighborhoodMutation, useNeighborhoodsQuery } from "features/neighborhoods/neighborhoodApiSlice";
import { setNeighborhoodsList } from "features/neighborhoods/neighborhoodsSlice";
import { getMappedResponse } from "shared/lib/getters";
import { NEIGHBORHOODS_PAGE_NAME } from "shared/constants";
import { toast } from "react-toastify";
import { useToggle } from "shared/hooks";
import NeighborhoodPage from "pages/NeighborhoodPage/NeighborhoodPage";

const DELETE_TITLE = "Delete Neighborhood";

const columns = [
  {
    field: "name",
    headerName: "Name",
    editable: false,
    flex: 1
  },
  {
    field: "site",
    headerName: "Site",
    editable: false,
    flex: 1
  },
  {
    field: "building",
    headerName: "Building",
    editable: false,
    flex: 1
  }
];

const NeighborhoodsListingPage = ({ history, path }) => {
  const dispatch = useDispatch();
  const neighborhoodsSidebar = useToggle();

  const list = useSelector(selectFilteredNeighborhoodsList);
  const site = useSelector(selectSiteFilter);
  const building = useSelector(selectBuildingFilter);
  const [neighborhoodName, setNeighborhoodName] = useState(null);

  const { data, isLoading } = useNeighborhoodsQuery({ site }, { skip: !site });
  const [deleteNeighborhood, { error, isSuccess }] = useDeleteNeighborhoodMutation();

  useEffect(() => {
    if (data && !isLoading) {
      const mappedData = getMappedResponse(data.neighborhoods);
      dispatch(setNeighborhoodsList(mappedData));
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Done.");
    }
    if (error) {
      toast.error(error.data.message);
    }
  }, [error, isSuccess]);

  const onEdit = (name) => {
    setNeighborhoodName(name);

    neighborhoodsSidebar.on();
  };

  const onAdd = () => {
    setNeighborhoodName(null);

    neighborhoodsSidebar.on();
  };

  const onDelete = async (neighborhoodName) => {
    try {
      await deleteNeighborhood({ name: neighborhoodName, site, building });
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = (value) => {
    setNeighborhoodName(null);
    neighborhoodsSidebar.set(value);
  };

  const deleteContentText = (
    <div>
      <span>If you choose to delete this neighborhood:</span>
      <ul>
        <li>All workstations in this neighborhood will change to a general neighborhood</li>
        <li>Any user will be able to sit in these workstations</li>
      </ul>
    </div>
  );

  return (
    <>
      {" "}
      <DataTable
        rows={list}
        columns={columns}
        pageName={NEIGHBORHOODS_PAGE_NAME}
        addNewText="Add Neighborhood"
        onEdit={onEdit}
        onDelete={onDelete}
        onAdd={onAdd}
        deleteTitle={DELETE_TITLE}
        deleteContentText={deleteContentText}
        showSiteAndBuilding
        showInfoColumn={false}
        isLoading={isLoading}
        deleteError={error}
      />
      <NeighborhoodPage
        history={history}
        isOpen={neighborhoodsSidebar.enable}
        onClose={onClose}
        neighborhoodName={neighborhoodName}
      />
    </>
  );
};

NeighborhoodsListingPage.propTypes = {
  history: PropTypes.object,
  path: PropTypes.string
};

export default withRouter(NeighborhoodsListingPage);
