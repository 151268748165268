import styled from "@mui/material/styles/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  height: 30,
  maxWidth: 60
}));

export const Image = styled("img")(() => ({
  display: "block",
  height: "100%"
}));
