import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import Box from "@mui/material/Box";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "@mui/material/styles/styled";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);

const doughnutData = {
  labels: ["Outgoing", "Incoming", "Cross-functional", "Mentorship", "Impromptu"],
  datasets: [
    {
      data: [30, 12, 25, 15, 18],
      backgroundColor: ["#66D9FF", "#33CCFF", "#0099CC", "#007399"],
      borderColor: ["#66D9FF", "#33CCFF", "#0099CC", "#007399"],
      borderWidth: 1
    }
  ]
};

const doughnutOptions = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 16
      }
    },
    datalabels: {
      display: false
    }
  },
  maintainAspectRatio: false,
  cutout: "85%"
};

const OrganizationConnectionsChart = () => {
  return (
    <DoughnutContainer py={2}>
      <Doughnut data={doughnutData} options={doughnutOptions} />
    </DoughnutContainer>
  );
};

const DoughnutContainer = styled(Box)(({ theme }) => ({
  height: "350px",

  [theme.breakpoints.up("lg")]: {
    maxHeight: "2300px"
  },

  [theme.breakpoints.up("xl")]: {
    maxHeight: "250px"
  },

  [theme.breakpoints.up("xxl")]: {
    maxHeight: "300px"
  },

  [theme.breakpoints.up("xxxl")]: {
    maxHeight: "460px"
  }
}));

export default OrganizationConnectionsChart;
