import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { setFormValues } from "shared/lib/setFormValues";
import { selectCToken, selectEmployeeData, selectSiteFilter } from "core/selectors";
import { useUsersByIdsMutation } from "features/userManagement/userManagementApiSlice";
import {
  ACTIVITY_TAG,
  useCreateInteractionMutation,
  useUpdateInteractionMutation,
  useDeleteInteractionMutation
} from "features/activities/activitiesApiSlice";
import { INTERACTION_TYPE_INTEREST_GROUP } from "shared/constants";

const defaultValues = {
  name: "",
  description: "",
  sessionDate: "",
  startTime: "",
  endTime: ""
};

export default function useSessionSidebarLogic({ group, session, isEdit, setIsOpen }) {
  /* ------------------ HOOKSs ------------------ */

  const {
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    formState: { isDirty }
  } = useForm({ mode: "onChange", defaultValues });

  /* ------------------ SELECTORs ------------------ */

  const { employeeId } = useSelector(selectEmployeeData);
  const site = useSelector(selectSiteFilter);
  const cToken = useSelector(selectCToken);

  /* ------------------ STATEs ------------------ */

  const [statusList, setStatusList] = useState([]);
  const [rows, setRows] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  /* ------------------ APIs ------------------ */

  const [usersByIds, { isLoading: isLoadingUserByIds }] = useUsersByIdsMutation();

  const [createInteraction, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, error: errorCreate }] =
    useCreateInteractionMutation();
  const [updateInteraction, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate }] =
    useUpdateInteractionMutation();
  const [deleteInteraction, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete, error: errorDelete }] =
    useDeleteInteractionMutation();

  /* ------------------ HANDLERs ------------------ */

  const guestsHandler = async (guests) => {
    if (!guests?.length) return;

    const guestsIds = [];

    let acceptedCount = 0;
    let declinedCount = 0;
    let pendingCount = 0;

    for (let i = 0; i < guests.length; i++) {
      if (guests[i].response === "ACCEPTED") {
        guestsIds.push(guests[i].employeeId);

        acceptedCount = acceptedCount + 1;
      }
      if (guests[i].response === "DECLINED") {
        declinedCount = declinedCount + 1;
      }
      if (guests[i].response === "PENDING") {
        pendingCount = pendingCount + 1;
      }
    }

    setStatusList([
      { value: acceptedCount, label: "Accepted", color: "#108000" },
      { value: declinedCount, label: "Declined", color: "#D52B1E" },
      { value: pendingCount, label: "No response", color: "#00203E" }
    ]);

    try {
      const response = await usersByIds({ ids: guestsIds, cToken }).unwrap();

      const employees = response.map(({ id, firstName, department, lastName }) => ({
        id,
        firstName,
        lastName,
        department
      }));

      setRows(employees);
    } catch (error) {
      console.error("ERROR:: usersByIds ::", { error });
    }
  };

  /* ------------------ MEMOs ------------------ */

  const isNew = useMemo(() => !session?.id, [session, isEdit]);

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (session?.id) {
      const { guests, name, description, startTime, endTime } = session;

      guestsHandler(guests);
      setFormValues({
        data: {
          name,
          description,
          startTime,
          endTime,
          sessionDate: new Date(startTime)
        },
        setValue
      });
    } else {
      resetForm();
    }
  }, [isEdit, session, group]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onSubmit = (values) => {
    if (!isNew && !isEdit) {
      setIsOpen(false);
      return;
    }

    const start = new Date(values.sessionDate);
    start.setHours(new Date(values.startTime).getHours());
    start.setMinutes(new Date(values.startTime).getMinutes());

    const end = new Date(values.sessionDate);
    end.setHours(new Date(values.endTime).getHours());
    end.setMinutes(new Date(values.endTime).getMinutes());

    const formattedValue = {
      ...values,
      startTime: start,
      endTime: end,
      icon: group.icon
    };

    delete formattedValue.sessionDate;

    if (isEdit) {
      updateInteraction({
        id: session.id,
        params: formattedValue,
        type: INTERACTION_TYPE_INTEREST_GROUP,
        tagType: ACTIVITY_TAG.group
      });
    } else {
      createInteraction({
        params: {
          ...formattedValue,
          organizerId: employeeId,
          interestGroupId: group.id,
          siteName: site
        },
        type: INTERACTION_TYPE_INTEREST_GROUP,
        tagType: ACTIVITY_TAG.group
      });
    }
  };

  const onDelete = () => {
    setShowConfirmDialog(true);
  };

  const onApplyDialog = () => {
    deleteInteraction({
      id: session.id,
      type: INTERACTION_TYPE_INTEREST_GROUP,
      tagType: ACTIVITY_TAG.group
    });
  };

  const onCancelDialog = () => {
    setShowConfirmDialog(false);
  };

  const onCopyPrevious = useCallback(() => {
    if (!group?.previous) return;

    setFormValues({
      data: {
        name: group.previous.name,
        description: group.previous.description,
        startTime: group.previous.startTime,
        endTime: group.previous.endTime,
        sessionDate: new Date()
      },
      setValue
    });
  }, [group.previous]);

  return {
    statusList,
    handleSubmit,
    onSubmit,
    isDirty,
    onCopyPrevious,
    control,
    rows,
    onDelete,
    showConfirmDialog,
    onApplyDialog,
    onCancelDialog,
    setShowConfirmDialog,
    isLoading: isLoadingUpdate || isLoadingCreate || isLoadingDelete,
    error: errorUpdate || errorCreate || errorDelete,
    isSuccess: isSuccessUpdate || isSuccessCreate || isSuccessDelete,
    isLoadingUserByIds,
    isNew
  };
}
