import { DAY_TYPES, MOMENT_DATE_FORMAT } from "shared/constants";
import moment from "moment";

export function checkIsWeekend(date, sundayFirst) {
  const SundayIndex = 0;
  const FridayIndex = 5;
  const SaturdayIndex = 6;
  const dayNumber = moment(date).weekday();

  return (sundayFirst ? dayNumber === FridayIndex : dayNumber === SundayIndex) || dayNumber === SaturdayIndex;
}

export function generateWorkdaysSchedule(model, { startDate, endDate, addWeekends, sundayFirst }) {
  let start = moment(startDate);

  const end = moment(endDate);
  const schedule = [];

  while (start <= end) {
    const date = start.format(MOMENT_DATE_FORMAT);

    if (addWeekends || !checkIsWeekend(date, sundayFirst)) {
      schedule.push({ ...model, date });
    }

    start = start.clone().add(1, "d");
  }

  return schedule;
}

export function fillEmployeesSchedules(data, { startDate, endDate, addWeekends, sundayFirst, monthNumber = null }) {
  if (!data?.length) return [];
  const emptySchedule = generateWorkdaysSchedule({ date: "" }, { startDate, endDate, addWeekends, sundayFirst });

  return data.map((employee) => {
    const schedule = [];

    emptySchedule.forEach((scheduleItem) => {
      const momentD = moment(scheduleItem.date);

      const dayData = employee.schedule.find(
        (item) => momentD.format(MOMENT_DATE_FORMAT) === moment(item.date).format(MOMENT_DATE_FORMAT)
      );

      if ((!monthNumber && dayData) || (dayData && momentD.month() + 1 === monthNumber)) {
        schedule.push(dayData);
      } else {
        schedule.push({
          date: momentD.format(MOMENT_DATE_FORMAT),
          dayOfWeek: momentD.format("ddd"),
          weekOfYear: momentD.week(),
          dayType: null,
          hasCheckedIn: false
        });
      }
    });

    const updatedEmployee = {
      ...employee,
      schedule
    };

    return updatedEmployee;
  });
}

export function calculateTotalArrivals(data, { startDate, endDate, addWeekends, sundayFirst, monthNumber }) {
  if (!data?.length) return [];

  const emptySchedule = generateWorkdaysSchedule(
    { date: "", checkInCount: 0, officeDaysCount: 0, activeDate: false },
    { startDate, endDate, addWeekends, sundayFirst }
  );

  emptySchedule.forEach((scheduleItem, index) => {
    const momentD = moment(scheduleItem.date);

    if (!monthNumber || momentD.month() + 1 === monthNumber) {
      data.forEach((employee) => {
        const employeeData = employee.schedule.find(
          (item) => momentD.format(MOMENT_DATE_FORMAT) === moment(item.date).format(MOMENT_DATE_FORMAT)
        );

        if (employeeData && employeeData.dayType) {
          emptySchedule[index].activeDate = true;
        }

        if (employeeData && employeeData.dayType?.toLowerCase() === DAY_TYPES.office) {
          emptySchedule[index].officeDaysCount = emptySchedule[index].officeDaysCount + 1;

          if (employeeData.hasCheckedIn) {
            emptySchedule[index].checkInCount = emptySchedule[index].checkInCount + 1;
          }
        }
      });
    }
  });

  return emptySchedule;
}

export function dataByPagination(data, page, rowsPerPage) {
  if (!data?.length || !rowsPerPage) return data;

  const start = page * rowsPerPage;
  const end = (page + 1) * rowsPerPage - 1;
  const copy = [...data];

  return copy.slice(start, end);
}
