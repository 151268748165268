import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";
import CustomizedSelect from "shared/components/Select/CustomizedSelectDeprecated";

// TODO: Deprecated

const FormInputDropdown = ({
  name,
  control,
  label,
  rules,
  placeholder,
  data,
  styles,
  isMultiple = true,
  defaultValue,
  defaultLabel,
  disabled,
  readonly
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <CustomizedLabel text={label} error={!!error} />
            <CustomizedSelect
              helperText={error ? error.message : null}
              error={!!error}
              handleChange={onChange}
              values={data}
              selectedValue={isMultiple ? value : [value]}
              fullWidth
              placeholder={placeholder}
              styles={styles}
              isMultiple={isMultiple}
              defaultValue={defaultValue}
              defaultLabel={defaultLabel}
              disabled={disabled}
              readonly={readonly}
            />
          </>
        );
      }}
    />
  );
};

FormInputDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  styles: PropTypes.object,
  isMultiple: PropTypes.bool,
  defaultValue: PropTypes.array,
  defaultLabel: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool
};

export default FormInputDropdown;
