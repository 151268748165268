import React from "react";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
} from "chart.js";
import Box from "@mui/material/Box";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { minIndex, minLabel, minValue } from "shared/lib/getters";

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, AnnotationPlugin);

const labels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"];

export const data = {
  labels,
  datasets: [
    {
      type: "bar",
      label: "Connections",
      backgroundColor: "#0099CC",
      data: [10, 12, 14, 12, 12, 16, 14],
      barThickness: 15,
      borderRadius: 32
    },
    {
      type: "line",
      label: "Benchmark",
      borderColor: "#7B61FF",
      borderWidth: 2,
      fill: false,
      data: [11, 15, 14, 10, 13, 15, 17],
      borderDash: [5, 5]
    }
  ]
};

const barOptions = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "start",
      labels: {
        usePointStyle: true,
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          const legend = datasets.map(({ label }) => {
            if (label === "Benchmark") {
              return {
                text: label,
                lineDash: [3, 3],
                lineWidth: 2,
                pointStyle: "dash",
                strokeStyle: "#7B61FF"
              };
            } else {
              return {
                text: label,
                fillStyle: "#0099CC"
              };
            }
          });
          return legend;
        }
      }
    },
    datalabels: {
      display: false
    },
    annotation: {
      annotations: [
        {
          type: "label",
          backgroundColor: "#33CCFF",
          borderRadius: 6,
          color: "white",
          callout: {
            enabled: true,
            borderColor: "#E2E2E2"
          },
          content: (ctx) => `Onboarded: ${minLabel(ctx)}, ${minValue(ctx)}`,
          font: {
            size: 14
          },
          position: {
            x: (ctx) => (minIndex(ctx) <= 3 ? "start" : minIndex(ctx) >= 10 ? "end" : "center"),
            y: "center"
          },
          xAdjust: (ctx) => (minIndex(ctx) <= 3 ? 20 : minIndex(ctx) >= 10 ? -20 : 0),
          xValue: (ctx) => minLabel(ctx),
          yAdjust: -80,
          yValue: (ctx) => minValue(ctx)
        }
      ]
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      min: 5,
      max: 30,
      grid: {
        display: false
      },
      ticks: {
        stepSize: 5
      }
    }
  }
};

const OnboardingChart = () => {
  return (
    <Box py={2}>
      <Chart type="bar" data={data} options={barOptions} />
    </Box>
  );
};

export default OnboardingChart;
