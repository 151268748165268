import BuildingPlan12 from "shared/assets/images/shibolet-12.svg";
import BuildingPlan13 from "shared/assets/images/shibolet-13.svg";
import BuildingPlan14 from "shared/assets/images/shibolet-14.svg";
import BuildingPlan15 from "shared/assets/images/shibolet-15.svg";
import BuildingPlan16 from "shared/assets/images/shibolet-16.svg";
import BuildingPlan17 from "shared/assets/images/shibolet-17.svg";
import BuildingPlan18 from "shared/assets/images/shibolet-18.svg";

export const GOOGLE_CAPTCHA_KEY = "6LcjgrUiAAAAAK6bRIEJRAnwfVTHKZCX7yFn4A6q";

/**
 * @type {RegExp}
 * http://emailregex.com/
 */
export const RGX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export const RGX_FILENAME = /filename="(?<filename>.*)"/;

/**
 * @type {String}
 */
export const FACILITY_MANAGER = "Facility manager";

/**
 * @type {String}
 */
export const HR_MANAGER = "HR manager";

/**
 * @type {String}
 */
export const COMPANY_GENERAL_MANAGEMENT = "Company general management";

/**
 * @type {String}
 */
export const USER_MANAGEMENT_TABLE_NAME = "User management";

/**
 * @type {String}
 */
export const WORKSTATION_TABLE_NAME = "Workstations";

/**
 * @type {String}
 */
export const STORAGE_USER_INFO = "USER_INFO";

/**
 * @type {String}
 */
export const STORAGE_COMPANY_SETTINGS = "COMPANY_SETTINGS";

/**
 * @type {String}
 */
export const STORAGE_SITE_AND_BUILDING = "SITE_AND_BUILDING_FILTERS";

/**
 * @type {String}
 */
export const STORAGE_FLOORS_FILTERS = "FLOORS_FILTERS";

/**
 * @type {String}
 */
export const STORAGE_ROLE = "ROLE";

/**
 * @type {String}
 */
export const STORAGE_NAVIGATION_RESOURCES = "NAVIGATION_RESOURCES";

/**
 * @type {String}
 */
export const STORAGE_SELECTED_TEAM_MEMBER = "SELECTED_TEAM_MEMBER_ID";

/**
 * @type {String}
 */
export const OVERLAP_WITH_MANAGER = "Overlap With Manager";

/**
 * @type {String}
 */
export const GENDER_INCLUSION = "Gender Inclusion";

/**
 * @type {String}
 */
export const TEAM_MEMBER_OVERLAP = "Team Member Overlap";

/**
 * @type {String}
 */
export const ORGANIZATION_CONNECTIONS = "Organization Connections";

/**
 * @type {String}
 */
export const AVERAGE_DAYS_PER_WEEK = "Average Days Per Week";

/**
 * @type {String}
 */
export const SUSTAINABILITY = "Sustainability";

/**
 * @type {Array}
 */
export const CHART_DAY_LABELS = ["MON", "TUE", "WED", "THU", "FRI"];

/**
 * @type {Number}
 */
export const AUTO_PLAY_INTERVAL = 5000;

/**
 * @type {String}
 */
export const NEIGHBORHOODS_PAGE_NAME = "Neighborhoods";

/**
 * @type {String}
 */
export const INTERACTION_TYPE_SITE_EVENT = "SITE_EVENT";

/**
 * @type {String}
 */
export const INTERACTION_TYPE_INTEREST_GROUP = "INTEREST_GROUP";

/**
 * @type {Number}
 */
export const WORK_DAYS_COUNT = 5;

/**
 * @type {Number} ms
 */
export const POLING_INTERVAL = 30000;

export const BUILDING_PLANS = [
  { floor: 12, plan: BuildingPlan12 },
  { floor: 13, plan: BuildingPlan13 },
  { floor: 14, plan: BuildingPlan14 },
  { floor: 15, plan: BuildingPlan15 },
  { floor: 16, plan: BuildingPlan16 },
  { floor: 17, plan: BuildingPlan17 },
  { floor: 18, plan: BuildingPlan18 }
];

/**
 * @type {String} date
 */
export const MOMENT_DATE_FORMAT = "YYYY-MM-DD";

/**
 * @type {String} url
 */
export const URL_CONGRATULATION_CONFIRM_PLAN = "https://www.bwith.ai/thanks-for-confirming";

export const EMPLOYEE_STATUS = {
  suspended: "SUSPENDED"
};

export const DAY_TYPES = {
  home: "home",
  office: "office",
  off: "off",
  sick: "sick",
  travel: "travel",
  nonWorking: "non-working"
};

export const EXPORT_ATTENDANCE_TYPE = {
  daysPerWeek: "daysPerWeek",
  employeesPerDay: "employeesPerDay"
};
