import React, { memo, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Box, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { WORK_DAYS_COUNT } from "shared/constants";
import { getDayColor, getDayOffSign } from "../utils/previousWeeksGetters";

const EMPTY_WEEK_DAY = {
  date: "",
  dayOfWeek: "",
  dayType: "",
  hasCheckedIn: false,
  weekOfYear: null
};

const styles = {
  getWeekStyle: (weeksCount) => ({
    width: `${100 / weeksCount}%`
  }),
  getDayStyle: (daysCount, dayType, hasCheckedIn) => ({
    width: `${100 / daysCount}%`,
    backgroundColor: getDayColor(dayType, hasCheckedIn)
  })
};

const AttendanceWeeks = ({ schedule, numberOfWeeks }) => {
  const [weeks, setWeeks] = useState([]);

  const parseScheduleHandler = () => {
    if (!schedule?.length) return [];

    const parsedWeeks = [];
    const copy = [...schedule];
    const weekNumbers = [];

    for (let i = 0; i < schedule.length; i++) {
      const weekNum = schedule[i].weekOfYear;

      if (weekNum && !weekNumbers.includes(weekNum)) {
        weekNumbers.push(weekNum);
      }
    }

    weekNumbers.forEach((weekNum) => {
      let weekDays = copy?.filter((day) => day.weekOfYear === weekNum);

      if (weekDays.length < WORK_DAYS_COUNT) {
        const len = WORK_DAYS_COUNT - weekDays.length;
        const emptyDays = new Array(len).fill(EMPTY_WEEK_DAY);

        weekDays = [...weekDays, ...emptyDays];
      }

      parsedWeeks.push(weekDays);
    });

    setWeeks(parsedWeeks);
  };

  useEffect(() => {
    if (!schedule) return null;

    parseScheduleHandler();
  }, [schedule, numberOfWeeks]);

  const renderWeekItem = (week, index) => {
    return (
      <Week key={index} style={styles.getWeekStyle(weeks.length)}>
        {week.map((day, index) => (
          <Day key={index} style={styles.getDayStyle(week.length, day.dayType, day.hasCheckedIn)}>
            <DayTypography>{getDayOffSign(day.dayType)}</DayTypography>
          </Day>
        ))}
      </Week>
    );
  };

  return <Container>{weeks.map((week, index) => renderWeekItem(week, index))}</Container>;
};

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Week = styled(Box)`
  display: flex;
  overflow: hidden;
`;

const Day = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: theme.spacing(8),
  border: "1px solid #E3E5E8",
  position: "relative",
  overflow: "hidden"
}));

const DayTypography = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

AttendanceWeeks.propTypes = {
  schedule: PropTypes.array,
  numberOfWeeks: PropTypes.number,
  userStatus: PropTypes.string
};

export default memo(AttendanceWeeks);
