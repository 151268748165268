import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { selectCurrentWorkstationsStatus } from "core/selectors";
import styled from "@mui/material/styles/styled";

const WorkstationInfo = () => {
  const { available, arrived, reserved, vacant } = useSelector(selectCurrentWorkstationsStatus);

  return (
    <Box py={1}>
      <Box textAlign="center">
        <BoldTypography variant="h4" color="#1ED400">
          {available}
        </BoldTypography>
        <Typography variant="body2" textTransform="uppercase" color="#1ED400">
          Available workstations
        </Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Grid container display="flex" justifyContent="space-evenly" alignItems="center" textAlign="center">
        <Grid item>
          <Typography variant="h4" color="#108000">
            {arrived}
          </Typography>
          <Typography variant="body2" textTransform="uppercase" color="#8D9096">
            In use
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" color="#007399">
            {reserved}
          </Typography>
          <Typography variant="body2" textTransform="uppercase" color="#8D9096">
            Reserved
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" color="#6B6C72">
            {vacant}
          </Typography>
          <Typography variant="body2" textTransform="uppercase" color="#8D9096">
            Vacant
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const BoldTypography = styled(Typography)`
  font-weight: bold;
`;

export default WorkstationInfo;
