import React from "react";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";

const CustomizedLabel = ({ variant = "body1", text, error }) => {
  return (
    <Label variant={variant} error={error}>
      {text}
    </Label>
  );
};

const Label = styled(Typography)(({ theme, error }) => ({
  color: error ? "#d32f2f" : "#00203E",
  margin: theme.spacing(1, 0, 0.5, 0)
}));

CustomizedLabel.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string.isRequired,
  error: PropTypes.bool
};

export default CustomizedLabel;
