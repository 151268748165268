import React from "react";
import WarningIcon from "shared/assets/icons/warning.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Overlap = () => {
  return (
    <Box py={2}>
      <Box display="flex" justifyContent="center" mb={2}>
        <img src={WarningIcon} />
        <Typography variant="h6" fontWeight="700" pl={1}>
          Low Overlap
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" pb={1}>
          Over the past 3 months, chet has had lower than average overlap with people important to him.
        </Typography>
        <Typography variant="h6">
          He has had only <strong>2 overlapping days in the past month with 35%</strong> of his coworkers and friends.
        </Typography>
      </Box>
    </Box>
  );
};

export default Overlap;
