import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CoffeeIcon from "shared/assets/icons/coffee.svg";

const LifeInteractions = () => {
  return (
    <Box py={2}>
      <Box display="flex" justifyContent="center" mb={2}>
        <img src={CoffeeIcon} />
        <Typography variant="h6" fontWeight="700" pl={1}>
          Real Life Interactions
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" pb={1}>
          Chet works from the office an average of 2.3 days per week, similar to the company benchmark.
        </Typography>
        <Typography variant="h6">
          Chet makes good use of office time for <strong>interacting with friends and coworkers in person</strong>, with
          many impromptu meetings such as lunch, coffee, and team activities.
        </Typography>
      </Box>
    </Box>
  );
};

export default LifeInteractions;
