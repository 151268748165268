import React from "react";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
} from "chart.js";
import Box from "@mui/material/Box";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { getAverageValue } from "shared/lib/getters";

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, AnnotationPlugin);

const labels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"];

const getBarBackgroundColors = (data) => {
  const result = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i] < 7) {
      result.push("#FFAD00");
    } else {
      result.push("#0099CC");
    }
  }

  return result;
};

const chartData = [9, 7, 7, 6, 9, 5, 8];

export const data = {
  labels,
  datasets: [
    {
      type: "bar",
      label: "Connections",
      backgroundColor: getBarBackgroundColors(chartData),
      data: [9, 7, 7, 6, 9, 7, 8],
      barThickness: 15,
      borderRadius: 32
    }
  ]
};

const barOptions = {
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    },
    annotation: {
      annotations: [
        {
          type: "line",
          borderColor: "#7B61FF",
          borderDash: [6, 6],
          borderDashOffset: 0,
          borderWidth: 3,
          label: {
            enabled: true,
            content: (ctx) => "Company average: " + getAverageValue(ctx).toFixed(1),
            position: "end",
            xAdjust: -10,
            backgroundColor: "#33CCFF",
            color: "white"
          },
          scaleID: "y",
          value: (ctx) => getAverageValue(ctx)
        }
      ]
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      min: 4,
      grid: {
        display: false
      },
      ticks: {
        stepSize: 2
      }
    }
  }
};

const CoworkersOverlapChart = () => {
  return (
    <Box py={2} sx={{ width: "auto", margin: "0 auto" }}>
      <Chart type="bar" data={data} options={barOptions} />
    </Box>
  );
};

export default CoworkersOverlapChart;
