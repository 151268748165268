import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import WorkstationSidebar from "shared/components/WorkstationSidebar/WorkstationSidebar";

const WorkstationPage = ({ history, isDrawer, isOpen, setIsOpen, isEdit, workstationId, roomName }) => {
  const headerText = isEdit ? "Edit Workstation" : "Add Workstation";
  const contentText = isEdit ? "Edit workstation" : "Add new workstation";

  return (
    <WorkstationSidebar
      history={history}
      isDrawer={isDrawer}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      headerText={headerText}
      contentText={contentText}
      isEdit={isEdit}
      workstationId={workstationId}
      currentRoomName={roomName}
    />
  );
};

WorkstationPage.propTypes = {
  history: PropTypes.object,
  isDrawer: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isEdit: PropTypes.bool,
  workstationId: PropTypes.number,
  roomName: PropTypes.string
};

export default withRouter(WorkstationPage);
