import React, { useEffect, useState } from "react";
import FilterIcon from "shared/assets/icons/filter.svg";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Badge } from "@mui/material";
import styled from "@mui/material/styles/styled";
import FilterButton from "../FilterButton/FilterButton";
import { selectUserManagementAppliedFiltersCount, selectWorkstationAppliedFiltersCount } from "core/selectors";
import { USER_MANAGEMENT_TABLE_NAME, WORKSTATION_TABLE_NAME } from "shared/constants";

const Filter = ({ onClick, pageName }) => {
  const [filterCount, setFilterCount] = useState(0);
  const userManagementFilterCount = useSelector(selectUserManagementAppliedFiltersCount);
  const workstationFilterCount = useSelector(selectWorkstationAppliedFiltersCount);

  useEffect(() => {
    if (pageName === WORKSTATION_TABLE_NAME) {
      setFilterCount(workstationFilterCount);
    }

    if (pageName === USER_MANAGEMENT_TABLE_NAME) {
      setFilterCount(userManagementFilterCount);
    }
  }, [pageName, workstationFilterCount, userManagementFilterCount]);

  return (
    <FilterBadge color="secondary" badgeContent={filterCount}>
      <FilterButton onClick={onClick}>
        <img src={FilterIcon} alt="Filter icon" />
        <Typography ml={1}>Filter</Typography>
      </FilterButton>
    </FilterBadge>
  );
};

const FilterBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: theme.spacing(0.6)
  }
}));

Filter.propTypes = {
  onClick: PropTypes.func,
  pageName: PropTypes.string
};

export default Filter;
