import { useEffect, useState } from "react";
import { EFileTypes } from "shared/constants/optionsConstants";
import { useForm } from "react-hook-form";

export default function useExportFileSidebarLogic({ startDate, endDate, handleExportHistory, handleSingleDayExport }) {
  /* ------------------ HOOKs ------------------ */

  const { control, watch, setValue } = useForm({
    defaultValues: {
      singleDate: new Date()
    }
  });

  /* ------------------ STATEs ------------------ */

  const [selectedFileType, setSelected] = useState([EFileTypes[0].value]);

  const [fromDate, toDate, singleDate] = watch(["startDate", "endDate", "singleDate"]); // TODO: add singleDate

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!startDate || !endDate) return;

    setValue("startDate", new Date(startDate));
    setValue("endDate", new Date(endDate));
  }, [startDate, endDate]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onSelectedFileType = (e) => {
    setSelected([e.target.value]);
  };

  const onExportHistory = (type) => () => {
    handleExportHistory && handleExportHistory(type, { fileType: selectedFileType, fromDate, toDate });
  };

  const onSingleDayExport = () => {
    handleSingleDayExport && handleSingleDayExport({ fileType: selectedFileType, date: singleDate });
  };

  return {
    selectedFileType,
    onSelectedFileType,
    onExportHistory,
    onSingleDayExport,
    control
  };
}
