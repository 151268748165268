import { useCallback, useEffect, useState } from "react";
import { useUsersByIdsMutation } from "features/userManagement/userManagementApiSlice";
import { useGroupsSessionsByIdsMutation } from "features/activities/activitiesApiSlice";
import { useSelector } from "react-redux";
import { selectCToken } from "core/selectors";

const MAX_CHART_DEPARTMENTS_COUNT = 10;
export const fromDate = new Date().toISOString();
const groupD = new Date(fromDate);
export const groupsToDate = new Date(groupD.setDate(groupD.getDate() + 14)).toISOString();

export default function useViewGroupSidebarLogic({
  setIsOpen,
  group,
  onEditGroup,
  onEditSession,
  onViewSession,
  onAddSession
}) {
  const cToken = useSelector(selectCToken);

  const [rows, setRows] = useState([]);
  const [activeTeams, setActiveTeams] = useState({
    labels: [],
    datasets: []
  });
  const [sessions, setSessions] = useState({});

  const [usersByIds, { isLoading }] = useUsersByIdsMutation();
  const [groupsSessionsByIds] = useGroupsSessionsByIdsMutation();

  const setActiveTeamsHandler = (employees) => {
    if (!employees?.length) return;

    let res;
    const total = [];

    for (let i = 0; i < employees.length; i++) {
      const index = total.findIndex((item) => item.label === employees[i].department);

      if (index === -1) {
        total.push({
          label: employees[i].department,
          data: 1
        });
      } else {
        total[index].data = total[index].data + 1;
      }
    }

    if (total?.length > MAX_CHART_DEPARTMENTS_COUNT) {
      res = total.slice(0, MAX_CHART_DEPARTMENTS_COUNT + 1);
    } else {
      const lengthDiff = MAX_CHART_DEPARTMENTS_COUNT - total.length;
      const mockArr = Array(lengthDiff).fill({
        label: "",
        data: 0
      });

      res = total.concat(mockArr);
    }

    const sorted = res.sort((a, b) => (a.data < b.data ? 1 : b.data < a.data ? -1 : 0));
    const labels = sorted.map((item) => item.label);
    const datasetItem = {
      data: sorted.map((item) => item.data),
      backgroundColor: ["#66D9FF", "#33CCFF", "#0099CC", "#007399"],
      borderWidth: 1,
      barThickness: 10,
      borderRadius: 32
    };

    const data = {
      labels,
      datasets: [datasetItem]
    };

    setActiveTeams(data);
  };

  const membersHandler = useCallback(
    async (group) => {
      if (!group?.employeeIds?.length) return;

      try {
        const response = await usersByIds({ ids: group.employeeIds, cToken }).unwrap();
        const employees = response.map(({ id, firstName, department, lastName }) => ({
          id,
          firstName,
          lastName,
          department
        }));

        setRows(employees);
        setActiveTeamsHandler(employees);
      } catch (error) {
        console.error("ERROR:: usersByIds ::", { error });
      }
    },
    [cToken]
  );

  const groupsSessionHandler = useCallback(async () => {
    if (!group?.id) return;

    try {
      const response = await groupsSessionsByIds({
        interestGroupIds: [group.id],
        fromStartTime: fromDate,
        toStartTime: groupsToDate
      }).unwrap();

      const sorted = response?.data?.length
        ? [...response.data].sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
        : [];

      setSessions(sorted);
    } catch (error) {
      console.error("ERROR: groupsSessionHandler ::", { error });
    }
  }, [group]);

  useEffect(() => {
    membersHandler(group);
    groupsSessionHandler();
  }, [group]);

  const onViewSessionClick = (session) => {
    if (!session?.id) return;

    onViewSession && onViewSession(session);
    setIsOpen(false);
  };

  const onEditSessionClick = (session) => {
    if (!session?.id) return;

    onEditSession && onEditSession(session);
    setIsOpen(false);
  };

  const onAddSessionClick = () => {
    if (!group) return;

    onAddSession && onAddSession(group);
    setIsOpen(false);
  };

  const onEdit = () => {
    onEditGroup(group);
    setIsOpen(false);
  };

  return {
    rows,
    activeTeams,
    isLoading,
    onEditSessionClick,
    onViewSessionClick,
    onAddSessionClick,
    onEdit,
    sessions
  };
}
