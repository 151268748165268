import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";

const FormInputRadio = ({ name, control, label, rules, data }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <CustomizedLabel text={label} error={!!error} />
          <RadioGroup row name="row-radio-buttons-group" sx={{ flexWrap: "nowrap" }} defaultValue={value}>
            {data.map(({ value, radioLabel }) => (
              <React.Fragment key={value}>
                <FormControlLabel
                  value={value}
                  onChange={onChange}
                  control={<Radio name={name} color="success" />}
                  label={radioLabel}
                  sx={{ marginRight: "12px" }}
                />
              </React.Fragment>
            ))}
          </RadioGroup>
        </>
      )}
    />
  );
};

FormInputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  data: PropTypes.array
};

export default FormInputRadio;
