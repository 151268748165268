import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FlexibilityIcon from "shared/assets/icons/flexibility.png";
import Grid from "@mui/material/Grid";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement
} from "chart.js";
import { CHART_DAY_LABELS } from "shared/constants";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement);

export const barData = {
  labels: CHART_DAY_LABELS,
  datasets: [
    {
      label: "Office",
      data: [16, 16, 18, 14, 12],
      backgroundColor: "#0099CC",
      stack: "Stack 0",
      barThickness: 10,
      borderRadius: 32
    }
  ]
};

const barOptions = {
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false
      }
    }
  },
  maintainAspectRatio: false
};

const Flexibility = () => {
  return (
    <Box py={2}>
      <Box display="flex" justifyContent="center" mb={2}>
        <img src={FlexibilityIcon} />
        <Typography variant="h6" fontWeight="700" pl={1}>
          Good Flexibility
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography variant="h6">
            Chet prefers Tuesdays and Thursdays, but also comes in on other days, making use of hybrid work flexibility.
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Box sx={{ height: "158px", width: "auto", margin: "0 auto" }}>
            <Bar data={barData} options={barOptions} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Flexibility;
