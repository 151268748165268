import { ROLE_HR, ROLE_TEAM_MANAGER } from "shared/constants";

export const getNavigationDrawlerBackground = (role) => {
  switch (role) {
    case ROLE_HR:
    case ROLE_TEAM_MANAGER:
      return "#013231";

    default:
      return "#00203E";
  }
};
