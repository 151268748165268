import React, { useCallback } from "react";
import PropTypes from "prop-types";
import DataTable from "shared/components/DataTable/DataTable";
import TotalArrivalsFooter from "../TotalArrivalsFooter";
import { calculateTotalArrivals } from "../../helpers";
import { Container, styles } from "./style/currentWeekTabStyle";
import useCurrentWeekTabLogic, { START_DATE, END_DATE } from "./hooks/useCurrentWeekTabLogic";

const CurrentWeekTab = (props) => {
  const { rows, columns, isLoading } = useCurrentWeekTabLogic(props);

  const renderFooterComponent = useCallback(
    (page, rowsPerPage) => {
      // const paginatedRows = dataByPagination(getState(), page, rowsPerPage); // TODO: Use if need pagination

      const arrivalsData = calculateTotalArrivals(rows, {
        startDate: START_DATE,
        endDate: END_DATE,
        sundayFirst: true,
        addWeekends: false
      });

      return <TotalArrivalsFooter title="Total actual arrivals" titleWidth={"510px"} arrivalsData={arrivalsData} />;
    },
    [rows, START_DATE, END_DATE]
  );

  return (
    <Container>
      <DataTable
        rows={rows}
        rowIdFieldName={"employeeId"}
        columns={columns}
        showDeleteColumn={false}
        showEditColumn={false}
        showInfoColumn={false}
        showSiteAndBuilding
        showFloorSelector
        showDepartmentSelector
        showSearch
        autoHeight={false}
        isLoading={isLoading}
        sx={styles.tableStyle}
        renderFooterComponent={renderFooterComponent}
        // onTableStateChange={onTableStateChange} // TODO: Use if need pagination
      />
    </Container>
  );
};

CurrentWeekTab.propTypes = {
  setSummary: PropTypes.func
};

export default CurrentWeekTab;
