import React from "react";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import { Box, Divider, Stack, Typography, Paper } from "@mui/material";
import { isValidFunction } from "shared/lib/validation";
import OutlinedPlusIcon from "shared/assets/icons/outlined-plus.svg";
import { activitiesIconsMap } from "pages/ActivitiesPage/utils/activitiesUtils";
import { format } from "date-fns";

const ActivitiesGroupItem = ({ group, onGroupClick, onAddSessionClick }) => {
  const { icon, name, employeeIds = [], active } = group;

  const onGroupItemClick = () => {
    if (isValidFunction(onGroupClick)) {
      onGroupClick(group);
    }
  };

  const onAddClick = (e) => {
    e.stopPropagation();
    if (isValidFunction(onAddSessionClick)) {
      onAddSessionClick(group);
    }
  };

  const renderIcon = () => {
    const iconName = icon || "yoga.svg";

    return <GroupIcon src={activitiesIconsMap[iconName]} />;
  };

  const renderDate = (label, date) => {
    const dateString = date ? format(new Date(date), "MMM d") : "Not set";

    return (
      <Box textAlign="center">
        <CustomizedH5Typography variant="h6" color={!active && "#6B6C72"}>
          {dateString}
        </CustomizedH5Typography>
        <CustomizedTypography variant="h6">{label}</CustomizedTypography>
      </Box>
    );
  };

  return (
    <GroupItemContainer elevation={0} onClick={onGroupItemClick} sx={{ height: "100%" }}>
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" py={2} position="relative">
        {renderIcon()}
        <Typography variant="h6" color={!active && "#6B6C72"} sx={{ textAlign: "center" }}>
          {name} {!active && "(inactive)"}
        </Typography>
        <AddButton onClick={onAddClick}>
          <img src={OutlinedPlusIcon} alt="Add" />
        </AddButton>
      </Box>
      <Divider />
      <Box py={2}>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          <Box textAlign="center">
            <Typography variant="h6" color={!active && "#6B6C72"}>
              {employeeIds.length}
            </Typography>
            <CustomizedTypography variant="h6">Members</CustomizedTypography>
          </Box>
          <Box textAlign="center">
            <Typography variant="h6" color={!active && "#6B6C72"}>
              {group?.total ? group.total : 0}
            </Typography>
            <CustomizedTypography variant="h6">Meetings</CustomizedTypography>
          </Box>
        </Stack>
      </Box>
      {/* TODO: later
      <Box py={2} textAlign="center">
        <CustomizedH5Typography color={!active && "#6B6C72"}>{topTeams}</CustomizedH5Typography>
        <CustomizedTypography variant="h6">Top teams</CustomizedTypography>
      </Box> */}
      <Box py={2}>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          {renderDate("Prev. Date", group?.previous?.startTime)}
          {renderDate("Next Date", group?.next?.startTime)}
        </Stack>
      </Box>
    </GroupItemContainer>
  );
};

const GroupItemContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: "#F7F7F7",
  border: "1px solid #E3E5E8",
  boxShadow: "0px 2px 2px rgba(9, 62, 12, 0.2)",
  borderRadius: theme.spacing(1),
  "&:hover": {
    background: "rgba(1, 50, 49, 0.2)",
    cursor: "pointer",
    transition: "background-color 250ms linear"
  }
}));

const CustomizedTypography = styled(Typography)(({ isActive }) => ({
  color: isActive ? "#8D9096" : "#6B6C72"
}));

const AddButton = styled(Box)(() => ({
  position: "absolute",
  top: "8px",
  right: "8px",

  "& > img": {
    "&:hover": {
      borderRadius: "50%",
      backgroundColor: "#ff9800",
      transition: "background-color 250ms linear"
    }
  }
}));

const GroupIcon = styled("img")(({ theme }) => ({
  width: "30px",
  height: "30px"
}));

const CustomizedH5Typography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  fontSize: "1.2rem !important",
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "auto",
  whiteSpace: "nowrap"
}));

ActivitiesGroupItem.propTypes = {
  group: PropTypes.object,
  onGroupClick: PropTypes.func,
  onAddSessionClick: PropTypes.func
};

export default React.memo(ActivitiesGroupItem);
