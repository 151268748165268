import React from "react";
import {
  TooltipContainer,
  TooltipTitleText,
  TooltipList,
  TooltipListItem,
  TooltipItemText
} from "./style/tooltipTextStyle";
import PropTypes from "prop-types";
import { isValidArray } from "shared/lib/validation";

const TooltipText = ({ title, message }) => (
  <TooltipContainer>
    {title ? <TooltipTitleText>{title}</TooltipTitleText> : null}

    {isValidArray(message) ? (
      <TooltipList>
        {message.map((item, index) => (
          <TooltipListItem key={index}>
            <TooltipItemText>{item}</TooltipItemText>
          </TooltipListItem>
        ))}
      </TooltipList>
    ) : (
      <TooltipItemText>{message}</TooltipItemText>
    )}
  </TooltipContainer>
);

TooltipText.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default React.memo(TooltipText);
