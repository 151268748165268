import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  paddingBottom: theme.spacing(3)
}));

export const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

export const styles = {
  tableStyle: {
    "& .work-days-table-header > div > div > div": {
      width: "100%",
      justifyContent: "space-between"
    }
  }
};
