export const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

export const getCenter = (p1, p2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2
  };
};

// export function parentByClassName(el, className) {
//   if (!el || !className) return;

//   if (el?.className?.includes(className)) return el;

//   return parentByClassName(el.parentElement, className);
// }

// export function childByClassName(elList, className) {
//   if (!elList?.length || !className) return;

//   let child;

//   for (const key in elList) {
//     const elClassNameList = elList[key]?.className;

//     if (elClassNameList && elClassNameList?.includes(className)) child = elList[key];
//   }

//   return child;
// }
