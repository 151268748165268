import {
  ROUTE_LIVE_VIEW,
  ROUTE_USER_MANAGEMENT,
  ROUTE_WORKSTATIONS,
  ROUTE_NEIGHBORHOODS,
  ROUTE_TEAM,
  ROUTE_INDIVIDUAL,
  ROUTE_ACTIVITIES,
  ROUTE_ATTENDANCE,
  ROUTE_SETTINGS,
  RESOURCES_NAMES
} from "./routerConstants";

/**
 * To hide element set {hide: true} and go to `routes.js` and set enable value for exact route {..., enabled: false}
 */
export const navigationMap = {
  [RESOURCES_NAMES.liveView]: { name: "Live View", route: `/${ROUTE_LIVE_VIEW}`, hide: true, badge: false, order: 1 },
  [RESOURCES_NAMES.workstations]: {
    name: "Workstations",
    route: `/${ROUTE_WORKSTATIONS}`,
    hide: false,
    badge: false,
    order: 2
  },
  [RESOURCES_NAMES.users]: {
    name: "User management",
    route: `/${ROUTE_USER_MANAGEMENT}`,
    hide: false,
    badge: false,
    order: 3
  },
  [RESOURCES_NAMES.neighborhoods]: {
    name: "Neighborhoods",
    route: `/${ROUTE_NEIGHBORHOODS}`,
    hide: false,
    badge: false,
    order: 4
  },
  [RESOURCES_NAMES.attendance]: {
    name: "Attendance",
    route: `/${ROUTE_ATTENDANCE}`,
    hide: false,
    badge: false,
    order: 5
  },
  [RESOURCES_NAMES.activities]: {
    name: "Activities",
    route: `/${ROUTE_ACTIVITIES}`,
    hide: false,
    badge: false,
    order: 6
  },
  [RESOURCES_NAMES.individual]: {
    name: "Individual",
    route: `/${ROUTE_INDIVIDUAL}`,
    hide: true,
    badge: false,
    order: 7
  },
  [RESOURCES_NAMES.team]: { name: "Team", route: `/${ROUTE_TEAM}`, hide: true, badge: false, order: 8 },
  [RESOURCES_NAMES.adminSettings]: {
    route: ROUTE_SETTINGS,
    name: "Admin Settings",
    hide: false,
    badge: false,
    order: null
  }
};
