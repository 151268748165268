import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import { selectCurrentSiteAndBuildingFiltersText } from "core/selectors";
import FilterButton from "../FilterButton/FilterButton";

const SiteAndBuilding = ({ onClick, title = "Site and Building" }) => {
  const currentFilter = useSelector(selectCurrentSiteAndBuildingFiltersText);

  return (
    <FilterButton onClick={onClick}>
      <Typography>{currentFilter || title}</Typography>
      <ArrowDropDownIcon color="#000" sx={{ marginLeft: "8px" }} />
    </FilterButton>
  );
};

SiteAndBuilding.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default SiteAndBuilding;
