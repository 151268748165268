import React, { useState } from "react";
import Tab from "@mui/material//Tab";
import Tabs from "@mui/material//Tabs";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";
import Overlap from "../individualInsightsTabs/Overlap/Overlap";
import Flexibility from "../individualInsightsTabs/Flexibility/Flexibility";
import LifeInteractions from "../individualInsightsTabs/LifeInteractions/LifeInteractions";
import { AUTO_PLAY_INTERVAL } from "shared/constants";
import Illustration from "../InsightsTabs/Illustration/Illustration";
import Tags from "../InsightsTabs/Tags/Tags";
import Chart from "../InsightsTabs/Chart/Chart";
import AlertIcon from "shared/assets/icons/alert.svg";
import InsightIcon from "shared/assets/icons/insight.svg";

const warningSteps = [Overlap, Flexibility, LifeInteractions];
const insightsSteps = [Illustration, Tags, Chart];

const AlertsAndInsightsCarousel = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (e, tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex" }}>
      <Tabs
        orientation="vertical"
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="secondary"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab icon={<img src={AlertIcon} alt="Alert" />} sx={{ minWidth: "50px" }} />
        <Tab icon={<img src={InsightIcon} alt="Insight" />} sx={{ minWidth: "50px" }} />
      </Tabs>
      {selectedTab === 0 ? (
        <Carousel interval={AUTO_PLAY_INTERVAL} animation="slide" height="223px" sx={{ flex: 2, padding: "8px" }}>
          {warningSteps.map((item, index) => {
            const Tab = item;
            return (
              <React.Fragment key={index}>
                <Tab />
              </React.Fragment>
            );
          })}
        </Carousel>
      ) : (
        <Carousel interval={AUTO_PLAY_INTERVAL} animation="slide" height="223px" sx={{ flex: 2, padding: "8px" }}>
          {insightsSteps.map((item, index) => {
            const Tab = item;
            return (
              <React.Fragment key={index}>
                <Tab />
              </React.Fragment>
            );
          })}
        </Carousel>
      )}
    </Box>
  );
};

export default AlertsAndInsightsCarousel;
