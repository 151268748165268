import { apiSlice } from "core/api/apiSlice";

export const siteApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSiteInfo: build.query({
      query: ({ site, cToken }) => ({
        url: "/seats/getSiteInfo",
        method: "GET",
        params: { cToken, site }
      })
    })
  })
});

export const { useGetSiteInfoQuery } = siteApiSlice;
