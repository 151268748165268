import {
  ROUTE_LIVE_VIEW,
  ROUTE_SETTINGS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_WORKSTATIONS,
  ROUTE_NEIGHBORHOODS,
  ROUTE_TEAM,
  ROUTE_INDIVIDUAL,
  ROUTE_ATTENDANCE,
  ROUTE_ACTIVITIES
} from "shared/constants";
import UserManagementListingPage from "pages/UserManagementListingPage/UserManagementListingPage";
import WorkstationListingPage from "pages/WorkstationListingPage/WorkstationListingPage";
import AdminSettingsPage from "pages/AdminSettingsPage";
import NeighborhoodsListingPage from "pages/NeighborhoodsListingPage/NeighborhoodsListingPage";
import NeighborhoodPage from "pages/NeighborhoodPage/NeighborhoodPage";
import TeamPage from "pages/TeamPage/TeamPage";
import IndividualPage from "pages/IndividualPage/IndividualPage";
import ActivitiesPage from "pages/ActivitiesPage";
import LiveViewPage from "pages/LiveViewPage/LiveViewPage";
import TeamInsightsSidebars from "shared/components/TeamInsightsSidebars/TeamInsightsSidebars";
import WorkstationMapView from "pages/WorkstationMapView/WorkstationMapView";
import Attendance from "pages/Attendance";

export const APP_ROUTES = [
  {
    path: `/${ROUTE_LIVE_VIEW}`,
    exact: true,
    component: LiveViewPage,
    enabled: false
  },
  {
    path: `/${ROUTE_USER_MANAGEMENT}`,
    exact: true,
    component: UserManagementListingPage,
    enabled: true
  },
  {
    path: `/${ROUTE_NEIGHBORHOODS}`,
    exact: true,
    component: NeighborhoodsListingPage,
    enabled: true
  },
  {
    path: `/${ROUTE_NEIGHBORHOODS}/:neighborhoodName`,
    exact: true,
    component: NeighborhoodsListingPage,
    sidebar: NeighborhoodPage,
    enabled: false
  },
  {
    path: `/${ROUTE_WORKSTATIONS}`,
    exact: true,
    component: WorkstationListingPage,
    enabled: true
  },
  {
    path: `/${ROUTE_WORKSTATIONS}/map-view`,
    exact: true,
    component: WorkstationMapView,
    enabled: true
  },
  {
    path: `/${ROUTE_SETTINGS}`,
    exact: true,
    component: AdminSettingsPage,
    enabled: true
  },
  {
    path: `/${ROUTE_TEAM}`,
    exact: true,
    component: TeamPage,
    enabled: false
  },
  {
    path: `/${ROUTE_TEAM}/indicators/:indicatorName`,
    exact: true,
    component: TeamPage,
    sidebar: TeamInsightsSidebars,
    enabled: false
  },
  {
    path: `/${ROUTE_INDIVIDUAL}/:userId`,
    exact: true,
    component: IndividualPage,
    enabled: false
  },
  {
    path: `/${ROUTE_ACTIVITIES}`,
    exact: true,
    component: ActivitiesPage,
    enabled: true
  },
  {
    path: `/${ROUTE_ATTENDANCE}`,
    exact: true,
    component: Attendance,
    enabled: true
  }
];
