import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "shared/components/Sidebar/Sidebar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import CustomizedDivider from "shared/components/CustomizedDivider/CustomizedDivider";
import WeeklyAssignment from "shared/components/WeeklyAssignment";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";
import RoomIcon from "shared/assets/icons/room.svg";
import KeyIcon from "shared/assets/icons/key.svg";
import FloorIcon from "shared/assets/icons/floor.svg";
import WindowIcon from "shared/assets/icons/window.svg";
import UserIcon from "shared/assets/icons/user.svg";
import EditIcon from "shared/assets/icons/edit.svg";
import DeleteIcon from "shared/assets/icons/delete.svg";
import AddIcon from "shared/assets/icons/plus.svg";
import { useForm } from "react-hook-form";
import {
  getDataEntityItemById,
  getDayType,
  getFeatureInformation,
  getFullName,
  getNumberWithOrdinal
} from "shared/lib/getters";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBuildingFilter,
  selectAccessToken,
  selectFilteredWorkstationsList,
  selectSiteFilter,
  selectUserManagementList,
  selectWorkstationWeeklyPlan
} from "core/selectors";
import {
  useAssignDailySeatMutation,
  useDeleteWorkstationMutation,
  useWeeklyPlanMutation,
  useGetWorkstationDailySeatPlanQuery,
  useRemoveDailyAssignmentMutation
} from "features/workstations/workstationApiSlice";
import { selectCompanyId } from "core/selectors/siteSelectors";
import { setWorkstationWeeklyPlanList } from "features/workstations/workstationsSlice";
import { format } from "date-fns";
import { upsert } from "shared/lib/upsert";
import ConfirmDialog from "shared/components/Dialogs/ConfirmDialog";
import WorkstationPage from "../WorkstationPage/WorkstationPage";
import moment from "moment";
import TooltipText from "shared/components/CustomizedTooltip/TooltipText";
import { Tab, Tabs } from "@mui/material";
import { DAY_TYPES } from "shared/constants";

export const WEEKLY_TAB = {
  thisWeek: 1,
  nextWeek: 2
};

export const START_DATE = moment().startOf("week").toDate();
export const END_DATE = moment().endOf("week").toDate();

export const NEXT_START_DATE = moment().startOf("week").add(1, "week").toDate();
export const NEXT_END_DATE = moment().endOf("week").add(1, "week").toDate();

const deleteWorkstationText = (
  <div>
    <span>If you choose to delete this workstation:</span>
    <ul>
      <li>It will no longer be visible in the BWith app</li>
      <li>Users will not be able to see who is seated here</li>
      <li>BWith will not be able to assign users here</li>
      <li>All attributes such as windows, owner etc. will be deleted</li>
    </ul>
    <div style={{ marginTop: "16px" }}>
      <span>
        Note that in case you don’t want this workstation to be in use you can simply click the edit icon and change its
        ‘Assignable’ state to ‘Not in use’.
      </span>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const site = selectSiteFilter(state);
  const building = selectBuildingFilter(state);
  const companyId = selectCompanyId(state);
  const workstationList = selectFilteredWorkstationsList(state);
  const userList = selectUserManagementList(state);
  const token = selectAccessToken(state);
  const weeklyPlan = selectWorkstationWeeklyPlan(state);

  return {
    site,
    building,
    companyId,
    workstationList,
    userList,
    token,
    weeklyPlan
  };
};

const WorkstationInfoPage = ({ history, isOpen, setIsOpen, workstationId }) => {
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [ownerName, setOwnerName] = useState("");
  const [showDeleteDialog, setShowDialog] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showAddSidebar, setShowAddSidebar] = useState(false);
  const [isCloseOnResolve, setCloseOnResolve] = useState(true);
  const [tab, setTab] = useState(WEEKLY_TAB.thisWeek);

  const dispatch = useDispatch();
  const { site, building, companyId, workstationList, userList, token, weeklyPlan } = useSelector(mapStateToProps);

  const workstation = getDataEntityItemById(workstationList, workstationId);

  const { handleSubmit, control } = useForm({ mode: "onChange", defaultValues: { dailyUser: "" } });

  const [assignDailySeat, { isLoading: isAssignLoading, error: assignError, isSuccess: isSuccessAssigned }] =
    useAssignDailySeatMutation();
  const [removeDailyAssignment, { isLoading: isRemoveLoading, error: removeError }] =
    useRemoveDailyAssignmentMutation();
  const [deleteWorkstation, { error, isSuccess: isSuccessDeleted, isLoading }] = useDeleteWorkstationMutation();
  const {
    data,
    isLoading: isWeeklyDataLoading,
    refetch
  } = useGetWorkstationDailySeatPlanQuery(
    { site, workstationId: workstation.workstationId },
    { skip: !token, refetchOnMountOrArgChange: true }
  );
  const [getWeeklyPlan, { data: weeklyPlanData }] = useWeeklyPlanMutation();

  const weeklyPlanByTabHandler = useCallback(
    (tab) => {
      if (!workstation?.ownerId) return;

      if (tab === WEEKLY_TAB.thisWeek) {
        getWeeklyPlan({
          employeeId: workstation?.ownerId,
          from: format(START_DATE, "yyyy-MM-dd"),
          to: format(END_DATE, "yyyy-MM-dd")
        });
      } else if (tab === WEEKLY_TAB.nextWeek) {
        getWeeklyPlan({
          employeeId: workstation?.ownerId,
          from: format(NEXT_START_DATE, "yyyy-MM-dd"),
          to: format(NEXT_END_DATE, "yyyy-MM-dd")
        });
      }
    },
    [getWeeklyPlan, workstation?.ownerId]
  );

  useEffect(() => {
    weeklyPlanByTabHandler(tab);
  }, [tab]);

  useEffect(() => {
    if (data?.length && !isWeeklyDataLoading) {
      const mappedData = data.map((item) => {
        let currentUser = null;
        if (item.employeeId) {
          currentUser = userList.find((user) => user.id === item.employeeId);
        }

        return {
          ...item,
          day: format(new Date(item.date), "EEEE"),
          userName: currentUser?.displayName || getFullName(currentUser?.firstName, currentUser?.lastName),
          status: currentUser?.status,
          image: currentUser?.image
        };
      });
      dispatch(setWorkstationWeeklyPlanList(mappedData));
    }
  }, [data, isWeeklyDataLoading]);

  useEffect(() => {
    if (workstation?.ownerId) {
      const owner = userList.find((user) => user.id === workstation.ownerId);
      if (owner) {
        setOwnerName(getFullName(owner.firstName, owner.lastName));
      }
    }
  }, [workstation]);

  const handleChangeRoom = (item, date, reason) => {
    const data = { item, date };

    upsert({ array: selectedUsers, element: data, setState: setSelectedUsers, reason });
  };

  const handleApplySelection = async (user, date) => {
    try {
      if (!user?.id) return;
      setCloseOnResolve(false);
      handleChangeRoom(user, date, "clear");

      await fetchData({ employeeId: user.id, seatId: workstationId, date, dayType: DAY_TYPES.office });
      await refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const onEditClick = () => {
    setIsEdit(true);
    setShowEditSidebar(true);
  };

  const onAddClick = () => {
    setIsEdit(false);
    setShowAddSidebar(true);
  };

  const onDelete = async () => {
    try {
      await deleteWorkstation({
        companyId,
        workstationId,
        site,
        building,
        floor: workstation.floor,
        roomName: workstation.roomName,
        ownerId: workstation.ownerId
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveAssignmentClick = async (date, employeeId) => {
    try {
      await removeDailyAssignment({ date, workstationId });
      await refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async (payload) => {
    try {
      await assignDailySeat(payload);
    } catch (e) {
      console.error("ERROR", e);
    }
  };

  function getAllData(URLs) {
    return Promise.allSettled(URLs.map(fetchData));
  }

  const onSubmit = () => {
    setCloseOnResolve(true);

    if (selectedUsers.length) {
      const mappedData = selectedUsers.map((user) => {
        return { date: user.date, seatId: workstationId, employeeId: user.item.id, dayType: DAY_TYPES.office };
      });
      getAllData(mappedData);
      return;
    }
    setIsSuccess(true);
  };

  const onChangeTab = (_event, newTab) => {
    setTab(newTab);
  };

  const renderTooltipTitle = useMemo(() => {
    if (!weeklyPlanData?.length) return null;

    return (
      <TooltipText
        title={"Weekly plan"}
        message={weeklyPlanData.map((item) => `${moment(item.date).format("dddd")} - ${getDayType(item.dayType)}`)}
      />
    );
  }, [weeklyPlanData]);

  return (
    <div>
      <Sidebar
        history={history}
        isDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shouldValidate
        resolveLabel="Ok"
        onResolve={handleSubmit(onSubmit)}
        isLoading={isLoading || isAssignLoading || isRemoveLoading}
        error={error || assignError || removeError}
        isSuccess={isSuccess || isSuccessDeleted || (isSuccessAssigned && isCloseOnResolve)}
      >
        <Box>
          <Typography variant="h4" fontWeight="700">
            Workstation info
          </Typography>
        </Box>
        <Box mt={2}>
          <Box sx={styles.tabsContainerStyle}>
            <Tabs
              value={tab}
              onChange={onChangeTab}
              textColor="secondary"
              indicatorColor="secondary"
              sx={styles.tabsStyle}
            >
              <Tab
                value={WEEKLY_TAB.thisWeek}
                label={`This week, ${moment(START_DATE).format("DD")} - ${moment(END_DATE).format("DD/MM/YYYY")}`}
                sx={styles.tabItemStyle}
              />
              {/* <Tab
                value={WEEKLY_TAB.nextWeek}
                label={`Next week, ${moment(NEXT_START_DATE).format("DD")} - ${moment(NEXT_END_DATE).format(
                  "DD/MM/YYYY"
                )}`}
                sx={styles.tabItemStyle}
              /> */}
            </Tabs>
          </Box>

          <Grid container my={2} display="flex" justifyContent="space-between" position="relative">
            {weeklyPlan?.length || isWeeklyDataLoading ? (
              weeklyPlan.map((item, index) => {
                const { day, userName, status, date, employeeId, image } = item;
                const isLastIndex = weeklyPlan.length - 1 === index;
                return (
                  <React.Fragment key={day}>
                    <Grid item xs={2.3}>
                      <WeeklyAssignment
                        day={day}
                        user={employeeId}
                        userName={userName}
                        status={status}
                        image={image}
                        index={index.toString()}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        handleChangeRoom={handleChangeRoom}
                        handleApplySelection={handleApplySelection}
                        selectedRooms={selectedUsers}
                        onRemoveAssignmentClick={onRemoveAssignmentClick}
                        date={date}
                        control={control}
                        seatId={employeeId}
                        isLoading={isWeeklyDataLoading}
                        disabled={isAssignLoading}
                      />
                    </Grid>
                    {!isLastIndex && (
                      <Divider orientation="vertical" flexItem variant="middle" sx={{ margin: "16px 0" }} />
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <Typography variant="h6" color="#6B6C72" textAlign={"center"} pb={3}>
                No weekly for next week generated, yet.
              </Typography>
            )}
          </Grid>
          <InfoContainer selectedIndex={selectedIndex}>
            <CustomizedDivider bottomWidth="2px" hrColor="#8D9096" />
            <CustomizedLabel text="Workstation info" variant="h5" />
            <Grid container mt={2} display="flex" justifyContent="space-between">
              <Grid item xs={5} my={1.5}>
                <InfoItem>
                  <Icon src={RoomIcon} />
                  <Typography>Room {workstation?.roomName}</Typography>
                </InfoItem>
                <CustomizedDivider />
              </Grid>
              <Grid item xs={5} my={1.5}>
                <InfoItem>
                  <Icon src={KeyIcon} />
                  <Typography>{workstation.roomType} room</Typography>
                </InfoItem>
                <CustomizedDivider />
              </Grid>
              <Grid item xs={5} my={1.5}>
                <InfoItem>
                  <Icon src={FloorIcon} />
                  <Typography>{getNumberWithOrdinal(workstation?.floor)} Floor</Typography>
                </InfoItem>
                <CustomizedDivider />
              </Grid>
              <Grid item xs={5} my={1.5}>
                <InfoItem>
                  <Icon src={WindowIcon} />
                  <Typography>{workstation?.windows} Windows</Typography>
                </InfoItem>
                <CustomizedDivider />
              </Grid>
              {workstation?.seatFeatures?.map((feature) => {
                const { name, icon } = getFeatureInformation(feature);
                if (!icon) return null;
                return (
                  <Grid item xs={5} my={1.5} key={feature}>
                    <InfoItem>
                      <Icon src={icon} />
                      <Typography>{name}</Typography>
                    </InfoItem>
                    <CustomizedDivider />
                  </Grid>
                );
              })}
              {ownerName && (
                <Grid item xs={5} my={1.5}>
                  <InfoItem>
                    <Icon src={UserIcon} />
                    <Typography>{ownerName}</Typography>

                    {weeklyPlanData?.length ? (
                      <Tooltip arrow title={renderTooltipTitle} placement="top">
                        <PlanText>{"Plan"}</PlanText>
                      </Tooltip>
                    ) : null}
                  </InfoItem>
                </Grid>
              )}
            </Grid>
          </InfoContainer>
          <Box mt={2}>
            <CustomizedDivider bottomWidth="2px" hrColor="#8D9096" />
            <CustomizedLabel text="Actions" variant="h5" />
            <Box>
              <Box display="flex" justifyContent="center">
                <EditButton onClick={onEditClick} disabled={isLoading}>
                  <CustomizedIcon src={EditIcon} />
                  <Typography>Edit workstation</Typography>
                </EditButton>
              </Box>
              <Box display="flex" justifyContent="center">
                <EditButton onClick={() => setShowDialog(true)} disabled={isLoading}>
                  <CustomizedIcon src={DeleteIcon} />
                  <Typography>Delete workstation</Typography>
                </EditButton>
              </Box>
              <Box display="flex" justifyContent="center">
                <EditButton onClick={onAddClick} disabled={isLoading}>
                  <CustomizedIcon src={AddIcon} />
                  <Typography>Add workstation</Typography>
                </EditButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Sidebar>
      <ConfirmDialog
        showDialog={showDeleteDialog}
        resolve={onDelete}
        reject={() => setShowDialog(false)}
        title="Delete Workstation"
        text={deleteWorkstationText}
        rowName={workstation.roomName}
        rowId={workstation.workstationId}
        setShowDialog={setShowDialog}
      />
      {showEditSidebar && (
        <WorkstationPage
          history={history}
          isOpen={showEditSidebar}
          setIsOpen={setShowEditSidebar}
          workstationId={workstationId}
          isEdit={isEdit}
        />
      )}
      {showAddSidebar && (
        <WorkstationPage history={history} isOpen={showAddSidebar} setIsOpen={setShowAddSidebar} isEdit={isEdit} />
      )}
    </div>
  );
};

const InfoContainer = styled(Box)(({ selectedIndex }) => ({
  marginTop: selectedIndex ? "170px" : "8px",
  transition: "margin-top 450ms cubic-bezier(0.4, 0, 0.2, 1)",
  zIndex: 5
}));

const InfoItem = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center"
}));

const Icon = styled("img")(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  marginRight: theme.spacing(1)
}));

const EditButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  minWidth: "200px",
  justifyContent: "flex-start",
  padding: theme.spacing(1, 2, 1, 5),
  margin: theme.spacing(1, 0),
  position: "relative"
}));

const CustomizedIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(1.3)
}));

const PlanText = styled(Typography)(({ theme }) => ({
  color: "#055393",
  marginLeft: 10,
  textDecoration: "underline",
  cursor: "pointer"
}));

export const styles = {
  tabsContainerStyle: {
    width: "100%",
    paddingBottom: "24px"
  },

  tabsStyle: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "4px solid #ccc"
    },

    "& .MuiTabs-indicator": {
      height: "4px"
    }
  },

  tabItemStyle: {
    fontSize: "1.1rem",

    "&&.MuiTab-root": {
      padding: "10px 15px",
      minWidth: "120px"
    }
  }
};

WorkstationInfoPage.propTypes = {
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  workstationId: PropTypes.number,
  isMapView: PropTypes.bool
};

export default withRouter(WorkstationInfoPage);
