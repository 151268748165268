import { Box, styled } from "@mui/material";

const colorsMap = {
  selected: "#34BAE7",
  employee: "#FFAD00",
  // manager: "#D169A9",
  // topManager: "#7DDE76",
  head: "#986BE1"
};

export const classNames = {
  nodes: {
    employee: "employee-node",
    manager: "manager-node",
    topManager: "top-manager-node",
    head: "head-node"
  }
};

export const Container = styled(Box)`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

export const Scene = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  width: "100%",
  overflow: "hidden",
  height: "100%",
  paddingBottom: theme.spacing(3),

  [`& .${classNames.nodes.employee}`]: {
    width: "1rem",
    height: "1rem",
    fontSize: 0,
    color: colorsMap.employee,
    backgroundColor: colorsMap.employee
  },
  [`& .${classNames.nodes.manager}`]: {
    width: "2rem",
    height: "2rem",
    fontSize: 10,
    color: colorsMap.manager
  },
  [`& .${classNames.nodes.topManager}`]: {
    width: "3rem",
    height: "3rem",
    fontSize: 12,
    color: colorsMap.topManager
  },
  [`& .${classNames.nodes.head}`]: {
    width: "4rem",
    height: "4rem",
    fontSize: 14,
    color: colorsMap.head
  }
}));

export const styles = {
  chart: {
    width: "100%",
    height: "100%"
  }
};

export const cyStylesheet = [
  {
    selector: "core",
    style: {
      "selection-box-color": "#AAD8FF",
      "selection-box-border-color": "#8BB0D0",
      "selection-box-opacity": "0.5"
    }
  },
  {
    selector: ".highlighted",
    style: {
      "z-index": "999999"
    }
  },
  {
    selector: "node",
    style: {
      width: "mapData(score, 0, 50, 20, 60)",
      height: "mapData(score, 0, 50, 20, 60)",
      content: "data(label)",
      "font-size": "12px",
      "text-valign": "center",
      "text-halign": "center",
      "background-color": `mapData(score, 0, 10, ${colorsMap.employee}, ${colorsMap.head})`,
      "text-outline-color": "#555",
      "text-outline-width": "2px",
      color: "#fff",
      "overlay-padding": "6px",
      "z-index": "10"
    }
  },
  {
    selector: "node[?attr]",
    style: {
      shape: "rectangle",
      "background-color": "#aaa",
      "text-outline-color": "#aaa",
      width: "16px",
      height: "16px",
      "font-size": "6px",
      "z-index": "1"
    }
  },
  {
    selector: "node[?query]",
    style: {
      "background-clip": "none",
      "background-fit": "contain"
    }
  },
  {
    selector: "node:selected",
    style: {
      "border-width": "6px",
      "border-color": "#AAD8FF",
      "border-opacity": "0.5",
      "background-color": "#77828C",
      "text-outline-color": "#77828C",
      color: colorsMap.selected
    }
  },
  {
    selector: "node.highlighted",
    style: {
      "border-width": "6px",
      "border-color": "#AAD8FF",
      "border-opacity": "0.5",
      "background-color": "#394855",
      "text-outline-color": "#394855"
    }
  },
  {
    selector: "node.unhighlighted",
    style: {
      opacity: "0.2"
    }
  },
  {
    selector: "edge",
    style: {
      "curve-style": "haystack",
      "haystack-radius": "0.5",
      opacity: "0.4",
      "line-color": "#000",
      width: "mapData(weight, 0, 1, 1, 1)",
      "overlay-padding": "1px"
    }
  },
  {
    selector: "edge.highlighted",
    style: {
      "line-color": "#ddd",
      opacity: "0.05"
    }
  },
  {
    selector: "edge.unhighlighted",
    style: {
      opacity: "0.05"
    }
  },
  {
    selector: "edge.filtered",
    style: {
      opacity: "0"
    }
  },
  {
    selector: 'edge[group="coexp"]',
    style: {
      "line-color": "#d0b7d5"
    }
  },
  {
    selector: 'edge[group="coloc"]',
    style: {
      "line-color": "#a0b3dc"
    }
  },
  {
    selector: 'edge[group="gi"]',
    style: {
      "line-color": "#90e190"
    }
  },
  {
    selector: 'edge[group="path"]',
    style: {
      "line-color": "#9bd8de"
    }
  },
  {
    selector: 'edge[group="pi"]',
    style: {
      "line-color": "#eaa2a2"
    }
  },
  {
    selector: 'edge[group="predict"]',
    style: {
      "line-color": "#f6c384"
    }
  },
  {
    selector: 'edge[group="spd"]',
    style: {
      "line-color": "#dad4a2"
    }
  },
  {
    selector: 'edge[group="spd_attr"]',
    style: {
      "line-color": "#D0D0D0"
    }
  },
  {
    selector: 'edge[group="reg"]',
    style: {
      "line-color": "#D0D0D0"
    }
  },
  {
    selector: 'edge[group="reg_attr"]',
    style: {
      "line-color": "#D0D0D0"
    }
  },
  {
    selector: 'edge[group="user"]',
    style: {
      "line-color": "#f0ec86"
    }
  }
];
