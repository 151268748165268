import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItemText, MenuItem, OutlinedInput, Select, Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled, useTheme } from "@mui/material/styles";
import { MenuProps, getStyles } from "shared/components/Select/CustomizedSelectDeprecated";
import { selectFloorsFilter, selectFloorsList } from "core/selectors";
import { STORAGE_FLOORS_FILTERS } from "shared/constants";
import { setFloorsFilter } from "features/filters/filtersSlice";
import PropTypes from "prop-types";

const FloorSelector = ({ isMultiple = true }) => {
  const [floor, setFloor] = useState([]);
  const [open, setOpen] = useState(false);

  const floorList = useSelector(selectFloorsList);
  const selectedFloors = useSelector(selectFloorsFilter);
  const getSelectedValue = () => {
    if (floor.length) {
      return floor;
    } else {
      if (!isMultiple) {
        return selectedFloors.length && [selectedFloors[0]];
      }
      return selectedFloors;
    }
  };

  const data = getSelectedValue();

  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    setFloor(getSelectedValue());
  }, [selectedFloors]);

  const handleChangeFloor = (event) => {
    const {
      target: { value }
    } = event;
    setFloor(typeof value === "string" ? value.split(",") : value);
    if (!isMultiple) {
      localStorage.setItem(STORAGE_FLOORS_FILTERS, JSON.stringify([value]));
      dispatch(setFloorsFilter([value]));
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (floor?.length && isMultiple) {
      setTimeout(() => {
        const copy = [...floor];
        const payload = copy.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

        localStorage.setItem(STORAGE_FLOORS_FILTERS, JSON.stringify(payload));
        dispatch(setFloorsFilter(payload));
      }, 0);
    }
  };

  const handleOpen = () => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-evenly" minWidth="200px">
      <Typography mr={1}>Floor</Typography>
      <Select
        multiple={isMultiple}
        displayEmpty
        required
        value={data || []}
        defaultValue={floorList || []}
        onChange={handleChangeFloor}
        fullWidth
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{ minHeight: "20px" }}
        renderValue={(selected) => selected?.join(", ")}
        input={<CustomizedOutlineInput label="" size="small" isTableHeaderSelect />}
        MenuProps={MenuProps}
      >
        {floorList?.map(({ option, value }) => (
          <MenuItem key={option} value={option} style={getStyles(option, data, theme)}>
            {data?.indexOf(option) > -1 && <CheckIcon sx={{ color: "#fff", paddingRight: "8px" }} fontSize="medium" />}
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const CustomizedOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  background: "#FFF",
  color: "#00203E",
  border: "1px solid #000",
  width: "120px",
  borderRadius: theme.spacing(2.3),
  "& .MuiOutlinedInput-root": {
    "& > fieldset": {
      borderColor: "#6B6C72",
      borderWidth: "1px"
    }
  }
}));

FloorSelector.propTypes = {
  isMultiple: PropTypes.bool
};

export default FloorSelector;
