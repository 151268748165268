import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sortList: []
};

export const tableSortSlice = createSlice({
  name: "tableSort",
  initialState,
  reducers: {
    setTableSort: (state, action) => {
      const index = state.sortList.findIndex((item) => item.tableName === action.payload.tableName);

      if (index > -1) {
        const newArr = [...state.sortList];
        newArr[index] = action.payload;
        state.sortList = newArr;
      } else {
        state.sortList.push(action.payload);
      }
    }
  }
});

export const { setTableSort } = tableSortSlice.actions;

export default tableSortSlice.reducer;
