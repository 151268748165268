/**
 * @type {String}
 */
export const ROLE_PREFIX = "ROLE_";

/**
 * @type {String}
 */
export const ROLE_ADMIN = "ADMIN";

/**
 * @type {String}
 */
export const ROLE_HR = "HR";

/**
 * @type {String}
 */
export const ROLE_TEAM_MANAGER = "TEAM_MANAGER";

/**
 * @type {String}
 */
export const ROLE_USER = "USER";

/**
 * @type {String}
 */
export const ROLE_FACILITIES_ADMIN = "FACILITIES_ADMIN";

/**
 * @type {String}
 */
export const ROLE_FACILITIES_OPERATOR = "FACILITIES_OPERATOR";
