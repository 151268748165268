import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement
} from "chart.js";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { CHART_DAY_LABELS } from "shared/constants";
import CheersIcon from "shared/assets/icons/cheers.svg";
import { minLabel, minValue } from "shared/lib/getters";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  AnnotationPlugin
);

const officeChartData = [16, 16, 18, 14, 12];
const homeChartData = [16, 12, 18, 14, 12];

export const barData = {
  labels: CHART_DAY_LABELS,
  datasets: [
    {
      label: "Office",
      data: officeChartData,
      backgroundColor: "#004D66",
      stack: "Stack 0",
      barThickness: 10,
      borderRadius: 32
    },
    {
      label: "Home",
      data: homeChartData,
      backgroundColor: "#33CCFF",
      stack: "Stack 0",
      barThickness: 10,
      borderRadius: 32
    }
  ]
};

const getDifference = (arr1, arr2) => {
  return arr1.filter((x) => !arr2.includes(x));
};

export const getIncreasedIndex = (ctx) => {
  const officeDataset = ctx.chart.data.datasets[0].data;
  const homeDataset = ctx.chart.data.datasets[1].data;

  const difference = getDifference(officeDataset, homeDataset);

  return officeDataset.indexOf(difference);
};

const showAnnotation = getDifference(officeChartData, homeChartData)?.length;

const barOptions = {
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    },
    annotation: {
      annotations: [
        {
          type: "label",
          display: !!showAnnotation,
          backgroundColor: "#33CCFF",
          borderRadius: 6,
          color: "white",
          callout: {
            enabled: true,
            borderColor: "#E2E2E2"
          },
          content: (ctx) => `Onboarded: ${minLabel(ctx)}, ${minValue(ctx)}`,
          font: {
            size: 14
          },
          position: {
            x: (ctx) => (getIncreasedIndex(ctx) <= 3 ? "start" : getIncreasedIndex(ctx) >= 10 ? "end" : "center"),
            y: "center"
          },
          xAdjust: (ctx) => (getIncreasedIndex(ctx) <= 3 ? 10 : getIncreasedIndex(ctx) >= 10 ? -10 : 0),
          xValue: (ctx) => minLabel(ctx),
          yAdjust: -80,
          yValue: (ctx) => minValue(ctx)
        }
      ]
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  },
  maintainAspectRatio: false
};

const Chart = () => {
  return (
    <Grid container spacing={2} py={2} height="100%" alignItems="center" justifyContent="center">
      <Grid item xs={6}>
        <Box p={2} sx={{ height: "220px", width: "250px", margin: "0 auto" }}>
          <Bar data={barData} options={barOptions} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="center">
          <img src={CheersIcon} style={{ width: "70px" }} />
        </Box>
        <Typography variant="h6">
          The past few events which ran on Tuesdays have brought 14% more people to the office, compared to eventless
          Tuesdays. Consider scheduling more of them.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Chart;
