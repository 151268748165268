import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";
import RejectButton from "shared/components/Buttons/RejectButton/RejectButton";

const ConfirmDialog = (props) => {
  const {
    title,
    text,
    resolve,
    reject,
    rowName,
    rowId,
    acceptLabel = "Delete",
    rejectLabel = "Cancel",
    showDialog = false,
    setShowDialog,
    warningText = "This action cannot be undone."
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(showDialog);
  }, [showDialog]);

  const onResolve = () => {
    resolve(rowId);
    setOpen(false);
    setShowDialog(false);
  };

  const onReject = () => {
    reject();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setShowDialog(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "16px" }}
        PaperProps={{
          style: { borderRadius: "12px", minWidth: "450px" }
        }}
      >
        <Box display="flex" justifyContent="flex-end" pt={1}>
          <Button onClick={handleClose}>
            <CloseIcon sx={{ color: "#393A3D" }} />
          </Button>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column" mb={2}>
          <Typography variant="h4">{title}</Typography>
          {rowName?.length ? <Typography variant="h4">{rowName}?</Typography> : null}
          {warningText?.length ? (
            <Typography variant="h5" color="#6B6C72" mt={1}>
              {warningText}
            </Typography>
          ) : null}
        </Box>
        {text && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions sx={{ padding: "24px" }}>
          <RejectButton onClick={onReject} text={rejectLabel} />
          <ResolveButton text={acceptLabel} onClick={onResolve} variant="contained" autoFocus />
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmDialog.propTypes = {
  resolve: PropTypes.func,
  reject: PropTypes.func.isRequired,
  title: PropTypes.string,
  rowName: PropTypes.string,
  rowId: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  acceptLabel: PropTypes.string,
  rejectLabel: PropTypes.string,
  showDialog: PropTypes.bool,
  warningText: PropTypes.string,
  setShowDialog: PropTypes.func
};

export default ConfirmDialog;
