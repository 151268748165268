import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Sidebar from "shared/components/Sidebar/Sidebar";
import FormInputDropdown from "shared/components/FormInputs/Dropdown";
import { Divider, Grid } from "@mui/material";
import useAdminSettingsUserSidebarLogic from "./hooks/useAdminSettingsUserSidebarLogic";
import FormInputAutocomplete from "shared/components/FormInputs/Autocomplete/FormInputAutocomplete";
import FormInputCheckbox from "shared/components/FormInputs/Checkbox/FormInputCheckbox";
import { styles } from "./style/adminSettingsUserSidebarStyle";

const AdminSettingsUserSidebar = ({ userId, onClose, isOpen }) => {
  const {
    isEdit,
    onSubmit,
    reset,
    isDirty,
    control,
    usersOptions,
    resourcesOptions,
    rolesOptions,
    departmentsOptions,
    isDisableDepartments,
    isSuccess,
    isLoading,
    error
  } = useAdminSettingsUserSidebarLogic({ userId });

  return (
    <Sidebar
      isOpen={isOpen}
      setIsOpen={onClose}
      history={history}
      onResolve={onSubmit}
      resetForm={reset}
      isDirty={isDirty}
      resolveLabel={isEdit ? "Update" : "Add"}
      isDrawer={true}
      isSuccess={isSuccess}
      isLoading={isLoading}
      error={error}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="700">
            {isEdit ? "Edit Dashboard User" : "Add Dashboard User"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={1} spacing={2}>
        <Grid item xs={12}>
          <FormInputAutocomplete
            label="Search for a name to add a new user:"
            control={control}
            name="user"
            rules={{ required: "Field is required" }}
            data={usersOptions}
            placeholder="Enter name to search"
            disabled={isEdit}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInputDropdown
            label="Assign role"
            control={control}
            name="role"
            data={rolesOptions}
            placeholder="Select Role"
          />
        </Grid>

        <Grid item xs={12}>
          <FormInputDropdown
            label="Allowed departments"
            control={control}
            name="departments"
            data={departmentsOptions}
            placeholder="Select departments"
            isMultiple={true}
            disabled={isDisableDepartments}
          />
        </Grid>

        <Divider />

        <Grid item xs={12}>
          <FormInputCheckbox
            label="Resources"
            control={control}
            name="resources"
            data={resourcesOptions}
            styles={styles.checkboxStyle}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Sidebar>
  );
};

AdminSettingsUserSidebar.propTypes = {
  isOpen: PropTypes.bool,
  userId: PropTypes.number,
  onClose: PropTypes.func
};

export default AdminSettingsUserSidebar;
