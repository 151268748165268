import { mapInitialScale } from "../hooks/useMainStageLogic";

export default class CustomTooltip {
  visible = false;
  moving = false;
  details = null;
  mapScale = mapInitialScale;
  #hideTimeout = null;

  // GETs

  getVisible() {
    return this.visible;
  }

  // SETs

  setVisible(visible) {
    this.visible = visible;
    this.moving = false;
  }

  setDetails(details) {
    this.details = details;
  }

  setMapScale(scale) {
    this.mapScale = scale;
  }

  move(el, { x = 0, y = 0 }) {
    if (!el) return;

    this.#elPosition(el, { x, y });

    if (!this.moving) {
      el.innerHTML = detailsTemplate(this.details?.name, this.details?.owner, this.details?.assigned);

      this.moving = true;
    }
  }

  show(el, { x, y }) {
    if (!el) return;

    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }

    this.#elPosition(el, { x, y });
    el.style.opacity = "1";
    el.style.visibility = "visible";
  }

  hide(el) {
    if (!el) return;

    el.style.opacity = "0";
    this.hideTimeout = setTimeout(() => {
      el.style.visibility = "hidden";
    }, 200);
  }

  #elPosition(el, { x, y }) {
    const circle = this.details?.radius ? this.details.radius * 2 * this.mapScale : 20;
    const w = el.clientWidth;
    const h = el.clientHeight;
    const posX = x - w / 2;
    const posY = y - h - circle - 3;

    el.style.top = `${posY}px`;
    el.style.left = `${posX}px`;
  }
}

function detailsTemplate(roomName, owner, assigned) {
  return `
<div>
  <p class='tooltip-template-room-name'>${roomName}</p>
  <p class='tooltip-template-user'>Owner: ${owner || "-"}</p>
  <p class='tooltip-template-user'>Today: ${assigned || "-"}</p>
</div>
`;
}
