import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import FormInputText from "shared/components/FormInputs/Text/FormInputText";
import FormInputDate from "shared/components/FormInputs/Date/FormInputDate";
import FormInputTime from "shared/components/FormInputs/TIme/FormInputTime";
import Sidebar from "shared/components/Sidebar/Sidebar";
import FormInputIconCheckbox from "shared/components/FormInputs/IconChecbox/FormInputIconCheckbox";
import { setFormValues } from "shared/lib/setFormValues";
import {
  selectBuildingsList,
  selectCToken,
  selectEmployeeData,
  selectMappedDepartments,
  selectSiteFilter
} from "core/selectors";
import {
  ACTIVITY_TAG,
  useCreateInteractionMutation,
  useDeleteInteractionMutation,
  useUpdateInteractionMutation
} from "features/activities/activitiesApiSlice";
import { activitiesIconsOptions } from "pages/ActivitiesPage/utils/activitiesUtils";
import styled from "@mui/material/styles/styled";
import DataTableLite from "shared/components/DataTable/DataTableLite";
import { getFullName } from "shared/lib/getters";
import { useUsersByIdsMutation } from "features/userManagement/userManagementApiSlice";
import moment from "moment";
import FormInputDropdown from "shared/components/FormInputs/Dropdown/FormInputDropdownDeprecated";
import { INTERACTION_TYPE_SITE_EVENT } from "shared/constants";
import ConfirmDialog from "shared/components/Dialogs/ConfirmDialog";
import RemoveButton from "shared/components/Buttons/RemoveButton/RemoveButton";
import DeleteIcon from "@mui/icons-material/Delete";

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => getFullName(params.row.firstName, params.row.lastName)
  },
  {
    field: "department",
    headerName: "Department",
    flex: 1
  }
];

const defaultValues = {
  name: "",
  description: "",
  eventDate: "",
  startTime: "",
  endTime: "",
  teams: [],
  buildings: [],
  icon: ""
};

const iconStyles = { width: "45px", height: "45px", padding: "8px" };

const EventSidebar = ({ history, isOpen, setIsOpen, event, isDrawer, isEdit }) => {
  const site = useSelector(selectSiteFilter);
  const departmentsList = useSelector(selectMappedDepartments);
  const { employeeId } = useSelector(selectEmployeeData);
  const buildingsList = useSelector(selectBuildingsList);
  const cToken = useSelector(selectCToken);

  const [statusList, setStatusList] = useState([]);
  const [rows, setRows] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const [createInteraction, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, error: errorCreate }] =
    useCreateInteractionMutation();
  const [updateInteraction, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate }] =
    useUpdateInteractionMutation();
  const [usersByIds, { isLoading: isLoadingUserByIds }] = useUsersByIdsMutation();
  const [deleteInteraction, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete, error: errorDelete }] =
    useDeleteInteractionMutation();

  const {
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    formState: { isDirty }
  } = useForm({ mode: "onChange", defaultValues });

  const guestsHandler = async (guests) => {
    if (!guests?.length) return;

    const guestsIds = [];

    let acceptedCount = 0;
    let declinedCount = 0;
    let pendingCount = 0;

    for (let i = 0; i < guests.length; i++) {
      guestsIds.push(guests[i].employeeId);

      if (guests[i].response === "ACCEPTED") {
        acceptedCount = acceptedCount + 1;
      }
      if (guests[i].response === "DECLINED") {
        declinedCount = declinedCount + 1;
      }
      if (guests[i].response === "PENDING") {
        pendingCount = pendingCount + 1;
      }
    }

    setStatusList([
      { value: acceptedCount, label: "Accepted", color: "#108000" },
      { value: declinedCount, label: "Declined", color: "#D52B1E" },
      { value: pendingCount, label: "No response", color: "#00203E" }
    ]);

    try {
      const response = await usersByIds({ ids: guestsIds, cToken }).unwrap();

      const employees = response.map(({ id, firstName, department, lastName }) => ({
        id,
        firstName,
        lastName,
        department
      }));

      setRows(employees);
    } catch (error) {
      console.warn("ERROR:: usersByIds ::", { error });
    }
  };

  useEffect(() => {
    if (isEdit && event) {
      const { guests, name, icon, description, startTime, endTime } = event;

      guestsHandler(guests);
      setFormValues({
        data: {
          name,
          description,
          icon,
          startTime,
          endTime,
          eventDate: new Date(startTime)
        },
        setValue
      });
    } else {
      resetForm();
    }
  }, [event]);

  const onSubmit = (values) => {
    const start = new Date(values.eventDate);
    start.setHours(new Date(values.startTime).getHours());
    start.setMinutes(new Date(values.startTime).getMinutes());

    const end = new Date(values.eventDate);
    end.setHours(new Date(values.endTime).getHours());
    end.setMinutes(new Date(values.endTime).getMinutes());

    let formattedValue = {
      ...values,
      startTime: start,
      endTime: end,
      departments: values.teams
    };

    delete formattedValue.eventDate;
    delete formattedValue.teams;

    if (isEdit) {
      updateInteraction({
        params: formattedValue,
        id: event.id,
        type: INTERACTION_TYPE_SITE_EVENT,
        tagType: ACTIVITY_TAG.event
      });
    } else {
      formattedValue = {
        ...formattedValue,
        organizerId: employeeId,
        siteName: site
      };

      createInteraction({ params: formattedValue, type: INTERACTION_TYPE_SITE_EVENT, tagType: ACTIVITY_TAG.event });
    }
  };

  const renderStatusesCount = () => {
    if (!isEdit || !statusList?.length)
      return (
        <Box py={2}>
          <Typography>{`Add a new event to ${site}`}</Typography>
        </Box>
      );

    return (
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexWrap: "wrap" }} mt={2}>
        {statusList.map(({ value, label, color }, index) => (
          <Box
            key={`${label}-${index}`}
            textAlign="center"
            minWidth="90px"
            sx={{ marginLeft: "0 !important", paddingLeft: index >= 1 && "24px" }}
          >
            <Typography variant="h6" color={color}>
              {value}
            </Typography>
            <Typography variant="h6">{label}</Typography>
          </Box>
        ))}
      </Stack>
    );
  };

  const confirmDialogTitle = useMemo(() => {
    if (isDeleting) return "Delete event";
    if (isEdit) return "Update event";
    return "Add event";
  }, [isDeleting, isEdit]);

  const confirmDialogText = useMemo(
    () => (
      <div>
        <ConfirmationText variant="span">
          {isDeleting
            ? "You are about to delete this event. This action cannot be undone. A cancelation message will be sent to all attendees."
            : "An email invitation will be sent to all people in the teams and building you selected."}
        </ConfirmationText>
      </div>
    ),
    [isDeleting]
  );

  const onResolveSidebar = () => {
    setShowConfirmDialog(true);
  };

  const onApplyDialog = () => {
    if (isDeleting) {
      deleteInteraction({
        id: event.id,
        type: INTERACTION_TYPE_SITE_EVENT,
        tagType: ACTIVITY_TAG.group
      });
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onCancelDialog = () => {
    setShowConfirmDialog(false);
  };

  const onDelete = () => {
    setDeleting(true);
    setShowConfirmDialog(true);
  };

  const onCloseConfirmationModal = (value) => {
    setShowConfirmDialog(value);
    setDeleting(false);
  };

  return (
    <div>
      <Sidebar
        history={history}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onResolve={onResolveSidebar}
        isDirty={isDirty}
        resolveLabel={isEdit ? "Update" : "Add"}
        isDrawer={isDrawer}
        shouldValidate
        isLoading={isLoadingUpdate || isLoadingCreate || isLoadingDelete}
        error={errorUpdate || errorCreate || errorDelete}
        isSuccess={isSuccessCreate || isSuccessUpdate || isSuccessDelete}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight="700">
              {isEdit ? `View Event (${site})` : `Add Event (${site})`}
            </Typography>

            {renderStatusesCount()}
          </Grid>
        </Grid>

        {isEdit ? (
          <>
            <Divider color="#BABEC5" sx={{ marginTop: "32px" }} />

            <Box mt={3}>
              <Typography variant="h6">Who signed up</Typography>
              <MembersList>
                <DataTableLite columns={columns} rows={rows} hideFooter={true} isLoading={isLoadingUserByIds} />
              </MembersList>
            </Box>

            <Divider color="#BABEC5" sx={{ marginTop: "32px", marginBottom: "24px" }} />
          </>
        ) : null}

        <Grid container rowSpacing={1} spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              label="Event name*"
              control={control}
              name="name"
              rules={{
                required: "Event name is required",
                maxLength: { value: 32, message: "Maximum 96 characters required" }
              }}
              placeholder="Enter new name"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              label="Event description and time*"
              control={control}
              name="description"
              rules={{
                required: "Description is required",
                maxLength: { value: 96, message: "Maximum 96 characters required" }
              }}
              placeholder="Enter description"
              multiline
            />
          </Grid>

          <Grid item xs={4} style={{ zIndex: 10 }}>
            <FormInputDate
              label="Event date*"
              rules={{ required: "Event date is required" }}
              control={control}
              name="eventDate"
              minDate={moment().toDate()}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputTime
              label="Start time*"
              rules={{ required: "Event date is required" }}
              control={control}
              name="startTime"
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputTime
              label="End time*"
              rules={{ required: "Event date is required" }}
              control={control}
              name="endTime"
            />
          </Grid>

          <>
            <Grid item xs={6}>
              <FormInputDropdown
                label="Select teams*"
                control={control}
                name="teams"
                data={departmentsList}
                placeholder="Select teams"
                defaultLabel={"Select All"}
                readonly={isEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputDropdown
                label="Select buildings*"
                control={control}
                name="buildings"
                data={buildingsList}
                placeholder="Select teams"
                defaultLabel={"Select All"}
                readonly={isEdit}
              />
            </Grid>
          </>

          {/* ICONS */}
          <Grid item xs={12} mt={2}>
            <FormInputIconCheckbox
              label="Select icon"
              control={control}
              name="icon"
              data={activitiesIconsOptions}
              styles={iconStyles}
              checkedStyles={{ ...iconStyles, filter: "brightness(0) invert(1)" }}
            />
          </Grid>
        </Grid>

        {isEdit ? (
          <>
            <Divider color="#BABEC5" sx={{ marginTop: "32px" }} />

            <Box mt={3}>
              <RemoveButton onClick={onDelete}>
                <DeleteIcon fontSize="small" sx={{ color: "#D52B1E" }} />
                <Typography color="#D52B1E" pl={1}>
                  Delete session
                </Typography>
              </RemoveButton>
            </Box>
          </>
        ) : null}
      </Sidebar>
      <ConfirmDialog
        showDialog={showConfirmDialog}
        resolve={onApplyDialog}
        reject={onCancelDialog}
        acceptLabel={"OK"}
        title={confirmDialogTitle}
        warningText={""}
        text={confirmDialogText}
        rowName={null}
        rowId={null}
        setShowDialog={onCloseConfirmationModal}
      />
    </div>
  );
};

const MembersList = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  paddingTop: theme.spacing(0.5),
  marginTop: theme.spacing(2),
  height: theme.spacing(41),
  borderRadius: "3px",
  border: "1px solid #E3E5E8"
}));

const ConfirmationText = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  textAlign: "center",
  lineHeight: theme.spacing(4),
  fontSize: theme.spacing(3),
  color: "#6B6C72",
  margin: 0,
  padding: 0
}));

EventSidebar.propTypes = {
  history: PropTypes.object,
  isDrawer: PropTypes.bool,
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  setIsOpen: PropTypes.func,
  event: PropTypes.any
};

export default withRouter(EventSidebar);
