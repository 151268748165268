import React, { useState } from "react";
import styled from "@mui/material/styles/styled";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Collapse,
  createFilterOptions,
  Grid,
  Skeleton,
  TextField,
  Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactHeight } from "react-height";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import TrashIcon from "shared/assets/icons/trash.svg";
import { selectUsersByBuildingOptions } from "core/selectors";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";

const filterOptions = createFilterOptions({
  stringify: (option) => option.value
});

const mapStateToProps = (state) => ({
  usersByBuildingOptions: selectUsersByBuildingOptions(state)
});

const WeeklyAssignment = ({
  day,
  status,
  user,
  seatId,
  date,
  index,
  selectedIndex,
  setSelectedIndex,
  handleChangeRoom,
  handleApplySelection,
  selectedRooms,
  onRemoveAssignmentClick,
  isLoading,
  image,
  userName,
  disabled
}) => {
  const [containerHeight, setContainerHeight] = useState(null);

  const { usersByBuildingOptions } = useSelector(mapStateToProps);

  const currentRoomData = selectedRooms?.find((room) => room?.date === date);

  const handleExpandClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(index.toString());
    }
  };

  const getMonthAndDate = (dateStr) => {
    if (!dateStr) return "";

    return format(new Date(dateStr), "MMM, do");
  };

  const getDayName = (dateStr) => {
    if (dateStr) {
      const date = new Date(dateStr);
      const name = date.toLocaleDateString("en-US", { weekday: "long" });
      const dayOfMonth = format(new Date(dateStr), "do");
      return `${name}, ${dayOfMonth}`;
    } else {
      return "";
    }
  };

  return (
    <ReactHeight onHeightReady={(height) => setContainerHeight(height)}>
      <WeekPlanContainer expanded={selectedIndex === index}>
        {!isLoading ? (
          <Day selected={selectedIndex === index}>
            <Typography color={selectedIndex === index ? "white" : "#00203E"} fontWeight="400" variant="body1">
              {day?.toUpperCase()}
            </Typography>
          </Day>
        ) : (
          <Skeleton width="100%" height="20px" />
        )}
        {!isLoading ? (
          <>
            <Box>
              <Typography color="#8D9096" fontSize="small" variant="body1">
                {getMonthAndDate(date)}
              </Typography>
            </Box>
            <Box height={"122px"}>
              <Box my={2} height="115px" display="flex" flexDirection="column" justifyContent="space-between">
                {user ? (
                  <Box position="relative" display="flex" flexDirection="column" alignItems="center">
                    <Avatar src={image || ""} alt="User Image" sx={{ width: 60, height: 60 }} />
                    {status === "arrived" && (
                      <Status>
                        <Typography variant="caption">{status?.toUpperCase()}</Typography>
                      </Status>
                    )}
                  </Box>
                ) : (
                  <FreeToBook>
                    <Typography variant="body2" color="#8D9096" textAlign="center">
                      Free to book
                    </Typography>
                  </FreeToBook>
                )}
                <Typography variant="body1">{userName}</Typography>
              </Box>
            </Box>
            <IconButton onClick={() => handleExpandClick(index)}>
              {index === selectedIndex ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        ) : (
          <Skeleton width="100%" height={"152px"} />
        )}
      </WeekPlanContainer>
      <CustomizedCollapse
        in={selectedIndex === index}
        timeout={{ appear: "100", enter: "100", exit: "150" }}
        easing={{ enter: "200ms ease", exit: "150ms ease" }}
        unmountOnExit
        collapsedSize="500px"
        orientation="horizontal"
        containerHeight={containerHeight}
      >
        <Box p={2}>
          <Typography color="#6B6C72">{getDayName(date)}</Typography>
          {seatId || user ? (
            <>
              <Grid container width="100%" alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="h6">{`Change this day's assignment`}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <RemoveButton onClick={() => onRemoveAssignmentClick(date, seatId)} disabled={isLoading}>
                    <CustomIcon src={TrashIcon} disabled={isLoading} />
                    <Typography>Remove daily assignment</Typography>
                  </RemoveButton>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container alignItems="center" mt={1}>
              <Grid item xs={5}>
                <Typography variant="h6">{"Assign a daily user"}</Typography>
              </Grid>
              <Grid item xs={7}>
                <AssignmentRow>
                  <Autocomplete
                    disablePortal
                    forcePopupIcon={false}
                    options={usersByBuildingOptions}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => (option.value ? option.value : "")}
                    onChange={(e, item, reason) => handleChangeRoom(item, date, reason)}
                    size="small"
                    value={currentRoomData?.item}
                    fullWidth
                    disabled={disabled}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.value}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <CustomizedTextField {...params} label="" placeholder={"Enter a name to search"} />
                    )}
                  />

                  {handleApplySelection && (
                    <ResolveButton
                      text="Apply"
                      variant={!currentRoomData?.item ? "outlined" : "contained"}
                      onClick={() => handleApplySelection(currentRoomData?.item, date)}
                      disabled={!currentRoomData?.item || disabled}
                    />
                  )}
                </AssignmentRow>
              </Grid>
            </Grid>
          )}
        </Box>
      </CustomizedCollapse>
    </ReactHeight>
  );
};

const WeekPlanContainer = styled(Box)(({ theme, expanded }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(0, 0.5),
  background: expanded ? "#EDF0F4" : "inherit"
}));

const Day = styled(Box)(({ theme, selected }) => ({
  display: "flex",
  justifyContent: "center",
  background: selected ? "#00203E" : "inherit",
  borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  minWidth: theme.spacing(14),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    minWidth: theme.spacing(10)
  }
}));

const Status = styled(Box)(({ theme }) => ({
  position: "absolute",
  color: "#2CA01C",
  textAlign: "center",
  border: "2px solid #2CA01C",
  padding: theme.spacing(0, 0.5),
  borderRadius: theme.spacing(0.5),
  bottom: "-18px"
}));

const FreeToBook = styled(Box)(({ theme }) => ({
  width: "65px",
  height: "65px",
  display: "flex",
  alignItems: "center",
  border: "1px solid #DADADA",
  borderRadius: "50%"
}));

const AssignmentRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));

const CustomizedCollapse = styled(Collapse)(({ theme, containerHeight }) => ({
  width: "99% !important",
  background: "#EDF0F4",
  borderRadius: theme.spacing(0.5),
  position: "absolute",
  top: `${containerHeight}px`,
  left: theme.spacing(0.5),
  zIndex: 10,

  "&.MuiCollapse-root": {
    transition: "none !important"
  },

  "& .MuiCollapse-wrapperInner": {
    width: "100%"
  }
}));

const RemoveButton = styled(Button)(({ theme }) => ({
  color: "#393A3D",
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.2),
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 3),
  margin: theme.spacing(0.5, 0),
  width: "100%",

  "&:hover": {
    background: "rgba(255,3,3,0.04)"
  }
}));

const CustomizedTextField = styled(TextField)({
  color: "#00203E",
  background: "#FFFFFF",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#8D9096"
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#f8fafe"
  }
});

const CustomIcon = styled("img")(({ disabled }) => ({
  opacity: disabled && "0.5"
}));

WeeklyAssignment.propTypes = {
  day: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  selectedIndex: PropTypes.string.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  user: PropTypes.string,
  userName: PropTypes.string,
  status: PropTypes.string,
  image: PropTypes.string,
  handleChangeRoom: PropTypes.func,
  handleApplySelection: PropTypes.func,
  selectedRooms: PropTypes.array,
  onRemoveAssignmentClick: PropTypes.func,
  seatId: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default React.memo(WeeklyAssignment);
