export const toLowerCaseArrayStrings = (arr) => arr.map((str) => str.toLowerCase());

export const isEqualData = (prevData, currentData) => {
  return prevData && JSON.stringify(prevData) !== JSON.stringify(currentData);
};

export const getJsonFromUrl = (url) => {
  if (!url) return {};

  const query = url.search.substr(1);
  const result = {};
  query.split("&").forEach(function (part) {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const fileToBase64 = async (files) => {
  if (!files) return null;

  try {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const data = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    return {
      fileName: file.name,
      data
    };
  } catch (error) {
    console.warn("ERROR:: fileToBase64 ::", { error, files });
  }
};

export function timeoutPromise(callback, delay = 0) {
  return new Promise((resolve, _reject) => {
    setTimeout(async () => {
      const res = await callback();

      resolve(res);
    }, delay);
  });
}

export function nonZero(val) {
  return !val && isNaN(parseInt(`${val}`));
}
