import React from "react";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const RejectButton = ({ variant = "outlined", color = "secondary", text, onClick, disabled, ...props }) => (
  <CustomizedButton onClick={onClick} variant={variant} color={color} disabled={disabled} {...props}>
    {text}
  </CustomizedButton>
);

const CustomizedButton = styled(Button)(({ theme }) => ({
  border: "1px solid #6B6C72",
  borderRadius: theme.spacing(2.25),
  padding: theme.spacing(0.5, 4)
}));

RejectButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool
};

export default React.memo(RejectButton);
