import React from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const CardContainer = ({ children, p = 2, minHeight, styles, ...rest }) => {
  return (
    <Container p={p} minHeight={minHeight} sx={styles} {...rest}>
      {children}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  background: "#fff",
  borderRadius: theme.spacing(1),
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)"
}));

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  p: PropTypes.number,
  minHeight: PropTypes.string,
  styles: PropTypes.object
};

export default CardContainer;
