import React from "react";
import { Container, TitleText, CountText, Row, style } from "./style/totalArrivalsFooterStyle";
import PropTypes from "prop-types";

const TotalArrivalsFooter = ({ title, titleWidth, arrivalsData, showBorder }) => {
  return (
    <Container>
      <TitleText style={{ width: titleWidth, minWidth: titleWidth }}>{title}</TitleText>

      <Row>
        {arrivalsData?.length
          ? arrivalsData.map((item, index) => (
              <CountText key={index} style={showBorder ? style.countTextStyle : {}}>
                {item.checkInCount}
              </CountText>
            ))
          : null}
      </Row>
    </Container>
  );
};

TotalArrivalsFooter.propTypes = {
  title: PropTypes.bool,
  titleWidth: PropTypes.string,
  minWidth: PropTypes.string,
  arrivalsData: PropTypes.array,
  showBorder: PropTypes.bool
};

export default React.memo(TotalArrivalsFooter);
