import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import styled from "@mui/material/styles/styled";
import { useSelector } from "react-redux";
import { selectCurrentWorkstationsStatus } from "core/selectors";

const WorkstationsCurrentStatus = () => {
  const { available, arrived, reserved, vacant } = useSelector(selectCurrentWorkstationsStatus);

  return (
    <>
      <Box sx={{ backgroundColor: "#ECEEF1" }} my={2} px={1}>
        <Box py={2}>
          <Typography textTransform="uppercase">Current Status</Typography>
        </Box>
      </Box>
      <Grid container display="flex" alignItems="center" spacing={2}>
        <Grid item>
          <StatusBox>
            <BoldTypography variant="h3" color="#1ED400">
              {available}
            </BoldTypography>
            <Typography textTransform="uppercase" color="#1ED400">
              Available workstations
            </Typography>
          </StatusBox>
        </Grid>
        <Grid item height="112px">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <StatusBox>
            <Typography variant="h3" color="#108000">
              {arrived}
            </Typography>
            <Typography textTransform="uppercase" color="#108000">
              In use
            </Typography>
          </StatusBox>
        </Grid>
        <Grid item>
          <StatusBox>
            <Typography variant="h3" color="#007399">
              {reserved}
            </Typography>
            <Typography textTransform="uppercase" color="#8D9096">
              Reserved
            </Typography>
          </StatusBox>
        </Grid>
        <Grid item>
          <StatusBox>
            <Typography variant="h3" color="#6B6C72">
              {vacant}
            </Typography>
            <Typography textTransform="uppercase" color="#8D9096">
              Vacant
            </Typography>
          </StatusBox>
        </Grid>
      </Grid>
    </>
  );
};

const StatusBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minWidth: "180px",
  justifyContent: "center",
  border: "1px solid #6B6C72",
  borderRadius: "18px",
  padding: theme.spacing(1, 3)
}));

const BoldTypography = styled(Typography)`
  font-weight: bold;
`;

export default WorkstationsCurrentStatus;
