import React, { useMemo } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import CardContainer from "shared/components/CardContainer/CardContainer";
import CustomizedTooltip from "shared/components/CustomizedTooltip/CustomizedTooltip";
import ActivitiesGroups from "./components/ActivitiesGroups/ActivitiesGroups";
import EventsTable from "./components/EventsTable/EventsTable";
import { UsersInterests } from "./components/UsersInterests";
import SiteAndBuilding from "shared/components/Filters/SiteAndBulding/SiteAndBulding";
import SiteAndBuildingPage from "shared/components/DataTable/components/SiteAndBuildingSidebar/SiteAndBuildingSidebar";
import EventSidebar from "./components/Sidebars/EventSidebar/EventSidebar";
import GroupSidebar from "./components/Sidebars/GroupSidebar/GroupSidebar";
import InterestsSidebar from "./components/Sidebars/InterestsSidebar/InterestsSidebar";
import SessionSidebar from "./components/Sidebars/SessionSidebar";
import ViewGroupSidebar from "./components/Sidebars/ViewGroupSidebar";
import { BoxHeader, Container, ActionButton, TeamIndicatorContainer } from "./style/activitiesPageStyle";
import useActivitiesLogic from "./hooks/useActivitiesLogic";
import PageTitle from "shared/components/PageTitle";
import useInterestsLogic from "./hooks/useInterestsLogic";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { interestsChartOptions, interestsChartData } from "./utils/interestsUtils";
import ConfirmDialog from "shared/components/Dialogs/ConfirmDialog";
import { sendReminderDialogContent } from "./utils/activitiesUtils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const InterestsTooltipText = () => (
  <Box p={1}>
    <Typography variant="h6">Added by users</Typography>
    <Typography variant="h6">
      Here you can see interests which users have added through the app. Currently, only the user which added an
      interest can see it. Clicking the + sign will add it to the main list so everybody in the company will be able to
      see and select it.
    </Typography>
  </Box>
);

const ActivitiesPage = () => {
  const {
    groups,
    events,
    siteAndBuildingSidebar,
    eventsSidebarProps,
    viewGroupSidebarProps,
    groupSidebarProps,
    sessionSidebarProps,
    onAddGroup,
    onGroupClick,
    onAddSession,
    onAddEventClick,
    onEventClick,
    sendReminderDialog,
    onPressSendReminder,
    sendReminderHandler
  } = useActivitiesLogic();

  const {
    onPersonalInterests,
    onProfessionalInterests,
    interestsSidebarProps,
    personalInterestsChartData,
    professionalInterestsChartData,
    usersInterests,
    personalInterestsNames,
    onPressUserInterest
  } = useInterestsLogic();

  const renderSidebars = () => (
    <>
      <SiteAndBuildingPage isDrawer isOpen={siteAndBuildingSidebar.enable} setIsOpen={siteAndBuildingSidebar.set} />

      <EventSidebar
        isDrawer
        isOpen={eventsSidebarProps.isOpen}
        setIsOpen={eventsSidebarProps.setIsOpen}
        isEdit={eventsSidebarProps.isEdit}
        event={eventsSidebarProps.event}
      />

      <ViewGroupSidebar
        isDrawer
        isOpen={viewGroupSidebarProps.isOpen}
        setIsOpen={viewGroupSidebarProps.setIsOpen}
        group={viewGroupSidebarProps.group}
        onEditGroup={viewGroupSidebarProps.onEditGroup}
        onEditSession={viewGroupSidebarProps.onEditSession}
        onViewSession={viewGroupSidebarProps.onViewSession}
        onAddSession={viewGroupSidebarProps.onAddSession}
        onSendReminderClick={onPressSendReminder}
      />

      <GroupSidebar
        isDrawer
        isOpen={groupSidebarProps.isOpen}
        setIsOpen={groupSidebarProps.setIsOpen}
        isEdit={groupSidebarProps.isEdit}
        group={groupSidebarProps.group}
      />

      <SessionSidebar
        isDrawer
        isOpen={sessionSidebarProps.isOpen}
        setIsOpen={sessionSidebarProps.setIsOpen}
        isEdit={sessionSidebarProps.isEdit}
        group={sessionSidebarProps.group}
        session={sessionSidebarProps.session}
        onEditSession={sessionSidebarProps.onEditSession}
      />

      <InterestsSidebar
        isDrawer
        isOpen={interestsSidebarProps.isOpen}
        setIsOpen={interestsSidebarProps.setIsOpen}
        isPersonal={interestsSidebarProps.isPersonal}
        list={interestsSidebarProps.list}
        onAdd={interestsSidebarProps.onAdd}
        onDelete={interestsSidebarProps.onDelete}
      />
    </>
  );

  const renderPersonalInterestsChart = useMemo(
    () => (
      <Box p={1} sx={{ width: "auto", margin: "0 auto" }}>
        <Bar options={interestsChartOptions} data={interestsChartData(personalInterestsChartData)} />
      </Box>
    ),
    [personalInterestsChartData]
  );

  const renderProfessionalInterestsChart = useMemo(
    () => (
      <Box p={1} sx={{ width: "auto", margin: "0 auto" }}>
        <Bar options={interestsChartOptions} data={interestsChartData(professionalInterestsChartData)} />
      </Box>
    ),
    [professionalInterestsChartData]
  );

  const renderDialogs = useMemo(
    () => (
      <>
        <ConfirmDialog
          showDialog={sendReminderDialog.enable}
          resolve={sendReminderHandler}
          reject={sendReminderDialog.off}
          acceptLabel={"OK"}
          title={sendReminderDialogContent.title}
          text={sendReminderDialogContent.body}
          // warningText={sendReminderDialogContent.warningText}
          rowName={null}
          rowId={null}
          setShowDialog={sendReminderDialog.set}
        />
      </>
    ),
    [sendReminderDialog, sendReminderHandler]
  );

  return (
    <Box>
      <PageTitle title="Activities and groups" />

      <Box mb={2}>
        <Grid container display="flex" alignItems="flex-start" spacing={2} my={1}>
          <Grid item>
            <SiteAndBuilding title="Site and Team" onClick={siteAndBuildingSidebar.on} />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Container>
        {/* GROUPS */}
        <Box style={{ gridColumn: "1 / 4", gridRow: "1 / 2" }}>
          <TeamIndicatorContainer p={2}>
            <BoxHeader>
              <Typography variant="h5" fontWeight="700">
                Groups
              </Typography>
              <ActionButton onClick={onAddGroup}>New Group</ActionButton>
            </BoxHeader>
            <ActivitiesGroups groups={groups?.data} onGroupClick={onGroupClick} onAddSessionClick={onAddSession} />
          </TeamIndicatorContainer>
        </Box>

        {/* EVENTS */}
        <Box style={{ gridColumn: "4 / 7", gridRow: "1 / 2" }}>
          <TeamIndicatorContainer p={2}>
            <BoxHeader>
              <Typography variant="h5" fontWeight="700">
                Events
              </Typography>
              <ActionButton onClick={onAddEventClick}>New Event</ActionButton>
            </BoxHeader>
            <EventsTable
              events={events?.data ? events.data : []}
              onEventClick={onEventClick}
              onSendReminderClick={onPressSendReminder}
            />
            {/* TODO: */}
          </TeamIndicatorContainer>
        </Box>

        {/* Personal interests */}
        <Box style={{ gridColumn: "1 / 3", gridRow: 3 }}>
          <CardContainer styles={{ height: "100%" }}>
            <BoxHeader>
              <Typography variant="h5" fontWeight="700">
                Personal interests
              </Typography>
              <ActionButton onClick={onPersonalInterests}>Edit</ActionButton>
            </BoxHeader>

            {renderPersonalInterestsChart}
          </CardContainer>
        </Box>

        {/* Professional interests */}
        <Box style={{ gridColumn: "3 / 5", gridRow: 3 }}>
          <CardContainer styles={{ height: "100%" }}>
            <BoxHeader>
              <Typography variant="h5" fontWeight="700">
                Professional interests
              </Typography>
              <ActionButton onClick={onProfessionalInterests}>Edit</ActionButton>
            </BoxHeader>

            {renderProfessionalInterestsChart}
          </CardContainer>
        </Box>

        {/* Users interests */}
        <Box style={{ gridColumn: "5 / 7", gridRow: 3 }}>
          <CardContainer minHeight="300px" styles={{ height: "100%" }}>
            <BoxHeader>
              <Typography variant="h5" fontWeight="700">
                Added by users
              </Typography>
              <CustomizedTooltip tooltipText={InterestsTooltipText()} />
            </BoxHeader>
            <UsersInterests
              data={usersInterests}
              onPress={onPressUserInterest}
              personalInterestsNames={personalInterestsNames}
            />
          </CardContainer>
        </Box>
      </Container>

      {renderSidebars()}
      {renderDialogs}
    </Box>
  );
};

export default ActivitiesPage;
