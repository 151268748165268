import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";
import styled from "@mui/material/styles/styled";

const FormInputCheckbox = ({ name, control, label, rules, data, styles, disabled, isBoolean = false, tempLabel }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormGroup row sx={styles}>
          {label && <CustomizedLabel text={label} error={!!error} />}
          {isBoolean ? (
            <FormControlLabel
              label={tempLabel}
              control={
                <Checkbox
                  color="success"
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  disabled={disabled}
                />
              }
            />
          ) : (
            data?.map(({ checkboxValue, checkboxLabel, icon = null }, index) => (
              <FormControlLabel
                key={index}
                label={
                  icon ? (
                    <CheckboxContainer display="flex" alignItems="center">
                      <img src={icon} style={{ marginRight: "8px", minWidth: "20px", maxHeight: "25px" }} />
                      {checkboxLabel}
                    </CheckboxContainer>
                  ) : (
                    checkboxLabel
                  )
                }
                control={
                  <Checkbox
                    color="success"
                    value={checkboxValue}
                    checked={field.value?.some((existingValue) => existingValue === checkboxValue)}
                    disabled={disabled}
                    onChange={(event, checked) => {
                      if (checked) {
                        field.onChange([...field.value, event.target.value]);
                      } else {
                        field.onChange(field?.value?.filter((value) => value !== event.target.value));
                      }
                    }}
                  />
                }
              />
            ))
          )}
        </FormGroup>
      )}
    />
  );
};

const CheckboxContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    padding: "24px 0"
  }
}));

FormInputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  data: PropTypes.array,
  styles: PropTypes.object,
  isBoolean: PropTypes.bool,
  disabled: PropTypes.bool,
  tempLabel: PropTypes.string
};

export default FormInputCheckbox;
